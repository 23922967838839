// === NPM
import React, { useEffect, useState } from "react";
import { Card, Grid, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
// === LOCAL
import { ReactComponent as Calendar } from "@/assets/icons/shared/calendar.svg";
import { ReactComponent as Duration } from "@/assets/icons/shared/duration.svg";
import { ReactComponent as Location } from "@/assets/icons/shared/location.svg";
import { ReactComponent as Pet } from "@/assets/icons/shared/pet.svg";
import { ReactComponent as Star } from "@/assets/icons/shared/star.svg";
import { ReactComponent as CalendarImage } from "@/assets/icons/training/calendrier.svg";
import ViewAction from "@/components/generics/actions/ViewAction";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import { INextSessionScheduled, ISession } from "@/components/HealthAccreditationTraining/interface";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { HttpStatus } from "@/interfaces/global";
import { colors, drawerColors } from "@/resources/CssConstant";
import TrainingService from "@/services/TrainingService";
import ViewSessionDialog from "./ViewSessionDialog";

export default function NextSessionCard() {
    const [inscription, setInscription] = useState<INextSessionScheduled>(null);
    const [session, setSession] = useState<ISession>(null);

    useEffect(() => {
        getInscription();
    }, []);

    const getInscription = async () => {
        const res = await TrainingService.getNextSessionScheduled();
        if (res.status === HttpStatus.OK) {
            setInscription(res.data);
        }
    };

    const getSession = async () => {
        const res = await TrainingService.getHealthAccreditationTrainingSession(inscription.sessionUuid);
        if (res.status === HttpStatus.OK) {
            setSession(res.data);
        }
    };

    return (
        <>
            <Card>
                <StyledCardContent>
                    {inscription ? (
                        <Stack p={2}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography variant="bold" fontSize={20} color={drawerColors.text}>
                                    Ma prochaine formation planifiée
                                </Typography>
                                <ViewAction title="Voir le détail" onClick={() => getSession()} />
                            </Stack>
                            <Grid container rowSpacing={4} columnSpacing={2} m={1}>
                                <Grid item xs={12} md={4} display="flex" gap={1}>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <CalendarImage scale={2} />
                                        <Typography variant="bold" color={colors.primaryColor}>
                                            {inscription.trainingTitle}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={4} display="flex" gap={1}>
                                    <Stack spacing={1}>
                                        <GenericIconText
                                            icon={<Calendar />}
                                            iconTooltip="Date de formation"
                                            text={DateTime.fromISO(inscription?.startDate).toLocaleString(
                                                DateTime.DATETIME_MED
                                            )}
                                        />
                                        <GenericIconText
                                            icon={<Location />}
                                            iconTooltip="Adresse"
                                            text={inscription?.address}
                                        />
                                        <GenericIconText
                                            icon={<Duration />}
                                            iconTooltip="Durée"
                                            text={`${inscription?.duration}h`}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={4} display="flex" gap={1}>
                                    <Stack spacing={1}>
                                        <GenericIconText
                                            icon={<Star />}
                                            iconTooltip="Points ECTS"
                                            text={inscription?.ectsPoints}
                                        />
                                        <GenericIconText
                                            icon={<Pet height={22} />}
                                            iconTooltip="Espèces traitées"
                                            text={inscription?.species?.label}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    ) : (
                        <Typography>Aucune formation à venir</Typography>
                    )}
                </StyledCardContent>
            </Card>
            {session && <ViewSessionDialog session={session} onClose={() => setSession(null)} />}
        </>
    );
}
