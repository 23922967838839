// === NPM
import React, { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
// === LOCAL
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import { HttpStatus } from "@/interfaces/global";
import { IVaccinationSite, VaccinationInterventionType, VaccineBatch } from "@/interfaces/vaccination";
import { getEnumKeyByValue } from "@/resources/utils";
import { routerLinks } from "@/routers/RouterConstant";
import VaccinationService from "@/services/VaccinationService";
import { initFormIntervention, useFormIntervention } from "../../useFormIntervention";
import AnimalBatches from "./AnimalBatches/AnimalBatches";
import AnimalInformation from "./AnimalInformation";
import FormStepper from "./FormStepper";
import GeneralInformation from "./GeneralInformation";
import RecapDialog from "./RecapDialog";

interface FormProps {
    vaccinationSite: IVaccinationSite;
    loading: boolean;
}

export default function Form({ vaccinationSite, loading }: Readonly<FormProps>) {
    const { setForm, form } = useFormIntervention();
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState<number>(0);
    const [openConfirmCancelDialog, setOpenConfirmCancelDialog] = useState<boolean>(false);
    const [openRecapDialog, setOpenRecapDialog] = useState<boolean>(false);
    const [selectedDiluentBatches, setSelectedDiluentBatches] = useState<VaccineBatch[]>([]);
    const [selectedVaccineBatches, setSelectedVaccineBatches] = useState<VaccineBatch[]>([]);

    const formRef = useRef<HTMLButtonElement>(null);

    const steps = useMemo(() => {
        const steps = ["Intervention de vaccination", "Animaux vaccinés"];
        if (form.type === getEnumKeyByValue(VaccinationInterventionType, VaccinationInterventionType.BREEDING)) {
            steps.push("Déclaration de lots");
        }
        return steps;
    }, [form.type]);

    const handleConfirmCancel = (confirm: boolean) => {
        setOpenConfirmCancelDialog(false);
        if (confirm) {
            setForm(initFormIntervention);
            navigate(routerLinks.iahp.vaccinationSite.view());
        }
    };

    const handleValidIntervention = async (certificateGeneration: boolean) => {
        if (!form.id) {
            const res = await VaccinationService.postVaccinationIntervention(
                vaccinationSite.id,
                form,
                certificateGeneration
            );
            if (res.status === HttpStatus.CREATED) {
                toast.success("Intervention enregistrée");
                setOpenRecapDialog(false);
                navigate(routerLinks.iahp.vaccinationSite.view());
            }
        } else {
            const res = await VaccinationService.putIntervention(form.id, form, certificateGeneration);
            if (res.status === HttpStatus.OK) {
                toast.success("Intervention enregistrée");
                setOpenRecapDialog(false);
                navigate(routerLinks.iahp.vaccinationSite.view());
            }
        }
    };

    const handleNextStep = () => {
        setActiveStep((prev) => prev + 1);
    };

    const handleAnimalInformation = () => {
        if (form.type === getEnumKeyByValue(VaccinationInterventionType, VaccinationInterventionType.HATCHERY)) {
            setOpenRecapDialog(true);
            return;
        }
        handleNextStep();
    };

    const renderSkeleton = () => (
        <Stack spacing={2}>
            <Skeleton variant="rounded" height={30} width={250} />
            <Skeleton variant="rounded" height={200} />
            <Skeleton variant="rounded" height={30} width={250} />
            <Skeleton variant="rounded" height={150} />
            <Skeleton variant="rounded" height={30} width={250} />
            <Skeleton variant="rounded" height={250} />
        </Stack>
    );
    const renderSteps = () => {
        switch (activeStep) {
            case 0:
                return (
                    <GeneralInformation vaccinationSite={vaccinationSite} formRef={formRef} onValid={handleNextStep} />
                );
            case 1:
                return (
                    <AnimalInformation
                        formRef={formRef}
                        onValid={handleAnimalInformation}
                        selectedVaccineBatches={selectedVaccineBatches}
                        selectedDiluentBatches={selectedDiluentBatches}
                        setSelectedVaccineBatches={setSelectedVaccineBatches}
                        setSelectedDiluentBatches={setSelectedDiluentBatches}
                    />
                );
            case 2:
                return (
                    <AnimalBatches
                        formRef={formRef}
                        vaccinationDate={vaccinationSite.date}
                        onValid={() => setOpenRecapDialog(true)}
                    />
                );
        }
    };
    return (
        <>
            <Stack spacing={2} width="100%">
                <Typography variant="h4">
                    Intervention de vaccination{" "}
                    {form.type === getEnumKeyByValue(VaccinationInterventionType, VaccinationInterventionType.HATCHERY)
                        ? "au couvoir"
                        : "en élevage"}
                </Typography>
                <FormStepper
                    activeStep={activeStep}
                    handleNextStep={() => {
                        formRef.current.click();
                    }}
                    handlePreviousStep={() => setActiveStep(activeStep - 1)}
                    setOpenConfirmCancelDialog={setOpenConfirmCancelDialog}
                    steps={steps}
                >
                    <Box sx={{ flexGrow: 1, my: 4 }}>{loading ? renderSkeleton() : renderSteps()}</Box>
                </FormStepper>
                {openConfirmCancelDialog && (
                    <GenericConfirmDialog
                        title="Annuler la saisie"
                        message="Êtes-vous sûr de vouloir annuler ? Vous allez perdre toutes les informations saisies."
                        onClose={handleConfirmCancel}
                    />
                )}
                {openRecapDialog && (
                    <RecapDialog
                        onClose={() => setOpenRecapDialog(false)}
                        vaccinationSite={vaccinationSite}
                        vaccineBatches={selectedVaccineBatches}
                        diluentBatches={selectedDiluentBatches}
                        onValid={handleValidIntervention}
                    />
                )}
            </Stack>
        </>
    );
}
