// === NPM
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled, Tab, Tabs } from "@mui/material";
import { useAuth } from "@/routers/useAuth";
import { Preference, UserSituation } from "./PermissionConstant";

// === LOCAL
const StyledTab = styled(Tab)(`
    &.Mui-selected {
      font-family: 'Nunito-Bold';
    }
  `);

interface ITabConfiguration {
    url: string;
    value?: string;
    label: string;
    permissions?: UserSituation[];
    preferences?: Preference[];
    id?: string;
}

interface PermissionsTabsProps {
    tabs: ITabConfiguration[];
    tabControl?: string;
}

export default function PermissionsTabs({ tabs, tabControl }: PermissionsTabsProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useAuth();

    const renderTabs = () =>
        tabs.reduce((result: ITabConfiguration[], tab: ITabConfiguration) => {
            if (tab.permissions?.length > 0 && !tab.permissions.includes(auth.userInfo.situation as UserSituation))
                return result;
            if (tab.preferences?.length > 0 && tab.preferences.every((p) => auth.userInfo.preferences[p] === false))
                return result;
            result.push(tab);
            return result;
        }, []);

    return (
        <Tabs value={tabControl ?? location.pathname} sx={{ ml: 12 }}>
            {renderTabs().map((tab) => (
                <StyledTab
                    key={tab.label}
                    label={tab.label}
                    value={tab.value ?? tab.url}
                    onClick={() => navigate(tab.url)}
                    id={tab.id}
                />
            ))}
        </Tabs>
    );
}
