// === Import: NPM
import React from "react";
import { TextField } from "@mui/material";

// === Import: LOCAL

interface Props {
    label?: string;
    name?: string;
    required?: boolean;
    value: string | number | string[];
    onChange?: any;
    size?: "medium" | "small";
    error?: boolean;
    helperText?: string;
    rows?: number;
    type?: string;
    variant?: "outlined" | "filled" | "standard";
    placeholder?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    color?: "error" | "primary" | "secondary" | "info" | "success" | "warning";
    focused?: boolean;
    maxLength?: number;
    textTransform?: "capitalize" | "uppercase" | "none" | "lowercase";
}

GenericTextField.defaultProps = {
    onChange: () => {
        console.info("No onChange method");
    },
    variant: "outlined",
};

export default function GenericTextField(props: Props) {
    return (
        <TextField
            fullWidth
            variant={props.variant}
            label={props.label}
            name={props.name}
            required={props.required}
            value={props.value}
            onChange={props.onChange}
            size={props.size}
            error={props.error}
            helperText={props.helperText}
            multiline={!!props.rows}
            rows={props.rows}
            type={props.type}
            placeholder={props.placeholder}
            disabled={props.disabled}
            focused={props.focused}
            inputProps={{
                maxLength: props.maxLength,
                style: { textTransform: props.textTransform, resize: props.rows ? "vertical" : "none" },
            }}
        />
    );
}
