// === Import: NPM
import React from "react";
import { Card } from "@mui/material";
// === Import: LOCAL
import { StyledCardContent } from "@/components/styled/StyledCardContent";

interface GenericCardProps {
    children: JSX.Element;
}

export default function GenericCard(props: GenericCardProps) {
    return (
        <Card sx={{ display: "flex", flexGrow: 1, flexDirection: "column", mt: 2, p: 2 }}>
            <StyledCardContent sx={{ height: "100%" }}>{props.children}</StyledCardContent>
        </Card>
    );
}
