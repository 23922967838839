// === Import: NPM
import React from "react";
import { Box, Tooltip } from "@mui/material";
// === Import: LOCAL
import { ReactComponent as MessageRead } from "@/assets/icons/notification/notificationRead.svg";
import { ReactComponent as MessageUnread } from "@/assets/icons/notification/notificationUnread.svg";
import IconActionButton from "@/components/generics/actions/IconActionButton";
import { useProvideGlobal } from "@/context/useGlobalContext";
import { colors } from "@/resources/CssConstant";

interface NotificationReadStatusProps {
    read: boolean;
    onClick: () => void;
}

export default function NotificationReadStatus({ read, onClick }: Readonly<NotificationReadStatusProps>) {
    const { loadingTable, loadingButton } = useProvideGlobal();
    return read ? (
        <Box
            sx={{
                color: loadingTable || loadingButton ? colors.disabled : colors.primaryColor,
                display: "flex",
                alignItems: "center",
                p: "8px",
            }}
        >
            <Tooltip title="Vous avez lu cette notification">
                <MessageRead />
            </Tooltip>
        </Box>
    ) : (
        <IconActionButton
            title={"Marquer cette notification comme lue"}
            onClick={() => onClick()}
            icon={<MessageUnread />}
        />
    );
}
