// === NPM
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Add } from "@mui/icons-material";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { GridRowParams, GridSortModel, GridValueFormatterParams } from "@mui/x-data-grid-pro";
// === LOCAL
import ArchiveAction from "@/components/generics/actions/ArchiveAction";
import EditAction from "@/components/generics/actions/EditAction";
import GenericButton from "@/components/generics/buttons/GenericButton";
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import PermissionsCheck from "@/components/generics/PermissionsCheck";
import GenericTable from "@/components/generics/table/GenericTable";
import useTimeout from "@/hooks/useTimeout";
import { ActionsColumnProps, FilterType, HttpStatus, IPagination, SortDirection } from "@/interfaces/global";
import { IAgency } from "@/interfaces/user";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { colors } from "@/resources/CssConstant";
import { UserSituation } from "@/resources/PermissionConstant";
import { createPayload, hasAccess } from "@/resources/utils";
import { useAuth } from "@/routers/useAuth";
import UserService from "@/services/UserService";
import { IFamDamUsersFilters } from "../../../interface";
import { LocalAgencyFilters } from "../FamDam";
import AgencyDialog from "./AgencyDialog";

interface FiltersAgency {
    id: string;
    name: string;
    active: string;
}

interface AgencyProps {
    getActiveAgencies: () => void;
    setLocalAgencyFilters: Dispatch<SetStateAction<LocalAgencyFilters>>;
    setFamDamUsersFilters: Dispatch<SetStateAction<IFamDamUsersFilters>>;
}

export default function Agency({
    getActiveAgencies,
    setLocalAgencyFilters,
    setFamDamUsersFilters,
}: Readonly<AgencyProps>) {
    const auth = useAuth();
    const [agencies, setAgencies] = useState<IAgency[]>([]);
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [inputFilters, setInputFilters] = useState<FiltersAgency>({
        id: "",
        name: "",
        active: "",
    });
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "id", sort: "asc" }]);
    const [selectedAgencyId, setSelectedAgencyId] = useState<number>(null);
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState<boolean>(false);
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);

    const selectedAgency = agencies.find((a) => a.id === selectedAgencyId);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getAgencies();
    }, [pagination, sortModel]);

    const getAgencies = async () => {
        const res = await UserService.getAgencies({
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        });
        if (res.status === HttpStatus.OK) {
            setAgencies(res.data);
            setRowCount(+res.headers[CALYPSO_HEADERS.TABLE_COUNT]);
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ ...pagination, pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const handleArchiveAgency = async (confirm: boolean) => {
        if (!confirm) {
            setOpenConfirmDialog(false);
            setSelectedAgencyId(null);
            return;
        }
        const res = await UserService.archiveAgency(selectedAgencyId);
        if (res.status === HttpStatus.OK) {
            toast.success("Groupe archivé avec succès");
            setOpenConfirmDialog(false);
            setSelectedAgencyId(null);
            getAgencies();
            getActiveAgencies();
        }
    };

    const handleUpdateAgency = async (agency: IAgency) => {
        const res = await UserService.updateAgency(selectedAgencyId, agency);
        if (res.status === HttpStatus.OK) {
            setOpenUpdateDialog(false);
            toast.success("Groupe modifié avec succès");
            getAgencies();
            getActiveAgencies();
        }
    };

    const handleCreateAgency = async (agency: IAgency) => {
        const res = await UserService.postAgency(agency);
        if (res.status === HttpStatus.CREATED) {
            setOpenCreateDialog(false);
            toast.success("Groupe créé avec succès");
            getAgencies();
            getActiveAgencies();
        }
    };

    const columns = [
        {
            field: "id",
            headerName: "N° du groupe",
            flex: 0.5,
        },
        {
            field: "name",
            headerName: "Nom",
            flex: 1,
        },
        {
            field: "active",
            headerName: "Statut",
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams<string>) => (params.value ? "Actif" : "Inactif"),
        },
        {
            ...ActionsColumnProps,
            width: 100,
            hide: !hasAccess(auth.userInfo.situation, [UserSituation.ADMIN_CALYPSO]),
            renderCell: (params) => (
                <Box>
                    {params.row.active && (
                        <>
                            <PermissionsCheck requiredPermissions={[UserSituation.ADMIN_CALYPSO]}>
                                <EditAction
                                    title="Modifier"
                                    onClick={() => {
                                        setOpenUpdateDialog(true);
                                        setSelectedAgencyId(params.row.id);
                                    }}
                                />
                            </PermissionsCheck>
                            <PermissionsCheck requiredPermissions={[UserSituation.ADMIN_CALYPSO]}>
                                <ArchiveAction
                                    title="Archiver"
                                    onClick={() => {
                                        setOpenConfirmDialog(true);
                                        setSelectedAgencyId(params.row.id);
                                    }}
                                />
                            </PermissionsCheck>
                        </>
                    )}
                </Box>
            ),
        },
    ];

    const filterConfigurations: FilterConfigurations<FiltersAgency> = {
        id: { label: "N° du groupe", type: FilterType.INPUT },
        name: { label: "Nom", type: FilterType.INPUT },
        active: {
            label: "Statut",
            type: FilterType.SINGLE_SELECT,
            values: [
                { label: "Actif", key: "true" },
                { label: "Inactif", key: "false" },
            ],
        },
    };

    return (
        <>
            <Stack height="100%" spacing={2} width="100%">
                <Typography variant="h4">Gestion des groupes</Typography>
                <Box justifyContent="flex-end" display="flex">
                    <PermissionsCheck requiredPermissions={[UserSituation.ADMIN_CALYPSO]}>
                        <GenericButton
                            label="Créer un groupe"
                            startIcon={<Add />}
                            onClick={() => {
                                setOpenCreateDialog(true);
                            }}
                        />
                    </PermissionsCheck>
                </Box>
                <Card>
                    <CardContent
                        sx={{
                            "& .archived": {
                                backgroundColor: colors.lightGray,
                            },
                            "& .selected": {
                                backgroundColor: colors.background,
                            },
                        }}
                    >
                        <GenericFilters
                            inputFilters={inputFilters}
                            filterConfigurations={filterConfigurations}
                            initialValues={{
                                id: "",
                                name: "",
                                active: "",
                            }}
                            setInputFilters={setInputFilters}
                        />
                        <GenericTable
                            rows={agencies}
                            columns={columns}
                            page={pagination.page}
                            rowCount={rowCount}
                            pageSize={pagination.pageSize}
                            onPageSizeChange={handlePageSizeChange}
                            onPageChange={handlePageChange}
                            sortingMode="server"
                            paginationMode="server"
                            sortModel={sortModel}
                            onSortModelChange={(model) => setSortModel(model)}
                            sortingOrder={[SortDirection.ASC, SortDirection.DESC]}
                            filterMode="server"
                            autoHeight
                            getRowClassName={(params) =>
                                params.row.id === selectedAgencyId ? "selected" : !params.row.active ? "archived" : ""
                            }
                            onRowClick={(params: GridRowParams) => {
                                setLocalAgencyFilters((prev) => ({ ...prev, agencyName: params.row.name }));
                                setFamDamUsersFilters((prev) => ({ ...prev, agencyName: params.row.name }));
                                setSelectedAgencyId(params.row.id);
                            }}
                        />
                    </CardContent>
                </Card>
            </Stack>

            {openConfirmDialog && (
                <GenericConfirmDialog
                    title="Archivage d'un groupe"
                    message={`Êtes-vous sûr de vouloir archiver le groupe ${selectedAgency?.name} ?`}
                    onClose={handleArchiveAgency}
                />
            )}
            {openUpdateDialog && (
                <AgencyDialog
                    title={`Modification de ${selectedAgency?.name}`}
                    currentAgency={selectedAgency}
                    onClose={() => setOpenUpdateDialog(false)}
                    onValid={handleUpdateAgency}
                />
            )}

            {openCreateDialog && (
                <AgencyDialog
                    title="Créer un groupe"
                    onClose={() => setOpenCreateDialog(false)}
                    onValid={handleCreateAgency}
                />
            )}
        </>
    );
}
