// === NPM
import React from "react";
import { SellOutlined } from "@mui/icons-material";
import { Card, Divider, Stack, Typography } from "@mui/material";
import * as DOMPurify from "dompurify";
// === LOCAL
import { ReactComponent as Message } from "@/assets/icons/notification/message.svg";
import { ReactComponent as Calendar } from "@/assets/icons/shared/calendar.svg";
import { ReactComponent as Document } from "@/assets/icons/shared/paper.svg";
import { ReactComponent as User } from "@/assets/icons/shared/user.svg";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import GenericTitleValueText from "@/components/generics/text/GenericTitleValueText";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { DisplayType, IAttachment } from "@/interfaces/notification";
import { toLocaleDateFormat } from "@/resources/utils";
import { IUserNotificationDetails } from "../interface";
import DownloadLink from "./DownloadLink";

interface DetailsDialogProps {
    onClose: () => void;
    notificationDetails: IUserNotificationDetails;
}

export default function DetailsDialog({ notificationDetails, onClose }: Readonly<DetailsDialogProps>) {
    const attachmentsLabel = `${notificationDetails.attachments.length > 1 ? "Pièces jointes :" : "Pièce jointe :"}`;

    const renderContent = () => {
        return (
            <Card sx={{ p: 2 }}>
                <StyledCardContent>
                    <Stack spacing={3}>
                        <Stack spacing={1}>
                            <GenericTitleValueText
                                title="Objet"
                                value={notificationDetails.subject}
                                icon={<Message />}
                                boldTitle
                            />
                            <GenericTitleValueText
                                title="Catégorie"
                                value={DisplayType[notificationDetails.displayType]}
                                icon={<SellOutlined />}
                                boldTitle
                            />
                            <GenericTitleValueText
                                title="Émetteur"
                                value={`${notificationDetails.authorFirstname} ${notificationDetails.authorLastname}`}
                                icon={<User />}
                                boldTitle
                            />
                            <GenericTitleValueText
                                title="Date de publication"
                                value={toLocaleDateFormat(notificationDetails.publicationDate)}
                                icon={<Calendar />}
                                boldTitle
                            />
                            <Stack direction="row" spacing={1} alignItems="flex-start">
                                <GenericIconText
                                    icon={<Document />}
                                    text={attachmentsLabel}
                                    iconTooltip={attachmentsLabel}
                                    bold
                                />
                                {notificationDetails.attachments.length === 0 ? (
                                    <Typography>Aucune pièce jointe</Typography>
                                ) : (
                                    <Stack alignItems={"flex-start"}>
                                        {notificationDetails.attachments.map((attachment: IAttachment) => (
                                            <DownloadLink
                                                key={attachment.uuid}
                                                file={attachment}
                                                notificationUuid={notificationDetails.uuid}
                                            />
                                        ))}
                                    </Stack>
                                )}
                            </Stack>
                        </Stack>
                        <Divider />
                        <div
                            className="ql-editor"
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(notificationDetails.content),
                            }}
                        />
                    </Stack>
                </StyledCardContent>
            </Card>
        );
    };

    return (
        <GenericDialog
            maxWidth="lg"
            title={`Nouvelle notification : ${notificationDetails.subject}`}
            onClose={onClose}
            renderContent={() => renderContent()}
            renderActions={() => <GenericActionsDialog onClose={onClose} closeLabel="Fermer" displaySubmit={false} />}
        />
    );
}
