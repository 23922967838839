// === NPM
import React, { useState } from "react";
// === LOCAL
import { ReactComponent as Vat } from "@/assets/icons/billing/vat.svg";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import { IConstraints, IVatCreateUpdate, IVatVersion } from "@/components/VaccinationIahp/Billing/interface";
import VersionTileLayout from "../../container/VersionTileLayout";
import VatVersionForm from "./VatVersionForm";

interface VatVersionTileProps {
    vatVersion: IVatVersion;
    constraints: IConstraints;
    updateVersion: (data: IVatCreateUpdate, uuid: string) => void;
    deleteVersion: (uuid: string) => void;
}
export default function VatVersionTile({ vatVersion, constraints, updateVersion, deleteVersion }: VatVersionTileProps) {
    const [isEditing, setIsEditing] = useState<boolean>(false);

    return isEditing ? (
        <VatVersionForm
            vatVersion={vatVersion}
            constraints={constraints}
            onCancel={() => setIsEditing(false)}
            onSubmit={updateVersion}
        />
    ) : (
        <VersionTileLayout version={vatVersion} setIsEditing={setIsEditing} deleteVersion={deleteVersion}>
            <GenericIconText icon={<Vat />} iconTooltip="Taux de TVA" text={`${vatVersion.rate.toFixed(4)}%`} />
        </VersionTileLayout>
    );
}
