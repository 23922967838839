export enum FeatureFlags {
    HEALTH_REPORTING = "healthReporting",
    VACCINATION_SITES = "vaccinationSites",
    FMHS = "fmhs",
    BILLING = "billing",
}

export const hasFeatureFlag = (name: FeatureFlags): boolean => {
    const featureFlags = JSON.parse(window._env_.REACT_APP_FEATURE_FLAG);

    return featureFlags.hasOwnProperty(name) ? featureFlags[name] : false;
};
