// === NPM
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { KeyboardArrowDown } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Card,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import { DateTime } from "luxon";
// === LOCAL
import { renderVeterinaryOptions } from "@/components/AntimicrobialDisposal/ManualDelivery/containers/FormManager/containers/Form/containers/FormUtils";
import GenericAutocomplete from "@/components/generics/inputs/GenericAutocomplete";
import GenericDatePicker from "@/components/generics/inputs/GenericDatePicker";
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import GenericTextField from "@/components/generics/inputs/GenericTextField";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import useEffectAfterMount from "@/hooks/useEffectAfterMount";
import { useForm } from "@/hooks/useForm";
import useIsFirstRender from "@/hooks/useIsFirstRender";
import { IFarmMonitoring } from "@/interfaces/farm";
import { HttpStatus } from "@/interfaces/global";
import { UserWithoutProperties } from "@/interfaces/user";
import {
    IMonitoringInterventionGeneralInformation,
    IMonitoringInterventionWorkshop,
    InterventionCorrectionType,
    MonitoringInterventionStatus,
} from "@/interfaces/vaccination";
import { defaultIntegerLimit, maxIntegerValue } from "@/resources/AppConstant";
import { FORM_TEXT, PATTERN } from "@/resources/FormUtils";
import { getEnumKeyByValue } from "@/resources/utils";
import { useAuth } from "@/routers/useAuth";
import UserService from "@/services/UserService";
import { MonitoringInterventionOutletContext } from "../../interface";
import { useFormMonitoringIntervention } from "../containers/useFormMonitoringIntervention";
import WorkshopSelection from "./containers/WorkshopSelection";

interface GeneralInformationProps {
    setOpenConfirmCancelDialog: Dispatch<SetStateAction<boolean>>;
    onValid: (status: keyof typeof MonitoringInterventionStatus) => void;
    farms: IFarmMonitoring[];
    setFarms: Dispatch<SetStateAction<IFarmMonitoring[]>>;
    veterinaries: UserWithoutProperties[];
    setVeterinaries: Dispatch<SetStateAction<UserWithoutProperties[]>>;
}

export default function GeneralInformation({
    setOpenConfirmCancelDialog,
    onValid,
    farms,
    setFarms,
    veterinaries,
    setVeterinaries,
}: Readonly<GeneralInformationProps>) {
    const { serologicalTypes, horsePower, nonFullfilmentReasons } =
        useOutletContext<MonitoringInterventionOutletContext>();
    const correctionType = useLocation().state?.correctionType;
    const auth = useAuth();
    const { form, setForm } = useFormMonitoringIntervention();
    const isFirstRender = useIsFirstRender();

    const { data, errors, handleSubmit, handleChange, cleanValue } = useForm<IMonitoringInterventionGeneralInformation>(
        {
            initialValues: form?.generalInformation ?? {
                dpeId: "",
                veterinaryId: "",
                interventionDate: "",
                status: "",
                nonFullfilmentReason: "",
                serologicalSamples: serologicalTypes.map((serological) => ({
                    sampleType: serological.key,
                    planned: false,
                    realized: false,
                })),
                plateNumber: "",
                horsePower: "",
                distance: null,
                farmId: "",
            },
            validations: {
                dpeId: {
                    required: {
                        value: true,
                        message: FORM_TEXT.required,
                    },
                },
                veterinaryId: {
                    required: {
                        value: true,
                        message: FORM_TEXT.required,
                    },
                },
                interventionDate: {
                    required: {
                        value: true,
                        message: FORM_TEXT.required,
                    },
                    custom: {
                        isValid: (value) =>
                            (!isPlanned && DateTime.fromISO(value) <= DateTime.now().startOf("day")) ||
                            (isPlanned && DateTime.fromISO(value) >= DateTime.now().startOf("day")),
                        message:
                            "La date d'intervention doit être antérieure ou égale à la date actuelle si l'intervention est saisie/réalisée, et postérieure ou égale à la date actuelle si l'intervention est planifiée.",
                    },
                },
                status: {
                    custom: {
                        isValid: (value) =>
                            (plannedIntervention === "false" && value) ||
                            (plannedIntervention === "true" &&
                                value ===
                                    getEnumKeyByValue(
                                        MonitoringInterventionStatus,
                                        MonitoringInterventionStatus.PLANNED
                                    )),
                        message: FORM_TEXT.required,
                    },
                },
                nonFullfilmentReason: {
                    custom: {
                        isValid: (value) =>
                            (value &&
                                data.status ===
                                    getEnumKeyByValue(
                                        MonitoringInterventionStatus,
                                        MonitoringInterventionStatus.NOT_REALIZED
                                    )) ||
                            (!value &&
                                data.status !==
                                    getEnumKeyByValue(
                                        MonitoringInterventionStatus,
                                        MonitoringInterventionStatus.NOT_REALIZED
                                    )),
                        message: FORM_TEXT.required,
                    },
                },
                distance: {
                    pattern: {
                        value: PATTERN.positiveNumber,
                        message: "La distance doit être un nombre positif entier",
                    },
                    custom: {
                        isValid: (value) => (!isPlanned && value?.toString() && +value >= 0) || (!value && isPlanned),
                        message: FORM_TEXT.required,
                    },
                    limitValue: {
                        isValid: (value) => +value <= maxIntegerValue,
                        message: FORM_TEXT.maxInteger,
                    },
                },
                plateNumber: {
                    pattern: {
                        value: "^[A-Z0-9-]{1,15}$",
                        message:
                            "Les caractères acceptés sont des majuscules, des chiffres et des tirets, avec 15 caractères maximum",
                    },
                    custom: {
                        isValid: (value) => (!isPlanned && value) || (!value && isPlanned),
                        message: FORM_TEXT.required,
                    },
                },
                horsePower: {
                    custom: {
                        isValid: (value) => (!isPlanned && value) || (!value && isPlanned),
                        message: FORM_TEXT.required,
                    },
                },
            },
            onSubmit: () => {
                if (workshops.length === 0) return;
                setForm((prev) => ({
                    ...prev,
                    generalInformation: data,
                    workshops,
                }));
                setIsValid(true);
            },
        }
    );
    const isPlanned: boolean =
        data.status &&
        data.status === getEnumKeyByValue(MonitoringInterventionStatus, MonitoringInterventionStatus.PLANNED);
    const plannedValue = isPlanned ? "true" : "false";

    const [plannedIntervention, setPlannedIntervention] = useState<string>(
        !form || form?.generalInformation?.status === "" ? "" : plannedValue
    );
    const [workshops, setWorkshops] = useState<IMonitoringInterventionWorkshop[]>(form?.workshops ?? []);
    const [workshopError, setWorkshopError] = useState<string>("");
    const [plannedError, setPlannedError] = useState<string>("");
    const [isValid, setIsValid] = useState<boolean>(false);

    useEffectAfterMount(() => {
        resetSerologicalRealized();
        cleanValue("nonFullfilmentReason");
    }, [data.status]);

    useEffect(() => {
        if (isValid) {
            onValid(data.status);
            setIsValid(false);
        }
    }, [isValid]);

    useEffect(() => {
        if (data.dpeId) {
            if (!isFirstRender) {
                const target = {
                    target: { value: auth.userInfo.id },
                } as ChangeEvent<HTMLInputElement & HTMLSelectElement>;
                handleChange("veterinaryId")(target);
            }
            getVeterinaries(data.dpeId);
        }
    }, [data.dpeId]);

    const getVeterinaries = async (ordinalNumber: string) => {
        const responses = await UserService.getDpeUsers(ordinalNumber);
        if (responses.status === HttpStatus.OK) setVeterinaries(responses.data);
    };

    const handleVeterinarySelect = (event) => {
        const target = {
            target: { value: event.target.value?.id ?? "" },
        } as ChangeEvent<HTMLInputElement & HTMLSelectElement>;
        handleChange("veterinaryId")(target);
    };

    const handlePlannedIntervention = (event) => {
        setPlannedIntervention(event.target.value);
        if (event.target.value === "true") {
            cleanValue("nonFullfilmentReason");
            cleanValue("plateNumber");
            cleanValue("horsePower");
            cleanValue("distance");
            handleChange("status")({
                target: {
                    value: getEnumKeyByValue(MonitoringInterventionStatus, MonitoringInterventionStatus.PLANNED),
                },
            } as ChangeEvent<HTMLInputElement & HTMLSelectElement>);
        } else {
            cleanValue("status");
            handleChange("horsePower")({
                target: {
                    value: auth.userInfo.vehicle?.horsePower ?? "",
                },
            } as ChangeEvent<HTMLInputElement & HTMLSelectElement>);
            handleChange("plateNumber")({
                target: {
                    value: auth.userInfo.vehicle?.plateNumber ?? "",
                },
            } as ChangeEvent<HTMLInputElement & HTMLSelectElement>);
        }
        setForm((prev) => ({
            ...prev,
            interventionData: null,
        }));
        setWorkshops((prev) => prev.map((workshop) => ({ ...workshop, controls: null })));
    };

    const resetSerologicalRealized = () => {
        const samples = data.serologicalSamples;
        samples.forEach((sample) => {
            sample.realized =
                data.status === getEnumKeyByValue(MonitoringInterventionStatus, MonitoringInterventionStatus.REALIZED)
                    ? sample.planned
                    : false;
        });
        handleChange("serologicalSamples")({
            target: {
                value: samples,
            } as unknown,
        } as ChangeEvent<HTMLInputElement & HTMLSelectElement>);
    };

    const handleUpdateSerological = (sampleType: string, value: boolean, field: string) => {
        const samples = data.serologicalSamples;
        const index = samples.findIndex((sample) => sample.sampleType === sampleType);
        samples[index][field] = value;

        handleChange("serologicalSamples")({
            target: {
                value: samples,
            } as unknown,
        } as ChangeEvent<HTMLInputElement & HTMLSelectElement>);
    };

    const shouldDisableEdition = () => {
        if (form?.generalInformation?.id) {
            return true;
        } else {
            return !data.dpeId || !data.interventionDate || !data.veterinaryId;
        }
    };

    const renderInformations = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                        <FormControl fullWidth error={!!errors.dpeId} required>
                            <InputLabel>N° ordre - Nom du DPE</InputLabel>
                            <Select
                                label="N° ordre - Nom du DPE"
                                onChange={handleChange("dpeId")}
                                value={data.dpeId}
                                IconComponent={KeyboardArrowDown}
                            >
                                {auth.userInfo.properties.dpes
                                    .filter((dpe) => dpe.sanitaryDpe)
                                    .map((dpe) => (
                                        <MenuItem key={dpe.id} value={dpe.id}>
                                            {`${dpe.id} - ${dpe.name}`}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <FormHelperText>{errors.dpeId}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <GenericAutocomplete
                            label="N° ordre - Nom du vétérinaire"
                            options={veterinaries}
                            value={veterinaries.find((v) => v.id === data.veterinaryId) || null}
                            onChange={handleVeterinarySelect}
                            required
                            getOptionLabel={(option) =>
                                option.id ? `${option.id} - ${option.lastname} ${option.firstname}` : ""
                            }
                            renderOption={renderVeterinaryOptions}
                            error={!!errors.veterinaryId}
                            helperText={errors.veterinaryId}
                            disabled={!data.dpeId}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <GenericDatePicker
                            label="Date d'intervention"
                            value={data.interventionDate}
                            required
                            onChange={handleChange("interventionDate")}
                            name="vaccinationDate"
                            minDate={isPlanned ? DateTime.now() : undefined}
                            maxDate={!isPlanned ? DateTime.now() : undefined}
                            error={!!errors.interventionDate}
                            helperText={errors.interventionDate}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Stack>
                            <Typography variant="bold">Prélèvements planifiés</Typography>
                            <FormGroup>
                                {serologicalTypes.map((serological) => (
                                    <FormControlLabel
                                        key={serological.key}
                                        sx={{ width: "fit-content" }}
                                        control={
                                            <Checkbox
                                                checked={
                                                    data.serologicalSamples.find(
                                                        (sample) => sample.sampleType === serological.key
                                                    )?.planned
                                                }
                                                onChange={(e) =>
                                                    handleUpdateSerological(
                                                        serological.key,
                                                        e.target.checked,
                                                        "planned"
                                                    )
                                                }
                                                name={serological.key}
                                            />
                                        }
                                        label={serological.label}
                                    />
                                ))}
                            </FormGroup>
                        </Stack>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <FormControl error={!!plannedError}>
                            <Typography variant="bold">État de l'intervention</Typography>
                            <RadioGroup value={plannedIntervention} onChange={handlePlannedIntervention}>
                                <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label="Planifier une intervention"
                                    labelPlacement="end"
                                    disabled={correctionType === InterventionCorrectionType.UPDATE}
                                />
                                <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label="Saisir une intervention"
                                    labelPlacement="end"
                                    disabled={correctionType === InterventionCorrectionType.UPDATE}
                                />
                            </RadioGroup>
                            {plannedError && <FormHelperText>{plannedError}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    {plannedIntervention === "false" && (
                        <Grid item md={4} xs={12}>
                            <Stack spacing={2}>
                                <FormControl error={!!errors.status}>
                                    <Typography variant="bold">Résultat de l'intervention</Typography>
                                    <RadioGroup
                                        value={data.status}
                                        onChange={(e) => {
                                            handleChange("status")(
                                                e as ChangeEvent<HTMLInputElement & HTMLSelectElement>
                                            );
                                            setForm((prev) => ({
                                                ...prev,
                                                interventionData: null,
                                            }));
                                            setWorkshops((prev) =>
                                                prev.map((workshop) => ({ ...workshop, controls: null }))
                                            );
                                        }}
                                    >
                                        <FormControlLabel
                                            value={getEnumKeyByValue(
                                                MonitoringInterventionStatus,
                                                MonitoringInterventionStatus.REALIZED
                                            )}
                                            control={<Radio />}
                                            label={MonitoringInterventionStatus.REALIZED}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value={getEnumKeyByValue(
                                                MonitoringInterventionStatus,
                                                MonitoringInterventionStatus.NOT_REALIZED
                                            )}
                                            control={<Radio />}
                                            label={MonitoringInterventionStatus.NOT_REALIZED}
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                    {errors.status && <FormHelperText>{errors.status}</FormHelperText>}
                                </FormControl>
                            </Stack>
                        </Grid>
                    )}
                    {data.status ===
                        getEnumKeyByValue(MonitoringInterventionStatus, MonitoringInterventionStatus.REALIZED) && (
                        <Grid item md={4} xs={12}>
                            <Stack>
                                <Typography variant="bold">Prélèvements réalisés</Typography>
                                <FormGroup>
                                    {serologicalTypes.map((serological) => (
                                        <FormControlLabel
                                            key={serological.key}
                                            sx={{ width: "fit-content" }}
                                            control={
                                                <Checkbox
                                                    checked={
                                                        data.serologicalSamples.find(
                                                            (sample) => sample.sampleType === serological.key
                                                        )?.realized
                                                    }
                                                    onChange={(e) =>
                                                        handleUpdateSerological(
                                                            serological.key,
                                                            e.target.checked,
                                                            "realized"
                                                        )
                                                    }
                                                    name={serological.key}
                                                />
                                            }
                                            label={serological.label}
                                        />
                                    ))}
                                </FormGroup>
                            </Stack>
                        </Grid>
                    )}
                    {data.status ===
                        getEnumKeyByValue(MonitoringInterventionStatus, MonitoringInterventionStatus.NOT_REALIZED) && (
                        <Grid item md={4} xs={12}>
                            <GenericSelect
                                onChange={handleChange("nonFullfilmentReason")}
                                options={nonFullfilmentReasons}
                                required
                                optionsValue="key"
                                error={!!errors.nonFullfilmentReason}
                                helperText={errors.nonFullfilmentReason}
                                label="Motif de non réalisation"
                                value={data.nonFullfilmentReason}
                            />
                        </Grid>
                    )}
                </Grid>
            </StyledCardContent>
        </Card>
    );

    const renderTravelingFees = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <GenericTextField
                            label="Kilomètres parcourus (aller et retour)"
                            value={data.distance}
                            onChange={handleChange("distance")}
                            required
                            error={!!errors.distance}
                            helperText={errors.distance}
                            maxLength={defaultIntegerLimit}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <GenericTextField
                            label="Immatriculation"
                            value={data.plateNumber}
                            onChange={(e) => {
                                const value = e.target.value.toUpperCase();
                                handleChange("plateNumber")({
                                    target: {
                                        value,
                                    },
                                } as ChangeEvent<HTMLInputElement & HTMLSelectElement>);
                            }}
                            required
                            error={!!errors.plateNumber}
                            helperText={errors.plateNumber}
                            maxLength={15}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <GenericSelect
                            onChange={handleChange("horsePower")}
                            options={horsePower}
                            required
                            optionsValue="key"
                            error={!!errors.horsePower}
                            helperText={errors.horsePower}
                            label="Chevaux fiscaux"
                            value={data.horsePower}
                        />
                    </Grid>
                </Grid>
            </StyledCardContent>
        </Card>
    );

    const renderNextButton = () => {
        switch (data.status) {
            case getEnumKeyByValue(MonitoringInterventionStatus, MonitoringInterventionStatus.REALIZED):
                return "Suivant";
            case getEnumKeyByValue(MonitoringInterventionStatus, MonitoringInterventionStatus.PLANNED):
                return "Je planifie mon intervention de surveillance afin de télécharger mes fiches de prélèvement";
            case getEnumKeyByValue(MonitoringInterventionStatus, MonitoringInterventionStatus.NOT_REALIZED):
            default:
                return "Vérifier ma saisie";
        }
    };

    return (
        <form
            onSubmit={(e) => {
                setPlannedError("");
                setWorkshopError("");
                if (plannedIntervention === "") setPlannedError("Veuillez sélectionner un état d'intervention");
                if (workshops.length === 0) setWorkshopError("Veuillez sélectionner au moins un atelier");
                handleSubmit(e);
            }}
            noValidate
        >
            <Stack spacing={3} width="100%">
                <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                    Informations d'intervention
                </Typography>
                {renderInformations()}
                {data.status && !isPlanned && (
                    <>
                        <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                            Facturation des frais de déplacements
                        </Typography>
                        {renderTravelingFees()}
                    </>
                )}
                <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                    Lieu d'intervention
                </Typography>
                <WorkshopSelection
                    dpeId={data.dpeId}
                    monitoringInterventionDate={data.interventionDate}
                    workshops={workshops}
                    setWorkshops={setWorkshops}
                    farmId={data.farmId}
                    handleChange={handleChange}
                    workshopError={workshopError}
                    setWorkshopError={setWorkshopError}
                    farms={farms}
                    setFarms={setFarms}
                    disableEdition={shouldDisableEdition()}
                />
                <Box display="flex" justifyContent="flex-end" gap={2}>
                    <SecondaryButton onClick={() => setOpenConfirmCancelDialog(true)} variant="outlined">
                        Annuler
                    </SecondaryButton>
                    <LoadingButton type="submit" variant="contained" disabled={!data.status}>
                        {renderNextButton()}
                    </LoadingButton>
                </Box>
            </Stack>
        </form>
    );
}
