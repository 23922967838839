// === NPM
import React, { useEffect, useState } from "react";
import { Box, Card, Stack, Typography } from "@mui/material";
import { applyPatch } from "fast-json-patch";
// === LOCAL
import TitleTextPatchValue from "@/components/generics/text/TitleTextPatchedValue";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { HttpStatus } from "@/interfaces/global";
import { IOvvtOrganizationShort, IReferential } from "@/interfaces/referential";
import { TraceabilityActions } from "@/interfaces/traceability";
import ReferentialService from "@/services/ReferentialService";
import TraceabilityService from "@/services/TraceabilityService";
import { IOvvtTraceabilityDetails } from "../../../interface";

interface OvvtTraceabilityDetailsProps {
    traceabilityUuid: string;
    traceabilityActions: IReferential[];
}

export default function OvvtTraceabilityDetails({
    traceabilityUuid,
    traceabilityActions,
}: Readonly<OvvtTraceabilityDetailsProps>) {
    const [traceabilityDetails, setTraceabilityDetails] = useState<IOvvtTraceabilityDetails>(null);
    const [content, setContent] = useState<IOvvtOrganizationShort>(null);
    const [regions, setRegions] = useState<IReferential[]>([]);

    useEffect(() => {
        if (traceabilityUuid) getTraceabilityDetails();
    }, [traceabilityUuid]);

    useEffect(() => {
        getRegions();
    }, []);

    const getTraceabilityDetails = async () => {
        const res = await TraceabilityService.getTraceabilityOvvtDetails(traceabilityUuid);
        if (res.status === HttpStatus.OK) {
            let organizationObject;
            if (res.data.action.includes(TraceabilityActions.DELETE)) {
                organizationObject = res.data.value;
            } else {
                organizationObject = applyPatch(res.data.value, res.data.patch, false, false).newDocument;
            }
            setContent(organizationObject);
            setTraceabilityDetails(res.data);
        }
    };

    const getRegions = async () => {
        const res = await ReferentialService.getRegions();
        if (res.status === HttpStatus.OK) {
            setRegions(res.data.map((region) => ({ key: region.inseeCode, label: region.name })));
        }
    };

    return (
        <Stack width="100%" spacing={2}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h4">
                    {traceabilityDetails
                        ? traceabilityActions.find((action) => action.key === traceabilityDetails.action)?.label
                        : "Détails de traçabilité"}
                </Typography>
            </Box>

            {traceabilityDetails ? (
                <Card>
                    <StyledCardContent>
                        <Stack m={2} spacing={1}>
                            <TitleTextPatchValue
                                title="Nom"
                                value={content.name}
                                patches={traceabilityDetails.patch}
                                action={traceabilityDetails.action}
                                path="/name"
                            />
                            <TitleTextPatchValue
                                title="Identifiant externe"
                                value={content.externalId}
                                patches={traceabilityDetails.patch}
                                action={traceabilityDetails.action}
                                path="/externalId"
                            />
                            <TitleTextPatchValue
                                title="Région"
                                value={regions.find((region) => region.key === content.regionInseeCode)?.label}
                                patches={traceabilityDetails.patch}
                                action={traceabilityDetails.action}
                                path="/regionInseeCode"
                            />
                            <TitleTextPatchValue
                                title="Emails"
                                value={content.emails.join(", ")}
                                patches={traceabilityDetails.patch}
                                action={traceabilityDetails.action}
                                path="/emails"
                            />
                        </Stack>
                    </StyledCardContent>
                </Card>
            ) : (
                <Card>
                    <StyledCardContent>
                        <Typography align="center">
                            Sélectionnez une traçabilité dont vous souhaitez voir les détails
                        </Typography>
                    </StyledCardContent>
                </Card>
            )}
        </Stack>
    );
}
