// === NPM
import React, { Dispatch, SetStateAction } from "react";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    Grid,
    MobileStepper,
    Stack,
    Step,
    StepLabel,
    Stepper,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// === LOCAL
import GenericButton from "@/components/generics/buttons/GenericButton";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { colors } from "@/resources/CssConstant";
import { getEnumKeyByValue } from "@/resources/utils";
import { INotificationCreateUpdate, NotificationStatus } from "../../../interface";
import { useSelectedNotification } from "../../useSelectedNotification";

interface FormStepperProps {
    activeStep: number;
    handleNextStep: () => void;
    handlePreviousStep: () => void;
    children: JSX.Element;
    setForm: Dispatch<SetStateAction<INotificationCreateUpdate>>;
    setOpenConfirmCancelDialog: Dispatch<SetStateAction<boolean>>;
    loading: boolean;
}

export default function FormStepper({
    activeStep,
    handleNextStep,
    handlePreviousStep,
    setForm,
    setOpenConfirmCancelDialog,
    children,
    loading,
}: Readonly<FormStepperProps>) {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up("md"));

    const { selectedNotification } = useSelectedNotification();

    const steps = ["Informations générales", "Contenu de la notification", "Destinataires", "Dates de publication"];

    const displayValidateButton = activeStep === steps.length - 1;
    const isFirstStep = activeStep === 0;
    const direction = theme.direction === "rtl";

    const renderCancelButton = () => (
        <SecondaryButton variant="outlined" onClick={() => setOpenConfirmCancelDialog(true)}>
            Annuler
        </SecondaryButton>
    );

    const renderMobile = () => (
        <Stack spacing={2} justifyContent="center">
            <MobileStepper
                sx={{ display: { md: "none" }, width: "100%", mt: 0.5, borderRadius: 10 }}
                variant="dots"
                steps={steps.length}
                position="static"
                activeStep={activeStep}
                nextButton={
                    displayValidateButton ? (
                        <LoadingButton color="primary" size="small" onClick={handleNextStep} loading={loading}>
                            Valider
                        </LoadingButton>
                    ) : (
                        <Button color="primary" size="small" onClick={handleNextStep}>
                            Suivant
                            {direction ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        </Button>
                    )
                }
                backButton={
                    <Button color="primary" size="small" onClick={handlePreviousStep} disabled={isFirstStep || loading}>
                        {direction ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                        Précédent
                    </Button>
                }
            />
            <Box display="flex" justifyContent="center">
                {renderCancelButton()}
            </Box>
        </Stack>
    );

    return (
        <Stack height="100%" width="100%">
            <Grid container>
                <Grid item xs={12} sx={{ display: { xs: "none", md: "block" } }}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label) => {
                            return (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Grid>
            </Grid>
            {!md && (
                <Box sx={{ display: { xs: "block", md: "none" } }}>
                    <Typography variant="h6">{`${activeStep + 1} - ${steps[activeStep]}`}</Typography>
                </Box>
            )}
            {children}
            {!md && renderMobile()}
            <Box mt={2} justifyContent="space-between" width="100%" sx={{ display: { xs: "none", md: "flex" } }}>
                <LoadingButton
                    sx={{ background: colors.white, visibility: isFirstStep ? "hidden" : "visible" }}
                    onClick={handlePreviousStep}
                    variant="outlined"
                    disabled={loading}
                >
                    Précédent
                </LoadingButton>
                {renderCancelButton()}
                <Box>
                    {!displayValidateButton && <GenericButton label="Suivant" onClick={handleNextStep} />}
                    {displayValidateButton && (
                        <Stack spacing={5} direction="row">
                            <GenericButton
                                label={selectedNotification ? "Enregistrer" : "Créer sans publication"}
                                onClick={handleNextStep}
                                loading={loading}
                            />
                            {(selectedNotification === null ||
                                NotificationStatus[selectedNotification.status] === NotificationStatus.CREATED) && (
                                <Tooltip title="Valide et programme la notification aux dates de publication renseignées">
                                    <span>
                                        <GenericButton
                                            label="Valider pour publication"
                                            onClick={async () => {
                                                await setForm((prev) => ({
                                                    ...prev,
                                                    status: getEnumKeyByValue(
                                                        NotificationStatus,
                                                        NotificationStatus.SCHEDULED
                                                    ),
                                                }));
                                                handleNextStep();
                                            }}
                                            loading={loading}
                                        />
                                    </span>
                                </Tooltip>
                            )}
                        </Stack>
                    )}
                </Box>
            </Box>
        </Stack>
    );
}
