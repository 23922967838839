// === NPM
import React from "react";
import {
    gridPageCountSelector,
    gridPageSelector,
    gridPageSizeSelector,
    useGridApiContext,
    useGridSelector,
} from "@mui/x-data-grid-pro";
// === LOCAL
import { GenericPagination } from "../../layout/GenericPagination";

export default function CustomPagination(props: any) {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
    const rowsPerPageOptions = props.rowsPerPage;

    return (
        <GenericPagination
            page={page}
            pageCount={pageCount}
            pageSize={pageSize}
            rowsPerPageOptions={rowsPerPageOptions}
            setPageSize={(value) => apiRef.current.setPageSize(value)}
            setPage={(value) => apiRef.current.setPage(value)}
        />
    );
}
