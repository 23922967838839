// === NPM
import React from "react";
// === LOCAL
import PowerBiBox from "@/components/generics/layout/PowerBiBox";

export default function DashBoard() {
    return (
        <PowerBiBox>
            <iframe title="tableau_de_bord" width="100%" height="100%" src={window._env_.REACT_APP_PBI_DASHBOARD} />
        </PowerBiBox>
    );
}
