// === NPM
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
// === LOCAL
import { UserSituation } from "@/resources/PermissionConstant";
import PermissionsTabs from "@/resources/PermissionsTabs";
import { routerLinks } from "@/routers/RouterConstant";
import PageContent from "../generics/layout/PageContent";

export default function Parameters() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === routerLinks.parameters.base) navigate(routerLinks.parameters.dpePreferences());
    }, []);

    return (
        <>
            <PermissionsTabs
                tabs={[
                    {
                        url: routerLinks.parameters.dpePreferences(),
                        label: "Gérer mes préférences de DPE",
                        permissions: [
                            UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                            UserSituation.REGISTERED_IN_PRACTICE,
                            UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                        ],
                    },
                    {
                        url: routerLinks.parameters.userPreferences(),
                        label: "Gérer mes préférences personnelles",
                    },
                ]}
            />
            <PageContent>
                <Outlet />
            </PageContent>
        </>
    );
}
