// === NPM
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Collapse, Divider, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
// === LOCAL
import PermissionsCheck from "@/components/generics/PermissionsCheck";
import { drawerColors } from "@/resources/CssConstant";
import { IDrawerSection, IDrawerSubSection, IDrawerSubSectionWithChild } from "../interface";
import DrawerIcon from "./DrawerIcon";
import { DrawerListItemButton } from "./DrawerListItemButton";

interface OpenList {
    [key: number]: boolean;
}

interface DrawerOpenProps {
    config: IDrawerSection[];
}

const DrawerOpen = React.memo(({ config }: Readonly<DrawerOpenProps>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [openList, setOpenList] = useState<OpenList>({});

    const handlerListOpen = (name) => {
        setOpenList((prev) => ({
            [name]: !prev[name],
        }));
    };

    const displayIcon = (expandedValue: number) => {
        return openList[expandedValue] ? <ExpandLess /> : <ExpandMore />;
    };

    const renderSubSections = (subSections: (IDrawerSubSection | IDrawerSubSectionWithChild)[]) => {
        return subSections.map((subSection, index) => (
            <PermissionsCheck
                key={index}
                requiredPermissions={subSection.permissions}
                preferences={subSection.preferences}
            >
                {subSection.children ? (
                    <>
                        <DrawerListItemButton
                            id={subSection.id}
                            selected={location.pathname.startsWith(subSection.selectedPath)}
                            onClick={() => handlerListOpen(subSection.expandedValue)}
                        >
                            <ListItemIcon>
                                <DrawerIcon
                                    icon={subSection.icon}
                                    selected={location.pathname.includes(subSection.path)}
                                />
                            </ListItemIcon>
                            <ListItemText primary={subSection.label} />
                            {displayIcon(subSection.expandedValue)}
                        </DrawerListItemButton>
                        <Collapse in={openList[subSection.expandedValue]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {subSection.children.map((child, i) => (
                                    <PermissionsCheck
                                        key={i}
                                        requiredPermissions={child.permissions}
                                        preferences={child.preferences}
                                    >
                                        <ListItemButton
                                            selected={location.pathname.includes(child.path)}
                                            sx={{ ml: 3, borderLeft: `1px solid ${drawerColors.border}` }}
                                            key={i}
                                            onClick={() => {
                                                navigate(child.path);
                                            }}
                                        >
                                            <ListItemText primary={child.label} />
                                        </ListItemButton>
                                    </PermissionsCheck>
                                ))}
                            </List>
                        </Collapse>
                    </>
                ) : (
                    <DrawerListItemButton
                        id={subSection.id}
                        selected={location.pathname.includes(subSection.path)}
                        onClick={() => {
                            navigate(subSection.path);
                        }}
                    >
                        <ListItemIcon>
                            <DrawerIcon icon={subSection.icon} selected={location.pathname.includes(subSection.path)} />
                        </ListItemIcon>
                        <ListItemText primary={subSection.label} />
                    </DrawerListItemButton>
                )}
            </PermissionsCheck>
        ));
    };

    return (
        <>
            {config.map((section, key) => (
                <PermissionsCheck key={key} requiredPermissions={section.permissions} preferences={section.preferences}>
                    <Box p={1} key={key}>
                        <Typography sx={{ pl: 0 }} variant="drawer">
                            {section.label}
                        </Typography>
                        <List sx={{ overflowY: "auto", overflowX: "hidden" }}>
                            {renderSubSections(section.children)}
                        </List>
                        {key !== config.length - 1 && <Divider variant="middle" sx={{ mt: 2 }} />}
                    </Box>
                </PermissionsCheck>
            ))}
        </>
    );
});

DrawerOpen.displayName = "DrawerOpen";

export default DrawerOpen;
