import * as React from "react";
import { IMaskInput } from "react-imask";

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

export const IMEPMaskComponent = React.forwardRef<HTMLInputElement, CustomProps>(
    function IMEPMaskComponent(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="AX"
                prepareChar={(char) => char.toUpperCase()}
                style={{
                    color: "inherit",
                }}
                blocks={{
                    A: {
                        mask: "aaa",
                        placeholderChar: "_",
                    },
                    X: {
                        mask: "00.000.000",
                        placeholderChar: "#",
                    },
                }}
                lazy={false}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    }
);
