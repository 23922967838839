// === NPM
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { GridRenderCellParams, GridSortModel } from "@mui/x-data-grid-pro";
// === LOCAL
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import GenericTable from "@/components/generics/table/GenericTable";
import useTimeout from "@/hooks/useTimeout";
import { FilterType, HttpStatus, IPagination } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import { ActionColumnConfig, InfoColumnsConfig, TraceabilityActionType } from "@/interfaces/traceability";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { createPayload } from "@/resources/utils";
import TraceabilityService from "@/services/TraceabilityService";
import { IHealthReportingTraceability, IHealthReportingTraceabilityFilters } from "../../interface";

export default function HealthReportingTraceability() {
    const location = useLocation();
    const healthReportingId = location.state?.id;

    const [healthReportings, setHealthReportings] = useState<IHealthReportingTraceability[]>([]);
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [inputFilters, setInputFilters] = useState<IHealthReportingTraceabilityFilters>({
        authorId: "",
        date: [null, null],
        healthReportingId: healthReportingId ?? "",
        userSituation: [],
        previousStatus: [],
        currentStatus: [],
        action: [],
    });
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "date", sort: "desc" }]);
    const [traceabilityActions, setTraceabilityActions] = useState<IReferential[]>([]);
    const [situations, setSituations] = useState<IReferential[]>([]);
    const [statuses, setStatuses] = useState<IReferential[]>([]);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getTraceabilityHealthReportings();
    }, [pagination, sortModel]);

    useEffect(() => {
        getTraceabilityActions();
        getUserSituations();
        getHealthReportingStatuses();
    }, []);

    const getTraceabilityActions = async () => {
        const res = await TraceabilityService.getTraceabilityActions(TraceabilityActionType.HealthReporting);
        if (res.status === HttpStatus.OK) {
            setTraceabilityActions(res.data);
        }
    };

    const getUserSituations = async () => {
        const res = await TraceabilityService.getUserSituations();
        if (res.status === HttpStatus.OK) {
            setSituations(res.data);
        }
    };

    const getHealthReportingStatuses = async () => {
        const res = await TraceabilityService.getHealthReportingStatuses();
        if (res.status === HttpStatus.OK) {
            setStatuses(res.data);
        }
    };

    const getTraceabilityHealthReportings = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        };
        const res = await TraceabilityService.getTraceabilityHealthReportings(payload);
        if (res.status === HttpStatus.OK) {
            setHealthReportings(res.data);
            setRowCount(+res.headers[CALYPSO_HEADERS.TABLE_COUNT]);
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const columns = [
        ...InfoColumnsConfig(),
        {
            field: "healthReportingId",
            headerName: "Identifiant signalement",
            flex: 1,
        },
        {
            field: "userSituation",
            headerName: "Situation utilisateur",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) =>
                situations.find((item) => item.key === params.row.userSituation)?.label,
        },
        {
            field: "previousStatus",
            headerName: "Statut précédent",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) =>
                statuses.find((item) => item.key === params.row.previousStatus)?.label,
        },
        {
            field: "currentStatus",
            headerName: "Statut modifié",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) =>
                statuses.find((item) => item.key === params.row.currentStatus)?.label,
        },
        ActionColumnConfig(traceabilityActions),
    ];

    const filterConfigurations: FilterConfigurations<IHealthReportingTraceabilityFilters> = {
        date: { label: "Date", type: FilterType.DATEPICKER },
        authorId: { label: "Utilisateur", type: FilterType.INPUT },
        healthReportingId: { label: "Identifiant signalement", type: FilterType.INPUT },
        userSituation: { label: "Situation utilisateur", type: FilterType.SELECT, values: situations },
        previousStatus: { label: "Statut précédent", type: FilterType.SELECT, values: statuses },
        currentStatus: { label: "Statut modifié", type: FilterType.SELECT, values: statuses },
        action: { label: "Action", type: FilterType.SELECT, values: traceabilityActions },
    };

    return (
        <Stack width="100%" spacing={2}>
            <Typography variant="h4">Traçabilité des signalements de maltraitance</Typography>
            <Card>
                <CardContent>
                    <GenericFilters
                        inputFilters={inputFilters}
                        filterConfigurations={filterConfigurations}
                        initialValues={{
                            authorId: "",
                            date: [null, null],
                            healthReportingId: "",
                            userSituation: [],
                            previousStatus: [],
                            currentStatus: [],
                            action: [],
                        }}
                        setInputFilters={setInputFilters}
                    />
                    <GenericTable
                        rows={healthReportings}
                        columns={columns}
                        getRowId={(row) => row.uuid}
                        onPageSizeChange={handlePageSizeChange}
                        onPageChange={handlePageChange}
                        page={pagination.page}
                        pageSize={pagination.pageSize}
                        autoHeight
                        sortModel={sortModel}
                        onSortModelChange={(model) => setSortModel(model)}
                        sortingOrder={["asc", "desc"]}
                        filterMode="server"
                        sortingMode="server"
                        paginationMode="server"
                        rowCount={rowCount}
                    />
                </CardContent>
            </Card>
        </Stack>
    );
}
