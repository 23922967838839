// === NPM
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { Card, Stack, TextField } from "@mui/material";
import { z, ZodIssueCode } from "zod";
// === LOCAL
import { ReactComponent as Warning } from "@/assets/icons/shared/danger-triangle.svg";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import IconBannerText from "@/components/generics/IconBannerText";
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import {
    IRegistration,
    IRegistrationCreate,
    SessionOutletContext,
} from "@/components/HealthAccreditationTraining/interface";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { statusColor } from "@/resources/CssConstant";
import { FORM_TEXT, stringRequired } from "@/resources/FormUtils";

interface IRegisterDialogProps {
    registration?: IRegistration;
    sessionTitle: string;
    closed?: boolean;
    onValid: (data: IRegistrationCreate) => void;
    onClose: () => void;
}

export default function RegisterDialog({
    registration,
    sessionTitle,
    closed,
    onValid,
    onClose,
}: Readonly<IRegisterDialogProps>) {
    const { horsePowers, species } = useOutletContext<SessionOutletContext>();

    const vehicleSchema = z.object({
        distance: z.coerce
            .number({ required_error: FORM_TEXT.required, invalid_type_error: FORM_TEXT.required })
            .int({ message: "La valeur doit être un entier positif ou nul" })
            .nonnegative({ message: "La valeur doit être un entier positif ou nul" }),
        horsePower: stringRequired(),
    });

    const speciesSchema = z
        .object({
            speciesUuid: stringRequired(),
            otherSpecies: z.string().optional().nullable(),
        })
        .superRefine((values, context) => {
            if (values.speciesUuid === species.find((s) => s.code === "HSAUT")?.uuid && !values.otherSpecies) {
                context.addIssue({
                    code: ZodIssueCode.custom,
                    message: FORM_TEXT.required,
                    path: ["otherSpecies"],
                });
            }
        });

    const formSchema = speciesSchema.and(vehicleSchema);

    type ValidationSchema = z.infer<typeof formSchema>;

    const {
        formState: { errors },
        handleSubmit,
        control,
        register,
        watch,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            speciesUuid: registration?.species?.uuid ?? "",
            otherSpecies: registration?.otherSpecies ?? null,
            distance: registration?.distance ?? null,
            horsePower: registration?.horsePower ?? "",
        },
    });

    const speciesUuid = watch("speciesUuid");

    return (
        <GenericDialog
            title={`Inscription à la session ${sessionTitle}`}
            onClose={onClose}
            renderActions={() => <GenericActionsDialog onClose={onClose} onSubmit={handleSubmit(onValid)} />}
            renderContent={() => (
                <Stack spacing={1}>
                    {closed && (
                        <IconBannerText
                            icon={<Warning />}
                            message="Vous souhaitez vous inscrire à une session dont les inscriptions ont été clôturées. En cas de désinscription d'un des participants, l'OVVT pourra éventuellement valider votre pré-inscription"
                            color={statusColor.warningText}
                            backgroundColor={statusColor.warningBackground}
                        />
                    )}
                    <Card>
                        <StyledCardContent>
                            <Stack spacing={2}>
                                <Controller
                                    name="speciesUuid"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                                        <GenericSelect
                                            value={value}
                                            onChange={onChange}
                                            error={!!error}
                                            helperText={error?.message}
                                            label="Espèces traitées"
                                            options={species?.map((s) => ({ value: s.uuid, label: s.label }))}
                                            required
                                        />
                                    )}
                                />
                                {speciesUuid === species.find((s) => s.code === "HSAUT")?.uuid && (
                                    <TextField
                                        {...register("otherSpecies")}
                                        error={!!errors?.horsePower}
                                        helperText={errors?.horsePower?.message}
                                        label="Autre espèce"
                                        required
                                    />
                                )}
                                <TextField
                                    {...register("distance")}
                                    error={!!errors?.distance}
                                    helperText={errors?.distance?.message}
                                    type="number"
                                    label="Distance à parcourir (aller et retour en km)"
                                    required
                                />
                                <Controller
                                    name="horsePower"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                                        <GenericSelect
                                            value={value}
                                            onChange={onChange}
                                            error={!!error}
                                            helperText={error?.message}
                                            label="Chevaux fiscaux"
                                            options={horsePowers.map((hp) => ({ value: hp.key, label: hp.label }))}
                                            required
                                        />
                                    )}
                                />
                            </Stack>
                        </StyledCardContent>
                    </Card>
                </Stack>
            )}
        />
    );
}
