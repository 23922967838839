import { useEffect, useLayoutEffect, useRef } from "react";
import useIsFirstRender from "./useIsFirstRender";

/**
 *
 * This is a custom React hook that wraps the native setTimeout function. It allows you to schedule a callback to be invoked after a specified delay.
 * @param {() => void | Promise<void>} callback A function that will be invoked after the specified delay.
 * @param {unknown} trigger A value that the hook will watch for changes. When the value changes, the timeout will be reset and the delay will start over.
 * @param {number} [delay] (optional): The delay, in milliseconds, before the callback function is invoked. The default value is 500ms.
 */
function useTimeout(callback: () => void | Promise<void>, trigger: unknown[], delay?: number) {
    const defaultDelay = delay ?? 500;
    const callbackRef = useRef(callback);
    const isFirstRender = useIsFirstRender();

    useLayoutEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        if (isFirstRender) {
            return;
        }
        const id = setTimeout(() => callbackRef.current(), defaultDelay);

        return () => clearTimeout(id);
    }, [...trigger]);
}

export default useTimeout;
