// === NPM
import React from "react";
import { Chip } from "@mui/material";
// === LOCAL
import { colors } from "@/resources/CssConstant";

interface VersionChipProps {
    versionNumber: number;
}
export default function VersionChip({ versionNumber }: Readonly<VersionChipProps>) {
    return (
        <Chip
            label={`Version ${versionNumber}`}
            sx={{
                backgroundColor: colors.primaryColor,
                color: colors.white,
                fontSize: 16,
                fontWeight: 500,
                width: "fit-content",
            }}
        />
    );
}
