export enum DisplayType {
    GLOBAL = "Information générale",
    REGULATIONS = "Réglementation",
    ALERT = "Alerte",
    ALERT_BANNER = "Bannière d'alerte",
}

export enum NotificationType {
    MANUAL = "Manuelle",
    AUTO = "Automatique",
}

export interface INotificationCore {
    uuid: string;
    publicationDate: string;
    publicationEndDate: string;
    authorFirstname: string;
    authorLastname: string;
    subject: string;
    displayType: DisplayType;
}

export interface IAttachment {
    filename: string;
    uuid: string;
}
