// === NPM
import React from "react";
// === LOCAL
import SecondaryButton from "@/components/styled/SecondaryButton";
import { useProvideGlobal } from "@/context/useGlobalContext";
import GenericButton from "../buttons/GenericButton";

interface GenericActionsDialogProps {
    onClose: any;
    onSubmit?: any;
    disableSubmit?: boolean;
    displaySubmit?: boolean;
    closeLabel?: string;
    validLabel?: string;
}

export default function GenericActionsDialog({
    onClose,
    onSubmit,
    disableSubmit,
    displaySubmit = true,
    closeLabel = "Annuler",
    validLabel = "Valider",
}: Readonly<GenericActionsDialogProps>) {
    const { loadingRequest } = useProvideGlobal();

    return (
        <>
            <SecondaryButton id="cancel-dialog-btn" onClick={onClose} variant="outlined" disabled={loadingRequest}>
                {closeLabel}
            </SecondaryButton>
            {displaySubmit && (
                <GenericButton
                    disabled={disableSubmit}
                    onClick={onSubmit}
                    color="primary"
                    label={validLabel}
                    loading={loadingRequest}
                />
            )}
        </>
    );
}
