// === NPM
import React from "react";
// === LOCAL
import { UserType } from "@/interfaces/user";
import { useAuth } from "@/routers/useAuth";
import ViewAdminCalypso from "./containers/ViewAdminCalypso/ViewAdminCalypso";
import ViewDdpp from "./containers/ViewDdpp/ViewDdpp";
import ViewOvvt from "./containers/ViewOvvt/ViewOvvt";
import ViewVeterinary from "./containers/ViewVeterinary/ViewVeterinary";

export function View() {
    const auth = useAuth();

    switch (auth.userInfo.type) {
        case UserType.OVVT:
            return <ViewOvvt />;
        case UserType.DDPP:
            return <ViewDdpp />;
        case UserType.ADMIN_CALYPSO:
            return <ViewAdminCalypso />;
        case UserType.VETERINARY:
            return <ViewVeterinary />;
    }
}
