// === NPM
import { AxiosResponse } from "axios";
import { formatParams } from "@/resources/utils";
// === LOCAL
import {
    IAmv,
    IAmvCreateUpdate,
    IAmvVersion,
    IChargeable,
    IChargeableCreateUpdate,
    IChargeableRequiredFields,
    IChargeableVersion,
    IVat,
    IVatCreateUpdate,
    IVatVersion,
} from "../components/VaccinationIahp/Billing/interface";
import { HttpMethod, Loader } from "../interfaces/global";
import { IReferential } from "../interfaces/referential";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class BillingService {
    async getVats(payload?: { name?: string; date?: string }): Promise<AxiosResponse<IVat[]>> {
        return HttpService.fetch({
            url: endpoints.billingService.vat.base(),
            params: formatParams(payload),
            method: HttpMethod.GET,
        });
    }

    async getVatVersions(vatKey: string): Promise<AxiosResponse<IVatVersion[]>> {
        return HttpService.fetch({
            url: endpoints.billingService.vat.versions(vatKey),
            method: HttpMethod.GET,
            loader: Loader.BUTTON,
        });
    }

    async createVatVersion(vatKey: string, data: IVatCreateUpdate): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.billingService.vat.versions(vatKey),
            method: HttpMethod.POST,
            loader: Loader.BUTTON,
            data: data,
        });
    }

    async updateVatVersion(uuid: string, data: IVatCreateUpdate): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.billingService.vat.specificVersion(uuid),
            method: HttpMethod.PUT,
            loader: Loader.BUTTON,
            data: data,
        });
    }

    async deleteVatVersion(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.billingService.vat.specificVersion(uuid),
            method: HttpMethod.DELETE,
            loader: Loader.BUTTON,
        });
    }

    async getVatReferentials(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.billingService.referential.vat(),
            method: HttpMethod.GET,
        });
    }

    async getAmvs(payload?: { name?: string; date?: string }): Promise<AxiosResponse<IAmv[]>> {
        return HttpService.fetch({
            url: endpoints.billingService.amv.base(),
            params: formatParams(payload),
            method: HttpMethod.GET,
        });
    }

    async getAmvVersions(amvKey: string): Promise<AxiosResponse<IAmvVersion[]>> {
        return HttpService.fetch({
            url: endpoints.billingService.amv.versions(amvKey),
            method: HttpMethod.GET,
        });
    }

    async createAmvVersion(amvKey: string, data: IAmvCreateUpdate): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.billingService.amv.versions(amvKey),
            method: HttpMethod.POST,
            loader: Loader.BUTTON,
            data: data,
        });
    }

    async updateAmvVersion(uuid: string, data: IAmvCreateUpdate): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.billingService.amv.specificVersion(uuid),
            method: HttpMethod.PUT,
            loader: Loader.BUTTON,
            data: data,
        });
    }

    async deleteAmvVersion(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.billingService.amv.specificVersion(uuid),
            method: HttpMethod.DELETE,
            loader: Loader.BUTTON,
        });
    }

    async getAmvReferentials(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.billingService.referential.amv(),
            method: HttpMethod.GET,
        });
    }

    async getChargeables(payload?: { name?: string; date?: string }): Promise<AxiosResponse<IChargeable[]>> {
        return HttpService.fetch({
            url: endpoints.billingService.chargeables.base(),
            params: formatParams(payload),
            method: HttpMethod.GET,
        });
    }

    async getChargeablesVersions(chargeableKey: string): Promise<AxiosResponse<IChargeableVersion[]>> {
        return HttpService.fetch({
            url: endpoints.billingService.chargeables.versions(chargeableKey),
            method: HttpMethod.GET,
        });
    }

    async getChargeableRequiredFields(chargeableKey: string): Promise<AxiosResponse<IChargeableRequiredFields>> {
        return HttpService.fetch({
            url: endpoints.billingService.chargeables.requiredFields(chargeableKey),
            method: HttpMethod.GET,
        });
    }

    async createChargeableVersion(chargeableKey: string, data: IChargeableCreateUpdate): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.billingService.chargeables.versions(chargeableKey),
            method: HttpMethod.POST,
            loader: Loader.BUTTON,
            data: data,
        });
    }

    async updateChargeableVersion(uuid: string, data: IChargeableCreateUpdate): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.billingService.chargeables.specificVersion(uuid),
            method: HttpMethod.PUT,
            loader: Loader.BUTTON,
            data: data,
        });
    }

    async deleteChargeableVersion(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.billingService.chargeables.specificVersion(uuid),
            method: HttpMethod.DELETE,
            loader: Loader.BUTTON,
        });
    }

    async getChargeablesReferentials(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.billingService.referential.chargeables(),
            method: HttpMethod.GET,
        });
    }
}

export default new BillingService();
