// === NPM
import React from "react";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { Box, Tooltip } from "@mui/material";
// === LOCAL
import { colors } from "@/resources/CssConstant";

interface WorkshopsProps {
    workshopIds: string[];
}

export default function WorkshopsCell({ workshopIds }: Readonly<WorkshopsProps>) {
    return (
        <Tooltip title={workshopIds.join(", ")}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "bottom" }}>
                <ManageSearchIcon sx={{ color: colors.primaryColor }} />
            </Box>
        </Tooltip>
    );
}
