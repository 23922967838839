// === NPM
import * as React from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Box, FormHelperText } from "@mui/material";
import { ImageActions } from "@xeger/quill-image-actions";
import { ImageFormats } from "@xeger/quill-image-formats";
import ImageCompress from "quill-image-compress";
import MagicUrl from "quill-magic-url";
// === LOCAL
import { colors } from "@/resources/CssConstant";
import "./TextEditor.scss";

Quill.register("modules/imageActions", ImageActions);
Quill.register("modules/imageFormats", ImageFormats);
Quill.register("modules/magicUrl", MagicUrl);
Quill.register("modules/imageCompress", ImageCompress);

const formats = [
    "align",
    "bold",
    "color",
    "height",
    "italic",
    "link",
    "list",
    "placeholder",
    "size",
    "underline",
    "width",
    "strike",
    "header",
];

interface TextEditorProps {
    value: string;
    required?: boolean;
    error?: boolean;
    helperText?: string;
    placeholder?: string;
    handleChange: (value: string) => void;
    allowImages?: boolean;
}

export default function TextEditor({
    value,
    required,
    error,
    helperText,
    placeholder,
    handleChange,
    allowImages = true,
}: Readonly<TextEditorProps>) {
    const modules = {
        imageActions: {},
        imageFormats: {},
        magicUrl: true,
        imageCompress: {
            quality: 0.9,
            maxWidth: 1000,
            maxHeight: 1000,
            keepImageTypes: ["image/jpeg", "image/png", "image/svg+xml"],
            ignoreImageTypes: ["image/gif"],
        },
        toolbar: [
            [{ color: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ align: [] }],
            [{ list: "bullet" }, { list: "ordered" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            allowImages ? ["link", "image"] : ["link"],
        ],
        clipboard: {
            matchVisual: false,
        },
    };

    return (
        <Box height="100%" width="100%" sx={{ minHeight: 300 }} className={error ? "text-editor error" : "text-editor"}>
            <ReactQuill
                value={value}
                formats={formats.concat(allowImages ? ["image"] : [])}
                onChange={(value: string, delta, source, editor: ReactQuill.UnprivilegedEditor) => {
                    // Le trim permet d'empêcher la saisie d'espaces uniquement
                    handleChange(editor.getText().trim().length === 0 ? "" : value);
                }}
                modules={modules}
                bounds={".text-editor"}
                style={{ height: "100%" }}
                placeholder={placeholder}
                preserveWhitespace
            />
            {required && error && (
                <FormHelperText style={{ paddingLeft: 16, color: colors.error }}>{helperText}</FormHelperText>
            )}
        </Box>
    );
}
