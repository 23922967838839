// === NPM
import React, { Dispatch, SetStateAction, useContext, useState } from "react";
// === LOCAL
import { INotificationFull } from "../interface";

export interface UseSelectedNotification {
    selectedNotification: INotificationFull;
    setSelectedNotification: Dispatch<SetStateAction<INotificationFull>>;
}

export const selectedNotificationContext = React.createContext<any>({});

export const useSelectedNotification = (): UseSelectedNotification => {
    return useContext(selectedNotificationContext);
};

export function SelectedNotificationProvider({ children }: any) {
    const auth = useProvideSelectedNotification();
    return <selectedNotificationContext.Provider value={auth}>{children}</selectedNotificationContext.Provider>;
}

function useProvideSelectedNotification() {
    const [selectedNotification, setSelectedNotification] = useState<INotificationFull>(null);

    return {
        selectedNotification,
        setSelectedNotification,
    };
}
