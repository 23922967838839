// === Import: NPM
import React from "react";
// === Import: LOCAL
import { ReactComponent as Delete } from "@/assets/icons/actions/delete.svg";
import { IActionButton } from "@/interfaces/global";
import IconActionButton from "./IconActionButton";

export default function DeleteAction({ title, onClick }: IActionButton) {
    return <IconActionButton title={title} onClick={onClick} icon={<Delete />} />;
}
