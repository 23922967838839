// === NPM
import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
// === LOCAL
import { ReactComponent as Document } from "@/assets/icons/actions/document.svg";
import { ReactComponent as Users } from "@/assets/icons/notification/users.svg";
import { ReactComponent as Calendar } from "@/assets/icons/shared/calendar.svg";
import { ReactComponent as Settings } from "@/assets/icons/shared/settings.svg";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import GenericAccordion from "@/components/generics/layout/GenericAccordion";
import HtmlBox from "@/components/generics/layout/HtmlBox";
import { GenericText } from "@/components/generics/text/GenericText";
import GenericTitleValueText from "@/components/generics/text/GenericTitleValueText";
import StyledChip from "@/components/styled/StyledChip";
import { HttpStatus } from "@/interfaces/global";
import { DisplayType } from "@/interfaces/notification";
import { getEnumKeyByValue } from "@/resources/utils";
import NotificationService from "@/services/NotificationService";
import { BroadcastType, NotificationStatus, SendMode } from "../../../interface";
import { useSelectedNotification } from "../../useSelectedNotification";

interface ViewDialogProps {
    onClose: () => void;
    publishNotification: () => void;
}

export default function ViewDialog({ onClose, publishNotification }: Readonly<ViewDialogProps>) {
    const { selectedNotification } = useSelectedNotification();

    const renderRecipients = () => {
        return selectedNotification.broadcastType === getEnumKeyByValue(BroadcastType, BroadcastType.GENERIC)
            ? selectedNotification.receiverGroups.map((group) => `${group.label}`).join(", ")
            : selectedNotification.receiverUsers.map((user) => `${user.firstname} ${user.lastname}`).join(", ");
    };

    const getFile = async (notificationUuid: string, fileUuid: string) => {
        const res = await NotificationService.getUserNotificationFile(notificationUuid, fileUuid);
        if (res.status === HttpStatus.OK) {
            const file = new Blob([res.data], { type: res.headers["content-type"] });
            saveAs(file, res.headers["content-disposition"].split("filename=")[1].slice(1, -1));
        }
    };

    const renderContent = () => (
        <Stack spacing={2}>
            <GenericAccordion defaultExpanded title="Contenu de la notification">
                <Stack>
                    <GenericTitleValueText title="Sujet" value={selectedNotification.subject} />
                    <GenericText bold value="Contenu : " />
                    <HtmlBox content={selectedNotification.content} sx={{ px: 0 }} />
                    {selectedNotification.attachments.length > 0 && (
                        <>
                            <GenericText bold value="Pièces jointes : " />
                            <Stack direction="row" m={2} spacing={2}>
                                {selectedNotification.attachments.map((attachment) => (
                                    <StyledChip
                                        key={attachment.uuid}
                                        icon={<Document />}
                                        label={attachment.filename}
                                        onClick={() => getFile(selectedNotification.uuid, attachment.uuid)}
                                    />
                                ))}
                            </Stack>
                        </>
                    )}
                </Stack>
            </GenericAccordion>
            <GenericAccordion defaultExpanded title="Paramètres de la notification">
                <Stack spacing={5}>
                    <Grid container rowSpacing={3} columnSpacing={2}>
                        <Grid item xs={12} md={6} display="flex" gap={1}>
                            <Box>
                                <Settings />
                            </Box>
                            <Stack spacing={1}>
                                <Box mt={-0.25}>
                                    <Typography variant="bold" fontSize={18}>
                                        Options d'envoi
                                    </Typography>
                                </Box>
                                <GenericTitleValueText
                                    title="Mode d'envoi"
                                    value={SendMode[selectedNotification.sendMode]}
                                />

                                <GenericTitleValueText
                                    title="Type de diffusion"
                                    value={BroadcastType[selectedNotification.broadcastType]}
                                />

                                {selectedNotification.displayType && (
                                    <GenericTitleValueText
                                        title="Catégorie"
                                        value={DisplayType[selectedNotification.displayType]}
                                    />
                                )}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} display="flex" gap={1}>
                            <Box>
                                <Calendar />
                            </Box>
                            <Stack spacing={1}>
                                <Box mt={-0.25}>
                                    <Typography variant="bold" fontSize={18}>
                                        Publication
                                    </Typography>
                                </Box>
                                <GenericTitleValueText
                                    title="Date de publication"
                                    value={DateTime.fromISO(selectedNotification.publicationDate).toLocaleString(
                                        DateTime.DATETIME_SHORT
                                    )}
                                />
                                <GenericTitleValueText
                                    title="Date de fin de publication"
                                    value={DateTime.fromISO(selectedNotification.publicationEndDate).toLocaleString(
                                        DateTime.DATETIME_SHORT
                                    )}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} display="flex" gap={1}>
                            <Box>
                                <Users />
                            </Box>
                            <Stack spacing={1}>
                                <Box mt={-0.25}>
                                    <Typography variant="bold" fontSize={18}>
                                        Destinataires
                                    </Typography>
                                </Box>
                                <GenericTitleValueText
                                    title={
                                        selectedNotification.broadcastType ===
                                        getEnumKeyByValue(BroadcastType, BroadcastType.GENERIC)
                                            ? "Situations"
                                            : "Utilisateurs"
                                    }
                                    value={renderRecipients()}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>
            </GenericAccordion>
        </Stack>
    );

    return (
        <GenericDialog
            maxWidth="xl"
            title={`Notification n°${selectedNotification.internalId}`}
            onClose={onClose}
            renderContent={renderContent}
            renderActions={() => (
                <GenericActionsDialog
                    onClose={onClose}
                    onSubmit={publishNotification}
                    closeLabel="Fermer"
                    validLabel="Publier"
                    displaySubmit={NotificationStatus[selectedNotification.status] === NotificationStatus.CREATED}
                />
            )}
        />
    );
}
