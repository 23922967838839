// === NPM
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, IconButton, SvgIcon, Tooltip } from "@mui/material";
// === LOCAL
import { ReactComponent as Notification } from "@/assets/icons/navbar/notification.svg";
import { useNotificationInfo } from "@/components/UserNotifications/containers/useNotificationInfo";
import useIsFirstRender from "@/hooks/useIsFirstRender";
import { routerLinks } from "@/routers/RouterConstant";

export default function NotificationBell() {
    const navigate = useNavigate();
    const { unreadNotificationCount, getNotificationInfo } = useNotificationInfo();
    const plural = unreadNotificationCount > 1 ? "s" : "";
    const firstAnimation = useIsFirstRender() && unreadNotificationCount > 0;

    useEffect(() => {
        getNotificationInfo();
    }, []);

    return (
        <Tooltip title={`Vous avez ${unreadNotificationCount} notification${plural} non lue${plural}`}>
            <IconButton
                key={unreadNotificationCount}
                id="notifications"
                onClick={() => navigate(routerLinks.notifications)}
            >
                <Badge badgeContent={unreadNotificationCount} color="primary">
                    <SvgIcon
                        component={Notification}
                        inheritViewBox
                        sx={{
                            "py": 0,
                            "animationName": "swing",
                            "animationDuration": firstAnimation ? "0s" : "4s",
                            "@keyframes swing": {
                                "0%": { transform: "rotate(0deg)" },
                                "10%": { transform: "rotate(20deg)" },
                                "20%": { transform: "rotate(-20deg)" },
                                "30%": { transform: "rotate(20deg)" },
                                "40%": { transform: "rotate(-20deg)" },
                                "50%": { transform: "rotate(20deg)" },
                                "60%": { transform: "rotate(-20deg)" },
                                "70%": { transform: "rotate(20deg)" },
                                "80%": { transform: "rotate(-20deg)" },
                                "90%": { transform: "rotate(20deg)" },
                                "100%": { transform: "rotate(0deg)" },
                            },
                        }}
                    />
                </Badge>
            </IconButton>
        </Tooltip>
    );
}
