// === NPM
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Badge, IconButton, Tooltip } from "@mui/material";
// === LOCAL
import { ReactComponent as DownloadPaper } from "@/assets/icons/navbar/generateExport.svg";
import { useDownload } from "@/components/Downloads/useDownload";
import { useSocket } from "@/context/useSocket";
import useIsFirstRender from "@/hooks/useIsFirstRender";
import { routerLinks } from "@/routers/RouterConstant";

export default function Download() {
    const isFirstRender = useIsFirstRender();
    const location = useLocation();
    const navigate = useNavigate();
    const webSocket = useSocket();
    const { availableDownloadCount, getAvailableDownloads } = useDownload();
    const plural = availableDownloadCount > 1 ? "s" : "";

    useEffect(() => {
        if (isFirstRender || webSocket.downloadAvailable) {
            getAvailableDownloads();
        }
    }, [webSocket.downloadAvailable]);

    return (
        <Tooltip title={`Vous avez ${availableDownloadCount} téléchargement${plural} disponible${plural}`}>
            <IconButton id="downloads" onClick={() => navigate(routerLinks.downloads)}>
                <Badge
                    badgeContent={location.pathname === routerLinks.downloads ? 0 : availableDownloadCount}
                    color="primary"
                >
                    <DownloadPaper />
                </Badge>
            </IconButton>
        </Tooltip>
    );
}
