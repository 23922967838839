// === NPM
import React from "react";
import { useDrop } from "react-dnd";
import { Box, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Add } from "@/assets/icons/actions/add_filled.svg";
import { ReactComponent as Billing } from "@/assets/icons/home/billing.svg";
import { colors, drawerColors } from "@/resources/CssConstant";
import { ItemTypes, IValidatedVaccinationSite } from "../../interface";
import VaccinationSiteTile from "./VaccinationSiteTile";

interface DropableListProps {
    selectedVaccinationSites: IValidatedVaccinationSite[];
    addVaccinationSite: (item: IValidatedVaccinationSite) => void;
    onDelete: (item: IValidatedVaccinationSite) => void;
}

export default function DropableList({ selectedVaccinationSites, addVaccinationSite, onDelete }: DropableListProps) {
    const [, drop] = useDrop(
        () => ({
            accept: ItemTypes.VACCINATION_SITE_TILE,
            drop: (item: IValidatedVaccinationSite) => addVaccinationSite(item),
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        }),
        [addVaccinationSite]
    );
    return (
        <Box
            ref={drop}
            sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                borderWidth: "2px",
                borderColor: drawerColors.background,
                borderStyle: "dashed",
                borderRadius: 5,
                p: 2,
                overflow: "auto",
            }}
        >
            {selectedVaccinationSites.length > 0 ? (
                <Stack spacing={2} width="100%">
                    {selectedVaccinationSites.map((site) => (
                        <VaccinationSiteTile key={site.id} vaccinationSite={site} onDelete={onDelete} />
                    ))}
                </Stack>
            ) : (
                <Stack width="100%" justifyContent="center" alignItems="center" spacing={2}>
                    <Billing />
                    <Stack direction="row" spacing={1}>
                        <Typography
                            variant="bold"
                            align="center"
                            color={colors.secondaryColor}
                        >
                            Cliquez sur le bouton
                        </Typography>
                        <Add />
                        <Typography
                            variant="bold"
                            align="center"
                            color={colors.secondaryColor}
                        >
                            d'un chantier
                        </Typography>
                    </Stack>
                    <Typography
                        variant="bold"
                        align="center"
                        color={colors.secondaryColor}
                    >
                        ou glissez ici des chantiers à ajouter au mémoire de paiement
                    </Typography>
                </Stack>
            )}
        </Box>
    );
}
