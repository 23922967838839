// === NPM
import React from "react";
import { Checkbox, FormControl, FormControlLabel, FormGroup } from "@mui/material";
// === LOCAL
import { FilterConfiguration } from "../GenericFilters";

interface SelectFilterProps {
    config: FilterConfiguration;
    value: string[];
    handleSelectFilter: (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    name: string;
    valuesAlphabeticalSort?: boolean;
}

export default function SelectFilter({
    config,
    value,
    handleSelectFilter,
    name,
    valuesAlphabeticalSort = true,
}: Readonly<SelectFilterProps>) {
    return (
        <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
            <FormGroup>
                {(valuesAlphabeticalSort
                    ? config.values.sort((v1, v2) => v1.label.localeCompare(v2.label))
                    : config.values
                ).map((v) => {
                    return (
                        <FormControlLabel
                            key={v.key}
                            control={
                                <Checkbox
                                    checked={value.includes(v.key)}
                                    onChange={handleSelectFilter(name)}
                                    name={v.key}
                                />
                            }
                            label={v.label}
                        />
                    );
                })}
            </FormGroup>
        </FormControl>
    );
}
