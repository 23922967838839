// === NPM
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Card, CardContent, FormControl, Stack, TextField } from "@mui/material";
import { z } from "zod";
// === LOCAL
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import GenericEmailField from "@/components/generics/inputs/GenericEmailField";
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import { IOvvtOrganization, IOvvtOrganizationShort, IReferential } from "@/interfaces/referential";
import { FORM_TEXT, stringRequired } from "@/resources/FormUtils";

interface CreateOrUpdateOvvtOrganizationDialogProps {
    title: string;
    organization?: IOvvtOrganization;
    onClose: () => void;
    onValid: (data: IOvvtOrganizationShort) => void;
    regions: IReferential[];
}

const formSchema = z.object({
    name: stringRequired(),
    externalId: stringRequired(),
    regionInseeCode: stringRequired(),
    emails: z
        .array(z.string().trim().email({ message: FORM_TEXT.email }))
        .min(1, "La liste d'emails doit contenir au moins une adresse mail")
        .max(3, "La liste d'emails ne peut pas en contenir plus de trois"),
});

type ValidationSchema = z.infer<typeof formSchema>;

export default function CreateOrUpdateOvvtOrganizationDialog({
    title,
    organization,
    onClose,
    onValid,
    regions,
}: Readonly<CreateOrUpdateOvvtOrganizationDialogProps>) {
    const {
        formState: { errors },
        handleSubmit,
        register,
        control,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: organization ?? {
            name: null,
            externalId: null,
            regionInseeCode: "",
            emails: [],
        },
    });

    return (
        <GenericDialog
            title={title}
            onClose={onClose}
            renderActions={() => (
                <GenericActionsDialog onClose={onClose} onSubmit={handleSubmit(onValid)} validLabel="Sauvegarder" />
            )}
            renderContent={() => (
                <Card sx={{ display: "flex", flexGrow: 1, flexDirection: "column", p: 2 }}>
                    <CardContent sx={{ height: "100%" }}>
                        <Stack spacing={2}>
                            <TextField
                                {...register("name")}
                                label="Nom de l'organisme"
                                error={!!errors.name}
                                helperText={errors?.name?.message}
                                required
                            />
                            <TextField
                                {...register("externalId")}
                                label="Identifiant externe"
                                error={!!errors.externalId}
                                helperText={errors?.externalId?.message}
                                required
                            />
                            <Controller
                                name="regionInseeCode"
                                control={control}
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <GenericSelect
                                        value={value}
                                        onChange={onChange}
                                        label="Région"
                                        error={!!error}
                                        helperText={error?.message}
                                        optionsValue="key"
                                        options={regions}
                                        required
                                    />
                                )}
                            />
                            <FormControl fullWidth required error={!!errors.emails}>
                                <Controller
                                    name="emails"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                                        <GenericEmailField
                                            label="Adresses mails de l'organisme"
                                            emails={value}
                                            onChange={onChange}
                                            error={error}
                                            required
                                        />
                                    )}
                                />
                            </FormControl>
                        </Stack>
                    </CardContent>
                </Card>
            )}
        />
    );
}
