export const SSOConfiguration = {
    CNOV: {
        client_id: window._env_.REACT_APP_CLIENT_ID,
        redirect_uri: window.location.origin,
        response_type: "code",
        post_logout_redirect_uri: window.location.origin,
        scope: "openid profile cnov",
        authority: window._env_.REACT_APP_SSO_URL,
    },
    PSC: {
        client_id: window._env_.REACT_APP_PSC_CLIENT_ID,
        redirect_uri: window.location.origin + "/connexionPsc",
        scope: "openid scope_all",
        authority: window._env_.REACT_APP_PSC_SSO_AUTHORITY_URL,
        authority_configuration: {
            authorization_endpoint: window._env_.REACT_APP_PSC_SSO_AUTH_URL,
        },
        extras: { acr_values: "eidas1" },
    },
};
