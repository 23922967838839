// === NPM
import { AxiosResponse } from "axios";
// === LOCAL
import { ReceiverUser } from "@/components/Administration/containers/Notifications/interface";
import { IProfileDetails, IProfileFeature, IRight } from "@/components/Administration/containers/Profiles/interface";
import {
    ICreateFamDamUser,
    ICreateMaUser,
    ICreateOvvtUser,
    IUserFilters,
} from "@/components/Administration/containers/User/interface";
import { IActivity } from "@/components/Home/interface";
import { IDpeConsent, IPutDpe, IUserPreferences, IVeterinaryConsent } from "@/components/Parameters/interface";
import { IOVDpe, IProfileInfo } from "@/components/Profile/interface";
import { ICreateLocalAgency, IFamDamUsersFilters } from "@/components/Referentials/containers/Delivery/interface";
import {
    IAgriMinistryGeoUnit,
    IMinistryAgentUser,
} from "@/components/Referentials/containers/Global/containers/AgriMinistryGeoUnit/interface";
import { UsersFilters } from "@/components/Referentials/containers/Global/containers/Ovvt/containers/Users";
import { IOvvtUser } from "@/components/Referentials/containers/Global/containers/Ovvt/interface";
import { IPharmacy } from "@/components/Referentials/containers/Global/containers/Pharmacy/interface";
import { HttpMethod, IReferentialSynchro, Loader, SearchParams } from "@/interfaces/global";
import { IOvvtOrganization, IOvvtOrganizationShort, IReferential, IRegion } from "@/interfaces/referential";
import {
    IAgency,
    IAgriMinistryGeoUnitShort,
    IBaseUser,
    IDpe,
    IDpeShort,
    ILocalAgency,
    IPharmacyShort,
    IType,
    IUserShort,
    IVeterinaryShort,
    UserWithoutProperties,
} from "@/interfaces/user";
import { formatParams, objToQueryParams } from "@/resources/utils";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class UserService {
    // --------------- USER ---------------

    async getUserInfo(userId: string): Promise<AxiosResponse<IProfileInfo>> {
        return HttpService.fetch({
            url: endpoints.userService.user.userInfo(userId),
            method: HttpMethod.GET,
        });
    }

    async getUserTutorials(userId: string): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.userService.user.tutorials(userId),
            method: HttpMethod.GET,
        });
    }

    async postUserTutorial(userId: string, tutorialKey: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.user.tutorial(userId, tutorialKey),
            method: HttpMethod.POST,
        });
    }

    async getUserOVDPE(userId: string): Promise<AxiosResponse<IOVDpe[]>> {
        return HttpService.fetch({
            url: endpoints.userService.user.userOVDPE(userId),
            method: HttpMethod.GET,
        });
    }

    async getUserDpes(userId: string): Promise<AxiosResponse<IDpeShort[]>> {
        return HttpService.fetch({
            url: endpoints.userService.user.userDPE(userId),
            method: HttpMethod.GET,
        });
    }

    async getLoggedUserDpes(): Promise<AxiosResponse<IDpeConsent[]>> {
        return HttpService.fetch({
            url: endpoints.userService.user.loggedUserDPE(),
            method: HttpMethod.GET,
            loader: Loader.TABLE,
        });
    }

    async getUsers(
        payload: SearchParams &
            Partial<{
                id: string;
                firstname: string;
                lastname: string;
                type: string[];
                situation: string[];
            }>
    ): Promise<AxiosResponse<IUserShort[]>> {
        return HttpService.fetch({
            url: endpoints.userService.user.base,
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async updateUserConsent(
        userId: string,
        dpeOrdinalNumber: string,
        data: IVeterinaryConsent
    ): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.user.userConsent(userId, dpeOrdinalNumber),
            method: HttpMethod.PUT,
            data,
            loader: Loader.BUTTON,
        });
    }

    async getUserSynchro(): Promise<AxiosResponse<IReferentialSynchro>> {
        return HttpService.fetch({
            url: endpoints.userService.user.sync(),
            method: HttpMethod.GET,
        });
    }

    async putUserSynchro(): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.user.sync(),
            method: HttpMethod.PUT,
            loader: Loader.REQUEST,
        });
    }

    async getUserLocalAgencies(): Promise<AxiosResponse<ILocalAgency[]>> {
        return HttpService.fetch({
            url: endpoints.userService.user.localAgencies(),
            method: HttpMethod.GET,
        });
    }

    async getUserActivities(): Promise<AxiosResponse<IActivity>> {
        return HttpService.fetch({
            url: endpoints.userService.user.activities(),
            method: HttpMethod.GET,
        });
    }

    async getVeterinaries(params: SearchParams): Promise<AxiosResponse<IVeterinaryShort[]>> {
        return HttpService.fetch({
            url: endpoints.userService.veterinaries(),
            method: HttpMethod.GET,
            params: formatParams(params),
        });
    }

    async getUserPreferences(userId: string): Promise<AxiosResponse<IUserPreferences>> {
        return HttpService.fetch({
            url: endpoints.userService.user.preferences(userId),
            method: HttpMethod.GET,
        });
    }

    async putUserPreferences(userId: string, data: IUserPreferences): Promise<AxiosResponse<IUserPreferences>> {
        return HttpService.fetch({
            url: endpoints.userService.user.preferences(userId),
            method: HttpMethod.PUT,
            data,
            loader: Loader.BUTTON,
        });
    }
    // --------------- DPE ---------------

    async getDpes(payload: { page: number; size: number; sorts?: string[] }): Promise<AxiosResponse<IDpe[]>> {
        const query = objToQueryParams(payload);

        return HttpService.fetch({
            url: endpoints.userService.dpes.dpes(query),
            method: HttpMethod.GET,
            loader: Loader.TABLE,
        });
    }

    async getDpe(ordinalNumber: string): Promise<AxiosResponse<IDpeShort>> {
        return HttpService.fetch({
            url: endpoints.userService.dpes.dpe(ordinalNumber),
            method: HttpMethod.GET,
        });
    }

    async putDpe(ordinalNumber: string, data: IPutDpe): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.dpes.dpe(ordinalNumber),
            method: HttpMethod.PUT,
            data,
            loader: Loader.BUTTON,
        });
    }

    async getDpeSynchro(): Promise<AxiosResponse<IReferentialSynchro>> {
        return HttpService.fetch({
            url: endpoints.userService.dpes.sync(),
            method: HttpMethod.GET,
        });
    }

    async putDpeSynchro(): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.dpes.sync(),
            method: HttpMethod.PUT,
            loader: Loader.REQUEST,
        });
    }

    async getDpeHistorySynchro(): Promise<AxiosResponse<IReferentialSynchro>> {
        return HttpService.fetch({
            url: endpoints.userService.dpes.historySync(),
            method: HttpMethod.GET,
        });
    }

    async putDpeHistorySynchro(): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.dpes.historySync(),
            method: HttpMethod.PUT,
            loader: Loader.REQUEST,
        });
    }

    async getDpeUsers(ordinalNumber: string): Promise<AxiosResponse<UserWithoutProperties[]>> {
        return HttpService.fetch({
            url: endpoints.userService.dpes.users(ordinalNumber),
            method: HttpMethod.GET,
        });
    }

    // --------------- REFERENTIAL ---------------

    async getRights(): Promise<AxiosResponse<IRight[]>> {
        return HttpService.fetch({
            url: endpoints.userService.referentials.rightsReferential(),
            method: HttpMethod.GET,
        });
    }

    async getUserTypesReferential(): Promise<AxiosResponse<IType[]>> {
        return HttpService.fetch({
            url: endpoints.userService.referentials.userTypesReferential(),
            method: HttpMethod.GET,
        });
    }

    async getProfileFeatures(
        payload: SearchParams &
            Partial<{
                situations: string[];
                profile: string;
                feature: string;
            }>
    ): Promise<AxiosResponse<IProfileFeature[]>> {
        return HttpService.fetch({
            url: endpoints.userService.referentials.profileFeatures(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getProfile(profileKey: string): Promise<AxiosResponse<IProfileDetails>> {
        return HttpService.fetch({
            url: endpoints.userService.referentials.profile(profileKey),
            method: HttpMethod.GET,
            loader: Loader.TABLE,
        });
    }

    async getProfiles(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.userService.referentials.profiles(),
            method: HttpMethod.GET,
        });
    }

    async getSituations(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.userService.referentials.situations(),
            method: HttpMethod.GET,
        });
    }

    async getGeoUnits(): Promise<AxiosResponse<IAgriMinistryGeoUnitShort[]>> {
        return HttpService.fetch({
            url: endpoints.userService.referentials.geoUnits(),
            method: HttpMethod.GET,
        });
    }

    // ------ ADMIN CALYPSO USERS

    async createUserAdminCalypso(data: IBaseUser): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.user.adminCalypso.base(),
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async updateUserAdminCalypso(data: IUserShort, userId: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.user.adminCalypso.user(userId),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    async deleteUserAdminCalypso(userId: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.user.adminCalypso.user(userId),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    // ------ ANMV USERS

    async createUserAnmv(data: IBaseUser): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.user.anmv.base(),
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async updateUserAnmv(data: IUserShort, userId: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.user.anmv.user(userId),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    async deleteUserAnmv(userId: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.user.anmv.user(userId),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    // ------ FAM DAM USERS

    async getAgencyFamDamUsers(
        payload: SearchParams & Partial<IUserFilters>,
        agencyId: number
    ): Promise<AxiosResponse<IUserShort[]>> {
        return HttpService.fetch({
            url: endpoints.userService.user.famDam.agencyUsers(agencyId),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getFamDamUsers(payload: SearchParams & Partial<IFamDamUsersFilters>): Promise<AxiosResponse<IUserShort[]>> {
        return HttpService.fetch({
            url: endpoints.userService.user.famDam.base(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async createUserFamDam(data: ICreateFamDamUser): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.user.famDam.base(),
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async updateUserFamDam(data: IUserShort, userId: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.user.famDam.user(userId),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    async deleteFamDamUser(userId: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.user.famDam.user(userId),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    // ------ MA USERS

    async createUserMA(data: ICreateMaUser): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.user.ma.base(),
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async deleteMaUser(userId: string, type: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.user.ma.typeUser(type, userId),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    async updateUserMa(data: IUserShort, userId: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.user.ma.user(userId),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    async getMaUsers(payload: any): Promise<AxiosResponse<IUserShort[]>> {
        return HttpService.fetch({
            url: endpoints.userService.user.ma.base(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    // ------ AGENCY

    async getAgencies(
        payload: SearchParams &
            Partial<{
                id: string;
                name: string;
                active: string;
            }>
    ): Promise<AxiosResponse<IAgency[]>> {
        return HttpService.fetch({
            url: endpoints.userService.agency.base,
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async postAgency(data: { name: string }): Promise<AxiosResponse<IAgency>> {
        return HttpService.fetch({
            url: endpoints.userService.agency.base,
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async updateAgency(agencyId: number, data: { name: string }): Promise<AxiosResponse<IAgency>> {
        return HttpService.fetch({
            url: endpoints.userService.agency.agency(agencyId),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    async archiveAgency(agencyId: number): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.agency.archive(agencyId),
            method: HttpMethod.PATCH,
            loader: Loader.REQUEST,
        });
    }

    async getLocalAgenciesByAgency(
        agencyId: number,
        payload: SearchParams &
            Partial<{
                postalCodes: string[];
                cities: string[];
            }>
    ): Promise<AxiosResponse<ILocalAgency[]>> {
        return HttpService.fetch({
            url: endpoints.userService.agency.localAgenciesByAgency(agencyId),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    // ------ LOCAL AGENCY

    async getLocalAgencies(
        payload: SearchParams &
            Partial<{
                authorizationNumber: string;
                name: string;
                postalCode: string[];
                city: string[];
            }>
    ): Promise<AxiosResponse<ILocalAgency[]>> {
        return HttpService.fetch({
            url: endpoints.userService.localAgency.base,
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async archiveLocalAgency(localAgencyUuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.localAgency.archive(localAgencyUuid),
            method: HttpMethod.PATCH,
            loader: Loader.REQUEST,
        });
    }

    async postLocalAgency(data: ICreateLocalAgency): Promise<AxiosResponse<ILocalAgency>> {
        return HttpService.fetch({
            url: endpoints.userService.localAgency.base,
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async updateLocalAgency(localAgencyUuid: string, data: ICreateLocalAgency): Promise<AxiosResponse<ILocalAgency>> {
        return HttpService.fetch({
            url: endpoints.userService.localAgency.localAgency(localAgencyUuid),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    // ------ SSO

    async getTokenSso(): Promise<AxiosResponse<string>> {
        return HttpService.fetch({
            url: endpoints.userService.user.tokenSso(),
            method: HttpMethod.GET,
        });
    }

    // ------ PHARMACY
    async getPharmacies(
        payload: SearchParams &
            Partial<{
                id: string;
                name: string;
            }>
    ): Promise<AxiosResponse<IPharmacy[]>> {
        return HttpService.fetch({
            url: endpoints.userService.pharmacy.base,
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async updatePharmacy(pharmacyId: string, data: { email: string }): Promise<AxiosResponse<IPharmacy>> {
        return HttpService.fetch({
            url: endpoints.userService.pharmacy.pharmacy(pharmacyId),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    async getUserPharmacy(userId: string): Promise<AxiosResponse<IPharmacyShort>> {
        return HttpService.fetch({
            url: endpoints.userService.user.pharmacy(userId),
            method: HttpMethod.GET,
        });
    }

    // ------ AGRI MINISTRY

    async getAgriMinistryGeoUnits(
        payload: SearchParams &
            Partial<{
                externalId: string;
                name: string;
                localisation: string;
                type: string;
            }>
    ): Promise<AxiosResponse<IAgriMinistryGeoUnit[]>> {
        return HttpService.fetch({
            url: endpoints.userService.agriMinistry.geoUnits(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async putAgriMinistryGeoUnit(
        uuid: string,
        data: { name: string; emails: string[] }
    ): Promise<AxiosResponse<IAgriMinistryGeoUnit>> {
        return HttpService.fetch({
            url: endpoints.userService.agriMinistry.geoUnit(uuid),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    async getAdminAgentUser(
        payload: SearchParams &
            Partial<{
                firstname: string;
                lastname: string;
                situation: string;
                externalId: string;
                agriMinistryService: string;
                localization: string;
                inseeCode: string;
            }>
    ): Promise<AxiosResponse<IMinistryAgentUser[]>> {
        return HttpService.fetch({
            url: endpoints.userService.agriMinistry.users(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getAllNotificationUsers(
        payload: SearchParams &
            Partial<{
                situation: string[];
                firstname: string;
                lastname: string;
                ordinalNumber: string;
                dpeCity: string;
                dpePostalCode: string;
                dpeName: string;
                dpeOrdinalNumber: string;
                agencyCity: string;
                agencyPostalCode: string;
            }>
    ): Promise<AxiosResponse<ReceiverUser[]>> {
        return HttpService.fetch({
            url: endpoints.userService.user.notifications(),
            method: HttpMethod.GET,
            params: formatParams(payload),
        });
    }

    async putHomePreferences(preferences: string[], userId: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.user.homePreferences(userId),
            method: HttpMethod.PUT,
            data: { preferences },
            loader: Loader.BUTTON,
        });
    }

    async getOvvtOrganizations(
        payload: SearchParams & Partial<Omit<IOvvtOrganization, "deletable" | "emails">>
    ): Promise<AxiosResponse<IOvvtOrganization[]>> {
        return HttpService.fetch({
            url: endpoints.userService.ovvt.base,
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getOvvtUsers(
        organizationUuid: string,
        payload: SearchParams & Partial<UsersFilters>
    ): Promise<AxiosResponse<IOvvtUser[]>> {
        return HttpService.fetch({
            url: endpoints.userService.ovvt.users(organizationUuid),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async createOvvtOrganization(data: IOvvtOrganizationShort): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.ovvt.base,
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async updateOvvtOrganization(
        uuid: string,
        data: IOvvtOrganizationShort
    ): Promise<AxiosResponse<IOvvtOrganizationShort>> {
        return HttpService.fetch({
            url: endpoints.userService.ovvt.organization(uuid),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    async deleteOvvtOrganization(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.ovvt.organization(uuid),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    async getOvvtVacantRegions(): Promise<AxiosResponse<IRegion[]>> {
        return HttpService.fetch({
            url: endpoints.userService.ovvt.vacantRegions(),
            method: HttpMethod.GET,
            loader: Loader.BUTTON,
        });
    }

    async createUserOvvt(data: ICreateOvvtUser): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.user.ovvt.base(),
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async updateUserOvvt(data: IUserShort, id: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.user.ovvt.user(id),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    async deleteOvvtUser(id: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.userService.user.ovvt.user(id),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }
}

export default new UserService();
