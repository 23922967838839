// === NPM
import React from "react";
// === LOCAL
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import GenericTextField from "@/components/generics/inputs/GenericTextField";
import GenericCard from "@/components/generics/layout/GenericCard";
import { useForm } from "@/hooks/useForm";
import { FORM_TEXT, PATTERN } from "@/resources/FormUtils";
import { IPharmacy } from "../interface";

interface UpdatePharmacyDialogProps {
    currentPharmacy: IPharmacy;
    onClose: () => void;
    onValid: (pharmacy: IPharmacy) => void;
}

export default function UpdatePharmacyDialog({
    currentPharmacy,
    onValid,
    onClose,
}: Readonly<UpdatePharmacyDialogProps>) {
    const {
        data: pharmacy,
        errors,
        handleSubmit,
        handleChange,
    } = useForm<IPharmacy>({
        initialValues: currentPharmacy,
        validations: {
            email: {
                required: {
                    value: true,
                    message: FORM_TEXT.required,
                },
                pattern: {
                    value: PATTERN.email,
                    message: FORM_TEXT.email,
                },
            },
        },
        onSubmit: () => {
            onValid(pharmacy);
        },
    });

    return (
        <GenericDialog
            title={`${pharmacy.name}`}
            onClose={onClose}
            paperProps={{
                sx: { width: "100%" },
            }}
            renderActions={() => <GenericActionsDialog onClose={onClose} onSubmit={handleSubmit} />}
            renderContent={() => (
                <GenericCard>
                    <GenericTextField
                        value={pharmacy.email}
                        onChange={handleChange("email")}
                        label="Email de l'officine"
                        error={!!errors.email}
                        helperText={errors.email}
                        required
                    />
                </GenericCard>
            )}
        />
    );
}
