import React from "react";
import { Stack, Switch, Typography } from "@mui/material";

interface GenericSwitchProps {
    title?: string;
    leftText: string;
    rightText: string;
    value: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function GenericSwitch({ title, leftText, rightText, value, onChange }: Readonly<GenericSwitchProps>) {
    return (
        <>
            {title && <Typography variant="bold">{title}</Typography>}
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant={!value ? "bold" : "body1"}>{leftText}</Typography>
                <Switch checked={value} onChange={onChange} />
                <Typography variant={value ? "bold" : "body1"}>{rightText}</Typography>
            </Stack>
        </>
    );
}
