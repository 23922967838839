// === NPM
import React, { useRef, useState } from "react";
import { BlockPicker, ColorResult } from "react-color";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { SquareRounded } from "@mui/icons-material";
import { ClickAwayListener, Grow, IconButton, Paper, Popper, Stack, TextField } from "@mui/material";
import { z } from "zod";
// === LOCAL
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import GenericCard from "@/components/generics/layout/GenericCard";
import { ITag } from "@/interfaces/faq";
import { stringRequired } from "@/resources/FormUtils";

interface TagDialogProps {
    onClose: () => void;
    onValid: (tag: ITag) => void;
    currentTag?: ITag;
    title: string;
}

const initialValues = {
    name: null,
    color: "#C2AFF0",
};

const colorPickers = [
    "#E9C10D", // Yellow
    "#E67A68", // Red
    "#C2AFF0", // Purple
    "#90D9E1", // Blue
    "#ABDB4A", // Green
    "#BB681B", // Orange
    "#81313A", // Brown
    "#6650A5", // Dark Purple
    "#185D81", // Dark Blue
    "#228661", // Dark Green
];

const formSchema = z.object({
    name: stringRequired(),
    color: stringRequired(),
});

type ValidationSchema = z.infer<typeof formSchema>;

export default function TagDialog({ onClose, onValid, title, currentTag }: Readonly<TagDialogProps>) {
    const {
        formState: { errors },
        handleSubmit,
        setValue,
        watch,
        register,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: currentTag ?? initialValues,
    });

    const [open, setOpen] = useState<boolean>(false);
    const anchorRef = useRef<null | HTMLButtonElement>(null);

    const watchColor = watch("color");

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    const handleColorChange = (color: ColorResult) => {
        setValue("color", color.hex);
        setOpen(false);
    };

    return (
        <GenericDialog
            title={title}
            onClose={onClose}
            renderActions={() => <GenericActionsDialog onClose={onClose} onSubmit={handleSubmit(onValid)} />}
            renderContent={() => (
                <GenericCard>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <IconButton ref={anchorRef} onClick={handleToggle} size="large">
                            <SquareRounded sx={{ color: watchColor, height: 70, width: 70 }} />
                        </IconButton>
                        <TextField
                            {...register("name")}
                            label="Nom"
                            required
                            error={!!errors?.name}
                            helperText={errors?.name?.message}
                        />
                        <Popper open={open} anchorEl={anchorRef.current} transition style={{ zIndex: 10001 }}>
                            {({ TransitionProps }) => (
                                <Grow {...TransitionProps}>
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <BlockPicker
                                                colors={colorPickers}
                                                color={watchColor}
                                                onChangeComplete={handleColorChange}
                                            />
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Stack>
                </GenericCard>
            )}
        />
    );
}
