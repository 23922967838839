// === NPM
import React, { useEffect, useState } from "react";
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { applyPatch } from "fast-json-patch";
// === LOCAL
import { ReactComponent as Location } from "@/assets/icons/shared/location.svg";
import { ReactComponent as Training } from "@/assets/icons/training/catalog/type.svg";
import TitleTextPatchValue from "@/components/generics/text/TitleTextPatchedValue";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { HttpStatus } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import { TraceabilityActions } from "@/interfaces/traceability";
import { SessionMode, TrainerType } from "@/interfaces/training";
import TraceabilityService from "@/services/TraceabilityService";
import {
    IHealthAccreditationTrainingSessionTraceabilityDetails,
    ITraceabilityObjectHealthAccreditationTrainingSession,
} from "../../../interface";

interface IHealthAccreditationTrainingSessionTraceabilityDetailsProps {
    traceabilityUuid: string;
    traceabilityActions: IReferential[];
}

export default function HealthAccreditationTrainingSessionTraceabilityDetails({
    traceabilityUuid,
    traceabilityActions,
}: IHealthAccreditationTrainingSessionTraceabilityDetailsProps) {
    const [traceabilityDetails, setTraceabilityDetails] =
        useState<IHealthAccreditationTrainingSessionTraceabilityDetails>(null);
    const [content, setContent] = useState<ITraceabilityObjectHealthAccreditationTrainingSession>(null);

    useEffect(() => {
        if (traceabilityUuid) getTraceabilityDetails();
    }, [traceabilityUuid]);

    const getTraceabilityDetails = async () => {
        const res =
            await TraceabilityService.getTraceabilityHealthAccreditationTrainingSessionDetails(traceabilityUuid);
        if (res.status === HttpStatus.OK) {
            let trainingObject;
            if (res.data.action.includes(TraceabilityActions.DELETE)) {
                trainingObject = res.data.value;
            } else {
                trainingObject = applyPatch(res.data.value, res.data.patch, false, false).newDocument;
            }
            setContent(trainingObject);
            setTraceabilityDetails(res.data);
        }
    };

    return (
        <Stack width="100%" spacing={2}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h4">
                    {traceabilityDetails
                        ? traceabilityActions.find((action) => action.key === traceabilityDetails.action)?.label
                        : "Détails de traçabilité"}
                </Typography>
            </Box>
            <Card>
                <StyledCardContent>
                    {traceabilityDetails ? (
                        <Grid container rowSpacing={4} columnSpacing={2} m={2}>
                            <Grid item xs={12} md={6} display="flex" gap={1}>
                                <Box>
                                    <Training />
                                </Box>
                                <Stack spacing={1}>
                                    <Box mt={-0.25}>
                                        <Typography variant="bold" fontSize={18}>
                                            Informations sur la session
                                        </Typography>
                                    </Box>
                                    <TitleTextPatchValue
                                        title="Date de début de formation"
                                        value={content?.startDate}
                                        patches={traceabilityDetails.patch}
                                        action={traceabilityDetails.action}
                                        path="/startDate"
                                    />
                                    <TitleTextPatchValue
                                        title="Nombre minimum de participants"
                                        value={content?.inscriptionNumberMin}
                                        patches={traceabilityDetails.patch}
                                        action={traceabilityDetails.action}
                                        path="/inscriptionNumberMin"
                                    />
                                    <TitleTextPatchValue
                                        title="Nombre maximum de participants"
                                        value={content?.inscriptionNumberMax}
                                        patches={traceabilityDetails.patch}
                                        action={traceabilityDetails.action}
                                        path="/inscriptionNumberMax"
                                    />
                                    {content?.comment && (
                                        <TitleTextPatchValue
                                            title="Commentaire"
                                            value={content?.comment}
                                            patches={traceabilityDetails.patch}
                                            action={traceabilityDetails.action}
                                            path="/comment"
                                        />
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6} display="flex" gap={1}>
                                <Box>
                                    <Location />
                                </Box>
                                <Stack spacing={1}>
                                    <Box mt={-0.25}>
                                        <Typography variant="bold" fontSize={18}>
                                            Lieu de la session
                                        </Typography>
                                    </Box>
                                    <TitleTextPatchValue
                                        title="DDPP responsable"
                                        value={content?.ddppName}
                                        patches={traceabilityDetails.patch}
                                        action={traceabilityDetails.action}
                                        path="/ddpp/name"
                                    />
                                    <TitleTextPatchValue
                                        title="Mode de formation"
                                        value={SessionMode[content?.sessionMode]}
                                        patches={traceabilityDetails.patch}
                                        action={traceabilityDetails.action}
                                        path="/trainingSessionMode"
                                    />
                                    {!!content?.address && (
                                        <>
                                            <TitleTextPatchValue
                                                title="Adresse"
                                                value={content.address}
                                                patches={traceabilityDetails.patch}
                                                action={traceabilityDetails.action}
                                                path="/address"
                                            />
                                            <TitleTextPatchValue
                                                title="Complément d'adresse"
                                                value={content.complementaryAddress}
                                                patches={traceabilityDetails.patch}
                                                action={traceabilityDetails.action}
                                                path="/complementaryAddress"
                                            />
                                            <TitleTextPatchValue
                                                title="Code postal"
                                                value={content.postalCode}
                                                patches={traceabilityDetails.patch}
                                                action={traceabilityDetails.action}
                                                path="/postalCode"
                                            />
                                            <TitleTextPatchValue
                                                title="Ville"
                                                value={content.city}
                                                patches={traceabilityDetails.patch}
                                                action={traceabilityDetails.action}
                                                path="/city"
                                            />
                                        </>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Stack direction="row" spacing={1}>
                                    <Box>
                                        <Location />
                                    </Box>
                                    <Box mt={-0.25}>
                                        <Typography variant="bold" fontSize={18}>
                                            Formateurs
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Grid container columnSpacing={2} m={2}>
                                    {content?.trainers.map((t, index) => (
                                        <Grid
                                            item
                                            xs={12}
                                            md={4}
                                            display="flex"
                                            flexDirection="column"
                                            gap={1}
                                            key={index}
                                        >
                                            <Typography
                                                variant="bold"
                                                fontSize={15}
                                            >{`Formateur ${index + 1}`}</Typography>
                                            <TitleTextPatchValue
                                                title="Type de formateur"
                                                value={TrainerType[t?.trainerType]}
                                                patches={traceabilityDetails.patch}
                                                action={traceabilityDetails.action}
                                                path={`trainers/${index}/trainerType`}
                                            />
                                            {TrainerType[t?.trainerType] === TrainerType.VETERINARY && (
                                                <TitleTextPatchValue
                                                    title="Identifiant vétérinaire"
                                                    value={t.veterinaryId}
                                                    patches={traceabilityDetails.patch}
                                                    action={traceabilityDetails.action}
                                                    path={`trainers/${index}/veterinaryId`}
                                                />
                                            )}
                                            {TrainerType[t?.trainerType] === TrainerType.MINISTRY_AGENT && (
                                                <>
                                                    <TitleTextPatchValue
                                                        title="Prénom"
                                                        value={t.firstname}
                                                        patches={traceabilityDetails.patch}
                                                        action={traceabilityDetails.action}
                                                        path={`trainers/${index}/firstname`}
                                                    />
                                                    <TitleTextPatchValue
                                                        title="Nom"
                                                        value={t.lastname}
                                                        patches={traceabilityDetails.patch}
                                                        action={traceabilityDetails.action}
                                                        path={`trainers/${index}/lastname`}
                                                    />
                                                </>
                                            )}
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Typography align="center">
                            Sélectionnez une traçabilité dont vous souhaitez voir les détails
                        </Typography>
                    )}
                </StyledCardContent>
            </Card>
        </Stack>
    );
}
