// === NPM
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Add } from "@mui/icons-material";
import { Box, Card, CardContent, Stack, Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams, GridSortModel } from "@mui/x-data-grid-pro";
// === LOCAL
import { ReactComponent as Publish } from "@/assets/icons/actions/publish.svg";
import ArchiveAction from "@/components/generics/actions/ArchiveAction";
import DeleteAction from "@/components/generics/actions/DeleteAction";
import EditAction from "@/components/generics/actions/EditAction";
import IconActionButton from "@/components/generics/actions/IconActionButton";
import ViewAction from "@/components/generics/actions/ViewAction";
import GenericButton from "@/components/generics/buttons/GenericButton";
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import GenericTable from "@/components/generics/table/GenericTable";
import useTimeout from "@/hooks/useTimeout";
import {
    ActionsColumnProps,
    FilterType,
    GenericGridColumns,
    HttpStatus,
    IPagination,
    SortDirection,
    UserTypeHeader,
} from "@/interfaces/global";
import { DisplayType } from "@/interfaces/notification";
import { IReferential } from "@/interfaces/referential";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { CampaignStatusStyle, colors, NotificationDisplayTypeStyle } from "@/resources/CssConstant";
import { UserSituation } from "@/resources/PermissionConstant";
import { convertEnumToKeyLabelObject, createPayload, hasAccess } from "@/resources/utils";
import { routerLinks } from "@/routers/RouterConstant";
import { useAuth } from "@/routers/useAuth";
import NotificationService from "@/services/NotificationService";
import {
    AuthorType,
    BroadcastType,
    CampaignStatus,
    INotificationShort,
    NotificationFilters,
    NotificationStatus,
    NotificationType,
    SendMode,
} from "../../interface";
import { useSelectedNotification } from "../useSelectedNotification";
import UpdatePublishedNotificationDialog from "./containers/UpdatePublishedNotificationDialog";
import ViewDialog from "./containers/ViewDialog";

interface NotificationsDashboardProps {
    isAutomaticNotifications: boolean;
}

export default function NotificationsDashboardTemplate({
    isAutomaticNotifications,
}: Readonly<NotificationsDashboardProps>) {
    const navigate = useNavigate();
    const auth = useAuth();
    const isAnmvNotif = auth.userInfo.situation === UserSituation.ANMV_NOTIF;

    const { selectedNotification, setSelectedNotification } = useSelectedNotification();

    const [notifications, setNotifications] = useState<INotificationShort[]>([]);
    const [inputFilters, setInputFilters] = useState<NotificationFilters>({
        status: [],
        campaignStatus: [],
        internalId: "",
        displayType: [],
        sendMode: [],
        broadcastType: [],
        subject: "",
        authorType: [],
        authorLastname: "",
        authorFirstname: "",
        authorId: "",
        publicationDate: [null, null],
        publicationEndDate: [null, null],
    });
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "publicationDate", sort: "asc" }]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [openArchiveDialog, setOpenArchiveDialog] = useState<boolean>(false);
    const [openPublishDialog, setOpenPublishDialog] = useState<boolean>(false);
    const [openViewDialog, setOpenViewDialog] = useState<boolean>(false);
    const [openModifyPublishedNotification, setOpenModifyPublishedNotification] = useState<boolean>(false);
    const [selectedNotificationUuid, setSelectedNotificationUuid] = useState<string>(null);
    const [authorsTypes, setAuthorsTypes] = useState<IReferential[]>([]);

    const selectedNotificationShort = notifications.find((notif) => notif.uuid === selectedNotificationUuid);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getAuthorsTypes();
    }, []);

    useEffect(() => {
        getNotifications();
    }, [pagination, sortModel]);

    const getAuthorsTypes = async () => {
        const res = await NotificationService.getAuthorsTypes();
        if (res.status === HttpStatus.OK) {
            setAuthorsTypes(res.data);
        }
    };

    const getNotifications = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            type: isAutomaticNotifications ? NotificationType.AUTO : NotificationType.MANUAL,
            ...createPayload(inputFilters),
        };
        const res = await NotificationService.getNotifications(UserTypeHeader.ADMIN, payload);
        if (res.status === HttpStatus.OK) {
            setNotifications(res.data);
            setRowCount(+res.headers[CALYPSO_HEADERS.TABLE_COUNT]);
        }
    };

    const canEditNotification = (status: string, sendMode: string) => {
        if (isAutomaticNotifications) {
            return NotificationStatus[status] === NotificationStatus.PUBLISHED;
        } else {
            return (
                NotificationStatus[status] !== NotificationStatus.ARCHIVED &&
                (NotificationStatus[status] !== NotificationStatus.PUBLISHED || SendMode[sendMode] !== SendMode.MAIL)
            );
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const handleArchiveNotification = async (confirm: boolean) => {
        if (!confirm) {
            setOpenArchiveDialog(false);
            setSelectedNotificationUuid(null);
            return;
        }
        const res = await NotificationService.archiveNotification(selectedNotificationUuid, UserTypeHeader.ADMIN);
        if (res.status === HttpStatus.NO_CONTENT) {
            reloadNotifications("Notification archivée avec succès");
            setOpenArchiveDialog(false);
            setSelectedNotificationUuid(null);
        }
    };

    const handleDeleteNotification = async (confirm: boolean) => {
        if (!confirm) {
            setOpenDeleteDialog(false);
            setSelectedNotification(null);
            return;
        }
        const res = await NotificationService.deleteNotification(selectedNotificationUuid, UserTypeHeader.ADMIN);
        if (res.status === HttpStatus.NO_CONTENT) {
            reloadNotifications("Notification supprimée avec succès");
            setOpenDeleteDialog(false);
            setSelectedNotificationUuid(null);
        }
    };

    const handlePublishNotification = async (confirm: boolean) => {
        if (!confirm) {
            setOpenPublishDialog(false);
            setSelectedNotificationUuid(null);
            setSelectedNotification(null);
            return;
        }
        const res = await NotificationService.scheduleNotification(selectedNotificationUuid, {
            publicationDate: selectedNotificationShort.publicationDate,
        });
        if (res.status === HttpStatus.NO_CONTENT) {
            reloadNotifications("Notification programmée avec succès");
            setOpenPublishDialog(false);
            setSelectedNotificationUuid(null);
            setSelectedNotification(null);
        }
    };

    const handleUpdatePublishedNotification = async (publicationEndDate: string) => {
        const res = await NotificationService.patchPublishedNotification(selectedNotification.uuid, {
            publicationEndDate: publicationEndDate,
        });
        if (res.status === HttpStatus.NO_CONTENT) {
            setOpenModifyPublishedNotification(false);
            reloadNotifications("Notification mise à jour avec succès");
            setSelectedNotification(null);
        }
    };

    const reloadNotifications = (message: string) => {
        getNotifications();
        toast.success(message);
    };

    const handleModifyDialog = async (uuid: string) => {
        const notification = await NotificationService.getNotificationByUuid(uuid, UserTypeHeader.ADMIN);
        if (notification.status === HttpStatus.OK) {
            setSelectedNotification(notification.data);
            if (NotificationStatus[notification.data.status] === NotificationStatus.PUBLISHED) {
                setOpenModifyPublishedNotification(true);
            } else {
                navigate(routerLinks.admin.notifications.form());
            }
        }
    };

    const handleViewDialog = async (uuid: string) => {
        setSelectedNotificationUuid(uuid);
        const notification = await NotificationService.getNotificationByUuid(uuid, UserTypeHeader.ADMIN);
        if (notification.status === HttpStatus.OK) {
            setSelectedNotification(notification.data);
            setOpenViewDialog(true);
        }
    };

    const columns: GenericGridColumns[] = [
        {
            field: "status",
            headerName: "Statut",
            flex: 0.7,
            valueGetter: (params: GridRenderCellParams) => NotificationStatus[params.row.status],
        },
        {
            field: "campaignStatus",
            headerName: "Statut d'envoi",
            flex: 1.3,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tooltip title={CampaignStatus[params.row.campaignStatus]}>
                        <Typography
                            style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                minHeight: 0,
                                ...CampaignStatusStyle[params.row.campaignStatus],
                            }}
                            variant="body2"
                        >
                            {CampaignStatus[params.row.campaignStatus]}
                        </Typography>
                    </Tooltip>
                );
            },
        },
        {
            field: "internalId",
            headerName: "Identifiant",
            flex: 1,
        },
        {
            field: "displayType",
            headerName: "Catégorie",
            flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tooltip title={DisplayType[params.row.displayType]}>
                        <Typography
                            style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                minHeight: 0,
                                ...NotificationDisplayTypeStyle[params.row.displayType],
                            }}
                            variant="body2"
                        >
                            {DisplayType[params.row.displayType]}
                        </Typography>
                    </Tooltip>
                );
            },
        },
        {
            field: "sendMode",
            headerName: "Mode d'envoi",
            flex: 1.2,
            valueGetter: (params: GridRenderCellParams) => SendMode[params.row.sendMode],
        },
        {
            field: "broadcastType",
            headerName: "Type de diffusion",
            flex: 1.5,
            valueGetter: (params: GridRenderCellParams) => BroadcastType[params.row.broadcastType],
        },
        {
            field: "subject",
            headerName: "Objet",
            flex: 1,
        },
        {
            field: "authorType",
            headerName: "Type émetteur",
            hide: !hasAccess(auth.userInfo.situation, [UserSituation.ADMIN_CALYPSO]),
            flex: 1.3,
            valueGetter: (params: GridRenderCellParams) =>
                authorsTypes.find((type) => type.key === params.row.authorType)?.label,
        },
        {
            field: "authorLastname",
            headerName: "Nom",
            flex: 0.6,
        },
        {
            field: "authorFirstname",
            headerName: "Prénom",
            flex: 0.8,
        },
        {
            field: "authorId",
            headerName: "ID Calypso",
            flex: 1,
        },
        {
            field: "publicationDate",
            headerName: "Date de publication",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) => new Date(params.row.publicationDate).toLocaleString(),
        },
        {
            field: "publicationEndDate",
            headerName: "Date de fin de publication",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) => new Date(params.row.publicationEndDate).toLocaleString(),
        },
        {
            ...ActionsColumnProps,
            renderCell: (params: GridRenderCellParams) => (
                <Box>
                    <ViewAction title="Voir" onClick={() => handleViewDialog(params.row.uuid)} />
                    {canEditNotification(params.row.status, params.row.sendMode) && (
                        <EditAction title="Modifier" onClick={() => handleModifyDialog(params.row.uuid)} />
                    )}
                    {NotificationStatus[params.row.status] !== NotificationStatus.PUBLISHED && (
                        <DeleteAction
                            title="Supprimer"
                            onClick={() => {
                                setSelectedNotificationUuid(params.row.uuid);
                                setOpenDeleteDialog(true);
                            }}
                        />
                    )}
                    {NotificationStatus[params.row.status] === NotificationStatus.CREATED && (
                        <IconActionButton
                            title="Publier"
                            onClick={() => {
                                setSelectedNotificationUuid(params.row.uuid);
                                setOpenPublishDialog(true);
                            }}
                            icon={<Publish />}
                        />
                    )}
                    {NotificationStatus[params.row.status] === NotificationStatus.PUBLISHED && (
                        <ArchiveAction
                            title="Archiver"
                            onClick={() => {
                                setSelectedNotificationUuid(params.row.uuid);
                                setOpenArchiveDialog(true);
                            }}
                        />
                    )}
                </Box>
            ),
            width: 200,
        },
    ];

    const filterConfigurations: FilterConfigurations<NotificationFilters> = {
        status: {
            label: "Statut",
            type: FilterType.SELECT,
            values: convertEnumToKeyLabelObject(NotificationStatus),
        },
        campaignStatus: {
            label: "Statut d'envoi",
            type: FilterType.SELECT,
            values: convertEnumToKeyLabelObject(CampaignStatus),
        },
        internalId: { label: "Identifiant", type: FilterType.INPUT },
        displayType: {
            label: "Catégorie",
            type: FilterType.SELECT,
            values: convertEnumToKeyLabelObject(DisplayType),
        },
        sendMode: {
            label: "Mode d'envoi",
            type: FilterType.SELECT,
            values: convertEnumToKeyLabelObject(SendMode),
        },
        broadcastType: {
            label: "Type de diffusion",
            type: FilterType.SELECT,
            values: convertEnumToKeyLabelObject(BroadcastType),
        },
        subject: { label: "Objet", type: FilterType.INPUT },
        ...(!isAnmvNotif && {
            authorType: {
                label: "Type émetteur",
                type: FilterType.SELECT,
                values: isAutomaticNotifications ? authorsTypes : convertEnumToKeyLabelObject(AuthorType),
            },
        }),
        authorLastname: { label: "Nom", type: FilterType.INPUT },
        authorFirstname: { label: "Prénom", type: FilterType.INPUT },
        authorId: { label: "ID Calypso", type: FilterType.INPUT },
        publicationDate: { label: "Date de publication", type: FilterType.DATEPICKER },
        publicationEndDate: { label: "Date de fin de publication", type: FilterType.DATEPICKER },
    };

    return (
        <>
            <Stack width="100%" spacing={3}>
                <Typography variant="h4">
                    {isAutomaticNotifications
                        ? "Suivi des notifications automatiques"
                        : "Gestion des notifications manuelles"}
                </Typography>
                {!isAutomaticNotifications && (
                    <Box justifyContent="flex-end" display="flex">
                        <GenericButton
                            label="Créer une notification"
                            startIcon={<Add />}
                            onClick={() => {
                                setSelectedNotification(null);
                                navigate(routerLinks.admin.notifications.form());
                            }}
                        />
                    </Box>
                )}
                <Card>
                    <CardContent
                        sx={{
                            "& .archived": {
                                backgroundColor: colors.lightGray,
                            },
                        }}
                    >
                        <GenericFilters
                            inputFilters={inputFilters}
                            filterConfigurations={filterConfigurations}
                            initialValues={{
                                status: [],
                                campaignStatus: [],
                                internalId: "",
                                displayType: [],
                                sendMode: [],
                                broadcastType: [],
                                subject: "",
                                authorType: [],
                                authorLastname: "",
                                authorFirstname: "",
                                authorId: "",
                                publicationDate: [null, null],
                                publicationEndDate: [null, null],
                            }}
                            setInputFilters={setInputFilters}
                        />

                        <GenericTable
                            rows={notifications}
                            columns={columns}
                            getRowId={(row) => row.uuid}
                            onPageSizeChange={handlePageSizeChange}
                            onPageChange={handlePageChange}
                            page={pagination.page}
                            pageSize={pagination.pageSize}
                            autoHeight
                            sortingMode="server"
                            paginationMode="server"
                            sortModel={sortModel}
                            rowCount={rowCount}
                            onSortModelChange={(model) => setSortModel(model)}
                            sortingOrder={[SortDirection.ASC, SortDirection.DESC]}
                            getRowClassName={(params) =>
                                params.row.status === NotificationStatus.ARCHIVED ? "archived" : ""
                            }
                            filterMode="server"
                        />
                    </CardContent>
                </Card>
            </Stack>

            {openDeleteDialog && (
                <GenericConfirmDialog
                    title={`Supprimer la notification ${selectedNotificationShort.internalId}`}
                    message={`Êtes-vous sûr de vouloir supprimer la notification n°${selectedNotificationShort.internalId} ? Vous ne pourrez pas revenir en arrière.`}
                    onClose={handleDeleteNotification}
                />
            )}

            {openArchiveDialog && (
                <GenericConfirmDialog
                    title="Archivage d'une notification"
                    message={`Êtes-vous sûr de vouloir archiver la notification n°${selectedNotificationShort.internalId} ? Vous ne pourrez pas revenir en arrière.`}
                    onClose={handleArchiveNotification}
                />
            )}

            {openPublishDialog && (
                <GenericConfirmDialog
                    title="Publication d'une notification"
                    message={`La publication de la notification n°${
                        selectedNotificationShort.internalId
                    } aura lieu le : ${new Date(
                        selectedNotificationShort.publicationDate
                    ).toLocaleString()}. Voulez-vous continuer ?`}
                    onClose={handlePublishNotification}
                />
            )}

            {openViewDialog && (
                <ViewDialog
                    onClose={() => {
                        setSelectedNotification(null);
                        setSelectedNotificationUuid(null);
                        setOpenViewDialog(false);
                    }}
                    publishNotification={() => {
                        handlePublishNotification(true);
                    }}
                />
            )}

            {openModifyPublishedNotification && (
                <UpdatePublishedNotificationDialog
                    onValid={handleUpdatePublishedNotification}
                    onClose={() => setOpenModifyPublishedNotification(false)}
                />
            )}
        </>
    );
}
