// === NPM
import React from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, MenuItem, Pagination, Select, Typography } from "@mui/material";
// === LOCAL
import { defaultRowsPerPage } from "@/resources/AppConstant";

interface GenericPaginationProps {
    page: number;
    pageCount: number;
    pageSize: number;
    rowsPerPageOptions: number[];
    setPageSize: (pageSize: number) => void;
    setPage: (page: number) => void;
}

GenericPagination.defaultProps = {
    rowsPerPageOptions: defaultRowsPerPage,
};

export function GenericPagination({
    page,
    pageCount,
    pageSize,
    rowsPerPageOptions,
    setPageSize,
    setPage,
}: Readonly<GenericPaginationProps>) {
    return (
        <Box display="flex" justifyContent="flex-end">
            <Typography variant="body2" sx={{ display: "flex", alignItems: "center", mr: 2 }}>
                Lignes par page:
            </Typography>
            <Select
                value={pageSize}
                size="small"
                disableUnderline
                sx={{ "alignItems": "end", "& .MuiSelect-select": { pb: 0 } }}
                variant="standard"
                onChange={(event) => setPageSize(+event.target.value)}
                IconComponent={KeyboardArrowDown}
            >
                {rowsPerPageOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
            <Pagination
                color="primary"
                count={pageCount}
                page={page + 1}
                onChange={(event, value) => setPage(value - 1)}
                sx={{
                    ".MuiPaginationItem-root": {
                        minWidth: 24,
                        height: 24,
                        borderRadius: 2,
                    },
                }}
            />
        </Box>
    );
}
