// === Import: NPM
import React from "react";
// === Import: LOCAL
import { ReactComponent as Edit } from "@/assets/icons/actions/edit.svg";
import { IActionButton } from "@/interfaces/global";
import IconActionButton from "./IconActionButton";

export default function EditAction({ title, onClick }: IActionButton) {
    return <IconActionButton title={title} onClick={onClick} icon={<Edit />} />;
}
