// === NPM
import React, { useEffect, useState } from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
// === LOCAL
import { ReactComponent as Finished } from "@/assets/icons/training/filters/finished.svg";
import { ReactComponent as Generate } from "@/assets/icons/training/filters/generate.svg";
import { ReactComponent as Presence } from "@/assets/icons/training/filters/presence.svg";
import { FilterConfigurations } from "@/components/generics/filters/GenericFilters";
import { PresetFilterConfigurations } from "@/components/generics/presetFilters/GenericPresetFilters";
import {
    ISessionCountsDdpp,
    IViewDdppFilters,
    SessionStatus,
} from "@/components/HealthAccreditationTraining/interface";
import { useDepartments } from "@/context/useDepartmentContext";
import { FilterType, HttpStatus, UserTypeHeader } from "@/interfaces/global";
import { SessionMode } from "@/interfaces/training";
import { convertEnumToKeyLabelObject, getEnumKeyByValue } from "@/resources/utils";
import TrainingService from "@/services/TrainingService";
import SessionsTable from "../SessionsTable";

const initialValues: IViewDdppFilters = {
    internalId: "",
    trainingTitle: "",
    startDate: [null, null],
    registrationLimitDate: [null, null],
    department: [],
    sessionMode: [],
    status: [],
    needAttestationSign: "",
    needAttendanceSheetSign: "",
};

export default function ViewDdpp() {
    const { departments } = useDepartments();

    const [inputFilters, setInputFilters] = useState<IViewDdppFilters>({
        internalId: "",
        trainingTitle: "",
        startDate: [null, null],
        registrationLimitDate: [null, null],
        department: [],
        sessionMode: [],
        status: [],
        needAttestationSign: "",
        needAttendanceSheetSign: "",
    });
    const [sessionCounts, setSessionCounts] = useState<ISessionCountsDdpp>(null);

    useEffect(() => {
        getSessionCounts();
    }, []);

    const getSessionCounts = async () => {
        const res = await TrainingService.getHealthAccreditationTrainingSessionCounts(UserTypeHeader.DDPP);
        if (res.status === HttpStatus.OK) {
            setSessionCounts(res.data);
        }
    };

    const renderActionToBeDone = (params: GridRenderCellParams) => {
        if (
            params.row.status === getEnumKeyByValue(SessionStatus, SessionStatus.CANCELED) ||
            params.row.status === getEnumKeyByValue(SessionStatus, SessionStatus.FINISHED)
        ) {
            return "Aucune";
        }

        if (params.row.needAttestationSign) {
            return "Générer et signer les attestations";
        }
        if (params.row.needAttendanceSheetSign) {
            return "Télécharger et signer la feuille d'émargement";
        }
        return "Aucune";
    };

    const filterConfigurations: FilterConfigurations<IViewDdppFilters> = {
        internalId: { type: FilterType.INPUT, label: "Identifiant" },
        trainingTitle: { type: FilterType.INPUT, label: "Nom de la formation" },
        startDate: { type: FilterType.DATEPICKER, label: "Date de formation" },
        registrationLimitDate: { type: FilterType.DATEPICKER, label: "Date limite d'inscription" },
        department: {
            type: FilterType.SELECT_AUTOCOMPLETE,
            label: "Département",
            values: departments.map((d) => ({ key: d.inseeCode, label: `${d.inseeCode} - ${d.name}` })),
        },
        sessionMode: {
            type: FilterType.SELECT,
            label: "Mode de présence",
            values: convertEnumToKeyLabelObject(SessionMode),
        },
        needAttestationSign: {
            type: FilterType.SINGLE_SELECT,
            label: "Attestations à signer",
            values: [
                { key: "true", label: "Oui" },
                { key: "false", label: "Non" },
            ],
        },
        needAttendanceSheetSign: {
            type: FilterType.SINGLE_SELECT,
            label: "Feuille d'émargement à signer",
            values: [
                { key: "true", label: "Oui" },
                { key: "false", label: "Non" },
            ],
        },
        status: { type: FilterType.SELECT, label: "Statut", values: convertEnumToKeyLabelObject(SessionStatus) },
    };

    const presetFilterConfigurationsOvvt: PresetFilterConfigurations<ISessionCountsDdpp, IViewDdppFilters> = {
        generateSign: {
            icon: <Generate />,
            value: sessionCounts?.generateSign,
            title: "Générer et signer les attestations de formation",
            config: {
                ...initialValues,
                needAttestationSign: "true",
            },
        },
        editAttendanceSheet: {
            icon: <Presence />,
            value: sessionCounts?.editAttendanceSheet,
            title: "Télécharger et signer la feuille d'émargement",
            config: {
                ...initialValues,
                needAttendanceSheetSign: "true",
            },
        },
        finishedSessions: {
            icon: <Finished />,
            value: sessionCounts?.finishedSessions,
            title: "Sessions terminées",
            config: {
                ...initialValues,
                status: [getEnumKeyByValue(SessionStatus, SessionStatus.FINISHED)],
            },
        },
    };

    return (
        <SessionsTable
            inputFilters={inputFilters}
            filterConfigurations={filterConfigurations}
            initialValues={{
                internalId: "",
                trainingTitle: "",
                startDate: [null, null],
                registrationLimitDate: [null, null],
                department: [],
                sessionMode: [],
                status: [],
                needAttestationSign: "",
                needAttendanceSheetSign: "",
            }}
            setInputFilters={setInputFilters}
            presetFilterConfigurations={presetFilterConfigurationsOvvt}
            renderActionToBeDone={renderActionToBeDone}
        />
    );
}
