// === NPM
import React, { useCallback, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { Add } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import update from "immutability-helper";
// === LOCAL
import GenericButton from "@/components/generics/buttons/GenericButton";
import { ICategory, IQuestion, IQuestionShort } from "@/interfaces/faq";
import { HttpStatus } from "@/interfaces/global";
import FaqService from "@/services/FaqService";
import { FaqOutletContext } from "../../interface";
import QuestionDialog from "./containers/QuestionDialog";
import QuestionGroup from "./containers/QuestionGroup";
import QuestionSkeleton from "./containers/QuestionSkeleton";

export default function Question() {
    const { categories, questions, loading, setQuestions, setCategories, getQuestions } =
        useOutletContext<FaqOutletContext>();

    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);

    const handleCreateQuestion = async (question: IQuestion) => {
        const res = await FaqService.postQuestion(question);
        if (res.status === HttpStatus.CREATED) {
            await getQuestions();
            setOpenCreateDialog(false);
            toast.success("Question créée avec succès");
        }
    };

    const findQuestion = useCallback(
        (uuid: string) => {
            const question = questions.find((c) => c.uuid === uuid);
            return {
                question,
                index: questions.indexOf(question),
            };
        },
        [questions]
    );

    const calculateCategoryQuestionCount = (category: ICategory, question: IQuestionShort): ICategory[] => {
        if (category.uuid === question.categoryUuid) return categories;

        const categoryIndex = categories.findIndex((c) => c.uuid === category.uuid);
        const oldCategoryIndex = categories.findIndex((c) => c.uuid === question.categoryUuid);
        const newCategories = [...categories];
        newCategories[oldCategoryIndex] = {
            ...categories[oldCategoryIndex],
            questionCount: categories[oldCategoryIndex].questionCount - 1,
        };
        newCategories[categoryIndex] = {
            ...category,
            questionCount: category.questionCount + 1,
        };

        return newCategories;
    };

    const moveQuestion = useCallback(
        (uuid: string, atIndex: number, categoryUuid: string) => {
            const { question, index } = findQuestion(uuid);
            const hoverredQuestionCategory = categories.find((c) => c.uuid === categoryUuid);
            const newCategories = calculateCategoryQuestionCount(hoverredQuestionCategory, question);
            setCategories(newCategories);
            setQuestions(
                update(questions, {
                    $splice: [
                        [index, 1],
                        [atIndex, 0, { ...question, categoryUuid }],
                    ],
                })
            );
        },
        [findQuestion, categories, questions, setCategories, setQuestions]
    );

    return loading ? (
        <QuestionSkeleton />
    ) : (
        <>
            <Stack height="100%" spacing={2} width="100%">
                <Box justifyContent="space-between" display="flex" sx={{ mb: 2 }}>
                    <Typography variant="h4">Gestion des questions</Typography>
                    <GenericButton
                        label="Ajouter une question"
                        startIcon={<Add />}
                        onClick={() => {
                            setOpenCreateDialog(true);
                        }}
                    />
                </Box>

                {categories.map((category) => (
                    <QuestionGroup
                        key={category.uuid}
                        category={category}
                        questions={questions.filter((q) => q.categoryUuid === category.uuid)}
                        findQuestion={findQuestion}
                        moveQuestion={moveQuestion}
                    />
                ))}
            </Stack>
            {openCreateDialog && (
                <QuestionDialog
                    onClose={() => setOpenCreateDialog(false)}
                    onValid={handleCreateQuestion}
                    title={"Ajouter une question à la FAQ"}
                />
            )}
        </>
    );
}
