// === NPM
import React, { useEffect, useState } from "react";
import { DateRange } from "@mui/lab";
import { Stack, TextField } from "@mui/material";
import { DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterLuxon } from "@mui/x-date-pickers-pro/AdapterLuxon";
import { DateTime } from "luxon";
// === LOCAL
import { ArrayOfTwo } from "@/interfaces/global";

interface DateRangeFilterProps {
    value: ArrayOfTwo;
    name: string;
    onChange: (event, name: string) => void;
}

export default function DateRangeFilter({ value, name, onChange }: Readonly<DateRangeFilterProps>) {
    const [dates, setDates] = useState<DateTime[]>([null, null]);

    useEffect(() => {
        const newDate = value && value.length > 0 ? value.map((v) => (v ? DateTime.fromISO(v) : null)) : [null, null];
        setDates(newDate);
    }, [value]);

    const formatDate = (newValue: DateRange<DateTime>): string[] => {
        return newValue.map((nv, index) => {
            if (!nv) return null;
            if (index === 1) {
                nv = nv.plus({ hours: 23, minutes: 59, seconds: 59 });
            }
            return nv.toISO();
        });
    };

    const handleChange = (newValue: DateRange<DateTime>) => {
        const targetEvent = {
            target: {
                value: newValue.every((val) => !val) ? [null, null] : formatDate(newValue),
            },
        };
        onChange(targetEvent, name);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="FR-fr" localeText={{ start: "", end: "" }}>
            <DateRangePicker
                value={dates as DateRange<DateTime>}
                onChange={(newValue) => {
                    handleChange(newValue);
                }}
                renderInput={(startProps, endProps) => (
                    <Stack spacing={2} direction="row">
                        <TextField size="small" {...startProps} label="A partir du" />
                        <TextField size="small" {...endProps} label="Jusqu'au" />
                    </Stack>
                )}
            />
        </LocalizationProvider>
    );
}
