import { Step } from "react-joyride";
import { GridColDef } from "@mui/x-data-grid-pro";

export interface IPagination {
    page: number;
    pageSize: number;
}

export interface IActionButton {
    title: string;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

export interface GenericGridColumns extends GridColDef {
    optionsValues?: any[];
    sortComparator?: any;
    optionValue?: string;
}

export type Sort = { field: string; direction: string };

// FILTERS

export interface ICityFilter {
    name: string;
    postalCode: string;
    inseeCode: string;
}

export enum FilterType {
    INPUT,
    NUMBER,
    SELECT,
    SELECT_AUTOCOMPLETE,
    DATEPICKER,
    SINGLE_DATEPICKER,
    SINGLE_SELECT,
    CITY_SEARCH,
    YEARPICKER,
    INTERVAL,
}

type FixedSizeArray<N extends number, T> = N extends 0
    ? never[]
    : {
        0: T;
        length: N;
    } & ReadonlyArray<T>;

export type ArrayOfTwo = FixedSizeArray<2, string>;

export enum SortDirection {
    ASC = "asc",
    DESC = "desc",
}

export enum HttpStatus {
    // 2XX
    OK = 200,
    CREATED = 201,
    ACCEPTED = 202,
    NO_CONTENT = 204,
    MULTI_STATUS = 207,
    // 4XX
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
}

export enum HttpMethod {
    PUT = "PUT",
    POST = "POST",
    DELETE = "DELETE",
    GET = "GET",
    PATCH = "PATCH",
}

export interface IReferentialSynchro {
    key: string;
    label: string;
    lastSuccessDate: string;
    lastSynchroDate: string;
    lastStatus: "SUCCESS" | "FAIL" | "WARNING";
    ready: boolean;
}

export interface ITourStep extends Step {
    condition?: () => boolean;
}

export enum TourState {
    START,
    RESET,
    STOP,
    NEXT_OR_PREV,
    RESTART,
    RESUME,
}

export const Quarter = {
    1: "Trimestre 1 : Janvier - Mars",
    2: "Trimestre 2 : Avril - Juin",
    3: "Trimestre 3 : Juillet - Septembre",
    4: "Trimestre 4 : Octobre - Décembre",
};

export interface SearchParams {
    page: number;
    size: number;
    sorts?: string[];
}

export const ActionsColumnProps = {
    field: "actions",
    headerName: "Actions",
    sortable: false,
    filterable: false,
    disableExport: true,
    resizable: false,
};

export interface IExportParams {
    sorts?: string[];
    params?: { [key: string]: string };
}

export interface IExportFilename extends IExportParams {
    filename: string;
}

export enum Loader {
    BUTTON,
    TABLE,
    REQUEST,
}

export enum UserTypeHeader {
    ADMIN = "admin",
    USER = "user",
    OVVT = "ovvt",
    DDPP = "ddpp",
}
