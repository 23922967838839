// === NPM
import React, { useState } from "react";
import { Stack } from "@mui/material";
// === LOCAL
import { IOvvtOrganization } from "@/interfaces/referential";
import OvvtTable from "./containers/OvvtTable";
import Users from "./containers/Users";

export default function Ovvt() {
    const [organizationUsers, setOrganizationUsers] = useState<IOvvtOrganization>(null);

    return (
        <Stack width="100%" spacing={3}>
            <OvvtTable setOrganizationUsers={setOrganizationUsers} />
            <Users organization={organizationUsers} />
        </Stack>
    );
}
