// === Import: NPM
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Card, CardContent, Stack, Typography } from "@mui/material";
// === Import: LOCAL
import { GenericStyledSelect } from "@/components/generics/inputs/GenericStyledSelect";
import { HttpStatus } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import UserService from "@/services/UserService";
import { IProfileDetails, IRight } from "../interface";
import PermissionTable from "./PermissionTable";

interface ProfileDetailsProps {
    profileKey: string;
    setProfileKey: Dispatch<SetStateAction<string>>;
}

export default function ProfileDetails({ profileKey, setProfileKey }: Readonly<ProfileDetailsProps>) {
    const [profiles, setProfiles] = useState<IReferential[]>([]);
    const [profile, setProfile] = useState<IProfileDetails>(null);
    const [rights, setRights] = useState<IRight[]>([]);

    useEffect(() => {
        getProfiles();
        getRights();
    }, []);

    useEffect(() => {
        if (profileKey) getProfile();
        else setProfile(null);
    }, [profileKey]);

    const getProfile = async () => {
        const res = await UserService.getProfile(profileKey);
        if (res.status === HttpStatus.OK) {
            setProfile(res.data);
        }
    };

    const getProfiles = async () => {
        const res = await UserService.getProfiles();
        if (res.status === HttpStatus.OK) {
            setProfiles(res.data);
        }
    };

    const getRights = async () => {
        const res = await UserService.getRights();
        if (res.status === HttpStatus.OK) {
            setRights(res.data);
        }
    };

    return (
        <>
            <Stack direction="row" spacing={4} alignItems="center">
                <Typography variant="h4">Droits par profils</Typography>
                <GenericStyledSelect
                    value={profileKey}
                    onChange={(event) => setProfileKey(event.target.value)}
                    options={profiles}
                    optionsValue="key"
                    size="small"
                    noneLabel="Sélectionnez un profil"
                />
            </Stack>
            <Card>
                <CardContent>
                    <PermissionTable profileRights={profile?.rights} rights={rights} />
                </CardContent>
            </Card>
        </>
    );
}
