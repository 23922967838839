// === NPM
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
// === LOCAL
import {
    FilterConfigurations,
    GenericFilters,
    SortingConfiguration,
} from "@/components/generics/filters/GenericFilters";
import { GenericPagination } from "@/components/generics/layout/GenericPagination";
import {
    ISessionShortVeterinary,
    SessionOutletContext,
    SessionStatus,
} from "@/components/HealthAccreditationTraining/interface";
import { useDepartments } from "@/context/useDepartmentContext";
import { useProvideGlobal } from "@/context/useGlobalContext";
import useTimeout from "@/hooks/useTimeout";
import { ArrayOfTwo, FilterType, HttpStatus, IPagination, SortDirection } from "@/interfaces/global";
import { SessionMode } from "@/interfaces/training";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { convertEnumToKeyLabelObject, createPayload } from "@/resources/utils";
import TrainingService from "@/services/TrainingService";
import SessionCard from "./containers/SessionCard";

interface ViewVeterinaryFilters {
    internalId: string;
    trainingTitle: string;
    startDate: ArrayOfTwo;
    trainingTypeUuid: string[];
    department: string[];
    region: string[];
    sessionMode: string[];
    status: string[];
}

interface ViewVeterinarySortConfig {
    trainingTitle: string;
    startDate: string;
    ectsPoints: string;
}

export default function ViewVeterinary() {
    const { loadingTable } = useProvideGlobal();
    const { departments } = useDepartments();
    const { regions, trainingTypes } = useOutletContext<SessionOutletContext>();

    const [sessions, setSessions] = useState<ISessionShortVeterinary[]>([]);
    const [inputFilters, setInputFilters] = useState<ViewVeterinaryFilters>({
        internalId: "",
        trainingTitle: "",
        startDate: [null, null],
        trainingTypeUuid: [],
        department: [],
        region: [],
        sessionMode: [],
        status: [],
    });
    const [sort, setSort] = useState({ field: "trainingTitle", direction: "asc" });
    const [rowCount, setRowCount] = useState<number>(0);
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getSessions();
    }, [pagination, sort]);

    const getSessions = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: [`${sort.field},${sort.direction}`],
            ...createPayload(inputFilters),
        };
        const res = await TrainingService.getHealthAccreditationTrainingSessions(payload);
        if (res.status === HttpStatus.OK) {
            setSessions(res.data as ISessionShortVeterinary[]);
            setRowCount(+res.headers[CALYPSO_HEADERS.TABLE_COUNT]);
        }
    };

    const renderSkeleton = () => (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
                <Skeleton variant="rounded" width={300} height={350} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
                <Skeleton variant="rounded" width={300} height={350} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
                <Skeleton variant="rounded" width={300} height={350} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
                <Skeleton variant="rounded" width={300} height={350} />
            </Grid>
        </Grid>
    );

    const renderContent = () =>
        sessions.length > 0 ? (
            <Grid container spacing={2}>
                {sessions.map((session) => (
                    <Grid item xs={12} sm={6} lg={4} xl={3} key={session.internalId}>
                        <SessionCard session={session} refresh={getSessions} />
                    </Grid>
                ))}
            </Grid>
        ) : (
            <Typography sx={{ display: "flex", justifyContent: "center" }}>Aucune formation trouvée.</Typography>
        );

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ ...pagination, pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const filterConfigurations: FilterConfigurations<ViewVeterinaryFilters> = {
        internalId: { type: FilterType.INPUT, label: "Identifiant session" },
        trainingTitle: { type: FilterType.INPUT, label: "Formation" },
        startDate: { type: FilterType.DATEPICKER, label: "Date de formation" },
        trainingTypeUuid: {
            type: FilterType.SELECT,
            label: "Type",
            values: trainingTypes.map((t) => ({ key: t.uuid, label: t.label })),
        },
        department: {
            type: FilterType.SELECT_AUTOCOMPLETE,
            label: "Département",
            values: departments.map((d) => ({ key: d.inseeCode, label: `${d.inseeCode} - ${d.name}` })),
        },
        region: {
            type: FilterType.SELECT_AUTOCOMPLETE,
            label: "Région",
            values: regions.map((r) => ({ key: r.inseeCode, label: `${r.inseeCode} - ${r.name}` })),
        },
        sessionMode: {
            type: FilterType.SELECT,
            label: "Mode",
            values: convertEnumToKeyLabelObject(SessionMode),
        },
        status: {
            type: FilterType.SELECT,
            label: "Statut",
            values: convertEnumToKeyLabelObject(SessionStatus).filter(
                (status) =>
                    SessionStatus[status.key] === SessionStatus.ACTIVE ||
                    SessionStatus[status.key] === SessionStatus.ACTIVE_CLOSED
            ),
        },
    };

    const sortingConfiguration: SortingConfiguration<ViewVeterinarySortConfig>[] = [
        {
            label: "Titre de la formation - Alphabétique",
            value: SortDirection.ASC,
            field: "trainingTitle",
        },
        {
            label: "Titre de la formation - Alphabétique inverse",
            value: SortDirection.DESC,
            field: "trainingTitle",
        },
        {
            label: "Date de formation - Du plus ancien au plus récent",
            value: SortDirection.ASC,
            field: "startDate",
        },
        {
            label: "Date de formation - Du plus récent au plus ancien",
            value: SortDirection.DESC,
            field: "startDate",
        },
        {
            label: "Nombre de points ECTS - Croissant",
            value: SortDirection.ASC,
            field: "ectsPoints",
        },
        {
            label: "Nombre de points ECTS - Décroissant",
            value: SortDirection.DESC,
            field: "ectsPoints",
        },
    ];

    return (
        <Stack height="100%" spacing={2} width="100%">
            <Typography variant="h4">
                Les sessions de formation en vue du maintien de l'habilitation sanitaire
            </Typography>
            <Box>
                <Box mb={2}>
                    <GenericFilters
                        inputFilters={inputFilters}
                        filterConfigurations={filterConfigurations}
                        initialValues={{
                            internalId: "",
                            trainingTitle: "",
                            startDate: [null, null],
                            trainingTypeUuid: [],
                            department: [],
                            region: [],
                            sessionMode: [],
                            status: [],
                        }}
                        setInputFilters={setInputFilters}
                        sortingConfigurations={sortingConfiguration}
                        sort={sort}
                        setSort={setSort}
                    />
                </Box>
                {loadingTable ? renderSkeleton() : renderContent()}
            </Box>
            <GenericPagination
                page={pagination.page}
                pageSize={pagination.pageSize}
                pageCount={Math.ceil(rowCount / pagination.pageSize)}
                setPage={handlePageChange}
                setPageSize={handlePageSizeChange}
            />
        </Stack>
    );
}
