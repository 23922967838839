// === NPM
import { AxiosResponse } from "axios";
// === LOCAL
import {
    IAnomalyType,
    IHealthReporting,
    IHealthReportingCounts,
    IHealthReportingFilters,
    IHealthReportingForm,
    IHealthReportingFull,
    IHealthReportingStatusForm,
} from "@/components/HealthReporting/AnimalAbuse/interface";
import { HttpMethod, Loader, SearchParams } from "@/interfaces/global";
import { CALYPSO_HEADERS, customClientHeader } from "@/resources/AppConstant";
import { formatParams } from "@/resources/utils";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class HealthReportingService {
    async getAnomalyTypes(): Promise<AxiosResponse<IAnomalyType[]>> {
        return HttpService.fetch({
            url: endpoints.healthReportingService.referential.anomalyTypes(),
            method: HttpMethod.GET,
        });
    }

    async getDraft(noToaster = false): Promise<AxiosResponse<IHealthReportingForm>> {
        const clientHeader = { ...customClientHeader };
        clientHeader.noToasterOnError = noToaster;
        return HttpService.fetch({
            url: endpoints.healthReportingService.draft(),
            method: HttpMethod.GET,
            loader: Loader.REQUEST,
            headers: {
                [CALYPSO_HEADERS.CLIENT_HEADER]: JSON.stringify(clientHeader),
            },
        });
    }

    async deleteDraft(): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.healthReportingService.draft(),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    async putDraft(data: IHealthReportingForm, attachments?: File[]): Promise<AxiosResponse> {
        const blob = new Blob([JSON.stringify(data)], {
            type: "application/json",
        });
        const formData = new FormData();
        attachments?.forEach((item) => {
            formData.append("files", item);
        });
        formData.append("healthReportingDraft", blob);
        return HttpService.fetch({
            url: endpoints.healthReportingService.draft(),
            method: HttpMethod.PUT,
            loader: Loader.REQUEST,
            data: formData,
        });
    }

    async postHealthReporting(data: IHealthReportingForm, attachments?: File[]): Promise<AxiosResponse> {
        const blob = new Blob([JSON.stringify(data)], {
            type: "application/json",
        });
        const formData = new FormData();
        attachments?.forEach((item) => {
            formData.append("files", item);
        });
        formData.append("healthReporting", blob);
        return HttpService.fetch({
            url: endpoints.healthReportingService.reportings(),
            method: HttpMethod.POST,
            loader: Loader.REQUEST,
            data: formData,
        });
    }

    async getHealthReportingFile(uuid: string, fileUuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.healthReportingService.file(uuid, fileUuid),
            method: HttpMethod.GET,
            responseType: "blob",
        });
    }

    async getDraftFile(fileUuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.healthReportingService.draftFile(fileUuid),
            method: HttpMethod.GET,
            responseType: "blob",
        });
    }

    async getHealthReportings(
        payload: SearchParams & Partial<IHealthReportingFilters>
    ): Promise<AxiosResponse<IHealthReporting[]>> {
        return HttpService.fetch({
            url: endpoints.healthReportingService.reportings(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getHealthReporting(id: string): Promise<AxiosResponse<IHealthReportingFull>> {
        return HttpService.fetch({
            url: endpoints.healthReportingService.reporting(id),
            method: HttpMethod.GET,
            loader: Loader.BUTTON,
        });
    }

    async patchHealthReportingStatus(
        id: string,
        form: IHealthReportingStatusForm
    ): Promise<AxiosResponse<IHealthReportingForm>> {
        return HttpService.fetch({
            url: endpoints.healthReportingService.reportStatus(id),
            method: HttpMethod.PATCH,
            data: form,
            loader: Loader.REQUEST,
        });
    }

    async getHealthReportingZip(id: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.healthReportingService.download(id),
            method: HttpMethod.GET,
            responseType: "blob",
            loader: Loader.BUTTON,
        });
    }

    async getHealthReportingsCounts(): Promise<AxiosResponse<IHealthReportingCounts>> {
        return HttpService.fetch({
            url: endpoints.healthReportingService.counts(),
            method: HttpMethod.GET,
        });
    }
}

export default new HealthReportingService();
