import React from "react";
import { Skeleton, Stack } from "@mui/material";

export default function BillingCarouselSkeleton() {
    return (
        <Stack spacing={1}>
            <Skeleton variant="text" sx={{ fontSize: "32px", width: "100px" }} />
            <Stack direction="row" justifyContent="space-between" spacing={2}>
                <Skeleton variant="rounded" width="100%" height="150px" sx={{ borderRadius: "30px" }} />
                <Skeleton variant="rounded" width="100%" height="150px" sx={{ borderRadius: "30px" }} />
                <Skeleton variant="rounded" width="100%" height="150px" sx={{ borderRadius: "30px" }} />
            </Stack>
        </Stack>
    );
}
