// === NPM
import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { Outlet } from "react-router-dom";
import { Stack } from "@mui/material";
// === LOCAL
import PageContent from "@/components/generics/layout/PageContent";
import { ICategory, IQuestionShort, ITag } from "@/interfaces/faq";
import { HttpStatus, UserTypeHeader } from "@/interfaces/global";
import PermissionsTabs from "@/resources/PermissionsTabs";
import { routerLinks } from "@/routers/RouterConstant";
import FaqService from "@/services/FaqService";

export function Faq() {
    const [tags, setTags] = useState<ITag[]>([]);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [questions, setQuestions] = useState<IQuestionShort[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        Promise.all([getTags(), getCategories(), getQuestions()]).then(() => {
            setLoading(false);
        });
    };

    const getTags = async () => {
        const res = await FaqService.getTags();
        if (res.status === HttpStatus.OK) setTags(res.data);
    };

    const getCategories = async () => {
        const res = await FaqService.getCategories(UserTypeHeader.ADMIN);
        if (res.status === HttpStatus.OK) setCategories(res.data);
    };

    const getQuestions = async () => {
        const res = await FaqService.getQuestions(UserTypeHeader.ADMIN);
        if (res.status === HttpStatus.OK) setQuestions(res.data);
    };

    const isTouchDevice = () => {
        if ("ontouchstart" in window) {
            return true;
        }
        return false;
    };

    return (
        <Stack height="100%" width="100%" px={2}>
            <PermissionsTabs
                tabs={[
                    {
                        url: routerLinks.admin.faq.categoryAndTag(),
                        label: "Catégories et tags",
                    },
                    {
                        url: routerLinks.admin.faq.questions(),
                        label: "Gestion des questions",
                    },
                ]}
            />
            <PageContent>
                <DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend}>
                    <Outlet
                        context={{
                            tags,
                            getTags,
                            categories,
                            setCategories,
                            getCategories,
                            loading,
                            questions,
                            getQuestions,
                            setQuestions,
                        }}
                    />
                </DndProvider>
            </PageContent>
        </Stack>
    );
}
