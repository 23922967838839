// === Import: NPM
import React from "react";
import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
// === Import: LOCAL
import GenericTitleValueText from "@/components/generics/text/GenericTitleValueText";
import { useAuth } from "@/routers/useAuth";

export default function AnmvProfile() {
    const auth = useAuth();

    return (
        <Stack spacing={3} height="100%">
            <Typography variant="h5">Informations personnelles</Typography>
            <Card sx={{ height: "100%", display: "flex", flexGrow: 1, flexDirection: "column" }}>
                <CardContent>
                    <Grid container p={2}>
                        <Grid item xs={12}>
                            <GenericTitleValueText title="Nom" value={auth.userInfo.lastname} />
                            <GenericTitleValueText title="Prénom" value={auth.userInfo.firstname} />
                            <GenericTitleValueText title="Adresse mail" value={auth.userInfo.email} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Stack>
    );
}
