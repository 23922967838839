// === NPM
import React, { RefObject } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { z, ZodIssueCode } from "zod";
// === LOCAL
import { ReactComponent as Add } from "@/assets/icons/actions/plus.svg";
import GenericButton from "@/components/generics/buttons/GenericButton";
import { ISessionCreate } from "@/components/HealthAccreditationTraining/interface";
import { UserType } from "@/interfaces/user";
import { FORM_TEXT, stringRequired } from "@/resources/FormUtils";
import TrainerCard from "./containers/TrainerCard";

interface TrainersProps {
    form: ISessionCreate;
    formRef: RefObject<HTMLButtonElement>;
    backRef: RefObject<HTMLButtonElement>;
    onStepChange: (data: Partial<Pick<ISessionCreate, "trainers">>, previous?: boolean) => void;
}

const trainerSchema = z
    .object({
        trainerType: stringRequired(),
        veterinaryId: z.string().optional().nullable(),
        lastname: z.string().optional().nullable(),
        firstname: z.string().optional().nullable(),
    })
    .superRefine((values, context) => {
        if (values.trainerType === UserType.VETERINARY && !values.veterinaryId) {
            context.addIssue({
                code: ZodIssueCode.custom,
                message: "Vous devez sélectionner un vétérinaire formateur",
                path: ["veterinaryId"],
            });
        } else if (values.trainerType === UserType.DDPP) {
            if (!values.lastname) {
                context.addIssue({
                    code: ZodIssueCode.custom,
                    message: FORM_TEXT.required,
                    path: ["lastname"],
                });
            }
            if (!values.firstname) {
                context.addIssue({
                    code: ZodIssueCode.custom,
                    message: FORM_TEXT.required,
                    path: ["firstname"],
                });
            }
        }
    });

const formSchema = z.object({
    trainers: z.array(trainerSchema).max(3, { message: "Vous ne pouvez pas ajouter plus de 3 formateurs" }),
});

type ValidationSchema = z.infer<typeof formSchema>;

const newTrainer = {
    trainerType: "",
    veterinaryId: null,
    lastname: null,
    firstname: null,
};

export default function Trainers({ form, formRef, backRef, onStepChange }: Readonly<TrainersProps>) {
    const formValidation = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            trainers: form?.trainers,
        },
    });

    const { handleSubmit, control, getValues } = formValidation;

    const { fields, append, remove } = useFieldArray({
        control,
        name: "trainers",
    });

    return (
        <>
            <form onSubmit={handleSubmit((data) => onStepChange(data as Partial<ISessionCreate>))} noValidate>
                <Stack spacing={2}>
                    <Typography variant="h5">Formateurs</Typography>
                    <Box display="flex" justifyContent="end">
                        <Tooltip title={fields.length >= 3 ? "Vous êtes limité à 3 formateurs" : ""}>
                            <span>
                                <GenericButton
                                    startIcon={<Add />}
                                    label="Ajouter un formateur"
                                    onClick={() => append(newTrainer)}
                                    disabled={fields?.length >= 3}
                                />
                            </span>
                        </Tooltip>
                    </Box>
                    {fields?.map((field, index) => (
                        <TrainerCard
                            index={index}
                            formValidation={formValidation}
                            handleDeleteTrainer={remove}
                            trainersLength={fields?.length}
                            key={field.id}
                        />
                    ))}
                </Stack>
                <button style={{ display: "none" }} type="submit" ref={formRef} />
            </form>
            <button
                style={{ display: "none" }}
                onClick={() => onStepChange(getValues() as Partial<ISessionCreate>, true)}
                ref={backRef}
            />
        </>
    );
}
