// === NPM
import React, { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { Box, Card, CardActionArea, CardContent, Fab, Stack, Tooltip, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Edit } from "@/assets/icons/actions/edit.svg";
import PermissionsCheck from "@/components/generics/PermissionsCheck";
import { colors } from "@/resources/CssConstant";
import { useAuth } from "@/routers/useAuth";
import { IModule } from "../interface";

interface HomeModulesProps {
    modules: IModule[];
    setUpdateModules: Dispatch<SetStateAction<boolean>>;
}

export function HomeModules({ modules, setUpdateModules }: Readonly<HomeModulesProps>) {
    const auth = useAuth();
    const { homePreferences } = auth.userInfo;

    const renderCards = (key: string) => {
        const module = modules.find((m: IModule) => m.key === key);
        return (
            module && (
                <PermissionsCheck requiredPermissions={module.permissions} preferences={module.preferences}>
                    <Card
                        variant="outlined"
                        sx={{
                            borderRadius: "20px",
                            width: "100%",
                        }}
                    >
                        <CardActionArea sx={{ height: "100%" }} component={Link} to={module.path}>
                            <CardContent
                                sx={{
                                    height: "100%",
                                    alignItems: "center",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    backgroundColor: module.background ?? colors.white,
                                    width: "100%",
                                }}
                            >
                                {module.icon}
                                <Box width="100%" sx={{ ml: 1 }}>
                                    <Typography variant="bold">{module.name}</Typography>
                                </Box>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </PermissionsCheck>
            )
        );
    };

    return (
        <Stack spacing={2}>
            <Box display="flex" justifyContent="space-between">
                <Typography variant="h4">Accès modules</Typography>
                <Tooltip title="Modifier les modules">
                    <Fab size="small" color="primary" onClick={() => setUpdateModules(true)}>
                        <Edit id="edit-home-modules" />
                    </Fab>
                </Tooltip>
            </Box>
            <Typography>
                Paramètrez vos modules en cliquant sur le bouton de modification à droite de la page
            </Typography>
            <Box>
                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="1fr" gap={2}>
                    {homePreferences.map((key: string, i: number) => {
                        return (
                            <Box sx={{ gridColumn: { xs: "span 6", md: "span 3" } }} key={i}>
                                {renderCards(key)}
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </Stack>
    );
}
