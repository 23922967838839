// === NPM
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Add, CheckCircle } from "@mui/icons-material";
import { Box, Card, Divider, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as VaccinationIntervention } from "@/assets/icons/home/vaccination_intervention.svg";
import { ReactComponent as Glass } from "@/assets/icons/vaccination/glass.svg";
import { ReactComponent as VaccineWarning } from "@/assets/icons/vaccination/vaccine_warning.svg";
import DeleteAction from "@/components/generics/actions/DeleteAction";
import EditAction from "@/components/generics/actions/EditAction";
import ViewAction from "@/components/generics/actions/ViewAction";
import GenericButton from "@/components/generics/buttons/GenericButton";
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import PermissionsCheck from "@/components/generics/PermissionsCheck";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { HttpStatus } from "@/interfaces/global";
import { IAudit, INonCompliance, IVaccinationSiteShort } from "@/interfaces/vaccination";
import { Preference, UserSituation } from "@/resources/PermissionConstant";
import { toLocaleDateFormat } from "@/resources/utils";
import { routerLinks } from "@/routers/RouterConstant";
import VaccinationService from "@/services/VaccinationService";
import VaccinationInterventionTable from "../../../containers/VaccinationInterventionTable";
import { IVaccinationSiteInfo } from "../../interface";
import AuditDialog from "./Audit/AuditDialog";
import ViewAuditDialog from "./Audit/ViewAuditDialog";
import NonComplianceDialog from "./NonCompliance/NonComplianceDialog";
import ViewNonComplianceDialog from "./NonCompliance/ViewNonComplianceDialog";

interface VaccinationSiteDetailsProps {
    vaccinationSite: IVaccinationSiteShort;
    refreshVaccinationSites: () => void;
}

export default function VaccinationSiteDetails({
    vaccinationSite,
    refreshVaccinationSites,
}: Readonly<VaccinationSiteDetailsProps>) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [infos, setInfos] = useState<IVaccinationSiteInfo>(null);
    const [audit, setAudit] = useState<IAudit>(null);
    const [openAuditDialog, setOpenAuditDialog] = useState<boolean>(false);
    const [openViewAuditDialog, setOpenViewAuditDialog] = useState<boolean>(false);
    const [openDeleteAuditDialog, setOpenDeleteAuditDialog] = useState<boolean>(false);
    const [nonCompliance, setNonCompliance] = useState<INonCompliance>(null);
    const [openViewNonComplianceDialog, setOpenViewNonComplianceDialog] = useState<boolean>(false);
    const [openNonComplianceDialog, setOpenNonComplianceDialog] = useState<boolean>(false);
    const [openDeleteNonComplianceDialog, setOpenDeleteNonComplianceDialog] = useState<boolean>(false);

    useEffect(() => {
        getInfos();
    }, [vaccinationSite]);

    const getInfos = async () => {
        setLoading(true);
        const res = await VaccinationService.getVaccinationSiteInfos(vaccinationSite.id);
        if (res.status === HttpStatus.OK) {
            setInfos(res.data);
        }
        setLoading(false);
    };

    const handleCreateVaccinationIntervention = async () => {
        const res = await VaccinationService.getVaccinationSite(vaccinationSite.id);
        if (res.status === HttpStatus.OK) {
            navigate(`${routerLinks.iahp.vaccinationSite.base()}/${vaccinationSite.id}/saisie-intervention`, {
                state: { vaccinationSite: res.data },
            });
        }
    };

    const handleAudit = async (uuid: string, setter: Dispatch<SetStateAction<boolean>>) => {
        const res = await VaccinationService.getAudit(uuid);
        if (res.status === HttpStatus.OK) {
            setter(true);
            setAudit(res.data);
        }
    };

    const createAudit = async (audit: IAudit) => {
        const res = await VaccinationService.postAudit(vaccinationSite.id, audit);
        if (res.status === HttpStatus.CREATED) {
            setOpenAuditDialog(false);
            toast.success("Audit créé avec succès");
            getInfos();
        }
    };

    const updateAudit = async (updatedAudit: IAudit) => {
        const res = await VaccinationService.putAudit(infos.auditUuid, updatedAudit);
        if (res.status === HttpStatus.OK) {
            setOpenAuditDialog(false);
            setAudit(null);
            toast.success("Audit modifié avec succès");
            getInfos();
        }
    };

    const deleteAudit = async (confirm: boolean) => {
        if (!confirm) {
            setOpenDeleteAuditDialog(false);
            return;
        }
        const res = await VaccinationService.deleteAudit(infos.auditUuid);
        if (res.status === HttpStatus.NO_CONTENT) {
            setOpenDeleteAuditDialog(false);
            toast.success("Audit supprimé avec succès");
            getInfos();
        }
    };

    const handleNonCompliance = async (uuid: string, setter: Dispatch<SetStateAction<boolean>>) => {
        const res = await VaccinationService.getNonCompliance(uuid);
        if (res.status === HttpStatus.OK) {
            setter(true);
            setNonCompliance(res.data);
        }
    };

    const createNonCompliance = async (nonCompliance: INonCompliance) => {
        const res = await VaccinationService.postNonCompliance(vaccinationSite.id, nonCompliance);
        if (res.status === HttpStatus.CREATED) {
            setOpenNonComplianceDialog(false);
            toast.success("Contrôle des non-conformités majeures créé avec succès");
            getInfos();
        }
    };

    const updateNonCompliance = async (updatedNonCompliance: INonCompliance) => {
        const res = await VaccinationService.putNonCompliance(infos.nonComplianceUuid, updatedNonCompliance);
        if (res.status === HttpStatus.OK) {
            setOpenNonComplianceDialog(false);
            setNonCompliance(null);
            toast.success("Contrôle des non-conformités majeures modifié avec succès");
            getInfos();
        }
    };

    const deleteNonCompliance = async (confirm: boolean) => {
        if (!confirm) {
            setOpenDeleteNonComplianceDialog(false);
            return;
        }
        const res = await VaccinationService.deleteNonCompliance(infos.nonComplianceUuid);
        if (res.status === HttpStatus.NO_CONTENT) {
            setOpenDeleteNonComplianceDialog(false);
            toast.success("Contrôle des non-conformités majeures supprimé avec succès");
            getInfos();
        }
    };

    const renderSkeleton = () => (
        <Stack spacing={2}>
            <Skeleton variant="rounded" width="100%" height={200} />
        </Stack>
    );

    const renderInfos = () => (
        <Card>
            <StyledCardContent>
                <Stack spacing={3} p={2} direction="row" justifyContent="space-evenly">
                    <Stack spacing={3}>
                        <Box display="flex" flexDirection="row" gap={2}>
                            <VaccinationIntervention />
                            <Stack justifyContent="center">
                                <Typography variant="secondaryBold">Interventions de vaccination</Typography>
                                <Typography variant="h5" sx={{ color: "#FEA272" }}>
                                    {infos.interventionCount}{" "}
                                    {infos.interventionCount > 1 ? "interventions" : "intervention"}
                                </Typography>
                            </Stack>
                        </Box>
                        {vaccinationSite.modifiable && (
                            <PermissionsCheck
                                requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                                preferences={[Preference.IAHP]}
                            >
                                <GenericButton
                                    onClick={handleCreateVaccinationIntervention}
                                    label="Nouvelle intervention"
                                    startIcon={<Add />}
                                />
                            </PermissionsCheck>
                        )}
                    </Stack>
                    <Divider orientation="vertical" flexItem />

                    <Stack spacing={3}>
                        <Box display="flex" flexDirection="row" gap={2}>
                            <Glass />
                            <Stack justifyContent="center">
                                <Typography variant="secondaryBold">Informations de l'audit</Typography>
                                <Typography variant="h5" sx={{ color: "#F1B31C" }}>
                                    {infos.auditUuid ? "Disponible" : "Indisponible"}
                                </Typography>
                            </Stack>
                        </Box>
                        {infos.auditUuid ? (
                            <Box display="flex" justifyContent="center">
                                <ViewAction
                                    title="Voir l'audit"
                                    onClick={() => handleAudit(infos.auditUuid, setOpenViewAuditDialog)}
                                />
                                {vaccinationSite.modifiable && (
                                    <PermissionsCheck
                                        requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                                        preferences={[Preference.IAHP]}
                                    >
                                        <EditAction
                                            title="Modifier l'audit"
                                            onClick={() => handleAudit(infos.auditUuid, setOpenAuditDialog)}
                                        />
                                        <DeleteAction
                                            title="Supprimer l'audit"
                                            onClick={() => setOpenDeleteAuditDialog(true)}
                                        />
                                    </PermissionsCheck>
                                )}
                            </Box>
                        ) : (
                            infos.concernedAuditWorkshops.length > 0 &&
                            vaccinationSite.modifiable && (
                                <PermissionsCheck
                                    requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                                    preferences={[Preference.IAHP]}
                                >
                                    <GenericButton
                                        onClick={() => setOpenAuditDialog(true)}
                                        label="Créer un audit"
                                        startIcon={<Add />}
                                    />
                                </PermissionsCheck>
                            )
                        )}
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                    <Stack spacing={3}>
                        <Box display="flex" flexDirection="row" gap={2} alignItems="center">
                            <VaccineWarning />
                            <Stack justifyContent="center">
                                <Typography variant="secondaryBold">Non-conformités majeures</Typography>
                                <Typography variant="h5" sx={{ color: "#90D9E1" }}>
                                    {infos.nonComplianceUuid ? "Disponible" : "Indisponible"}
                                </Typography>
                            </Stack>
                            {infos.allNonCompliancePerformed && (
                                <Tooltip title="Toutes les non-conformités sont renseignées">
                                    <CheckCircle color="primary" />
                                </Tooltip>
                            )}
                        </Box>
                        {infos.nonComplianceUuid ? (
                            <Box display="flex" justifyContent="center">
                                <ViewAction
                                    title="Voir le contrôle des non-conformités majeures"
                                    onClick={() =>
                                        handleNonCompliance(infos.nonComplianceUuid, setOpenViewNonComplianceDialog)
                                    }
                                />
                                {vaccinationSite.modifiable && (
                                    <PermissionsCheck
                                        requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                                        preferences={[Preference.IAHP]}
                                    >
                                        <EditAction
                                            title="Modifier le contrôle des non-conformités majeures"
                                            onClick={() =>
                                                handleNonCompliance(infos.nonComplianceUuid, setOpenNonComplianceDialog)
                                            }
                                        />
                                        <DeleteAction
                                            title="Supprimer le contrôle des non-conformités majeures"
                                            onClick={() => setOpenDeleteNonComplianceDialog(true)}
                                        />
                                    </PermissionsCheck>
                                )}
                            </Box>
                        ) : (
                            infos.concernedNonComplianceWorkshops.length > 0 &&
                            vaccinationSite.modifiable && (
                                <PermissionsCheck
                                    requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                                    preferences={[Preference.IAHP]}
                                >
                                    <GenericButton
                                        onClick={() => setOpenNonComplianceDialog(true)}
                                        label="Créer un Contrôle des non-conformités majeures"
                                        startIcon={<Add />}
                                    />
                                </PermissionsCheck>
                            )
                        )}
                    </Stack>
                </Stack>
            </StyledCardContent>
        </Card>
    );

    return (
        <>
            <Stack spacing={2}>
                <Typography variant="h4">
                    Suivi du chantier n°{vaccinationSite.id} en date du {toLocaleDateFormat(vaccinationSite.date)}
                </Typography>
                {loading ? renderSkeleton() : renderInfos()}
                <VaccinationInterventionTable
                    vaccinationSiteId={vaccinationSite.id}
                    refreshData={getInfos}
                    refreshVaccinationSites={refreshVaccinationSites}
                    canEdit={vaccinationSite.modifiable}
                    canDownload
                />
            </Stack>

            {openAuditDialog && (
                <AuditDialog
                    onClose={() => {
                        setOpenAuditDialog(false);
                        setAudit(null);
                    }}
                    workshopIds={infos.concernedAuditWorkshops}
                    vaccinationSite={vaccinationSite}
                    audit={audit}
                    onValid={audit ? updateAudit : createAudit}
                />
            )}
            {openViewAuditDialog && audit && (
                <ViewAuditDialog
                    audit={audit}
                    onClose={() => {
                        setOpenViewAuditDialog(false);
                        setAudit(null);
                    }}
                />
            )}

            {openViewNonComplianceDialog && nonCompliance && (
                <ViewNonComplianceDialog
                    nonCompliance={nonCompliance}
                    onClose={() => {
                        setOpenViewNonComplianceDialog(null);
                        setNonCompliance(null);
                    }}
                />
            )}
            {openDeleteNonComplianceDialog && (
                <GenericConfirmDialog
                    title={`Suppression du contrôle des non-conformité majeures du chantier n°${vaccinationSite.id}`}
                    message={`Êtes-vous sûr de vouloir supprimer le contrôle des non-conformités majeures ? Cette action n’est pas réversible.`}
                    onClose={deleteNonCompliance}
                />
            )}
            {openNonComplianceDialog && (
                <NonComplianceDialog
                    onClose={() => {
                        setOpenNonComplianceDialog(false);
                        setNonCompliance(null);
                    }}
                    workshopIds={infos.concernedNonComplianceWorkshops}
                    vaccinationSite={vaccinationSite}
                    nonCompliance={nonCompliance}
                    onValid={nonCompliance ? updateNonCompliance : createNonCompliance}
                />
            )}
            {openDeleteAuditDialog && (
                <GenericConfirmDialog
                    title={`Suppression de l'audit du chantier n°${vaccinationSite.id}`}
                    message={`Êtes-vous sûr de vouloir supprimer l'audit ? Cette action n’est pas réversible.`}
                    onClose={deleteAudit}
                />
            )}
        </>
    );
}
