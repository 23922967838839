export enum TypeCode {
    ORD = "ORD",
    DEL = "DEL",
}

export enum ImportStatus {
    OK = "Importé",
    OK_WITH_WARNING = "Importé avec avertissements",
    REJECTED = "Rejeté",
    PARTIAL = "Partiellement importé",
}

export enum TransferorType {
    FAM_DAM = "FAM/DAM",
    PHARMACIST = "Pharmacien",
    VETERINARY = "Vétérinaire",
}
