// === NPM
import { AxiosResponse } from "axios";
// === LOCAL
import {
    IAllowedTarget,
    IConfig,
    INotificationCreateUpdate,
    INotificationFull,
    INotificationShort,
    IPublishedNotificationPatch,
    NotificationFilters,
    NotificationPublish,
    SendMode,
} from "@/components/Administration/containers/Notifications/interface";
import {
    IUserNotification,
    IUserNotificationDetails,
    IUserNotificationInfo,
} from "@/components/UserNotifications/interface";
import { HttpMethod, Loader, SearchParams, UserTypeHeader } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import { CALYPSO_HEADERS } from "@/resources/AppConstant";
import { formatParams } from "@/resources/utils";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class NotificationService {
    async getNotifications(
        userType: UserTypeHeader.ADMIN,
        payload: SearchParams & Partial<NotificationFilters>
    ): Promise<AxiosResponse<INotificationShort[]>>;
    async getNotifications(
        userType: UserTypeHeader.USER,
        payload: SearchParams & Partial<NotificationFilters>
    ): Promise<AxiosResponse<IUserNotification[]>>;
    async getNotifications(
        userType: UserTypeHeader,
        payload: SearchParams & Partial<NotificationFilters>
    ): Promise<AxiosResponse<INotificationShort[] | IUserNotification[]>> {
        return HttpService.fetch({
            url: endpoints.notificationService.notifications(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            headers: {
                [CALYPSO_HEADERS.USER_TYPE]: userType,
            },
            loader: Loader.TABLE,
        });
    }

    async getNotificationByUuid(
        uuid: string,
        userType: UserTypeHeader.ADMIN
    ): Promise<AxiosResponse<INotificationFull>>;
    async getNotificationByUuid(
        uuid: string,
        userType: UserTypeHeader.USER
    ): Promise<AxiosResponse<IUserNotificationDetails>>;
    async getNotificationByUuid(
        uuid: string,
        userType: UserTypeHeader
    ): Promise<AxiosResponse<INotificationFull | IUserNotificationDetails>> {
        return HttpService.fetch({
            url: endpoints.notificationService.notification(uuid),
            method: HttpMethod.GET,
            headers: {
                [CALYPSO_HEADERS.USER_TYPE]: userType,
            },
            loader: Loader.BUTTON,
        });
    }

    async getUserNotificationFile(uuid: string, fileUuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.notificationService.file(uuid, fileUuid),
            method: HttpMethod.GET,
            responseType: "blob",
        });
    }

    async getUserNotificationZip(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.notificationService.download(uuid),
            method: HttpMethod.GET,
            responseType: "blob",
            loader: Loader.BUTTON,
        });
    }

    async getUserNotificationInfo(): Promise<AxiosResponse<IUserNotificationInfo>> {
        return HttpService.fetch({
            url: endpoints.notificationService.notificationInfo(),
            method: HttpMethod.GET,
        });
    }

    async markUserNotificationAsRead(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.notificationService.read(uuid),
            method: HttpMethod.PATCH,
            loader: Loader.BUTTON,
        });
    }

    async deleteNotification(uuid: string, userType: UserTypeHeader): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.notificationService.notification(uuid),
            method: HttpMethod.DELETE,
            headers: {
                [CALYPSO_HEADERS.USER_TYPE]: userType,
            },
            loader: Loader.REQUEST,
        });
    }

    async scheduleNotification(uuid: string, data: NotificationPublish): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.notificationService.schedule(uuid),
            method: HttpMethod.PATCH,
            data,
            loader: Loader.REQUEST,
        });
    }

    async archiveNotification(uuid: string, userType: UserTypeHeader): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.notificationService.archive(uuid),
            method: HttpMethod.PATCH,
            headers: {
                [CALYPSO_HEADERS.USER_TYPE]: userType,
            },
            loader: Loader.REQUEST,
        });
    }

    async putNotification(uuid: string, notification: INotificationCreateUpdate, attachments?: File[]) {
        const blob = new Blob([JSON.stringify(notification)], {
            type: "application/json",
        });
        const formData = new FormData();
        attachments?.forEach((item) => {
            formData.append("files", item);
        });
        formData.append("notification", blob);
        return HttpService.fetch({
            url: endpoints.notificationService.notification(uuid),
            method: HttpMethod.PUT,
            data: formData,
            loader: Loader.REQUEST,
        });
    }

    async postNotification(notification: INotificationCreateUpdate, attachments?: File[]) {
        const blob = new Blob([JSON.stringify(notification)], {
            type: "application/json",
        });

        const formData = new FormData();
        attachments?.forEach((item) => {
            formData.append("files", item);
        });
        formData.append("notification", blob);
        return HttpService.fetch({
            url: endpoints.notificationService.notifications(),
            method: HttpMethod.POST,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
            loader: Loader.REQUEST,
        });
    }

    async patchPublishedNotification(uuid: string, notification: IPublishedNotificationPatch) {
        return HttpService.fetch({
            url: endpoints.notificationService.notification(uuid),
            method: HttpMethod.PATCH,
            data: notification,
            loader: Loader.REQUEST,
        });
    }

    async getAutoNotificationTypes(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.notificationService.referentials.autoNotificationTypes(),
            method: HttpMethod.GET,
        });
    }

    async getConfigs(): Promise<AxiosResponse<IConfig[]>> {
        return HttpService.fetch({
            url: endpoints.notificationService.configs(),
            method: HttpMethod.GET,
        });
    }
    async putConfig(key: string, data: { sendMode: keyof typeof SendMode; publicationDayDelay: number }) {
        return HttpService.fetch({
            url: endpoints.notificationService.config(key),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    async getAuthorsTypes(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.notificationService.authors(),
            method: HttpMethod.GET,
        });
    }

    async getAllowedTargets(): Promise<AxiosResponse<IAllowedTarget[]>> {
        return HttpService.fetch({
            url: endpoints.notificationService.allowedTargets(),
            method: HttpMethod.GET,
        });
    }
}

export default new NotificationService();
