// === NPM
import React from "react";
// === LOCAL
import { defaultTextLimit } from "@/resources/AppConstant";
import GenericTextField from "./GenericTextField";

interface GenericCommentProps {
    label: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    rows?: number;
    required?: boolean;
    placeholder: string;
    helperText?: string;
    maxLength?: number;
    disabled?: boolean;
}

export default function GenericComment({
    label,
    value,
    onChange,
    rows,
    required,
    placeholder,
    helperText,
    maxLength = defaultTextLimit,
    disabled = false,
}: Readonly<GenericCommentProps>) {
    return (
        <GenericTextField
            label={label}
            value={value}
            onChange={onChange}
            rows={rows ?? 6}
            required={required}
            placeholder={placeholder}
            error={!!helperText}
            helperText={helperText ? helperText : `${value?.length ?? 0}/${maxLength}`}
            maxLength={maxLength}
            disabled={disabled}
        />
    );
}
