// === NPM
import React from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Warning } from "@/assets/icons/shared/danger-triangle.svg";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { useProvideGlobal } from "@/context/useGlobalContext";
import { colors } from "@/resources/CssConstant";

interface ConfirmDialogWithWarningProps {
    onClose: any;
    warningDisplay: boolean;
    warningText?: string;
    confirmationText: string;
    title: string;
}

export default function ConfirmDialogWithWarning({
    onClose,
    warningDisplay,
    warningText,
    confirmationText,
    title,
}: Readonly<ConfirmDialogWithWarningProps>) {
    const { loadingRequest } = useProvideGlobal();
    const handleCancel = () => {
        onClose(false);
    };

    const handleOk = () => {
        onClose(true);
    };

    return (
        <Dialog maxWidth="md" open>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
                {warningDisplay && (
                    <Box display="flex" my={1}>
                        <Warning color="error" />
                        <Typography sx={{ fontWeight: 600, color: colors.error }}>{warningText}</Typography>
                    </Box>
                )}
                <Typography>{confirmationText}</Typography>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item>
                        <SecondaryButton variant="outlined" onClick={handleCancel} disabled={loadingRequest}>
                            Non
                        </SecondaryButton>
                    </Grid>
                    <Grid item>
                        <LoadingButton onClick={handleOk} variant="contained" loading={loadingRequest}>
                            Oui
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
