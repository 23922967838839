// === NPM
import { AxiosResponse } from "axios";
import { IHealthAccreditationTrainingSessionTraceabilityFilters } from "@/components/Administration/containers/Traceability/containers/HealthAccreditationTrainingSessionTraceability/HealthAccreditationTrainingSessionTraceability";
// === LOCAL
import {
    IAgencyTraceability,
    IContinuousTrainingOrganizationTraceability,
    IHealthAccreditationTrainingSessionTraceability,
    IHealthAccreditationTrainingSessionTraceabilityDetails,
    IHealthAccreditationTrainingTraceability,
    IHealthAccreditationTrainingTraceabilityDetails,
    IHealthReportingTraceability,
    IHealthReportingTraceabilityFilters,
    INonDeliveryTraceability,
    IOvvtTraceability,
    IOvvtTraceabilityDetails,
    IUserTraceability,
} from "@/components/Administration/containers/Traceability/interface";
import {
    IMonitoringInterventionTraceability,
    IScrappingTraceability,
    IVaccinationSiteTraceability,
    ScrappingTraceabilityDetailsType,
    VaccinationSiteTraceabilityDetailsType,
} from "@/components/VaccinationIahp/TraceabilityIahp/interface";
import { HttpMethod, Loader, SearchParams } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import { IDefaultTraceabilityFilters } from "@/interfaces/traceability";
import { formatParams } from "@/resources/utils";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class TraceabilityService {

    async getTraceabilityContinuousTrainingOrganization(
        payload: SearchParams &
            Partial<{
                date: string[];
                authorId: string;
                action: string;
            }>
    ): Promise<AxiosResponse<IContinuousTrainingOrganizationTraceability[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.continuousTrainingOrganization(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }
    async getTraceabilityUsers(
        payload: SearchParams &
            Partial<{
                date: string[];
                authorId: string;
                action: string;
            }>
    ): Promise<AxiosResponse<IUserTraceability[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.users(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getTraceabilityAgencies(
        payload: SearchParams &
            Partial<{
                date: string[];
                authorId: string;
                action: string;
            }>
    ): Promise<AxiosResponse<IAgencyTraceability[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.agencies(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getTraceabilityNonDeliveries(
        payload: SearchParams &
            Partial<{
                date: string[];
                authorId: string;
                action: string;
                year: string;
                authorizationNumber: string;
                quarter: string;
            }>
    ): Promise<AxiosResponse<INonDeliveryTraceability[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.nonDeliveries(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getTraceabilityVaccinationSite(
        payload: SearchParams &
            Partial<{ type: string; vaccinationSiteId: string; interventionId: string & IDefaultTraceabilityFilters }>
    ): Promise<AxiosResponse<IVaccinationSiteTraceability[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.vaccinationSites.base(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getTraceabilityVaccinationSiteDetails(
        uuid: string
    ): Promise<AxiosResponse<VaccinationSiteTraceabilityDetailsType>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.vaccinationSites.details(uuid),
        });
    }

    async getTraceabilityActions(type: string): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.referential.actions(),
            method: HttpMethod.GET,
            params: formatParams({ type }),
        });
    }

    async getTraceabilityVaccinationSiteFile(uuid: string, fileUuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.vaccinationSites.file(uuid, fileUuid),
            method: HttpMethod.GET,
            responseType: "blob",
        });
    }

    async getTraceabilityMonitoringIntervention(
        payload: SearchParams & Partial<{ interventionId: string & IDefaultTraceabilityFilters }>
    ): Promise<AxiosResponse<IMonitoringInterventionTraceability[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.monitoringIntervention(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getTraceabilityMonitoringInterventionFile(uuid: string, fileUuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.monitoringInterventionFile(uuid, fileUuid),
            method: HttpMethod.GET,
            responseType: "blob",
        });
    }

    async getTraceabilityScrapping(
        payload: SearchParams & Partial<{ scrappingId: string; dpe: string & IDefaultTraceabilityFilters }>
    ): Promise<AxiosResponse<IScrappingTraceability[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.scrapping.base,
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getTraceabilityScrappingDetails(uuid: string): Promise<AxiosResponse<ScrappingTraceabilityDetailsType>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.scrapping.details(uuid),
        });
    }

    async getTraceabilityMonitoringInterventionDetails(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.monitoringInterventionDetails(uuid),
            method: HttpMethod.GET,
        });
    }

    async getTraceabilityHealthReportings(
        payload: SearchParams & Partial<IHealthReportingTraceabilityFilters>
    ): Promise<AxiosResponse<IHealthReportingTraceability[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.healthReportings(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getUserSituations(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.referential.userSituations(),
            method: HttpMethod.GET,
        });
    }

    async getHealthReportingStatuses(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.referential.healthReportingStatuses(),
            method: HttpMethod.GET,
        });
    }

    async getTraceabilityOvvt(
        payload: SearchParams & Partial<IDefaultTraceabilityFilters>
    ): Promise<AxiosResponse<IOvvtTraceability[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.ovvt(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getTraceabilityOvvtDetails(uuid: string): Promise<AxiosResponse<IOvvtTraceabilityDetails>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.ovvtDetails(uuid),
            method: HttpMethod.GET,
        });
    }

    async getTraceabilityHealthAccreditationTrainings(
        payload: SearchParams & Partial<IHealthReportingTraceabilityFilters>
    ): Promise<AxiosResponse<IHealthAccreditationTrainingTraceability[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.healthAccreditationTrainings(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getTraceabilityHealthAccreditationTrainingDetails(
        uuid: string
    ): Promise<AxiosResponse<IHealthAccreditationTrainingTraceabilityDetails>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.healthAccreditationTrainingsDetails(uuid),
            method: HttpMethod.GET,
        });
    }

    async getTraceabilityHealthAccreditationTrainingSessions(
        payload: SearchParams & Partial<IHealthAccreditationTrainingSessionTraceabilityFilters>
    ): Promise<AxiosResponse<IHealthAccreditationTrainingSessionTraceability[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.healthAccreditationTrainingSessions(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getTraceabilityHealthAccreditationTrainingSessionDetails(
        uuid: string
    ): Promise<AxiosResponse<IHealthAccreditationTrainingSessionTraceabilityDetails>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.healthAccreditationTrainingSessionsDetails(uuid),
            method: HttpMethod.GET,
        });
    }
}

export default new TraceabilityService();
