// === NPM
import React, { useState } from "react";
// === LOCAL
import { FilterConfigurations } from "@/components/generics/filters/GenericFilters";
import { IViewAdminFilters, SessionStatus } from "@/components/HealthAccreditationTraining/interface";
import { useDepartments } from "@/context/useDepartmentContext";
import { FilterType } from "@/interfaces/global";
import { SessionMode } from "@/interfaces/training";
import { convertEnumToKeyLabelObject } from "@/resources/utils";
import SessionsTable from "../SessionsTable";

export default function ViewAdminCalypso() {
    const { departments } = useDepartments();

    const [inputFilters, setInputFilters] = useState<IViewAdminFilters>({
        internalId: "",
        trainingTitle: "",
        startDate: [null, null],
        registrationLimitDate: [null, null],
        department: [],
        sessionMode: [],
        status: [],
    });

    const filterConfigurations: FilterConfigurations<IViewAdminFilters> = {
        internalId: { type: FilterType.INPUT, label: "Identifiant" },
        trainingTitle: { type: FilterType.INPUT, label: "Nom de la formation" },
        startDate: { type: FilterType.DATEPICKER, label: "Date de formation" },
        registrationLimitDate: { type: FilterType.DATEPICKER, label: "Date limite d'inscription" },
        department: {
            type: FilterType.SELECT_AUTOCOMPLETE,
            label: "Département",
            values: departments.map((d) => ({ key: d.inseeCode, label: `${d.inseeCode} - ${d.name}` })),
        },
        sessionMode: {
            type: FilterType.SELECT,
            label: "Mode de présence",
            values: convertEnumToKeyLabelObject(SessionMode),
        },
        status: { type: FilterType.SELECT, label: "Statut", values: convertEnumToKeyLabelObject(SessionStatus) },
    };
    return (
        <SessionsTable
            inputFilters={inputFilters}
            filterConfigurations={filterConfigurations}
            initialValues={{
                internalId: "",
                trainingTitle: "",
                startDate: [null, null],
                registrationLimitDate: [null, null],
                department: [],
                sessionMode: [],
                status: [],
            }}
            setInputFilters={setInputFilters}
        />
    );
}
