import { Dispatch, SetStateAction } from "react";
import { ICategory, IQuestionShort, ITag } from "@/interfaces/faq";

export interface IQuestionDraft {
    content: string;
    tagUuids: string[];
    title: string;
}

export type FaqOutletContext = {
    tags: ITag[];
    getTags: () => void;
    categories: ICategory[];
    getCategories: () => void;
    setCategories: Dispatch<SetStateAction<ICategory[]>>;
    loading: boolean;
    questions: IQuestionShort[];
    getQuestions: () => void;
    setQuestions: Dispatch<SetStateAction<IQuestionShort[]>>;
};

export enum FaqDropableType {
    CATEGORY = "CATEGORY",
    QUESTION = "QUESTION",
}
