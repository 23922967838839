// === NPM
import React from "react";
import { TextField } from "@mui/material";
// === LOCAL
import { defaultIntegerLimit } from "@/resources/AppConstant";

interface NumberInputFilterProps {
    label?: string;
    name?: string;
    required?: boolean;
    value: number;
    onChange?: any;
    error?: boolean;
    maxLength?: number;
    placeholder?: string;
}

export default function NumberInputFilter({
    label,
    name,
    required,
    value,
    onChange,
    error,
    maxLength = defaultIntegerLimit,
    placeholder,
}: NumberInputFilterProps) {
    const authorizedActionKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight"];

    const blockInvalidNumberChar = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (authorizedActionKeys.includes(event.code)) {
            return;
        }
        return !/^\d+$/.test(event.key) && event.preventDefault();
    };

    return (
        <TextField
            fullWidth
            variant="standard"
            autoFocus
            label={label}
            name={name}
            required={required}
            value={value}
            onChange={onChange}
            size="small"
            error={error}
            onKeyDown={blockInvalidNumberChar}
            placeholder={placeholder}
            inputProps={{
                htmlInput: {
                    maxLength: maxLength,
                },
            }}
        />
    );
}
