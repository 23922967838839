// === Import: NPM
import React, { useEffect, useState } from "react";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { GridRenderCellParams, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro";
// === Import: LOCAL
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import PageContent from "@/components/generics/layout/PageContent";
import GenericTable from "@/components/generics/table/GenericTable";
import useTimeout from "@/hooks/useTimeout";
import { FilterType, HttpStatus, IPagination, SortDirection } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { createPayload } from "@/resources/utils";
import UserService from "@/services/UserService";
import ProfileDetails from "./containers/ProfileDetails";
import { IProfileFeature } from "./interface";

interface Filters {
    situations: string[];
    profile: string;
    feature: string;
}

export default function Profiles() {
    const [profileFeatures, setProfileFeatures] = useState<IProfileFeature[]>([]);
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "profile", sort: "asc" }]);
    const [situations, setSituations] = useState<IReferential[]>([]);
    const [inputFilters, setInputFilters] = useState<Filters>({
        situations: [],
        profile: "",
        feature: "",
    });
    const [profileKey, setProfileKey] = useState<string>("");

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getSituations();
    }, []);

    useEffect(() => {
        getProfileFeatures();
    }, [pagination, sortModel]);

    const getProfileFeatures = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        };
        const res = await UserService.getProfileFeatures(payload);
        if (res.status === HttpStatus.OK) {
            setRowCount(+res.headers[CALYPSO_HEADERS.TABLE_COUNT]);
            setProfileFeatures(res.data);
        }
    };

    const getSituations = async () => {
        const res = await UserService.getSituations();
        if (res.status === HttpStatus.OK) setSituations(res.data);
    };
    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ ...pagination, pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const columns = [
        {
            field: "situations",
            headerName: "Situation",
            flex: 1,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <ul>
                    {params.row.situations.map((situation) => (
                        <li key={situation.key}>{situation.label}</li>
                    ))}
                </ul>
            ),
        },
        {
            field: "profile",
            headerName: "Profil",
            flex: 1,
        },
        {
            field: "feature",
            headerName: "Fonctionnalité",
            flex: 1,
        },
    ];

    const filterConfigurations: FilterConfigurations<Filters> = {
        situations: {
            label: "Situation",
            type: FilterType.SELECT,
            values: situations,
        },
        profile: { label: "Profil", type: FilterType.INPUT },
        feature: { label: "Fonctionnalité", type: FilterType.INPUT },
    };

    return (
        <PageContent>
            <Stack height="100%" spacing={2} width="100%">
                <Typography variant="h4">Utilisateurs et fonctionnalités associées</Typography>
                <Card>
                    <CardContent>
                        <GenericFilters
                            inputFilters={inputFilters}
                            filterConfigurations={filterConfigurations}
                            initialValues={{
                                situations: [],
                                profile: "",
                                feature: "",
                            }}
                            setInputFilters={setInputFilters}
                        />
                        <GenericTable
                            rows={profileFeatures}
                            columns={columns}
                            page={pagination.page}
                            rowCount={rowCount}
                            pageSize={pagination.pageSize}
                            onPageSizeChange={handlePageSizeChange}
                            onPageChange={handlePageChange}
                            sortingMode="server"
                            paginationMode="server"
                            sortModel={sortModel}
                            onSortModelChange={(model) => setSortModel(model)}
                            sortingOrder={[SortDirection.ASC, SortDirection.DESC]}
                            filterMode="server"
                            autoHeight
                            getRowId={(row) => `${row.featureKey}-${row.profileKey}`}
                            onRowClick={(params: GridRowParams) => setProfileKey(params.row.profileKey)}
                        />
                    </CardContent>
                </Card>
                <ProfileDetails profileKey={profileKey} setProfileKey={setProfileKey} />
            </Stack>
        </PageContent>
    );
}
