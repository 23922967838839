// === NPM
import React from "react";
import { useOutletContext } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
// === LOCAL
import { ICategory, IQuestionShort } from "@/interfaces/faq";
import { FaqOutletContext } from "../../../interface";
import Dropzone from "./Dropzone";
import QuestionCard from "./QuestionCard";

interface QuestionGroupProps {
    category: ICategory;
    questions: IQuestionShort[];
    findQuestion: (uuid: string) => { index: number };
    moveQuestion: (uuid: string, to: number, categoryUuid) => void;
}

export default function QuestionGroup({
    category,
    questions,
    findQuestion,
    moveQuestion,
}: Readonly<QuestionGroupProps>) {
    const { categories } = useOutletContext<FaqOutletContext>();

    const categoryIndex = categories.indexOf(category);
    const startIndex = categories.slice(0, categoryIndex).reduce((a, cat) => a + cat.questionCount, 0);

    return (
        <Stack spacing={2}>
            <Typography variant="h5">{category.title}</Typography>
            <Dropzone moveQuestion={moveQuestion} index={startIndex} categoryUuid={category.uuid} />
            {questions.length === 0 && <Typography>Il n'y a pas de question dans cette catégorie</Typography>}

            {questions.map((question, index) => (
                <QuestionCard
                    key={question.uuid}
                    index={index}
                    question={question}
                    findQuestion={findQuestion}
                    moveQuestion={moveQuestion}
                />
            ))}
            <Dropzone
                moveQuestion={moveQuestion}
                index={startIndex + category.questionCount}
                categoryUuid={category.uuid}
            />
        </Stack>
    );
}
