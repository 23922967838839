import { DateTime } from "luxon";

export const computeMinimumStartDate = (actualStartDate: string, previousVersionStartDate: string) => {
    // If we are creating a NEW chargeable version, we can not create it in the past or today.
    // To know if we are creating a new chargeable version, we must check if our version object already has a start date.

    if (actualStartDate) {
        // We are editing an existing chargeable version
        //if(version.startDate <= today), we can move
        // if the startdate is in the past, minimum date is version.starddate
        //if start date is in the future :
        // if previousstartdate is in the past : minimum is today + 1
        //if previous start date is in the future : minimum is the start date
        return DateTime.fromISO(actualStartDate) <= DateTime.now().startOf("day")
            ? DateTime.fromISO(actualStartDate)
            : DateTime.max(DateTime.fromISO(previousVersionStartDate), DateTime.now().startOf("day")).plus({ days: 1 });

        // We can move the date between the previous version's start date and the current date
        // return previousValues?.startDate
        //     ? DateTime.fromISO(previousValues.startDate).plus({ days: 1 })
        //     : DateTime.now();
    } else {
        // We are creating a new chargeable version
        // Minimum date is the BIGGEST DATE between (today, previous version's starting date) + 1.
        return DateTime.max(DateTime.fromISO(previousVersionStartDate), DateTime.now().startOf("day")).plus({
            days: 1,
        });
    }
};
