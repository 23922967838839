// === NPM
import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
// === LOCAL
import SecondaryButton from "@/components/styled/SecondaryButton";
import { useProvideGlobal } from "@/context/useGlobalContext";
import GenericButton from "../buttons/GenericButton";

interface GenericConfirmDialogProps {
    onClose: (value: boolean) => void;
    title: string;
    message: string;
    loading?: boolean;
}

export default function GenericConfirmDialog({
    onClose,
    title,
    message,
    loading,
}: Readonly<GenericConfirmDialogProps>) {
    const { loadingRequest } = useProvideGlobal();
    const handleCancel = () => {
        onClose(false);
    };

    const handleOk = () => {
        onClose(true);
    };

    return (
        <Dialog maxWidth="md" open>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
                <Typography sx={{ whiteSpace: "pre-line" }}>{message}</Typography>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item>
                        <SecondaryButton variant="outlined" onClick={handleCancel} disabled={loadingRequest || loading}>
                            Non
                        </SecondaryButton>
                    </Grid>
                    <Grid item>
                        <GenericButton onClick={handleOk} label="Oui" loading={loadingRequest || loading} />
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
