// === NPM
import React from "react";
import { useOutletContext } from "react-router-dom";
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Location } from "@/assets/icons/shared/location.svg";
import { ReactComponent as Vaccine } from "@/assets/icons/vaccination/vaccine.svg";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import GenericTitleValueText from "@/components/generics/text/GenericTitleValueText";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { VaccinationOutletContext } from "@/interfaces/vaccination";
import { toLocaleDateFormat } from "@/resources/utils";
import { IScrapping } from "../interface";

interface ViewDialogProps {
    scrapping: IScrapping;
    onClose: () => void;
}

export default function ViewDialog({ scrapping, onClose }: Readonly<ViewDialogProps>) {
    const { scrappingReasons } = useOutletContext<VaccinationOutletContext>();

    const renderContent = () => (
        <Card>
            <StyledCardContent>
                <Grid container rowSpacing={4} columnSpacing={2} p={2}>
                    <Grid item xs={12} md={6} display="flex" gap={1}>
                        <Box>
                            <Location />
                        </Box>
                        <Stack spacing={1}>
                            <Box mt={-0.25}>
                                <Typography variant="bold" fontSize={18}>
                                    Informations générales
                                </Typography>
                            </Box>
                            <GenericTitleValueText
                                title="DPE"
                                value={`${scrapping.dpeId} - ${scrapping.dpeName} - ${scrapping.dpePostalCode} ${scrapping.dpeCity}`}
                            />
                            <GenericTitleValueText title="Vaccin" value={scrapping.vaccineName} />
                            <GenericTitleValueText title="Numéro de lot" value={scrapping.batch.number} />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} display="flex" gap={1}>
                        <Box>
                            <Vaccine />
                        </Box>
                        <Stack spacing={1}>
                            <Box mt={-0.25}>
                                <Typography variant="bold" fontSize={18}>
                                    Informations de mise au rebut
                                </Typography>
                            </Box>
                            <GenericTitleValueText
                                title="Date de mise au rebut"
                                value={toLocaleDateFormat(scrapping.date)}
                            />
                            <GenericTitleValueText title="Nombre de flacons mis au rebut" value={scrapping.quantity} />
                            <GenericTitleValueText
                                title="Motif de mise au rebut"
                                value={scrappingReasons.find((s) => s.key === scrapping.reason)?.label}
                            />
                            {scrapping.reason === "OTHER" && (
                                <GenericTitleValueText title="Commentaire" value={scrapping.comment} />
                            )}
                        </Stack>
                    </Grid>
                </Grid>
            </StyledCardContent>
        </Card>
    );

    return (
        <GenericDialog
            maxWidth="lg"
            title={`Mise au rebut n°${scrapping.id}`}
            onClose={onClose}
            renderContent={() => renderContent()}
            renderActions={() => <GenericActionsDialog onClose={onClose} closeLabel="Fermer" displaySubmit={false} />}
        />
    );
}
