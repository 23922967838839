// === NPM
import React from "react";
// === LOCAL
import { IAmvCreateUpdate, IAmvVersion, IConstraints } from "@/components/VaccinationIahp/Billing/interface";
import AmvVatForm from "../../container/AmvVatForm";

interface AmvVersionFormProps {
    amvVersion: IAmvVersion;
    constraints: IConstraints;
    onCancel: () => void;
    onSubmit: (data: IAmvCreateUpdate, uuid?: string) => void;
}

export default function AmvVersionForm({ amvVersion, constraints, onCancel, onSubmit }: Readonly<AmvVersionFormProps>) {
    return (
        <AmvVatForm
            version={amvVersion}
            valueName="La valeur de l'AMV"
            valueUnit="Valeur (en €)"
            constraints={constraints}
            onCancel={onCancel}
            onSubmit={onSubmit}
        />
    );
}
