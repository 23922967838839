// === NPM
import React from "react";
import { Box, Drawer, Stack, Toolbar, Typography } from "@mui/material";
// === LOCAL
import { UserSituation } from "@/resources/PermissionConstant";
import { useAuth } from "@/routers/useAuth";
import { IModule } from "../interface";
import Module from "./Module";

interface ModuleDrawerProps {
    moduleListWidth: number;
    modules: IModule[];
    editPreferences: string[];
}

export default function ModuleDrawer({ moduleListWidth, modules, editPreferences }: Readonly<ModuleDrawerProps>) {
    const auth = useAuth();

    const renderList = () => {
        const list = modules.filter(
            (m: IModule) =>
                !editPreferences.includes(m.key) && m.permissions.includes(auth.userInfo.situation as UserSituation)
        );
        return list.map((module: IModule) => <Module key={module.key} module={module} onDrawer />);
    };
    return (
        <Box sx={{ width: moduleListWidth }}>
            <Drawer
                variant={"permanent"}
                open
                anchor="right"
                sx={{
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        border: "none",
                        width: moduleListWidth,
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        overflow: "hidden",
                    },
                }}
            >
                <Toolbar />
                <Box minHeight={55} />
                <Box sx={{ p: 2, gap: 2, display: "flex", flexDirection: "column", overflowY: "auto" }}>
                    <Typography variant="h5">Modules disponibles</Typography>
                    <Typography>Glissez un module vers la gauche pour l'ajouter à votre écran d'accueil</Typography>
                    <Stack spacing={2} sx={{ mt: 2, transform: "translate3d(0,0,0)" }}>
                        {renderList()}
                    </Stack>
                </Box>
            </Drawer>
        </Box>
    );
}
