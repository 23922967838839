// === NPM
import React from "react";
// === LOCAL
import { ReactComponent as Warning } from "@/assets/icons/shared/danger-triangle.svg";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import IconBannerText from "@/components/generics/IconBannerText";
import { statusColor } from "@/resources/CssConstant";

interface DeleteOvvtOrganizationDialogProps {
    organizationId: string;
    deletable: boolean;
    onClose: (confirm: boolean) => void;
}

export default function DeleteOvvtOrganizationDialog({
    organizationId,
    deletable,
    onClose,
}: Readonly<DeleteOvvtOrganizationDialogProps>) {
    return deletable ? (
        <GenericConfirmDialog
            title={`Suppression de l'organisme ${organizationId}`}
            onClose={onClose}
            message={`Êtes-vous sûr de vouloir supprimer l'organisme ${organizationId} ?`}
        />
    ) : (
        <GenericDialog
            title={`Suppression de l'organisme ${organizationId}`}
            onClose={() => onClose(false)}
            renderContent={() => (
                <IconBannerText
                    icon={<Warning />}
                    message={
                        "Suppression impossible :\nVous devez d'abord supprimer les utilisateurs liés à l'organisme avant de pouvoir le supprimer."
                    }
                    color={statusColor.warningText}
                    backgroundColor={statusColor.warningBackground}
                />
            )}
            renderActions={() => (
                <GenericActionsDialog onClose={() => onClose(false)} displaySubmit={false} closeLabel="J'ai compris" />
            )}
        />
    );
}
