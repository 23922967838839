import { ShippingAddressType } from "@/interfaces/vaccination";

export interface IOrderRoot {
    type: string;
    dpeId: string;
    vaccineGtinCode: string;
    quantity: number;
}

export interface IOrder extends IOrderRoot {
    requestedDeliveryDate: string;
    deliveryName: string;
    address: string;
    complementaryAddress: string;
    city: string;
    postalCode: string;
    phoneNumber: string;
    shippingAddressType: ShippingAddressType;
    deliveries: IManualDelivery[];
}

export interface IServipharForm {
    requestedDeliveryDate: string;
    deliveryName: string;
    address: string;
    complementaryAddress: string;
    city: string;
    postalCode: string;
    phoneNumber: string;
    shippingAddressType: ShippingAddressType;
}

export enum DelivererType {
    SERVIPHAR = "SERVIPHAR",
    OTHER = "Autre",
}

export interface IManualDelivery {
    expeditionDate: string;
    expirationDate: string;
    deliveredQuantity: number;
    batchNumber: string;
    internalId?: string;
}

export const manualDeliveryInitialValues: IManualDelivery = {
    expeditionDate: "",
    expirationDate: "",
    deliveredQuantity: 0,
    batchNumber: "",
};

// THE DTOs FOR THE BACKEND
export interface IServipharOrder extends IServipharForm, IOrderRoot {}
export interface IManualOrder extends IOrderRoot {
    deliveries: IManualDelivery[];
}
