import React from "react";
import { LoadingButton } from "@mui/lab";

interface GenericButtonProps {
    onClick: any;
    label: string;
    disabled?: boolean;
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | "neutral";
    fullWidth?: boolean;
    startIcon?: React.ReactElement;
    sx?: object;
    id?: string;
    loading?: boolean;
}

export default function GenericButton(props: GenericButtonProps) {
    return (
        <LoadingButton
            id={props.id}
            loading={props.loading}
            onClick={props.onClick}
            disabled={props.disabled}
            color={props.color}
            variant="contained"
            fullWidth={props.fullWidth}
            loadingPosition={props.startIcon ? "start" : undefined}
            startIcon={props.startIcon}
            sx={props.sx}
        >
            {props.label}
        </LoadingButton>
    );
}
