// === NPM
import { Button, styled } from "@mui/material";
// === LOCAL
import { colors } from "@/resources/CssConstant";

const NavBarButton = styled(Button)(`
  &.MuiButton-root {
    font-family: 'Nunito-Bold'
  },
  &.selected {
    border-radius: 10px ;
    box-shadow: 0 16px 16px 0 rgba(38, 130, 96, 0.1);
    background-color: #fff;
    color:${colors.primaryColor};
    padding: 8px 16px;
    letter-spacing: 0.32px;
    font-family: 'Nunito-ExtraBold'
  }
`);

export default NavBarButton;
