// === NPM
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridSortModel } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
// === LOCAL
import { ReactComponent as Cancel } from "@/assets/icons/actions/cancel.svg";
import { ReactComponent as Info } from "@/assets/icons/info.svg";
import { ReactComponent as Warning } from "@/assets/icons/shared/danger-triangle.svg";
import { ReactComponent as User } from "@/assets/icons/shared/user.svg";
import { ReactComponent as Valid } from "@/assets/icons/shared/valid.svg";
import { ReactComponent as Unregister } from "@/assets/icons/training/inscriptions/unregister.svg";
import EditAction from "@/components/generics/actions/EditAction";
import IconActionButton from "@/components/generics/actions/IconActionButton";
import ViewAction from "@/components/generics/actions/ViewAction";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import IconBannerText from "@/components/generics/IconBannerText";
import GenericTable from "@/components/generics/table/GenericTable";
import {
    IInscriptionVeterinary,
    InscriptionStatus,
    IRegistration,
    IRegistrationCreate,
    ISession,
    PresenceStatus,
} from "@/components/HealthAccreditationTraining/interface";
import SecondaryButton from "@/components/styled/SecondaryButton";
import useTimeout from "@/hooks/useTimeout";
import { ArrayOfTwo, FilterType, HttpStatus, IPagination, SortDirection } from "@/interfaces/global";
import { SessionMode } from "@/interfaces/training";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { colors, statusColor } from "@/resources/CssConstant";
import { convertEnumToKeyLabelObject, createPayload, getEnumKeyByValue } from "@/resources/utils";
import TrainingService from "@/services/TrainingService";
import NextSessionCard from "../NextSessionCard";
import RegisterDialog from "../RegisterDialog";
import ViewSessionDialog from "../ViewSessionDialog";

export interface IInscriptionsVeterinaryFilters {
    sessionInternalId: string;
    trainingTitle: string;
    startDate: ArrayOfTwo;
    address: string;
    duration: string;
    sessionMode: string[];
    registrationStatus: string[];
    presenceStatus: string[];
}

const initialValues: IInscriptionsVeterinaryFilters = {
    sessionInternalId: "",
    trainingTitle: "",
    address: "",
    startDate: [DateTime.now().toISODate(), null],
    duration: "",
    sessionMode: [],
    registrationStatus: [],
    presenceStatus: [],
};

export default function InscriptionsVeterinary() {
    const [inscriptions, setInscriptions] = useState<IInscriptionVeterinary[]>([]);
    const [inputFilters, setInputFilters] = useState<IInscriptionsVeterinaryFilters>({
        sessionInternalId: "",
        trainingTitle: "",
        address: "",
        startDate: [DateTime.now().toISODate(), null],
        duration: "",
        sessionMode: [],
        registrationStatus: [],
        presenceStatus: [],
    });
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "startDate", sort: "asc" }]);
    const [session, setSession] = useState<ISession>(null);
    const [registration, setRegistration] = useState<IRegistration>(null);
    const [sessionTitle, setSessionTitle] = useState<string>(null);
    const [registrationUuid, setRegistrationUuid] = useState<string>(null);
    const [openDeleteImpossibleDialog, setOpenDeleteImpossibleDialog] = useState<boolean>(false);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getInscriptions();
    }, [pagination, sortModel]);

    const getInscriptions = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        };
        const res = await TrainingService.getVeterinaryRegistrations(payload);
        if (res.status === HttpStatus.OK) {
            setInscriptions(res.data);
            setRowCount(+res.headers[CALYPSO_HEADERS.TABLE_COUNT]);
        }
    };

    const getSession = async (sessionUuid: string) => {
        const res = await TrainingService.getHealthAccreditationTrainingSession(sessionUuid);
        if (res.status === HttpStatus.OK) {
            setSession(res.data);
        }
    };

    const getRegistration = async (uuid: string, trainingTitle: string) => {
        const res = await TrainingService.getRegistration(uuid);
        if (res.status === HttpStatus.OK) {
            setRegistration(res.data);
            setSessionTitle(trainingTitle);
        }
    };

    const updateRegistration = async (data: IRegistrationCreate) => {
        const res = await TrainingService.putRegistration(registration.uuid, data);
        if (res.status === HttpStatus.OK) {
            toast.success("Inscription mise à jour avec succès");
            setRegistration(null);
            setSessionTitle(null);
            getInscriptions();
        }
    };

    const unregister = async (confirm: boolean) => {
        if (!confirm) {
            setRegistrationUuid(null);
            setSessionTitle(null);
            return;
        }
        const res = await TrainingService.deleteRegistration(registrationUuid);
        if (res.status === HttpStatus.NO_CONTENT) {
            toast.success("Inscription annulée avec succès");
            setRegistrationUuid(null);
            setSessionTitle(null);
            getInscriptions();
        }
    };

    const renderInscriptionStatus = (params: GridRenderCellParams) => (
        <Box width={100} display="flex" justifyContent="center">
            {InscriptionStatus[params.row.registrationStatus] === InscriptionStatus.PRE_REGISTERED ? (
                <IconBannerText
                    icon={<Info />}
                    message={InscriptionStatus.PRE_REGISTERED}
                    color="black"
                    backgroundColor={statusColor.infoBackground}
                    alignItems
                />
            ) : (
                <IconBannerText
                    icon={<Valid />}
                    message={InscriptionStatus.REGISTERED}
                    color={statusColor.successText}
                    backgroundColor={statusColor.successBackground}
                    alignItems
                />
            )}
        </Box>
    );

    const renderPresenceStatus = (params: GridRenderCellParams) => (
        <Box width={150} display="flex" justifyContent="center">
            {PresenceStatus[params.row.presenceStatus] === PresenceStatus.UNDEFINED && (
                <IconBannerText
                    icon={<Info />}
                    message="En attente"
                    color="black"
                    backgroundColor={statusColor.infoBackground}
                    alignItems
                />
            )}
            {PresenceStatus[params.row.presenceStatus] === PresenceStatus.PRESENT && (
                <IconBannerText
                    icon={<User />}
                    message={PresenceStatus.PRESENT}
                    color={statusColor.successText}
                    backgroundColor={statusColor.successBackground}
                    alignItems
                />
            )}
            {PresenceStatus[params.row.presenceStatus] === PresenceStatus.ABSENT && (
                <IconBannerText
                    icon={<Cancel />}
                    message={PresenceStatus.ABSENT}
                    color={statusColor.errorText}
                    backgroundColor={statusColor.errorBackground}
                    alignItems
                />
            )}
        </Box>
    );

    const handleUnregisterButton = async (params: GridRenderCellParams) => {
        setSessionTitle(params.row.trainingTitle);
        if (
            DateTime.fromISO(params.row.startDate).startOf("day") <= DateTime.now().startOf("day").plus({ day: 3 }) &&
            params.row.registrationStatus === getEnumKeyByValue(InscriptionStatus, InscriptionStatus.REGISTERED)
        ) {
            setOpenDeleteImpossibleDialog(true);
        } else {
            setRegistrationUuid(params.row.uuid);
        }
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteImpossibleDialog(false);
        setSessionTitle(null);
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const columns: GridColDef[] = [
        {
            field: "sessionInternalId",
            headerName: "ID de la session",
            flex: 1,
        },
        {
            field: "trainingTitle",
            headerName: "Titre de la formation",
            flex: 1,
        },
        {
            field: "address",
            headerName: "Lieu",
            flex: 1,
            sortable: false,
        },
        {
            field: "startDate",
            headerName: "Date",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) =>
                params.row.startDate
                    ? DateTime.fromISO(params.row.startDate).toLocaleString(DateTime.DATETIME_MED)
                    : "",
        },
        {
            field: "duration",
            headerName: "Durée",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) => `${params.row.duration}h`,
        },
        {
            field: "sessionMode",
            headerName: "Mode de session",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) => SessionMode[params.row.sessionMode],
        },
        {
            field: "registrationStatus",
            headerName: "Statut d'inscription",
            flex: 1,
            renderCell: renderInscriptionStatus,
        },
        {
            field: "presenceStatus",
            headerName: "Statut de présence",
            flex: 1,
            renderCell: renderPresenceStatus,
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <>
                    <ViewAction title="Voir les détails" onClick={() => getSession(params.row.sessionUuid)} />
                    {DateTime.fromISO(params.row.startDate).startOf("day") > DateTime.now() &&
                        params.row.registrationStatus !==
                            getEnumKeyByValue(InscriptionStatus, InscriptionStatus.UNREGISTERED) && (
                            <>
                                {params.row.registrationStatus ===
                                    getEnumKeyByValue(InscriptionStatus, InscriptionStatus.PRE_REGISTERED) && (
                                    <EditAction
                                        title="Modifier"
                                        onClick={() => getRegistration(params.row.uuid, params.row.trainingTitle)}
                                    />
                                )}
                                <IconActionButton
                                    icon={<Unregister />}
                                    title="Se désinscrire"
                                    onClick={() => handleUnregisterButton(params)}
                                    color={colors.error}
                                />
                            </>
                        )}
                </>
            ),
        },
    ];

    const filterConfigurations: FilterConfigurations<IInscriptionsVeterinaryFilters> = {
        sessionInternalId: { type: FilterType.INPUT, label: "Identifiant de la session" },
        trainingTitle: { type: FilterType.INPUT, label: "Titre de la formation" },
        address: { type: FilterType.INPUT, label: "Lieu de formation" },
        startDate: { type: FilterType.DATEPICKER, label: "Date" },
        duration: { type: FilterType.INPUT, label: "Durée" },
        sessionMode: {
            type: FilterType.SELECT,
            label: "Mode de session",
            values: convertEnumToKeyLabelObject(SessionMode),
        },
        registrationStatus: {
            type: FilterType.SELECT,
            label: "Statut d'inscription",
            values: Object.entries(InscriptionStatus)
                .filter(([key]) => key !== getEnumKeyByValue(InscriptionStatus, InscriptionStatus.UNREGISTERED))
                .map(([key, label]) => ({ key, label })),
        },
        presenceStatus: {
            type: FilterType.SELECT,
            label: "Statut de présence",
            values: Object.entries(PresenceStatus)
                .filter(([key]) => key !== getEnumKeyByValue(PresenceStatus, PresenceStatus.UNDEFINED))
                .map(([key, label]) => ({ key, label })),
        },
    };

    return (
        <>
            <Stack spacing={2} width="100%">
                <Typography variant="h4">Mes demandes d'inscriptions</Typography>
                <NextSessionCard />
                <Typography variant="h4">
                    Mes demandes de formations en vue du maintien de l'habilitation sanitaire
                </Typography>
                <Box>
                    <SecondaryButton
                        onClick={() =>
                            JSON.stringify(inputFilters) === JSON.stringify(initialValues)
                                ? setInputFilters({
                                      sessionInternalId: "",
                                      trainingTitle: "",
                                      address: "",
                                      startDate: [null, null],
                                      duration: "",
                                      sessionMode: [],
                                      registrationStatus: [],
                                      presenceStatus: [],
                                  })
                                : setInputFilters(initialValues)
                        }
                        variant="outlined"
                    >
                        {JSON.stringify(inputFilters) === JSON.stringify(initialValues)
                            ? "Voir mon historique"
                            : "Voir mes inscriptions"}
                    </SecondaryButton>
                </Box>
                <Card>
                    <CardContent>
                        <GenericFilters
                            inputFilters={inputFilters}
                            filterConfigurations={filterConfigurations}
                            setInputFilters={setInputFilters}
                            initialValues={{
                                sessionInternalId: "",
                                trainingTitle: "",
                                address: "",
                                startDate: [null, null],
                                duration: "",
                                sessionMode: [],
                                registrationStatus: [],
                                presenceStatus: [],
                            }}
                        />
                        <GenericTable
                            rows={inscriptions}
                            columns={columns}
                            getRowId={(row) => row.uuid}
                            onPageSizeChange={handlePageSizeChange}
                            onPageChange={handlePageChange}
                            page={pagination.page}
                            pageSize={pagination.pageSize}
                            autoHeight
                            sortingMode="server"
                            paginationMode="server"
                            sortModel={sortModel}
                            rowCount={rowCount}
                            onSortModelChange={(model) => setSortModel(model)}
                            sortingOrder={[SortDirection.ASC, SortDirection.DESC]}
                            filterMode="server"
                        />
                    </CardContent>
                </Card>
            </Stack>
            {session && <ViewSessionDialog session={session} onClose={() => setSession(null)} />}
            {registration && (
                <RegisterDialog
                    registration={registration}
                    sessionTitle={sessionTitle}
                    onClose={() => {
                        setRegistration(null);
                        setSessionTitle(null);
                    }}
                    onValid={updateRegistration}
                />
            )}
            {registrationUuid && (
                <GenericConfirmDialog
                    title="Désinscription"
                    message={`Êtes-vous sûr de vouloir vous désinscrire de la session ${sessionTitle} ?`}
                    onClose={unregister}
                />
            )}
            {openDeleteImpossibleDialog && (
                <GenericDialog
                    title="Désinscription"
                    onClose={handleCloseDeleteDialog}
                    renderActions={() => (
                        <GenericActionsDialog
                            onClose={handleCloseDeleteDialog}
                            displaySubmit={false}
                            closeLabel="Fermer"
                        />
                    )}
                    renderContent={() => (
                        <IconBannerText
                            icon={<Warning />}
                            message={`Vous souhaitez vous désinscrire de la session ${sessionTitle} à 3 jours de son organisation. Merci de contacter directement l'OVVT responsable afin qu'elle procède à votre désinscription dans Calypso.`}
                            color={statusColor.warningText}
                            backgroundColor={statusColor.warningBackground}
                        />
                    )}
                />
            )}
        </>
    );
}
