import { z } from "zod";

export const FORM_TEXT = {
    required: "Veuillez renseigner ce champ",
    validDate: "La date doit être valide",
    email: "L'email n'est pas valide",
    gtinCode: "Le code GTIN ou CIP ne peut dépasser 14 caractères.",
    maxInteger: "Vous ne pouvez pas saisir une valeur aussi élevée",
    emptyString: "Vous ne pouvez pas saisir uniquement des caractères vides",
    plateNumber: "Les caractères acceptés sont des majuscules, des chiffres et des tirets, avec 15 caractères maximum",
};

export const PATTERN = {
    code: "^[A-Z]{3,5}$",
    email: "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)+$",
    positiveNumber: "^[0-9]{1,10}$",
    // need to check empty string or null
    positiveNumberWithDecimal: "^([0-9]+(\\.[0-9]{1,10})?)$",
    humanGtinCode: "^[a-zA-Z0-9]{1,14}$",
    mobilePhoneNumber: "^(06|07)\\d{8}$",
    lowercaseOnly: "[a-zà-ÿ]+",
    plateNumber: "^[A-Z0-9-]{1,15}$",
    siret: "^[0-9]{1,14}$",
    siren: "^[0-9]{9}$",
    ede: "^[0-9]{8}$",
};

export const stringRequired = () => {
    return z
        .string({
            required_error: FORM_TEXT.required,
            invalid_type_error: FORM_TEXT.required,
        })
        .trim()
        .min(1, { message: FORM_TEXT.required });
};
