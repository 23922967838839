// === Import: NPM
import React, { ReactNode } from "react";
import { HelpOutlineOutlined } from "@mui/icons-material";
// === Import: LOCAL
import { colors } from "@/resources/CssConstant";
import { InfoTooltip } from "../styled/InfoTooltip";

interface InfoIconProps {
    tooltip: ReactNode;
}

export default function InfoIcon({ tooltip }: Readonly<InfoIconProps>) {
    return (
        <InfoTooltip title={tooltip}>
            <HelpOutlineOutlined sx={{ height: 30, width: 30, color: colors.primaryColor }} />
        </InfoTooltip>
    );
}
