// === NPM
import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Pingouin } from "@/assets/icons/pingouin.svg";
import { ITourStep } from "@/interfaces/global";
import { TourKey, UserType } from "@/interfaces/user";
import { routerLinks } from "@/routers/RouterConstant";
import { useAuth } from "@/routers/useAuth";
import Tour from "../generics/Tour";

export default function HomeVetTour() {
    const location = useLocation();
    const auth = useAuth();
    const [config, setSteps] = useState([]);

    useMemo(() => {
        const steps: ITourStep[] = [
            {
                target: "body",
                content: (
                    <Box>
                        <Pingouin style={{ height: 50, width: 50 }} />
                        <Typography>
                            Bienvenue sur votre page d'accueil Calypso ! <br /> Suivez le guide pour découvrir ses
                            fonctionnalités principales.
                        </Typography>
                    </Box>
                ),
                disableBeacon: true,
                placement: "center",
            },
            {
                target: "#edit-home-modules",
                content:
                    "En cliquant sur le stylet, vous pouvez ajouter et supprimer les accès rapides aux différents modules de votre plateforme (Mes formations, Mes cessions antimicrobiens, ...). ",
            },
            {
                target: "#account",
                content: "En cliquant sur cette icône, vous accédez aux informations de votre profil.",
            },
            {
                target: "#parameters",
                content: "Ce bouton vous permet de configurer les paramètres de votre compte.",
                placement: "bottom",
            },
            {
                target: "#downloads",
                content: "Tous vos documents exportés seront disponibles dans cet espace.",
            },
            {
                target: "#notifications",
                content: "Ici, vous retrouverez vos notifications.",
            },
            {
                target: "#faq",
                content: "En cliquant sur cette icône, vous accédez à la foire aux questions.",
            },

            {
                target: "body",
                content:
                    "Pour mieux comprendre le fonctionnement de chaque module, cliquez dessus et laissez-vous guider !",
                placement: "center",
            },
        ];
        setSteps(steps);
    }, []);

    return (
        <Tour
            config={config}
            display={location.pathname === routerLinks.home && auth.userInfo.type === UserType.VETERINARY}
            tourKey={TourKey.HOME_VETERINARY}
        />
    );
}
