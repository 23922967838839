// === Import: NPM
import React from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
// === Import: LOCAL
import GenericTitleValueText from "@/components/generics/text/GenericTitleValueText";
import { IOVDpe } from "@/components/Profile/interface";
import { toLocaleDateFormat } from "@/resources/utils";

interface DpeCardProps {
    dpe: IOVDpe;
}
export default function DpeCard({ dpe }: Readonly<DpeCardProps>) {
    return (
        <Card variant="outlined" sx={{ height: "100%" }}>
            <CardHeader
                titleTypographyProps={{ fontSize: 18, fontWeight: 500 }}
                sx={{ m: 2 }}
                title={`${dpe.ordinalNumber} - ${dpe.name}`}
            />
            <CardContent sx={{ m: 2 }}>
                <GenericTitleValueText title="Ville" value={dpe.address.city} />
                <GenericTitleValueText
                    title="Date de début d'exercice"
                    value={dpe.exerciseDate ? toLocaleDateFormat(dpe.exerciseDate) : ""}
                />
                <GenericTitleValueText title="Mode d'exercice" value={dpe.exercise} />
            </CardContent>
        </Card>
    );
}
