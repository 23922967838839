class LocaleStorageService {
    setLocaleStorageItem = (key: string, value: any) => {
        localStorage.setItem(key, value);
    };

    getLocaleStorageItem = (key: string): any => {
        return localStorage.getItem(key);
    };

    removeLocaleStorageItem = (key: string) => {
        localStorage.removeItem(key);
    };
}

export default new LocaleStorageService();
