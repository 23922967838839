// === NPM
import React, { useEffect, useReducer, useState } from "react";
import JoyRide, { ACTIONS, CallBackProps, EVENTS, STATUS } from "react-joyride";
import { QuestionMark } from "@mui/icons-material";
import { Fab, Tooltip } from "@mui/material";
// === LOCAL
import { HttpStatus, ITourStep, TourState } from "@/interfaces/global";
import { colors } from "@/resources/CssConstant";
import { useAuth } from "@/routers/useAuth";
import UserService from "@/services/UserService";
import { useTour } from "./useTour";

interface TourProps {
    config: any[];
    tourKey: string;
    display?: boolean;
}

const INITIAL_STATE = {
    run: false,
    continuous: true,
    stepIndex: 0,
};

export default function Tour({ config, tourKey, display = true }: Readonly<TourProps>) {
    const auth = useAuth();
    const { setTour } = useTour();
    const [steps, setSteps] = useState<ITourStep[]>([]);

    // Reducer will manage updating the local state
    const reducer = (state, action) => {
        switch (action.type) {
            case TourState.START:
                return { ...state, steps, run: true };
            case TourState.RESET:
                return { ...state, stepIndex: 0 };
            case TourState.STOP:
                return { ...state, run: false, stepIndex: 0 };
            case TourState.NEXT_OR_PREV:
                return { ...state, ...action.payload };
            case TourState.RESTART:
            case TourState.RESUME:
                return {
                    ...state,
                    run: true,
                    loading: false,
                    steps,
                };
            default:
                return state;
        }
    };
    const [tourState, dispatch] = useReducer(reducer, { ...INITIAL_STATE, steps });

    const waitForElement = (selector) => {
        return new Promise(function (resolve) {
            const element = document.querySelector(selector);

            if (element) {
                resolve(element);
                return;
            }
            const observer = new MutationObserver(function (mutations) {
                mutations.forEach(function (mutation) {
                    const nodes = Array.from(mutation.addedNodes);
                    for (const node of nodes) {
                        const nodeElement = node as Element;
                        if (nodeElement.matches && nodeElement.matches(selector)) {
                            observer.disconnect();
                            resolve(node);
                            return;
                        }
                    }
                });
            });

            observer.observe(document.documentElement, { childList: true, subtree: true });
        });
    };

    const scrollIntoView = (element) => {
        element.scrollIntoView({ block: "end" });
    };

    useEffect(() => {
        if (auth.userTutorials?.find((tutorial) => tutorial.key === tourKey) && display) {
            startTour();
        }
    }, [auth.userTutorials]);

    useEffect(() => {
        setSteps(config);
    }, [config]);

    useEffect(() => {
        waitForElement(steps[tourState.stepIndex]?.target).then(scrollIntoView);
    }, [tourState.stepIndex]);

    useEffect(() => {
        if (steps[tourState.stepIndex]?.condition && steps[tourState.stepIndex]?.condition()) {
            dispatch({
                type: TourState.NEXT_OR_PREV,
                payload: { stepIndex: tourState.stepIndex + 1 },
            });
        }
    }, [steps[tourState.stepIndex]?.condition]);

    const completeTour = async () => {
        const res = await UserService.postUserTutorial(auth.userInfo.id, tourKey);
        if (res.status === HttpStatus.NO_CONTENT) {
            auth.removeTutorial(tourKey);
        }
    };

    const callback = (data: CallBackProps) => {
        const { action, index, type, status } = data;
        // If close button clicked, then close the tour
        if (action === ACTIONS.CLOSE) {
            dispatch({ type: TourState.STOP });
        }
        if (
            // If skipped or end tour, then close the tour
            (status === STATUS.SKIPPED && tourState.run) ||
            status === STATUS.FINISHED
        ) {
            dispatch({ type: TourState.STOP });
            completeTour();
        } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
            // Check whether next or back button click and update the step.
            dispatch({
                type: TourState.NEXT_OR_PREV,
                payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
            });
        }
    };

    const startTour = () => {
        setTour({});
        if (tourState.stepIndex !== steps.length) {
            dispatch({ type: TourState.START });
        } else {
            dispatch({ type: TourState.RESTART });
        }
    };

    return (
        (display || tourState.run) && (
            <>
                {!tourState.run && (
                    <Tooltip title="Démarrer le didacticiel">
                        <Fab
                            sx={{ position: "absolute", top: 15, right: 15 }}
                            color="primary"
                            size="medium"
                            onClick={startTour}
                        >
                            <QuestionMark sx={{ color: colors.white }} />
                        </Fab>
                    </Tooltip>
                )}
                {steps.length > 0 && (
                    <JoyRide
                        {...tourState}
                        callback={callback}
                        showSkipButton
                        scrollToFirstStep
                        disableScrolling
                        disableScrollParentFix
                        disableOverlayClose
                        showProgress
                        locale={{
                            back: "Précédent",
                            close: "Fermer",
                            last: "Terminer",
                            next: "Suivant",
                            open: "Suivre le tour",
                            skip: "Passer",
                        }}
                        styles={{
                            options: {
                                zIndex: 1500,
                            },
                            buttonNext: {
                                backgroundColor: colors.primaryColor,
                            },
                            buttonBack: {
                                color: colors.secondaryColor,
                            },
                            tooltipContainer: {
                                textAlign: "start",
                            },
                            tooltip: {
                                minWidth: 500,
                            },
                        }}
                    />
                )}
            </>
        )
    );
}
