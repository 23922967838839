import { ChangeEvent, FormEvent, useState } from "react";
import { FORM_TEXT } from "../resources/FormUtils";

interface Validation {
    required?: {
        value: boolean;
        message: string;
    };
    // define pattern if necessary, has to be a regex (ex: validate an email);
    pattern?: {
        value: string;
        message: string;
    };
    // custom validation who can't be put in pattern (ex: conditionnal check for an input)
    custom?: {
        isValid: (value: any) => boolean;
        message: string;
    };
    // custom validation for value interval limitation (ex: minimal length for an input)
    limitValue?: {
        isValid: (value: any) => boolean;
        message: string;
    };
}

export interface Form<T> {
    data: T;
    handleChange: (key: keyof T) => (e: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => void;
    handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
    errors: ErrorRecord<T>;
    cleanValue: (key: string) => void;
    initValues: (initialValues: Partial<T>) => void;
}

type ErrorRecord<T> = Partial<Record<keyof T, string>>;

export type Validations<T extends {}> = Partial<Record<keyof T, Validation>>;

export const useForm = <T extends Partial<Record<keyof T, any>> = {}>(options?: {
    validations?: Validations<T>;
    initialValues?: Partial<T>;
    onSubmit?: (e) => void;
}): Form<T> => {
    const [data, setData] = useState<T>((options?.initialValues || {}) as T);
    const [errors, setErrors] = useState<ErrorRecord<T>>({});

    /**
     * @description update data
     */
    const handleChange = (key: keyof T) => (e: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
        const value: string | string[] | boolean = e.target.value ?? e.target.checked;
        setData((prev) => ({ ...prev, [key]: value }));
    };

    const initValues = (initialValues: Partial<T>) => {
        setData((prev) => ({ ...prev, ...initialValues }));
    };

    const handleValidation = (validation: Validation, value: any): { valid: boolean; error: string } => {
        const custom = validation?.custom;
        const limitValue = validation?.limitValue;
        let valid = true;
        let error = "";

        if (limitValue?.isValid && !limitValue?.isValid(value)) {
            valid = false;
            error = limitValue.message;
        }

        if (custom?.isValid && !custom?.isValid(value)) {
            valid = false;
            error = custom.message;
        }

        const pattern = validation?.pattern;
        if (pattern?.value && value && !RegExp(pattern.value).test(value)) {
            valid = false;
            error = pattern.message;
        }

        if (validation.required?.value && !value) {
            valid = false;
            error = validation.required.message;
        }

        if (value && typeof value === "string" && value.trim().length === 0) {
            valid = false;
            error = FORM_TEXT.emptyString;
        }

        return { valid, error };
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const validations = options?.validations;
        let isValid = true;
        if (validations) {
            const newErrors: ErrorRecord<T> = {};
            // iterate over all validations rules to check necessary input

            for (const key in validations) {
                const { valid, error } = handleValidation(validations[key], data[key]);
                newErrors[key] = error;
                if (!valid) {
                    isValid = valid;
                }
            }
            if (!isValid) {
                setErrors(newErrors);
                return;
            }
        }
        setErrors({});
        if (options.onSubmit) {
            options.onSubmit(e);
        }
    };

    const cleanValue = (key: string) => {
        setData((prev) => ({ ...prev, [key]: "" }));
    };

    return {
        data,
        handleChange,
        handleSubmit,
        errors,
        cleanValue,
        initValues,
    };
};
