// === Import: NPM
import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { NavigateNext } from "@mui/icons-material";
import { Box, Divider, Link, LinkProps, Breadcrumbs as MuiBreadcrumbs, Typography } from "@mui/material";
// === Import: LOCAL
import { ReactComponent as Home } from "@/assets/icons/shared/home.svg";
import { colors } from "@/resources/CssConstant";
import { routerLinks } from "@/routers/RouterConstant";

const breadcrumbsConfig = {
    [routerLinks.profile]: "Mon profil",
    [routerLinks.admin.base]: "Administration",
    // Utilisateur
    [routerLinks.admin.users.global()]: "Utilisateurs",
    [routerLinks.admin.users.famDam()]: "Utilisateurs",
    [routerLinks.admin.users.ma()]: "Utilisateurs",

    [routerLinks.admin.profile()]: "Profils",

    // API KEY
    [routerLinks.admin.apiKey.base()]: "Clefs d'API",
    [routerLinks.admin.apiKey.delivery()]: "API cession de médicaments",
    [routerLinks.admin.apiKey.siPartners()]: "SI Partenaires",
    [routerLinks.admin.apiKey.continuousTraining()]: "API formations continues",
    [routerLinks.admin.apiKey.alerts()]: "Alertes",

    // Référentiel Global
    [routerLinks.referential.base]: "Référentiels",
    [routerLinks.referential.dashboard()]: "Monitoring",

    //Référentiel transverse
    [routerLinks.referential.global.base()]: "Référentiel transverse",
    [routerLinks.referential.global.dpeDirectory()]: "DPE",
    [routerLinks.referential.global.species()]: "Catégorie et sous-catégorie d'espèces",
    [routerLinks.referential.global.workshops()]: "Ateliers",
    [routerLinks.referential.global.agriMinistryGeoUnit()]: "Services administratifs",
    [routerLinks.referential.global.famDamAgencies()]: "Établissements",
    [routerLinks.referential.global.ovvt()]: "OVVT",

    // Réferentiel Cessions médicaments
    [routerLinks.referential.delivery.base()]: "Cession de médicament",
    [routerLinks.referential.delivery.typeIdentifiant()]: "Type identifiant",
    [routerLinks.referential.delivery.drugs()]: "Médicaments",
    [routerLinks.referential.delivery.famDam()]: "Groupes et établissements FAM/DAM",
    [routerLinks.referential.delivery.drugTransferOrganization()]: "Souches logicielles",
    [routerLinks.referential.delivery.pharmacies()]: "Pharmacies",

    // Référentiel training
    [routerLinks.referential.training.base()]: "Formation continue",
    [routerLinks.referential.training.types()]: "Types de formation",
    [routerLinks.referential.training.formats()]: "Formats de formation",
    [routerLinks.referential.training.organizations()]: "Organismes de formation",

    // Delivery dashboard
    [routerLinks.delivery.base]: "Cessions antimicrobiens",
    [routerLinks.delivery.dashboard.base()]: "Mes données de cession",
    [routerLinks.delivery.dashboard.trackingLogs()]: "Suivi des flux de collecte",
    [routerLinks.delivery.dashboard.deliveries()]: "Suivi des cessions",
    [routerLinks.delivery.dashboard.prescriptions()]: "Suivi des ordonnances",
    //Saisies manuelles
    [routerLinks.delivery.manualDelivery.base()]: "Mes saisies manuelles",
    [routerLinks.delivery.manualDelivery.form()]: "Déclaration de saisie manuelle",
    [routerLinks.delivery.manualDelivery.view()]: "Consultation des saisies manuelles",
    //PowerBI
    [routerLinks.delivery.powerBi.base()]: "Mes tableaux d'analyse",
    [routerLinks.delivery.powerBi.followUp()]: "Tableaux de suivi",
    [routerLinks.delivery.powerBi.indicators()]: "Tableaux d'indicateurs",
    [routerLinks.delivery.powerBi.dashBoard()]: "Tableau de bord",

    //Traceability
    [routerLinks.admin.traceability.base()]: "Traçabilité",
    [routerLinks.admin.traceability.users()]: "Utilisateurs",
    [routerLinks.admin.traceability.organizations()]: "Organismes de formation",
    [routerLinks.admin.traceability.nonDelivery()]: "Déclarations de non cession",
    [routerLinks.admin.traceability.famDamAgencies()]: "Groupes et établissements FAM/DAM",
    [routerLinks.admin.traceability.healthReportings()]: "Signalements de maltraitance",
    [routerLinks.admin.traceability.ovvt()]: "Organismes Vétérinaires à Vocation Technique",
    [routerLinks.admin.traceability.healthAccreditationTrainings()]: "Formations habilitation sanitaire",
    [routerLinks.admin.traceability.healthAccreditationTrainingSessions()]:
        "Sessions de formation habilitation sanitaire",
    //Traceability Iahp
    [routerLinks.iahp.traceabilityIahp.base()]: "Traçabilité IAHP",
    [routerLinks.iahp.traceabilityIahp.monitoringIntervention()]: "Interventions de surveillance",
    [routerLinks.iahp.traceabilityIahp.scrapping()]: "Mise au rebut",
    [routerLinks.iahp.traceabilityIahp.vaccinationSite()]: "Chantiers de vaccination",
    // Parameters
    [routerLinks.parameters.base]: "Paramètres",
    [routerLinks.parameters.dpePreferences()]: "Préférences DPE",
    [routerLinks.parameters.userPreferences()]: "Mes préférences",

    // FAQ
    [routerLinks.admin.faq.base()]: "Foire aux questions",
    [routerLinks.admin.faq.questions()]: "Gestion des questions",
    [routerLinks.admin.faq.categoryAndTag()]: "Gestion des catégories",
    [routerLinks.faq]: "Foire aux questions",

    //Vaccination campaign
    [routerLinks.vaccinationCampaign.base]: "Campagnes de vaccination",
    // Vaccination
    [routerLinks.vaccinationCampaign.vaccine.base()]: "Mes commandes de vaccins",
    [routerLinks.vaccinationCampaign.vaccine.order()]: "Saisie d'une commande de vaccin",
    [routerLinks.vaccinationCampaign.vaccine.view()]: "Suivi des commandes de vaccin",
    [routerLinks.vaccinationCampaign.vaccine.scrappingView()]: "Suivi des mises au rebut",
    [routerLinks.vaccinationCampaign.vaccine.scrappingDeclare()]: "Saisie d'une mise au rebut",

    // IAHP
    [routerLinks.iahp.base]: "Vaccination IAHP",
    // Farm
    [routerLinks.iahp.farm.base()]: "Élevages",
    [routerLinks.iahp.farm.view()]: "Mes élevages",
    [routerLinks.iahp.farm.declareRelation()]: "Déclarer une relation VS",

    //Vaccination site
    [routerLinks.iahp.vaccinationSite.base()]: "Chantiers de vaccination",
    [routerLinks.iahp.vaccinationSite.view()]: "Mes chantiers",
    [routerLinks.iahp.vaccinationSite.form()]: "Saisie d'un chantier",
    [routerLinks.iahp.vaccinationSite.editForm()]: "Saisie d'un chantier",
    [routerLinks.iahp.vaccinationSite.vaccinationIntervention.base()]: "Mes interventions de vaccination",

    //Monitoring intervention
    [routerLinks.iahp.monitoringIntervention.base()]: "Mes interventions de surveillance",
    [routerLinks.iahp.monitoringIntervention.view()]: "Suivi des interventions",
    [routerLinks.iahp.monitoringIntervention.form()]: "Saisie d'une intervention",

    //Notifications
    [routerLinks.admin.notifications.base()]: "Notifications",
    [routerLinks.admin.notifications.form()]: "Saisie de notification",
    [routerLinks.admin.notifications.gestion()]: "Gestion des notifications manuelles",
    [routerLinks.admin.notifications.autoNotifConfig()]: "Gestion des notifications automatiques",
    [routerLinks.admin.notifications.automatic()]: "Suivi des notifications automatiques",

    //Formations continues
    [routerLinks.continuousTraining.base]: "Formations continues",
    [routerLinks.continuousTraining.trainings()]: "Mes formations continues",
    [routerLinks.continuousTraining.catalog()]: "Catalogue des formations continues",

    //Formations habilitation sanitaire
    [routerLinks.healthAccreditationTraining.base]: "Formations habilitation sanitaire",
    [routerLinks.healthAccreditationTraining.catalog()]: "Catalogue des formations habiliation sanitaire",
    [routerLinks.healthAccreditationTraining.sessions.base()]: "Sessions de formations",
    [routerLinks.healthAccreditationTraining.sessions.form()]: "Saisie d'une session",
    [routerLinks.healthAccreditationTraining.sessions.view()]: "Suivi des sessions",
    [routerLinks.healthAccreditationTraining.sessions.process()]: "Mes sessions à traiter",
    [routerLinks.healthAccreditationTraining.sessions.inscriptions()]: "Inscriptions d'une session",
    [routerLinks.healthAccreditationTraining.sessions.viewInscriptions()]: "Mes demandes d'inscriptions",
    [routerLinks.healthAccreditationTraining.sessions.attestations()]: "Mes attestations de formation",

    //ButcherySector
    [routerLinks.sanitaryFollowUp.base]: "Mes suivis sanitaires",
    [routerLinks.sanitaryFollowUp.butcherySector.base()]: "Exclusion de la filière bouchère",
    [routerLinks.sanitaryFollowUp.butcherySector.exclusion()]: "Suivi du flux de l'exclusion de la filière bouchère",
    [routerLinks.sanitaryFollowUp.butcherySector.search()]: "Recherche d'un équidé",

    //Exports
    [routerLinks.iahp.exports()]: "Exports",

    //PBI IAHP
    [routerLinks.iahp.iahpPowerBi()]: "Mes tableaux de suivi",

    //AnimalAbuse
    [routerLinks.healthReporting.base]: "Signalements de maltraitance",
    [routerLinks.healthReporting.animalAbuse.base()]: "Maltraitance animale",
    [routerLinks.healthReporting.animalAbuse.view()]: "Mes signalements de maltraitance",
    [routerLinks.healthReporting.animalAbuse.form()]: "Déclarer un nouveau signalement",

    //Facturation IAHP Billing
    [routerLinks.iahp.billing.base()]: "Facturation IAHP",
    [routerLinks.iahp.billing.management()]: "Référentiels de facturation",
    [routerLinks.iahp.billing.vaccinationSites()]: "Mes chantiers en demande de paiement",
    [routerLinks.iahp.billing.vaccinationSitesOutsideCalypso()]: "Mes chantiers facturés hors Calypso",
    [routerLinks.iahp.billing.memories()]: "Mémoires de paiement",
};

interface LinkRouterProps extends LinkProps {
    to: string;
    replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

export default function Breadcrumbs() {
    const location = useLocation();
    const pathnames = location.pathname.split("/").filter((path) => path);

    const renderBreadcrumbs = () => {
        const content = [];
        pathnames.forEach((value, index) => {
            const to = `/${pathnames.slice(0, index + 1).join("/")}`;
            if (!breadcrumbsConfig[to]) return;

            content.push(
                <Typography color="text.primary" key={to}>
                    {breadcrumbsConfig[to]}
                </Typography>
            );
        });
        return content;
    };

    return (
        <Box sx={{ position: "fixed", zIndex: 1300, width: "100%", background: colors.white }}>
            <Divider sx={{ borderCorlor: colors.background }} />

            <MuiBreadcrumbs
                sx={{
                    ml: 11,
                    mt: 2,
                    mb: 1,
                }}
                separator={<NavigateNext fontSize="small" />}
            >
                <LinkRouter
                    key="1"
                    underline="hover"
                    sx={{ display: "flex", alignItems: "center", color: colors.primaryColor }}
                    color="inherit"
                    to={routerLinks.home}
                >
                    <Home />
                </LinkRouter>
                {renderBreadcrumbs()}
            </MuiBreadcrumbs>
            <Divider sx={{ borderCorlor: colors.background }} />
        </Box>
    );
}
