// === NPM
import React, { Dispatch, SetStateAction, useContext, useState } from "react";
// === LOCAL
import { IMonitoringInterventionForm } from "@/interfaces/vaccination";

export interface UseFormMonitoringIntervention {
    form: IMonitoringInterventionForm;
    setForm: Dispatch<SetStateAction<IMonitoringInterventionForm>>;
}

export const formInterventionContext = React.createContext<UseFormMonitoringIntervention>({
    form: null,
    setForm: undefined,
});

export const useFormMonitoringIntervention = (): UseFormMonitoringIntervention => {
    return useContext(formInterventionContext);
};

export const FormMonitoringInterventionProvider = ({ children }: React.PropsWithChildren) => {
    const auth = useProvideFormIntervention();
    return <formInterventionContext.Provider value={auth}>{children}</formInterventionContext.Provider>;
};

const useProvideFormIntervention = () => {
    const [form, setForm] = useState<IMonitoringInterventionForm>(null);

    return {
        form,
        setForm,
    };
};
