// === NPM
import React from "react";
import { Outlet } from "react-router-dom";
// === LOCAL
import PageContent from "@/components/generics/layout/PageContent";

export default function Traceability() {
    return (
        <PageContent>
            <Outlet />
        </PageContent>
    );
}
