// === NPM
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
// === LOCAL
import PageContent from "@/components/generics/layout/PageContent";
import { HttpStatus } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import VaccinationService from "@/services/VaccinationService";

export default function TraceabilityIahp() {
    const [horsePower, setHorsePower] = useState<IReferential[]>([]);

    useEffect(() => {
        getHorsePower();
    }, []);

    const getHorsePower = async () => {
        const res = await VaccinationService.getHorsePower();
        if (res.status === HttpStatus.OK) {
            setHorsePower(res.data);
        }
    };

    return (
        <PageContent>
            <Outlet context={{ horsePower }} />
        </PageContent>
    );
}
