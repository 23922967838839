import React, { PropsWithChildren } from "react";
import { ExpandMore, Info } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Tooltip, Typography } from "@mui/material";
import { colors } from "@/resources/CssConstant";

interface GenericAccordionProps {
    title: string;
    defaultExpanded?: boolean;
    info?: string;
}

export default function GenericAccordion({
    title,
    children,
    defaultExpanded,
    info,
}: PropsWithChildren<GenericAccordionProps>) {
    return (
        <Accordion
            defaultExpanded={defaultExpanded}
            sx={{
                "&.Mui-expanded:first-of-type": {
                    marginTop: "16px",
                },
                "&:before": {
                    display: "none",
                },
            }}
        >
            <AccordionSummary expandIcon={<ExpandMore />}>
                {info && (
                    <Tooltip title={info}>
                        <Info sx={{ position: "relative", top: -25, left: "101.5%", color: colors.primaryColor }} />
                    </Tooltip>
                )}
                <Typography variant="h5">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
}
