// === NPM
import React from "react";
import { FieldError, Merge } from "react-hook-form";
import { FormHelperText } from "@mui/material";

interface GenericErrorMessageTextProps {
    fieldError: Merge<FieldError, FieldError[]>;
}
export default function GenericErrorMessageText({ fieldError }: Readonly<GenericErrorMessageTextProps>) {
    return fieldError && <FormHelperText error>{fieldError?.message}</FormHelperText>;
}
