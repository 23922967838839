// === NPM
import React from "react";
// === LOCAL
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import GenericTextField from "@/components/generics/inputs/GenericTextField";
import { IAccount } from "../../interface";

interface AccountInformationDialogProps {
    account: IAccount;
    title: string;
    onClose: any;
}

export default function AccountInformationDialog({ account, title, onClose }: Readonly<AccountInformationDialogProps>) {
    return (
        <GenericDialog
            title={title}
            onClose={onClose}
            renderContent={() => <GenericTextField value={account.email} label="Email" disabled />}
            renderActions={() => <GenericActionsDialog onClose={onClose} displaySubmit={false} />}
        />
    );
}
