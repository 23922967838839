// === Import: NPM
import React, { useState, useContext, Dispatch, SetStateAction } from "react";

export interface UseGlobalContext {
    drawerOpen: boolean;
    setDrawerOpen: Dispatch<SetStateAction<boolean>>;
    loadingButton: boolean;
    setLoadingButton: Dispatch<SetStateAction<boolean>>;
    loadingRequest: boolean;
    setLoadingRequest: Dispatch<SetStateAction<boolean>>;
    loadingTable: boolean;
    setLoadingTable: Dispatch<SetStateAction<boolean>>;
}

export const globalComponentContext = React.createContext<UseGlobalContext>({
    drawerOpen: false,
    setDrawerOpen: undefined,
    loadingButton: false,
    setLoadingButton: undefined,
    loadingRequest: false,
    setLoadingRequest: undefined,
    loadingTable: false,
    setLoadingTable: undefined,
});

export const useProvideGlobal = (): UseGlobalContext => {
    return useContext(globalComponentContext);
};

export const GlobalProvider = ({ children }: React.PropsWithChildren) => {
    const drawer = ProvideGlobal();
    return <globalComponentContext.Provider value={drawer}>{children}</globalComponentContext.Provider>;
};

const ProvideGlobal = () => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
    const [loadingTable, setLoadingTable] = useState<boolean>(false);

    return {
        drawerOpen,
        setDrawerOpen,
        loadingButton,
        setLoadingButton,
        loadingRequest,
        setLoadingRequest,
        loadingTable,
        setLoadingTable,
    };
};
