// === NPM
import React, { useState } from "react";
// === LOCAL
import { ReactComponent as Amv } from "@/assets/icons/billing/amv.svg";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import { IAmvCreateUpdate, IAmvVersion, IConstraints } from "@/components/VaccinationIahp/Billing/interface";
import VersionTileLayout from "../../container/VersionTileLayout";
import AmvVersionForm from "./AmvVersionForm";

interface AmvVersionTileProps {
    amvVersion: IAmvVersion;
    constraints: IConstraints;
    updateVersion: (data: IAmvCreateUpdate, uuid: string) => void;
    deleteVersion: (uuid: string) => void;
}
export default function AmvVersionTile({ amvVersion, constraints, updateVersion, deleteVersion }: AmvVersionTileProps) {
    const [isEditing, setIsEditing] = useState<boolean>(false);

    return isEditing ? (
        <AmvVersionForm
            amvVersion={amvVersion}
            constraints={constraints}
            onCancel={() => setIsEditing(false)}
            onSubmit={updateVersion}
        />
    ) : (
        <VersionTileLayout version={amvVersion} setIsEditing={setIsEditing} deleteVersion={deleteVersion}>
            <GenericIconText icon={<Amv />} iconTooltip="Valeur de l'AMV" text={`${amvVersion.rate.toFixed(4)} €`} />
        </VersionTileLayout>
    );
}
