// === NPM
import React, { useRef } from "react";
// === LOCAL
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import GenericCard from "@/components/generics/layout/GenericCard";
import { IType, IUserShort } from "@/interfaces/user";
import { ICreateFamDamUser } from "../interface";
import UserInformations from "./UserInformations";

interface UserDialogProps {
    onClose: any;
    userTypes: IType[];
    onValid: (userInfo: ICreateFamDamUser) => void;
    label: string;
    disabled?: boolean;
    userValues?: IUserShort;
    type: string;
}

export default function UserDialog({
    onClose,
    userTypes,
    onValid,
    label,
    userValues,
    disabled,
    type,
}: Readonly<UserDialogProps>) {
    const initialValues = {
        firstname: "",
        lastname: "",
        email: "",
        type: type,
        situation: "",
    };
    const formRef = useRef(null);

    const onSubmit = async (userInfo: ICreateFamDamUser) => {
        onValid(userInfo);
    };

    return (
        <GenericDialog
            title={label}
            onClose={onClose}
            paperProps={{ sx: { width: "100%" } }}
            renderContent={() => (
                <GenericCard>
                    <UserInformations
                        onSubmit={onSubmit}
                        userTypes={userTypes}
                        formRef={formRef}
                        initForm={userValues ?? initialValues}
                        disabled={disabled}
                    />
                </GenericCard>
            )}
            renderActions={() => (
                <GenericActionsDialog
                    onClose={onClose}
                    validLabel="Sauvegarder"
                    onSubmit={() => formRef.current.click()}
                    displaySubmit={!disabled}
                />
            )}
        />
    );
}
