// === NPM
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Card, CardActions, CardContent, CardHeader, Stack, TextField, Tooltip } from "@mui/material";
import { DateTime } from "luxon";
import { z } from "zod";
// === LOCAL
import GenericDatePicker from "@/components/generics/inputs/GenericDatePicker";
import InfoText from "@/components/generics/text/InfoText";
import SecondaryButton from "@/components/styled/SecondaryButton";
import {
    IAmvVersion,
    IConstraints,
    IVatCreateUpdate,
    IVatVersion,
} from "@/components/VaccinationIahp/Billing/interface";
import { colors } from "@/resources/CssConstant";
import { FORM_TEXT, stringRequired } from "@/resources/FormUtils";
import { computeMinimumStartDate } from "../referentialFormUtils";

interface VersionFormProps {
    version: IVatVersion | IAmvVersion;
    valueName: string;
    valueUnit: string;
    constraints: IConstraints;
    onCancel: () => void;
    onSubmit: (data: IVatCreateUpdate, uuid?: string) => void;
}

export default function VatVersionForm({
    version,
    valueName,
    valueUnit,
    constraints,
    onCancel,
    onSubmit,
}: Readonly<VersionFormProps>) {
    const minDate = computeMinimumStartDate(version.startDate, constraints?.previousDate);

    const formSchema = z.object({
        startDate: stringRequired()
            .refine((value) => DateTime.fromISO(value) >= minDate, {
                message: "Vous ne pouvez pas reculer la date de début.",
            })
            .refine(
                (value) =>
                    constraints.nextDate ? DateTime.fromISO(value) < DateTime.fromISO(constraints.nextDate) : true,
                {
                    message: "La date de début ne peut pas excéder celle de la version suivante.",
                }
            ),
        rate: z.coerce
            .number({
                required_error: FORM_TEXT.required,
                invalid_type_error: `${valueName} doit être un nombre positif`,
            })
            .positive(`${valueName} doit être un nombre positif`)
            .multipleOf(0.0001, { message: `${valueName} ne doit pas excéder 4 chiffres après la virgule` })
            .refine((value) => constraints.previousRate !== value && constraints.nextRate !== value, {
                message: "Deux versions consécutives ne peuvent pas être identiques",
            }),
    });
    type ValidationSchema = z.infer<typeof formSchema>;
    const justTheRate = formSchema.pick({ rate: true });
    type ValidationRateOnly = z.infer<typeof justTheRate>;
    const isFirstVersion = version.version === 1;

    const {
        formState: { errors },
        handleSubmit,
        register,
        control,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(isFirstVersion ? justTheRate : formSchema),
        defaultValues: {
            startDate:
                version.startDate ??
                DateTime.max(DateTime.fromISO(constraints.previousDate), DateTime.now()).plus({ days: 1 }).toISODate(),
            rate: version.rate ?? null,
        },
    });

    const onValid = (formValues: ValidationSchema) => {
        onSubmit({ startDate: formValues.startDate, rate: formValues.rate }, version.uuid);
    };

    const onValidRateOnly = (formValues: ValidationRateOnly) => {
        onSubmit({ rate: formValues.rate }, version.uuid);
    };

    return (
        <Card variant="outlined" sx={{ paddingY: 1, paddingX: 2, width: "100%" }}>
            <CardHeader
                titleTypographyProps={{ fontSize: 18, fontWeight: 500, color: colors.primaryColor }}
                title={`Version ${version.version}`}
            />
            <CardContent>
                {!isFirstVersion && (
                    <InfoText message={`La date minimale autorisée est le ${minDate.toLocaleString()}`} />
                )}
                <Stack spacing={2}>
                    <Controller
                        name="startDate"
                        control={control}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                            <Tooltip
                                title={
                                    isFirstVersion ? "La date de début de la première version n'est pas modifiable" : ""
                                }
                            >
                                <span>
                                    <GenericDatePicker
                                        value={isFirstVersion ? "2023-01-01" : value}
                                        name="startDate"
                                        label="Date de début"
                                        onChange={onChange}
                                        error={!!error}
                                        required
                                        helperText={error?.message}
                                        minDate={minDate}
                                        maxDate={DateTime.fromISO(constraints.nextDate).minus({ days: 1 })}
                                        disabled={isFirstVersion}
                                    />
                                </span>
                            </Tooltip>
                        )}
                    />

                    <TextField
                        label={valueUnit}
                        {...register("rate")}
                        required
                        fullWidth
                        error={!!errors.rate}
                        helperText={errors?.rate?.message}
                    />
                </Stack>
            </CardContent>
            <CardActions sx={{ justifyContent: "center" }}>
                <SecondaryButton onClick={() => onCancel()} variant="outlined">
                    Annuler
                </SecondaryButton>
                <Button
                    onClick={handleSubmit((data) => {
                        if (isFirstVersion) onValidRateOnly(data);
                        else onValid(data);
                    })}
                    variant="contained"
                    color="primary"
                >
                    Enregistrer
                </Button>
            </CardActions>
        </Card>
    );
}
