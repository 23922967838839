import axios, { AxiosInstance } from "axios";

class HttpService {
    axiosApiInstance: AxiosInstance;
    _token = null;
    _tokenData = null;
    constructor() {
        this.axiosApiInstance = axios.create();
    }

    async fetch(config: any) {
        return new Promise<any>((resolve) => {
            this.axiosApiInstance(config)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    resolve(err);
                });
        });
    }

    /**
     * Authentication interceptor.
     *
     * @param {string} token The token to set in header.
     * @returns {Function} New interceptor function.
     */
    authInterceptor = () => {
        return (config) => {
            config.headers["Authorization"] = `Bearer ${localStorage.getItem("CALYPSO_TOKEN")}`;
            return config;
        };
    };

    /**
     * Reset axios instance
     */
    resetAxiosInstance() {
        this.axiosApiInstance = axios.create();
    }

    /**
     * Removing authorization interceptor.
     */
    clearToken() {
        this.resetAxiosInstance();
        this._token = null;
        this._tokenData = {};
    }

    /**
     * Setting token to `newToken` and adding
     * authentication interceptor.
     * @param {string} newToken New token.
     */
    setToken(newToken) {
        this._token = newToken;
        try {
            this._tokenData = newToken;
        } catch (err) {
            this.clearToken();
            return;
        }
        this.axiosApiInstance.interceptors.request.use(this.authInterceptor());
    }
}

export default new HttpService();
