// === NPM
import React from "react";
import { useDrag } from "react-dnd";
import { Card, CardHeader, Stack } from "@mui/material";
// === LOCAL
import { ReactComponent as Add } from "@/assets/icons/actions/add_filled.svg";
import DeleteAction from "@/components/generics/actions/DeleteAction";
import IconActionButton from "@/components/generics/actions/IconActionButton";
import GenericTitleValueText from "@/components/generics/text/GenericTitleValueText";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { useDepartments } from "@/context/useDepartmentContext";
import { colors } from "@/resources/CssConstant";
import { toLocaleDateFormat } from "@/resources/utils";
import { ItemTypes, IValidatedVaccinationSite } from "../../interface";

interface VaccinationSiteTileProps {
    vaccinationSite: IValidatedVaccinationSite;
    onDelete?: (vaccinationSite: IValidatedVaccinationSite) => void;
    onAdd?: (vaccinationSite: IValidatedVaccinationSite) => void;
}

export default function VaccinationSiteTile({ vaccinationSite, onDelete, onAdd }: Readonly<VaccinationSiteTileProps>) {
    const { departments } = useDepartments();
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.VACCINATION_SITE_TILE,
            item: vaccinationSite,
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [vaccinationSite]
    );

    return (
        <Card
            ref={drag}
            key={vaccinationSite.id}
            sx={{
                "paddingTop": 1,
                "paddingX": 2,
                "width": "100%",
                "cursor": "move",
                "opacity": isDragging ? 0.5 : 1,
                "&:before": {
                    display: "none",
                },
            }}
        >
            <CardHeader
                titleTypographyProps={{ fontSize: 20, fontWeight: 500, color: colors.primaryColor }}
                title={`Chantier ${vaccinationSite.farmName} - ${toLocaleDateFormat(vaccinationSite.date)}`}
                action={
                    <>
                        {onDelete && (
                            <DeleteAction
                                title="Retirer le chantier du mémoire"
                                onClick={() => onDelete(vaccinationSite)}
                            />
                        )}
                        {onAdd && (
                            <IconActionButton
                                title="Ajouter le chantier au mémoire"
                                onClick={() => onAdd(vaccinationSite)}
                                icon={<Add />}
                            />
                        )}
                    </>
                }
            />
            <StyledCardContent>
                <Stack spacing={1}>
                    <GenericTitleValueText title="N° du chantier" value={`${vaccinationSite.id} `} />
                    <GenericTitleValueText title="Identifiant établissement" value={`${vaccinationSite.farmId}`} />
                    <GenericTitleValueText
                        title="Département"
                        value={`${departments.find((department) => department.inseeCode === vaccinationSite.departmentInseeCode)?.name} (${vaccinationSite.departmentInseeCode})`}
                    />
                </Stack>
            </StyledCardContent>
        </Card>
    );
}
