// === NPM
import React from "react";
// === LOCAL
import { IConstraints, IVatCreateUpdate, IVatVersion } from "@/components/VaccinationIahp/Billing/interface";
import AmvVatForm from "../../container/AmvVatForm";

interface VatVersionFormProps {
    vatVersion: IVatVersion;
    constraints: IConstraints;
    onCancel: () => void;
    onSubmit: (data: IVatCreateUpdate, uuid?: string) => void;
}

export default function VatVersionForm({ vatVersion, constraints, onCancel, onSubmit }: Readonly<VatVersionFormProps>) {
    return (
        <AmvVatForm
            version={vatVersion}
            valueName="Le taux de TVA"
            valueUnit="Taux (en %)"
            constraints={constraints}
            onCancel={onCancel}
            onSubmit={onSubmit}
        />
    );
}
