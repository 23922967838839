// === Import: NPM
import React, { SyntheticEvent } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Autocomplete, TextField } from "@mui/material";

// === Import: LOCAL

interface GenericAutocompleteProps<T> {
    label: string;
    options: Array<T>;
    optionValue?: string;
    labelParameter?: string;
    value?: T;
    onChange: (event) => void;
    isOptionEqualToValue?: (option: T, value: T) => boolean;
    getOptionLabel?: (option: T) => string;
    onInputChange?: (event: React.SyntheticEvent, value: string, reason: string) => void;
    inputValue?: string;
    renderOption?: (props: object, option: T, state: object) => React.ReactNode;
    disabled?: boolean;
    size?: "small" | "medium";
    variant?: "outlined" | "filled" | "standard";
    required?: boolean;
    error?: boolean;
    helperText?: string;
    freeSolo?: boolean;
    defaultValue?: T;
    noOptionsText?: string;
    multiple?: boolean;
    shrink?: boolean;
}

export default function GenericAutocomplete<T extends {}>({
    label,
    options,
    optionValue = "id",
    labelParameter = "label",
    isOptionEqualToValue = (option, val) => option[optionValue] === val[optionValue],
    getOptionLabel = (option) => option[labelParameter],
    value,
    onChange,
    onInputChange,
    inputValue,
    renderOption,
    disabled,
    size,
    variant = "outlined",
    required,
    error,
    helperText,
    freeSolo,
    defaultValue,
    multiple,
    noOptionsText = "Aucun résultat",
    shrink,
}: GenericAutocompleteProps<T>) {
    const handleChange = (_event: SyntheticEvent, newValue: T) => {
        const targetEvent = {
            target: {
                value: newValue,
            },
        };
        onChange(targetEvent);
    };

    return (
        <Autocomplete
            popupIcon={<KeyboardArrowDown />}
            size={size}
            options={options}
            value={value}
            getOptionLabel={getOptionLabel}
            defaultValue={defaultValue}
            onChange={handleChange}
            onInputChange={onInputChange}
            renderOption={renderOption}
            inputValue={inputValue}
            disabled={disabled}
            fullWidth
            isOptionEqualToValue={isOptionEqualToValue}
            noOptionsText={noOptionsText}
            multiple={multiple}
            renderInput={(selected) => (
                <TextField
                    variant={variant}
                    {...selected}
                    label={label}
                    required={required}
                    error={error}
                    helperText={helperText}
                    InputLabelProps={{ shrink: shrink }}
                />
            )}
            freeSolo={freeSolo}
        />
    );
}
