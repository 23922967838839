// === NPM
import React from "react";
// === LOCAL
import { IUserShort } from "@/interfaces/user";
import { UserSituation } from "@/resources/PermissionConstant";
import { useAuth } from "@/routers/useAuth";
import UserService from "@/services/UserService";
import { ICreateMaUser, MaUnit } from "../../interface";
import UserSpecificPage from "../UserSpecificPage";

export default function UserMa() {
    const auth = useAuth();

    const getUsers = async (payload) => {
        return UserService.getMaUsers(payload);
    };

    const handleDeleteUser = async (userId: string) => {
        return UserService.deleteMaUser(userId, auth.userInfo.type);
    };

    const handleCreateUser = async (user: ICreateMaUser) => {
        return UserService.createUserMA({
            ...user,
            type: MaUnit[auth.userInfo.type.toLocaleUpperCase()],
            entityInseeCode: auth.userInfo.properties?.inseeCode,
        });
    };

    const handleUpdateUser = async (user: IUserShort, userId: string) => {
        return UserService.updateUserMa(user, userId);
    };

    return (
        <UserSpecificPage
            getData={getUsers}
            createUser={handleCreateUser}
            updateUser={handleUpdateUser}
            deleteUser={handleDeleteUser}
            permissions={[UserSituation.ADMIN_DDPP, UserSituation.ADMIN_DGAL, UserSituation.ADMIN_DRAAF]}
            title={`${auth.userInfo.properties?.name} ${
                auth.userInfo.properties?.inseeCode ? `- ${auth.userInfo.properties?.inseeCode}` : ""
            }`}
        />
    );
}
