// === NPM
import React, { Dispatch, SetStateAction } from "react";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, MobileStepper, Stack, Step, StepLabel, Stepper, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// === LOCAL
import GenericButton from "@/components/generics/buttons/GenericButton";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { colors } from "@/resources/CssConstant";

interface FormStepperProps {
    activeStep: number;
    steps: string[];
    handleNextStep: () => void;
    handlePreviousStep: () => void;
    handleSaveAndQuit: () => void;
    setOpenConfirmCancelDialog: Dispatch<SetStateAction<boolean>>;
    children: JSX.Element;
}

export default function FormStepper({
    activeStep,
    steps,
    handleNextStep,
    handlePreviousStep,
    setOpenConfirmCancelDialog,
    handleSaveAndQuit,
    children,
}: Readonly<FormStepperProps>) {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up("md"));
    const dispayValidateButton = activeStep === steps.length - 1;
    const isFirstStep = activeStep === 0;
    const direction = theme.direction === "rtl";

    const renderCancelAndSaveButton = () => (
        <>
            <SecondaryButton onClick={() => setOpenConfirmCancelDialog(true)} variant="outlined">
                Annuler
            </SecondaryButton>
            <Button color="primary" variant="contained" onClick={handleSaveAndQuit}>
                Enregistrer le brouillon et quitter
            </Button>
        </>
    );

    const renderMobile = () => (
        <Stack spacing={2}>
            <Box display="flex" justifyContent="space-between">
                {renderCancelAndSaveButton()}
            </Box>
            <MobileStepper
                sx={{ width: "100%", mt: 0.5, borderRadius: 10 }}
                variant="dots"
                steps={steps.length}
                position="static"
                activeStep={activeStep}
                nextButton={
                    dispayValidateButton ? (
                        <Button color="primary" size="small" onClick={handleNextStep}>
                            Valider
                        </Button>
                    ) : (
                        <Button color="primary" size="small" onClick={handleNextStep}>
                            Suivant
                            {direction ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        </Button>
                    )
                }
                backButton={
                    <Button color="primary" size="small" onClick={handlePreviousStep} disabled={isFirstStep}>
                        {direction ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                        Précédent
                    </Button>
                }
            />
        </Stack>
    );

    return (
        <Stack height="100%" width="100%">
            <Stepper activeStep={activeStep}>
                {steps.map((label) => {
                    return (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {!md && (
                <Box>
                    <Typography variant="h6">{`${activeStep + 1} - ${steps[activeStep]}`}</Typography>
                </Box>
            )}
            {children}
            {!md && renderMobile()}
            <Box mt={2} justifyContent="space-between" width="100%" sx={{ display: { xs: "none", md: "flex" } }}>
                <LoadingButton
                    sx={{ background: colors.white, visibility: isFirstStep ? "hidden" : "visible" }}
                    onClick={handlePreviousStep}
                    variant="outlined"
                >
                    Précédent
                </LoadingButton>
                <Box sx={{ gap: 2 }} display="flex">
                    {renderCancelAndSaveButton()}
                </Box>
                <Box>
                    {dispayValidateButton ? (
                        <GenericButton label="Valider" onClick={handleNextStep} />
                    ) : (
                        <GenericButton label="Suivant" onClick={handleNextStep} />
                    )}
                </Box>
            </Box>
        </Stack>
    );
}
