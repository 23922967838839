// === Import: NPM
import React, { useEffect, useRef, useState } from "react";
import { Tooltip, Typography } from "@mui/material";

interface OverflowTooltipProps {
    value: string;
    sx?: object;
}

export default function OverflowTooltip({ value, sx }: Readonly<OverflowTooltipProps>) {
    // Create Ref
    const textElementRef = useRef<any>();

    const compareSize = () => {
        const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
        setHover(compare);
    };

    // compare once and add resize listener on "componentDidMount"
    useEffect(() => {
        compareSize();
        window.addEventListener("resize", compareSize);
    }, []);

    // remove resize listener again on "componentWillUnmount"
    useEffect(
        () => () => {
            window.removeEventListener("resize", compareSize);
        },
        []
    );

    // Define state and function to update the value
    const [hoverStatus, setHover] = useState(false);

    return (
        <Tooltip title={value ?? ""} disableHoverListener={!hoverStatus}>
            <Typography
                ref={textElementRef}
                className={sx ? "" : "MuiDataGrid-cellContent"}
                sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", ...sx }}
                variant="body2"
            >
                {value}
            </Typography>
        </Tooltip>
    );
}
