// === Import: NPM
import React from "react";
// === Import: LOCAL
import { ReactComponent as Show } from "@/assets/icons/shared/show.svg";
import { IActionButton } from "@/interfaces/global";
import IconActionButton from "./IconActionButton";

export default function ViewAction({ title, onClick }: IActionButton) {
    return <IconActionButton title={title} onClick={onClick} icon={<Show />} />;
}
