// === NPM
import React from "react";
import { useOutletContext } from "react-router-dom";
import { Box, Grid, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Billing } from "@/assets/icons/drawer/billing.svg";
import { ReactComponent as Calendar } from "@/assets/icons/shared/calendar.svg";
import { ReactComponent as Location } from "@/assets/icons/shared/location.svg";
import { ReactComponent as User } from "@/assets/icons/shared/user.svg";
import GenericAccordion from "@/components/generics/layout/GenericAccordion";
import GenericTitleValueText from "@/components/generics/text/GenericTitleValueText";
import { IVaccinationSite, VaccinationSiteUserType } from "@/interfaces/vaccination";
import { getEnumKeyByValue, parseDuration, toLocaleDateFormat } from "@/resources/utils";
import { IVaccinationSiteForm, VaccinationSiteOutletContext } from "../VaccinationSite/interface";

interface InformationAccordionProps {
    vaccinationSite: IVaccinationSite | IVaccinationSiteForm;
    dpeName: string;
    dpePostalCode: string;
    dpeCity: string;
    veterinaryFirstname: string;
    veterinaryLastname: string;
    billingStatus?: string;
}

export default function InformationAccordion({
    vaccinationSite,
    dpeName,
    dpePostalCode,
    dpeCity,
    veterinaryFirstname,
    veterinaryLastname,
    billingStatus,
}: Readonly<InformationAccordionProps>) {
    const { horsePower, billingStatuses } = useOutletContext<VaccinationSiteOutletContext>();

    return (
        <GenericAccordion title="Informations du chantier" defaultExpanded>
            <Grid container rowSpacing={3} columnSpacing={2}>
                {billingStatus && (
                    <Grid item xs={12} display="flex" gap={1}>
                        <GenericTitleValueText
                            icon={<Billing />}
                            title="Statut facturation"
                            value={billingStatuses.find((status) => status.key === billingStatus)?.label}
                        />
                    </Grid>
                )}
                <Grid item xs={12} md={4} display="flex" gap={1}>
                    <Box>
                        <Calendar />
                    </Box>
                    <Stack spacing={1}>
                        <Box mt={-0.25}>
                            <Typography variant="bold" fontSize={18}>
                                Informations d'intervention
                            </Typography>
                        </Box>
                        <GenericTitleValueText
                            title="Date de l'intervention"
                            value={toLocaleDateFormat(vaccinationSite.date)}
                        />
                        <GenericTitleValueText
                            title="Vaccinateur"
                            value={VaccinationSiteUserType[vaccinationSite.userType]}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={3.5} display="flex" gap={1}>
                    <Box>
                        <User />
                    </Box>
                    <Stack spacing={1}>
                        <Box mt={-0.25}>
                            <Typography variant="bold" fontSize={18}>
                                Responsable de l'intervention
                            </Typography>
                        </Box>
                        <GenericTitleValueText
                            title="Vétérinaire"
                            value={`${vaccinationSite.veterinaryId} - ${veterinaryLastname} ${veterinaryFirstname}`}
                        />
                        <GenericTitleValueText
                            title="DPE"
                            value={`${vaccinationSite.dpeId} - ${dpeName} -\n ${dpePostalCode} ${dpeCity} `}
                        />
                    </Stack>
                </Grid>
                {vaccinationSite.userType ===
                    getEnumKeyByValue(VaccinationSiteUserType, VaccinationSiteUserType.VETERINARY) && (
                    <Grid item xs={12} md={4.5} display="flex" gap={1}>
                        <Box>
                            <Location />
                        </Box>
                        <Stack spacing={1}>
                            <Box mt={-0.25}>
                                <Typography variant="bold" fontSize={18}>
                                    Déplacement
                                </Typography>
                            </Box>
                            <GenericTitleValueText
                                title="Kilomètres parcourus"
                                value={vaccinationSite.distance ?? "Aucune information"}
                            />
                            <GenericTitleValueText
                                title="Immatriculation"
                                value={vaccinationSite.plateNumber ?? "Aucune information"}
                            />
                            <GenericTitleValueText
                                title="Chevaux fiscaux"
                                value={
                                    horsePower.find((h) => h.key === vaccinationSite.horsePower)?.label ??
                                    "Aucune information"
                                }
                            />
                            <GenericTitleValueText
                                title="Temps passé"
                                value={
                                    vaccinationSite.interventionDuration
                                        ? `${parseDuration(vaccinationSite.interventionDuration)} heures, soit ${vaccinationSite.interventionDuration} minutes`
                                        : "Aucune information"
                                }
                            />
                        </Stack>
                    </Grid>
                )}
            </Grid>
        </GenericAccordion>
    );
}
