// === NPM
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, Typography } from "@mui/material";
// === LOCAL
import PageContent from "@/components/generics/layout/PageContent";
import { HttpStatus } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import { UserType } from "@/interfaces/user";
import { IVaccine } from "@/interfaces/vaccination";
import { UserSituation } from "@/resources/PermissionConstant";
import PermissionsTabs from "@/resources/PermissionsTabs";
import { routerLinks } from "@/routers/RouterConstant";
import { useAuth } from "@/routers/useAuth";
import VaccinationService from "@/services/VaccinationService";

export default function Vaccination() {
    const auth = useAuth();
    const [vaccines, setVaccines] = useState<IVaccine[]>([]);
    const [scrappingReasons, setScrappingReasons] = useState<IReferential[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        Promise.all([getVaccines(), getScrappingReasons()]).then(() => {
            setLoading(false);
        });
    };

    const getVaccines = async () => {
        const res = await VaccinationService.getVaccines();
        if (res.status === HttpStatus.OK) {
            setVaccines(res.data);
        }
    };

    const getScrappingReasons = async () => {
        const res = await VaccinationService.getScrappingReasons();
        if (res.status === HttpStatus.OK) {
            setScrappingReasons(res.data);
        }
    };

    return (
        <>
            <PermissionsTabs
                tabs={[
                    {
                        url: routerLinks.vaccinationCampaign.vaccine.view(),
                        label: "Mes commandes de vaccins",
                    },
                    {
                        url: routerLinks.vaccinationCampaign.vaccine.order(),
                        label: "Saisir une commande",
                        permissions: [UserSituation.REGISTERED_IN_PRACTICE],
                    },
                    {
                        url: routerLinks.vaccinationCampaign.vaccine.scrappingView(),
                        label: "Mes mises au rebut",
                    },
                    {
                        url: routerLinks.vaccinationCampaign.vaccine.scrappingDeclare(),
                        label: "Saisir une mise au rebut",
                        permissions: [UserSituation.REGISTERED_IN_PRACTICE],
                    },
                ]}
            />
            <PageContent>
                {auth.userInfo.type !== UserType.VETERINARY ||
                (auth.userInfo.properties.dpes.some((dpe) => dpe.sanitaryDpe) &&
                    auth.userInfo.preferences.iahp === true) ||
                auth.userInfo.preferences.sheep === true ||
                auth.userInfo.preferences.cattle === true ? (
                    <Outlet context={{ vaccines, scrappingReasons, loading }} />
                ) : (
                    <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                        <Typography variant="h4">
                            Vous ne pouvez pas effectuer de saisie car vous ne possédez pas de DPE ayant un lien
                            vétérinaire sanitaire.
                        </Typography>
                    </Box>
                )}
            </PageContent>
        </>
    );
}
