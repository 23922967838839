// === NPM
import React from "react";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
// === LOCAL
import { FilterConfiguration } from "../GenericFilters";

interface SingleSelectFilterProps {
    config: FilterConfiguration;
    value: string[];
    handleSingleSelectFilter: (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    name: string;
    valuesAlphabeticalSort?: boolean;
}

export default function SingleSelectFilter({
    config,
    value,
    handleSingleSelectFilter,
    name,
    valuesAlphabeticalSort = true,
}: Readonly<SingleSelectFilterProps>) {
    return (
        <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
            <RadioGroup onChange={handleSingleSelectFilter(name)}>
                {(valuesAlphabeticalSort
                    ? config.values.sort((v1, v2) => v1.label.localeCompare(v2.label))
                    : config.values
                ).map((v) => {
                    return (
                        <FormControlLabel
                            key={v.key}
                            value={v.key}
                            control={<Radio value={v.key} checked={value.includes(v.key)} name={v.key} />}
                            label={v.label}
                        />
                    );
                })}
            </RadioGroup>
        </FormControl>
    );
}
