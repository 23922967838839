// === Import: NPM
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
// === LOCAL
import { useSocket } from "@/context/useSocket";
import { HttpStatus } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import { IUserInfo, UserType } from "@/interfaces/user";
import { CALYPSO_HEADERS, CALYPSO_TOKEN } from "@/resources/AppConstant";
import AuthenticationService from "@/services/AuthenticationService";
import HttpService from "@/services/HttpService";
import LocalStorageService from "@/services/LocalStorageService";
import UserService from "@/services/UserService";

export interface UseAuth {
    logged: boolean;
    userInfo: IUserInfo;
    login: (code: string) => Promise<void>;
    loginPsc: (code: string) => Promise<void>;
    introspection: () => Promise<void>;
    refreshUserInfo: () => Promise<void>;
    updateToken: (token: string) => void;
    logout: () => void;
    userTutorials: IReferential[];
    removeTutorial: (tutorial_key: string) => void;
    updateHomePreferences: (preferences: string[]) => void;
}

export const authContext = React.createContext<any>({});

export const useAuth = (): UseAuth => {
    return useContext(authContext);
};

export const AuthProvider = ({ children }: React.PropsWithChildren) => {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

const useProvideAuth = () => {
    const webSocket = useSocket();
    const [userInfo, setUserInfo] = useState<IUserInfo>(null);
    const [userTutorials, setUserTutorials] = useState<IReferential[]>([]);
    const [logged, setLogged] = useState<boolean>(null);

    useEffect(() => {
        if (userInfo) {
            getUserTutorials();
            if (userInfo.type === UserType.PHARMACIST) {
                const interval = setInterval(
                    () => {
                        refreshToken();
                    },
                    1000 * 60 * 28
                ); // every 28 minutes
                return () => clearInterval(interval);
            }
        }
    }, [userInfo]);

    const login = async (code: string) => {
        const res = await AuthenticationService.login({ code });
        if (res.status === HttpStatus.OK) {
            const token = res.headers[CALYPSO_HEADERS.TOKEN];
            setUserInfo(res.data);
            updateToken(token);
            webSocket.open();
        } else if (axios.isAxiosError(res)) {
            toast.error(res.response.data.message);
        }
    };

    const loginPsc = async (code: string) => {
        const res = await AuthenticationService.loginPsc({ code });
        if (res.status === HttpStatus.OK) {
            const token = res.headers[CALYPSO_HEADERS.TOKEN];
            setUserInfo(res.data);
            updateToken(token);
            webSocket.open();
        } else if (axios.isAxiosError(res)) {
            toast.error(res.response.data.message);
        }
    };

    const refreshToken = async () => {
        await AuthenticationService.refresh();
    };

    const updateToken = (token: string) => {
        LocalStorageService.setLocaleStorageItem(CALYPSO_TOKEN, token);
        HttpService.setToken(token);
        setLogged(true);
    };

    const getUserTutorials = async () => {
        const res = await UserService.getUserTutorials(userInfo.id);
        if (res.status === HttpStatus.OK) {
            setUserTutorials(res.data);
        }
    };

    const removeTutorial = (tutorial_key: string) => {
        setUserTutorials((prev) => prev.filter((tutorial) => tutorial.key !== tutorial_key));
    };

    const introspection = async () => {
        const currentToken = LocalStorageService.getLocaleStorageItem(CALYPSO_TOKEN);
        HttpService.setToken(currentToken);
        const res = await AuthenticationService.introspection();
        if (res.status === HttpStatus.OK) {
            setUserInfo(res.data);
            const token = res.headers[CALYPSO_HEADERS.TOKEN];
            updateToken(token);
            webSocket.open();
        } else {
            logout();
        }
    };

    const refreshUserInfo = async () => {
        const res = await AuthenticationService.refreshUserInfo();
        if (res.status === HttpStatus.OK) {
            setUserInfo(res.data);
        }
    };

    const updateHomePreferences = (preferences: string[]) => {
        setUserInfo((prev) => ({ ...prev, homePreferences: preferences }));
    };

    const logout = () => {
        setLogged(false);
        LocalStorageService.removeLocaleStorageItem(CALYPSO_TOKEN);
        HttpService.resetAxiosInstance();
        setUserInfo(null);
        webSocket.close();
    };

    return {
        userInfo,
        logged,
        userTutorials,
        login,
        updateToken,
        introspection,
        refreshUserInfo,
        logout,
        removeTutorial,
        loginPsc,
        updateHomePreferences,
    };
};
