// === NPM
import React, { useState } from "react";
import { ConnectableElement, useDrag, useDrop } from "react-dnd";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { DragIndicator } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import DeleteAction from "@/components/generics/actions/DeleteAction";
import EditAction from "@/components/generics/actions/EditAction";
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import { useProvideGlobal } from "@/context/useGlobalContext";
import { ICategory } from "@/interfaces/faq";
import { HttpStatus } from "@/interfaces/global";
// === LOCAL
import { colors } from "@/resources/CssConstant";
import FaqService from "@/services/FaqService";
import { FaqDropableType, FaqOutletContext } from "../../../interface";
import CategoryDialog from "./CategoryDialog";

interface CategoryCardProps {
    category: ICategory;
    findCategory: (uuid: string) => { index: number };
    moveCategory: (uuid: string, to: number) => void;
}

export function CategoryCard({ category, findCategory, moveCategory }: Readonly<CategoryCardProps>) {
    const { loadingTable } = useProvideGlobal();
    const { getQuestions, getCategories } = useOutletContext<FaqOutletContext>();
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: FaqDropableType.CATEGORY,
            item: category,
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const didDrop = monitor.didDrop();
                if (!didDrop) {
                    moveCategory(item.uuid, category.position - 1);
                    return;
                }
                handleCategoryOrder();
            },
        }),
        [category, moveCategory]
    );

    const [, drop] = useDrop(
        () => ({
            accept: FaqDropableType.CATEGORY,
            hover(draggedCategory: ICategory) {
                if (draggedCategory.uuid !== category.uuid) {
                    const { index: overIndex } = findCategory(category.uuid);
                    moveCategory(draggedCategory.uuid, overIndex);
                }
            },
        }),
        [findCategory, moveCategory]
    );

    const [openUpdateDialog, setOpenUpdateDialog] = useState<boolean>(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

    const handleCategoryOrder = async () => {
        const { index: newPosition } = findCategory(category.uuid);
        const res = await FaqService.patchCategoryPosition(category.uuid, newPosition);
        if (res.status === HttpStatus.NO_CONTENT) {
            toast.success("Catégories réordonnées avec succès");
            getQuestions();
        } else {
            moveCategory(category.uuid, category.position - 1);
        }
    };

    const handleUpdateCategory = async (updatedCategory: ICategory) => {
        const res = await FaqService.putCategory({ ...updatedCategory, uuid: category.uuid });
        if (res.status === HttpStatus.OK) {
            await getCategories();
            toast.success("Catégorie modifiée avec succès");
        } else {
            moveCategory(updatedCategory.uuid, updatedCategory.position - 1);
        }
        setOpenUpdateDialog(false);
    };

    const handleDeleteCategory = async (confirm: boolean) => {
        if (!confirm) {
            setOpenDeleteDialog(false);
            return;
        }
        const res = await FaqService.deleteCategory(category.uuid);
        if (res.status === HttpStatus.NO_CONTENT) {
            await getCategories();
            setOpenDeleteDialog(false);
            toast.success("Catégorie supprimée avec succès");
        }
    };

    const opacity = isDragging ? 0 : 1;

    return (
        <>
            <Box
                ref={(node) => drag(drop(node as ConnectableElement))}
                key={category.uuid}
                display="flex"
                sx={{
                    color: colors.primaryColor,
                    backgroundColor: colors.white,
                    cursor: "move",
                    borderRadius: 5,
                    height: "100%",
                    opacity,
                    p: 1,
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
                    <DragIndicator />
                    <Typography variant="h5">{category.title}</Typography>
                </Box>
                <Box>
                    <EditAction
                        title="Modifier la catégorie"
                        onClick={() => {
                            setOpenUpdateDialog(true);
                        }}
                    />
                    {category?.questionCount === 0 && (
                        <DeleteAction
                            title="Supprimer la catégorie"
                            onClick={() => {
                                setOpenDeleteDialog(true);
                            }}
                        />
                    )}
                </Box>
            </Box>

            {openUpdateDialog && (
                <CategoryDialog
                    onClose={() => setOpenUpdateDialog(false)}
                    onValid={handleUpdateCategory}
                    title={"Modification d'une catégorie"}
                    currentCategory={category}
                />
            )}
            {openDeleteDialog && (
                <GenericConfirmDialog
                    title="Suppression d'une catégorie"
                    message={`Êtes-vous sûr de vouloir supprimer la catégorie ${category?.title} ?`}
                    onClose={handleDeleteCategory}
                    loading={loadingTable}
                />
            )}
        </>
    );
}
