// === NPM
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, FormControl, Grid, TextField } from "@mui/material";
import { z, ZodObject } from "zod";
// === LOCAL
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import { IBaseUser, IType } from "@/interfaces/user";
import { FORM_TEXT, stringRequired } from "@/resources/FormUtils";
import { ICreateUserRenderConfig } from "../interface";

interface UserInformationsProps {
    userTypes: IType[];
    onSubmit: any;
    formRef: any;
    initForm: ICreateUserType;
    additionalValidations?: ZodObject<any>;
    additionalRender?: ICreateUserRenderConfig[];
    disabled?: boolean;
}
interface ICreateUserType extends IBaseUser {
    [key: string]: any;
}

const initialValues = {
    firstname: null,
    lastname: null,
    email: null,
    type: "",
    situation: "",
};

export default function UserInformations({
    userTypes,
    onSubmit,
    formRef,
    initForm,
    additionalValidations,
    additionalRender,
    disabled,
}: Readonly<UserInformationsProps>) {
    const initFormSchema = z.object({
        firstname: stringRequired(),
        lastname: stringRequired(),
        email: stringRequired().email({ message: FORM_TEXT.email }),
        situation: stringRequired(),
        type: z.string().optional().nullable(),
    });
    const formSchema = additionalValidations ? initFormSchema.merge(additionalValidations) : initFormSchema;

    type ValidationSchema = z.infer<typeof formSchema>;

    const {
        formState: { errors },
        handleSubmit,
        control,
        watch,
        register,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: { ...initialValues, ...initForm },
    });

    const watchType = watch("type");

    const renderAdditionalFields = () => {
        return additionalRender.map((config, i) => renderInput(config, i));
    };

    const renderInput = (config: ICreateUserRenderConfig, key: number) => {
        switch (config.type) {
            case "select":
                return (
                    <Grid item xs={12} key={key}>
                        <FormControl fullWidth error={!!errors[config.name]} required>
                            <Controller
                                name={config.name}
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <GenericSelect
                                        value={value}
                                        label={config.label}
                                        error={!!error}
                                        helperText={error?.message as string}
                                        onChange={onChange}
                                        options={config.options}
                                        required={config.required}
                                        disabled={disabled}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                );
            case "input":
                return (
                    <Grid item xs={12}>
                        <TextField
                            {...register(config.name)}
                            label={config.label}
                            name={config.name}
                            error={!!errors[config.name]}
                            helperText={errors[config.name]?.message as string}
                            required
                            fullWidth
                            disabled={disabled}
                        />
                    </Grid>
                );
            default:
                break;
        }
    };

    return (
        <Box p={2} mt={2} width="100%">
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            {...register("firstname")}
                            label="Prénom"
                            error={!!errors?.firstname}
                            helperText={errors?.firstname?.message as string}
                            required
                            fullWidth
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            {...register("lastname")}
                            label="Nom"
                            error={!!errors?.lastname?.message}
                            helperText={errors?.lastname?.message as string}
                            required
                            fullWidth
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            {...register("email")}
                            label="Email"
                            error={!!errors?.email}
                            helperText={errors?.email?.message as string}
                            required
                            type="email"
                            fullWidth
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth error={!!errors.situation} required>
                            <Controller
                                name="situation"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <GenericSelect
                                        value={value}
                                        label="Situation"
                                        error={!!error}
                                        helperText={error?.message as string}
                                        optionsValue={"key"}
                                        onChange={onChange}
                                        options={
                                            (watchType && userTypes.find((t) => t.key === watchType).situations) || []
                                        }
                                        required
                                        disabled={disabled}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    {additionalRender && renderAdditionalFields()}
                </Grid>
                <button style={{ display: "none" }} type="submit" ref={formRef} />
            </form>
        </Box>
    );
}
