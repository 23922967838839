export interface IFarm {
    id: string;
    name: string;
    workshops: IWorkshopShort[];
    closed: boolean;
}

export interface IWorkshopShort {
    id: string;
    dpeId: string;
    closed: boolean;
}

export interface IFarmMonitoring extends IFarm {
    workshops: IWorkshopMonitoring[];
}

export interface IWorkshopMonitoring extends IWorkshopShort {
    alreadyInvolved: boolean;
    city: string;
    departmentInseeCode: string;
}

export interface IWorkshop extends IWorkshopShort {
    id: string;
    closed: boolean;
    speciesUuid: string;
    lastUpdate: string;
    closingDate: string;
    farmName: string;
    farmId: string;
    farmCity: string;
    farmDepartmentCode: string;
    farmDepartmentName: string;
    workshopCity: string;
    workshopDepartmentCode: string;
    workshopDepartmentName: string;
}

export interface IWorkshopRelation {
    uuid: string;
    dpeId: string;
    dpeName: string;
    farmId: string;
    farmName: string;
    speciesUuid: string;
    workshopId: string;
    workshopCity: string;
    workshopDepartmentCode: string;
    workshopDepartmentName: string;
    workshopClosingDate: string;
    openingDate: string;
    closingDate: string;
    cerfaFileUuid: string;
    closingReason: string;
    closingComment: string;
}

export enum FarmHeader {
    MONITORING = "monitoring",
    SANITARY = "sanitary",
}
