// === NPM
import React, { createContext, useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
// === LOCAL
import { IReferential } from "@/interfaces/referential";
import { HttpStatus } from "../../../../interfaces/global";
import BillingService from "../../../../services/BillingService";
import { BillingReferentialType, IAmv, IChargeable, IVat } from "../interface";
import BillingCarousel from "./containers/Carousels/BillingCarousel";
import BillingCarouselSkeleton from "./containers/Carousels/BillingCarouselSkeleton";

export const VatReferentialsContext = createContext(null);
export const AmvReferentialsContext = createContext(null);

export default function Management() {
    const [loading, setLoading] = useState<boolean>(true);
    const [vats, setVats] = useState<IVat[]>([]);
    const [amvs, setAmvs] = useState<IAmv[]>([]);
    const [chargeables, setChargeables] = useState<IChargeable[]>([]);
    const [vatReferentials, setVatReferentials] = useState<IReferential[]>([]);
    const [amvReferentials, setAmvReferentials] = useState<IReferential[]>([]);
    const [chargeablesReferentials, setChargeablesReferentials] = useState<IReferential[]>([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        Promise.all([
            getVats(),
            getAmvs(),
            getChargeables(),
            getVatReferentials(),
            getAmvReferentials(),
            getChargeablesReferentials(),
        ]).then(() => {
            setLoading(false);
        });
    };

    const getVats = async (payload?: { name?: string; date?: string }) => {
        const res = await BillingService.getVats(payload);
        if (res.status === HttpStatus.OK) {
            setVats(res.data);
        }
    };

    const getAmvs = async (payload?: { name?: string; date?: string }) => {
        const res = await BillingService.getAmvs(payload);
        if (res.status === HttpStatus.OK) {
            setAmvs(res.data);
        }
    };

    const getChargeables = async (payload?: { name?: string; date?: string }) => {
        const res = await BillingService.getChargeables(payload);
        if (res.status === HttpStatus.OK) {
            setChargeables(res.data);
        }
    };

    const getVatReferentials = async () => {
        const res = await BillingService.getVatReferentials();
        if (res.status === HttpStatus.OK) {
            setVatReferentials(res.data);
        }
    };

    const getAmvReferentials = async () => {
        const res = await BillingService.getAmvReferentials();
        if (res.status === HttpStatus.OK) {
            setAmvReferentials(res.data);
        }
    };

    const getChargeablesReferentials = async () => {
        const res = await BillingService.getChargeablesReferentials();
        if (res.status === HttpStatus.OK) {
            setChargeablesReferentials(res.data);
        }
    };

    return (
        <Stack width="100%" spacing={5}>
            <Typography variant="h4">Référentiels de facturation</Typography>
            {loading ? (
                <Stack width="100%" height="100%" spacing={5}>
                    <BillingCarouselSkeleton />
                    <BillingCarouselSkeleton />
                    <BillingCarouselSkeleton />
                </Stack>
            ) : (
                <Stack width="100%" spacing={5}>
                    <BillingCarousel
                        elements={vats}
                        type={BillingReferentialType.VAT}
                        referentials={vatReferentials}
                        getBillingReferentials={getVats}
                    />
                    <BillingCarousel
                        elements={amvs}
                        type={BillingReferentialType.AMV}
                        referentials={amvReferentials}
                        getBillingReferentials={getAmvs}
                    />
                    <VatReferentialsContext.Provider value={vatReferentials}>
                        <AmvReferentialsContext.Provider value={amvReferentials}>
                            <BillingCarousel
                                chargeables={chargeables}
                                type={BillingReferentialType.CHARGEABLES}
                                referentials={chargeablesReferentials}
                                getBillingReferentials={getChargeables}
                            />
                        </AmvReferentialsContext.Provider>
                    </VatReferentialsContext.Provider>
                </Stack>
            )}
        </Stack>
    );
}
