// === NPM
import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as HealthReporting } from "@/assets/icons/healthreporting/health_reporting.svg";
import { ReactComponent as Workshop } from "@/assets/icons/shared/workshop.svg";
import GenericAccordion from "@/components/generics/layout/GenericAccordion";
import GenericTitleValueText from "@/components/generics/text/GenericTitleValueText";
import { toLocaleDateFormat } from "@/resources/utils";
import { HealthReportingStatus, IReportInformationsFull, IWorkshopInformations } from "../../interface";

interface InformationsAccordionProps {
    reportInformations: IReportInformationsFull & IWorkshopInformations;
    status?: string;
}

export default function InformationsAccordion({ reportInformations, status }: InformationsAccordionProps) {
    return (
        <GenericAccordion title="I. Informations relatives au signalement" defaultExpanded>
            <Grid container rowSpacing={4} columnSpacing={2}>
                <Grid item xs={12} display="flex" gap={1}>
                    <Box>
                        <HealthReporting />
                    </Box>
                    <Stack spacing={1}>
                        <Box mt={-0.25}>
                            <Typography variant="bold" fontSize={18}>
                                Informations du signalement
                            </Typography>
                        </Box>
                        <GenericTitleValueText
                            title="Date d'observation"
                            value={toLocaleDateFormat(reportInformations.observationDate)}
                        />
                        <GenericTitleValueText
                            title="Vétérinaire concerné"
                            value={`${reportInformations.veterinaryId} - ${reportInformations.veterinaryLastname} ${reportInformations.veterinaryFirstname}`}
                        />
                        <GenericTitleValueText
                            title="DPE concerné"
                            value={`${reportInformations.dpeId} - ${reportInformations.dpeName}`}
                        />
                        <GenericTitleValueText
                            title="Signalement transmis au procureur de la République par le vétérinaire"
                            value={reportInformations.transmitted ? "Oui" : "Non"}
                        />
                        {status && <GenericTitleValueText title="Statut" value={HealthReportingStatus[status]} />}
                    </Stack>
                </Grid>
                <Grid item xs={12} display="flex" gap={1}>
                    <Box>
                        <Workshop />
                    </Box>
                    <Stack spacing={1}>
                        <Box mt={-0.25}>
                            <Typography variant="bold" fontSize={18}>
                                Informations de détention
                            </Typography>
                        </Box>
                        {reportInformations.businessName && (
                            <GenericTitleValueText
                                title="Raison Sociale"
                                value={`${reportInformations.businessName} - ${reportInformations.typeIdentifiant} ${reportInformations.holderId}`}
                            />
                        )}
                        <GenericTitleValueText
                            title="Détenteur"
                            value={`${reportInformations.holderLastname} ${reportInformations.holderFirstname}`}
                        />
                        <GenericTitleValueText
                            title="Adresse"
                            value={`${reportInformations.address}, ${reportInformations.postalCode} ${reportInformations.city}`}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </GenericAccordion>
    );
}
