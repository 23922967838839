// === NPM
import React, { Dispatch, SetStateAction } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Button, Popover } from "@mui/material";
// === LOCAL
import { colors } from "@/resources/CssConstant";

interface GenericButtonFilterProps {
    active: boolean;
    buttonLabel: string;
    open: boolean;
    anchor: HTMLButtonElement;
    setAnchor: Dispatch<SetStateAction<HTMLButtonElement>>;
    children: JSX.Element;
}

export default function GenericButtonFilter({
    setAnchor,
    active,
    buttonLabel,
    open,
    anchor,
    children,
}: Readonly<GenericButtonFilterProps>) {
    return (
        <>
            <Button
                variant="outlined"
                onClick={(event) => setAnchor(event.currentTarget)}
                sx={{
                    borderRadius: 30,
                    borderColor: colors.secondaryColor,
                    backgroundColor: active ? colors.background : colors.white,
                    mb: 1,
                    mr: 1,
                }}
                endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            >
                {buttonLabel}
            </Button>
            <Popover
                open={open}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                PaperProps={{
                    sx: { width: "fit-content", maxHeight: 500 },
                }}
            >
                {children}
            </Popover>
        </>
    );
}
