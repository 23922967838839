// === NPM
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Card, CardActions, CardContent, CardHeader, Stack } from "@mui/material";
// === LOCAL
import { ReactComponent as Publish } from "@/assets/icons/actions/publish.svg";
import { ReactComponent as Duration } from "@/assets/icons/shared/duration.svg";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { HttpStatus } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import { getEnumKeyByValue } from "@/resources/utils";
import NotificationService from "@/services/NotificationService";
import { IConfig, SendMode } from "../../../interface";
import EditConfigDialog from "./EditConfigDialog";

interface ConfigCardProps {
    config: IConfig;
    types: IReferential[];
    updateConfigs: (config: IConfig) => void;
}

export default function ConfigCard({ config, types, updateConfigs }: Readonly<ConfigCardProps>) {
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);

    const title = types.find((t) => t.key === config.key)?.label;

    const handleValid = async (data: { sendMode: keyof typeof SendMode; publicationDayDelay: number }) => {
        const res = await NotificationService.putConfig(config.key, data);
        if (res.status === HttpStatus.OK) {
            toast.success("La configuration a bien été modifiée");
            setOpenEditDialog(false);
            updateConfigs(res.data);
        }
    };
    return (
        <>
            <Card
                variant="outlined"
                sx={{
                    paddingY: 1,
                    paddingX: 2,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <CardHeader title={title} />
                <CardContent>
                    <Stack spacing={2}>
                        <GenericIconText
                            icon={<Publish />}
                            text={SendMode[config.sendMode]}
                            iconTooltip="Mode d'envoi"
                        />
                        {config.sendMode !== getEnumKeyByValue(SendMode, SendMode.MAIL) && (
                            <GenericIconText
                                icon={<Duration />}
                                text={`${config.publicationDayDelay} jour(s)`}
                                iconTooltip="Durée de publication"
                            />
                        )}
                    </Stack>
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                    <SecondaryButton variant="outlined" onClick={() => setOpenEditDialog(true)}>
                        Modifier la configuration
                    </SecondaryButton>
                </CardActions>
            </Card>
            {openEditDialog && (
                <EditConfigDialog
                    config={config}
                    onClose={() => setOpenEditDialog(false)}
                    title={`Modification des notifications ${title} `}
                    onValid={handleValid}
                />
            )}
        </>
    );
}
