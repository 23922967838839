// === NPM
import React from "react";
import { useOutletContext } from "react-router-dom";
import { Box, Card, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
// === LOCAL
import { ReactComponent as Check } from "@/assets/icons/farm/check.svg";
import { ReactComponent as Close } from "@/assets/icons/farm/close-square.svg";
import { ReactComponent as Comment } from "@/assets/icons/shared/comment.svg";
import { ReactComponent as Search } from "@/assets/icons/shared/search.svg";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import GenericTitleValueText from "@/components/generics/text/GenericTitleValueText";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { ComplianceType, IAudit, IWorkshopAudit } from "@/interfaces/vaccination";
import { getEnumKeyByValue, toLocaleDateFormat } from "@/resources/utils";
import { VaccinationSiteOutletContext } from "../../../interface";

interface ViewAuditDialogProps {
    onClose: () => void;
    audit: IAudit;
}

export default function ViewAuditDialog({ onClose, audit }: Readonly<ViewAuditDialogProps>) {
    const { horsePower } = useOutletContext<VaccinationSiteOutletContext>();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up("md"));

    const renderAuditInfo = () => (
        <Stack spacing={1} p={2}>
            <Typography variant="bold" fontSize={18}>
                Informations générales
            </Typography>
            <Stack direction={md ? "row" : "column"} justifyContent="space-between">
                <GenericTitleValueText title="Date de l'audit" value={toLocaleDateFormat(audit.date)} />
                <GenericTitleValueText title="Kilomètres parcourus" value={audit.distance} />
                <GenericTitleValueText title="Immatriculation" value={audit.plateNumber} />
                <GenericTitleValueText
                    title="Chevaux fiscaux"
                    value={horsePower.find((h) => h.key === audit.horsePower)?.label}
                />
            </Stack>
        </Stack>
    );

    const renderWorkshopAudit = (workshop: IWorkshopAudit) => (
        <Box sx={{ gridColumn: { xs: "span 2", md: "span 1" } }} key={workshop.id}>
            <Card sx={{ height: "100%", p: 2 }}>
                <StyledCardContent>
                    <Stack spacing={1}>
                        <Typography variant="bold">Atelier {workshop.id} </Typography>
                        <GenericIconText
                            text={workshop.auditPerformed ? "Audit réalisé" : "Audit non réalisé"}
                            icon={workshop.auditPerformed ? <Check /> : <Close />}
                            iconTooltip="Réalisation de l'audit"
                        />

                        {workshop.auditPerformed && (
                            <GenericIconText
                                text={ComplianceType[workshop.complianceType]}
                                icon={<Search />}
                                iconTooltip="Résultat de l'audit"
                            />
                        )}
                        {workshop.complianceType &&
                            workshop.complianceType !== getEnumKeyByValue(ComplianceType, ComplianceType.COMPLIANT) && (
                                <GenericIconText
                                    text={workshop.complianceComment}
                                    icon={<Comment />}
                                    iconTooltip="Motif de non-conformité"
                                />
                            )}
                    </Stack>
                </StyledCardContent>
            </Card>
        </Box>
    );

    const renderContent = () => (
        <Stack spacing={2} width="100%">
            <Card>
                <StyledCardContent>{renderAuditInfo()}</StyledCardContent>
            </Card>
            <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gridAutoRows="1fr" gap={2}>
                {audit.workshops.map((workshop) => renderWorkshopAudit(workshop))}
            </Box>
        </Stack>
    );

    const displayActionButton = () => (
        <SecondaryButton onClick={onClose} variant="outlined">
            Fermer
        </SecondaryButton>
    );

    return (
        <GenericDialog
            maxWidth="xl"
            title={`Récapitulatif d'audit du chantier n°${audit.vaccinationSiteId}`}
            onClose={onClose}
            renderContent={renderContent}
            renderActions={displayActionButton}
        />
    );
}
