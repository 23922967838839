// === NPM
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// === LOCAL
import { HttpStatus } from "@/interfaces/global";
import { ISpeciesDetails, ITypeIdentifiant, TypeIdentifiantUse } from "@/interfaces/referential";
import { IDpeShort, UserWithoutProperties } from "@/interfaces/user";
import { routerLinks } from "@/routers/RouterConstant";
import DrugService from "@/services/DrugService";
import ReferentialService from "@/services/ReferentialService";
import UserService from "@/services/UserService";
import { IManualDelivery } from "../../../interface";

export interface UseFormDelivery {
    form: IManualDelivery;
    setForm: Dispatch<SetStateAction<IManualDelivery>>;
    isFromDraft: boolean;
    setIsFromDraft: Dispatch<SetStateAction<boolean>>;
    getVeterinaries: (ordinalNumber: string) => Promise<void>;
    veterinaries: UserWithoutProperties[];
    isFilling: boolean;
    setIsFilling: Dispatch<SetStateAction<boolean>>;
    resetData: () => void;
    species: ISpeciesDetails[];
    subSpecies: ISpeciesDetails[];
    getSubSpecies: (speciesUuid: string) => Promise<void>;
    typeIdentifiants: ITypeIdentifiant[];
    dpes: IDpeShort[];
    getUserDpes: (userId: string) => Promise<void>;
    getPrescription: () => void;
    getDeliveries: () => void;
    activeStep: number;
    setActiveStep: Dispatch<SetStateAction<number>>;
    isUpdate: boolean;
    setIsUpdate: Dispatch<SetStateAction<boolean>>;
}

export const formDeliveryContext = React.createContext<any>({});

export const useFormDelivery = (): UseFormDelivery => {
    return useContext(formDeliveryContext);
};

export function FormDeliveryProvider({ children }: any) {
    const auth = useProvideFormDelivery();
    return <formDeliveryContext.Provider value={auth}>{children}</formDeliveryContext.Provider>;
}

function useProvideFormDelivery() {
    const navigate = useNavigate();
    const location = useLocation();
    const [form, setForm] = useState<IManualDelivery>(null);
    const [isFromDraft, setIsFromDraft] = useState<boolean>(false);
    const [isFilling, setIsFilling] = useState<boolean>(false);
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [activeStep, setActiveStep] = useState<number>(0);

    // ----- Referential data
    const [veterinaries, setVeterinaries] = useState<UserWithoutProperties[]>([]);
    const [species, setSpecies] = useState<ISpeciesDetails[]>([]);
    const [subSpecies, setSubSpecies] = useState<ISpeciesDetails[]>([]);
    const [typeIdentifiants, setTypeIdentifiants] = useState<ITypeIdentifiant[]>([]);
    const [dpes, setDpes] = useState<IDpeShort[]>([]);

    useEffect(() => {
        if (isFilling && (isFromDraft || isUpdate)) {
            initReferentials();
        }
    }, [isFromDraft, isFilling, isUpdate]);

    useEffect(() => {
        if (location.pathname !== routerLinks.delivery.manualDelivery.form()) resetData();
    }, [location.pathname]);

    useEffect(() => {
        getSpecies();
        getTypeIdentifiant();
    }, []);

    useEffect(() => {
        if (isUpdate) {
            handleUpdateForm();
        }
    }, [isUpdate]);

    const handleUpdateForm = async () => {
        Promise.all([getPrescription(), getDeliveries()]).then(() => {
            setIsFilling(true);
            navigate(routerLinks.delivery.manualDelivery.form());
        });
    };

    const initReferentials = () => {
        if (form?.info?.dpeOrdinalNumber) getVeterinaries(form?.info?.dpeOrdinalNumber);
        if (form?.info?.prescriptionVeterinaryId) getUserDpes(form?.info?.prescriptionVeterinaryId);
    };

    const getVeterinaries = async (ordinalNumber: string) => {
        const responses = await UserService.getDpeUsers(ordinalNumber);
        if (responses.status === HttpStatus.OK) setVeterinaries(responses.data);
    };

    const getSpecies = async () => {
        const response = await ReferentialService.getSpecies({ use: ["drug_transfer"] });
        if (response.status === HttpStatus.OK) {
            setSpecies(response.data.filter((species) => species.parentUuid === null));
            setSubSpecies(response.data.filter((species) => species.parentUuid !== null));
        }
    };

    const getUserDpes = async (userId: string) => {
        if (!userId) return;
        const response = await UserService.getUserDpes(userId);
        if (response.status === HttpStatus.OK) setDpes(response.data);
    };

    const getTypeIdentifiant = async () => {
        const response = await ReferentialService.getTypeIdentifiant(TypeIdentifiantUse.FARM);
        if (response.status === HttpStatus.OK) {
            setTypeIdentifiants(response.data);
        }
    };

    const getPrescription = async () => {
        const { info } = form;
        const response = await DrugService.getPrescription(
            info.prescriptionId,
            info.dpeOrdinalNumber,
            info.prescriptionVeterinaryId
        );
        if (response.status === HttpStatus.OK)
            setForm((prev) => ({ ...prev, prescription: response.data.prescription }));
    };

    const getDeliveries = async () => {
        const { info } = form;
        const response = await DrugService.getDeliveries(
            info.prescriptionId,
            info.dpeOrdinalNumber,
            info.prescriptionVeterinaryId
        );
        if (response.status === HttpStatus.OK) {
            setForm((prev) => ({
                ...prev,
                deliveries: response.data.deliveries.map((delivery) => ({
                    ...delivery,
                    internalId: crypto.randomUUID(),
                    deliveryId: delivery.deliveryId,
                    drugs: delivery.drugs.map((drug) => ({ ...drug, internalId: crypto.randomUUID() })),
                })),
            }));
        }
    };

    const resetData = () => {
        setVeterinaries([]);
        setDpes([]);
        setForm(null);
        setIsFilling(false);
        setIsFromDraft(false);
        setIsUpdate(false);
        setActiveStep(0);
    };

    return {
        form,
        setForm,
        isFromDraft,
        setIsFromDraft,
        getVeterinaries,
        veterinaries,
        isFilling,
        setIsFilling,
        resetData,
        species,
        subSpecies,
        typeIdentifiants,
        dpes,
        getUserDpes,
        getPrescription,
        getDeliveries,
        activeStep,
        setActiveStep,
        isUpdate,
        setIsUpdate,
    };
}
