// === NPM
import React from "react";
import { Box, Card, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
// === LOCAL
import { ReactComponent as Download } from "@/assets/icons/actions/download.svg";
import { ReactComponent as FileUpload } from "@/assets/icons/file_upload.svg";
import { HttpStatus } from "@/interfaces/global";
import { colors } from "@/resources/CssConstant";
import DownloadService from "@/services/DownloadService";
import { IDownload } from "../interface";

interface DownloadCardProps {
    download: IDownload;
}

export default function DownloadCard({ download }: Readonly<DownloadCardProps>) {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up("md"));
    const date = download.creationDate ? DateTime.fromISO(download.creationDate) : null;

    const handleDownload = async (uuid: string) => {
        const res = await DownloadService.getDownload(uuid);
        if (res.status === HttpStatus.OK) {
            const file = new Blob([res.data], { type: res.headers["content-type"] });
            saveAs(file, res.headers["content-disposition"].split("filename=")[1].slice(1, -1));
        }
    };

    return (
        <Tooltip title="Télécharger">
            <Card
                sx={{
                    "display": "flex",
                    "alignItems": "center",
                    "borderRadius": "10px",
                    "justifyContent": "space-between",
                    "paddingX": 3,
                    "paddingY": 2,
                    "flexDirection": md ? "row" : "column",
                    "&:hover": {
                        border: `2px solid ${colors.secondaryColor}`,
                        backgroundColor: `${colors.lightGray}`,
                        cursor: "pointer",
                    },
                }}
                onClick={() => handleDownload(download.uuid)}
            >
                <Stack direction={md ? "row" : "column"} alignItems="center" spacing={2}>
                    <FileUpload />
                    <Stack>
                        <Typography variant="bold">{download.filename}</Typography>
                        <Typography fontSize={14}>
                            {date
                                ? `Généré le ${date.toLocaleString()}, à ${date.toLocaleString(DateTime.TIME_SIMPLE)}`
                                : ""}
                        </Typography>
                    </Stack>
                </Stack>
                <Box sx={{ color: colors.primaryColor }}>
                    <Download />
                </Box>
            </Card>
        </Tooltip>
    );
}
