// === Import: NPM
import React from "react";
// === Import: LOCAL
import { ReactComponent as Download } from "@/assets/icons/actions/download.svg";
import { IActionButton } from "@/interfaces/global";
import IconActionButton from "./IconActionButton";

export default function DownloadAction({ title, onClick }: IActionButton) {
    return <IconActionButton title={title} onClick={onClick} icon={<Download />} />;
}
