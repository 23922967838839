// === NPM
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
// === LOCAL
import PageContent from "@/components/generics/layout/PageContent";
import { HttpStatus } from "@/interfaces/global";
import { IReferential, IRegion, ISpecies } from "@/interfaces/referential";
import { ITrainingType } from "@/interfaces/training";
import { UserType } from "@/interfaces/user";
import { UserSituation } from "@/resources/PermissionConstant";
import PermissionsTabs from "@/resources/PermissionsTabs";
import { routerLinks } from "@/routers/RouterConstant";
import { useAuth } from "@/routers/useAuth";
import ReferentialService from "@/services/ReferentialService";
import TrainingService from "@/services/TrainingService";

export default function Sessions() {
    const auth = useAuth();
    const [regions, setRegions] = useState<IRegion[]>([]);
    const [horsePowers, setHorsePowers] = useState<IReferential[]>([]);
    const [species, setSpecies] = useState<ISpecies[]>([]);
    const [trainingTypes, setTrainingTypes] = useState<ITrainingType[]>([]);

    useEffect(() => {
        Promise.all([getRegions(), getHorsePowers(), getSpecies(), getTrainingTypes()]);
    }, []);

    const getRegions = async () => {
        const res = await ReferentialService.getRegions();
        if (res.status === HttpStatus.OK) {
            setRegions(res.data);
        }
    };

    const getHorsePowers = async () => {
        if (auth.userInfo.type === UserType.ADMIN_CALYPSO || auth.userInfo.type === UserType.DDPP) return;
        const res = await TrainingService.getHorsePowers();
        if (res.status === HttpStatus.OK) {
            setHorsePowers(res.data);
        }
    };

    const getSpecies = async () => {
        const res = await ReferentialService.getSpecies({ use: ["health_accreditation_training"] }, false);
        if (res.status === HttpStatus.OK) {
            setSpecies(res.data);
        }
    };

    const getTrainingTypes = async () => {
        const res = await TrainingService.getHealthAccreditationTrainingTypes();
        if (res.status === HttpStatus.OK) {
            setTrainingTypes(res.data);
        }
    };

    return (
        <>
            <PermissionsTabs
                tabs={[
                    {
                        url: routerLinks.healthAccreditationTraining.sessions.attestations(),
                        label: "Mes attestations de formation",
                        permissions: [UserSituation.REGISTERED_IN_PRACTICE],
                    },
                    {
                        url: routerLinks.healthAccreditationTraining.sessions.process(),
                        label: "Mes sessions à traiter",
                        permissions: [UserSituation.ADMIN_DDPP, UserSituation.USER_DDPP],
                    },
                    {
                        url: routerLinks.healthAccreditationTraining.sessions.viewInscriptions(),
                        label: "Mes demandes d'inscriptions",
                        permissions: [UserSituation.REGISTERED_IN_PRACTICE],
                    },
                    {
                        url: routerLinks.healthAccreditationTraining.sessions.view(),
                        label: "Suivi des sessions",
                        permissions: [
                            UserSituation.ADMIN_CALYPSO,
                            UserSituation.ADMIN_DDPP,
                            UserSituation.USER_DDPP,
                            UserSituation.ADMIN_OVVT,
                            UserSituation.USER_OVVT,
                            UserSituation.REGISTERED_IN_PRACTICE,
                        ],
                    },
                    {
                        url: routerLinks.healthAccreditationTraining.sessions.form(),
                        label: "Saisie d'une session",
                        permissions: [UserSituation.ADMIN_OVVT, UserSituation.USER_OVVT],
                    },
                ]}
            />
            <PageContent>
                <Outlet context={{ regions, horsePowers, species, trainingTypes }} />
            </PageContent>
        </>
    );
}
