// === NPM
import React, { useEffect, useState } from "react";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { GridColDef, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro";
// === LOCAL
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import GenericTable from "@/components/generics/table/GenericTable";
import useTimeout from "@/hooks/useTimeout";
import { FilterType, HttpStatus, IPagination } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import {
    ActionColumnConfig,
    IDefaultTraceabilityFilters,
    InfoColumnsConfig,
    TraceabilityActionType,
} from "@/interfaces/traceability";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { colors } from "@/resources/CssConstant";
import { createPayload } from "@/resources/utils";
import TraceabilityService from "@/services/TraceabilityService";
import { IHealthAccreditationTrainingSessionTraceability } from "../../interface";
import HealthAccreditationTrainingSessionTraceabilityDetails from "./containers/HealthAccreditationTrainingSessionTraceabilityDetails";

export interface IHealthAccreditationTrainingSessionTraceabilityFilters extends IDefaultTraceabilityFilters {
    internalId: string;
    trainingTitle: string;
    ovvtName: string;
}

export default function HealthAccreditationTrainingSessionTraceability() {
    const [sessions, setSessions] = useState<IHealthAccreditationTrainingSessionTraceability[]>([]);
    const [inputFilters, setInputFilters] = useState<IHealthAccreditationTrainingSessionTraceabilityFilters>({
        date: [null, null],
        authorId: "",
        action: [],
        internalId: "",
        trainingTitle: "",
        ovvtName: "",
    });
    const [traceabilityActions, setTraceabilityActions] = useState<IReferential[]>([]);
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "date", sort: "desc" }]);
    const [selectedUuid, setSelectedUuid] = useState<string>(null);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getSessions();
    }, [pagination, sortModel]);

    useEffect(() => {
        getTraceabilityActions();
    }, []);

    const getSessions = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        };
        const res = await TraceabilityService.getTraceabilityHealthAccreditationTrainingSessions(payload);
        if (res.status === HttpStatus.OK) {
            setSessions(res.data);
            setRowCount(+CALYPSO_HEADERS.TABLE_COUNT);
        }
    };

    const getTraceabilityActions = async () => {
        const res = await TraceabilityService.getTraceabilityActions(
            TraceabilityActionType.HealthAccreditationTrainingSession
        );
        if (res.status === HttpStatus.OK) {
            setTraceabilityActions(res.data);
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const columns: GridColDef[] = [
        ...InfoColumnsConfig(),
        {
            field: "authorFirstname",
            headerName: "Prénom",
            flex: 1,
        },
        {
            field: "authorLastname",
            headerName: "Nom",
            flex: 1,
        },
        {
            field: "internalId",
            headerName: "Identifiant session",
            flex: 1,
        },
        {
            field: "trainingTitle",
            headerName: "Titre de la formation",
            flex: 1,
        },
        {
            field: "ovvtName",
            headerName: "Nom de l'organisation",
            flex: 1,
        },
        ActionColumnConfig(traceabilityActions),
    ];

    const filterConfigurations: FilterConfigurations<IHealthAccreditationTrainingSessionTraceabilityFilters> = {
        date: { type: FilterType.DATEPICKER, label: "Date" },
        authorId: { type: FilterType.INPUT, label: "Auteur" },
        action: { type: FilterType.SELECT, label: "Action", values: traceabilityActions },
        internalId: { type: FilterType.INPUT, label: "Identifiant session" },
        trainingTitle: { type: FilterType.INPUT, label: "Titre de la formation" },
        ovvtName: { type: FilterType.INPUT, label: "Nom de l'organisation" },
    };

    return (
        <Stack width="100%" spacing={2}>
            <Typography variant="h4">Traçabilité des sessions de formation habilitation sanitaire</Typography>
            <Card>
                <CardContent
                    sx={{
                        "& .selected": {
                            backgroundColor: colors.background,
                        },
                    }}
                >
                    <GenericFilters
                        inputFilters={inputFilters}
                        filterConfigurations={filterConfigurations}
                        initialValues={{
                            date: [null, null],
                            authorId: "",
                            action: [],
                            internalId: "",
                            trainingTitle: "",
                            ovvtName: "",
                        }}
                        setInputFilters={setInputFilters}
                    />
                    <GenericTable
                        rows={sessions}
                        columns={columns}
                        getRowId={(row) => row.uuid}
                        onPageSizeChange={handlePageSizeChange}
                        onPageChange={handlePageChange}
                        page={pagination.page}
                        pageSize={pagination.pageSize}
                        autoHeight
                        sortModel={sortModel}
                        onSortModelChange={(model) => setSortModel(model)}
                        sortingOrder={["asc", "desc"]}
                        filterMode="server"
                        sortingMode="server"
                        paginationMode="server"
                        rowCount={rowCount}
                        onRowClick={(params: GridRowParams) => setSelectedUuid(params.row.uuid)}
                        getRowClassName={(params) => (params.row.uuid === selectedUuid ? "selected" : "")}
                    />
                </CardContent>
            </Card>
            <HealthAccreditationTrainingSessionTraceabilityDetails
                traceabilityUuid={selectedUuid}
                traceabilityActions={traceabilityActions}
            />
        </Stack>
    );
}
