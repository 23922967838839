// === Import: NPM
import React, { Dispatch, SetStateAction } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// === Import: LOCAL
import Login from "@/components/Login/Login";
import RetrieveKey from "@/components/RetrieveKey/RetrieveKey";
import { routerLinks } from "./RouterConstant";

interface UnauthenticatedRouterProps {
    setConfigurationName: Dispatch<SetStateAction<string>>;
    configurationName: string;
}

export default function UnauthenticatedRouter({
    setConfigurationName,
    configurationName,
}: Readonly<UnauthenticatedRouterProps>) {
    return (
        <Routes>
            <Route
                path={routerLinks.login}
                element={<Login setConfigurationName={setConfigurationName} configurationName={configurationName} />}
            />
            <Route path={routerLinks.retrieveKey} element={<RetrieveKey />} />
            <Route path="*" element={<Navigate to={routerLinks.login} />} />
        </Routes>
    );
}
