// === NPM
import React from "react";
import { FiberManualRecord } from "@mui/icons-material";
import { Chip } from "@mui/material";
// === LOCAL
import { ITag } from "@/interfaces/faq";
import { colors } from "@/resources/CssConstant";

interface ChipTagProps {
    tag: ITag;
    handleClick?: () => void;
    selected?: boolean;
}

export default function ChipTag({ tag, handleClick, selected }: Readonly<ChipTagProps>) {
    return (
        <Chip
            icon={<FiberManualRecord style={{ color: tag.color }} fontSize="small" />}
            sx={{
                backgroundColor: colors.white,
                border: selected ? `2px solid ${tag.color}` : "",
            }}
            label={tag.name}
            onClick={handleClick}
            variant="outlined"
        />
    );
}
