// === NPM
import React from "react";
// === LOCAL
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import { IHealthReportingFull } from "../../../interface";
import AttachedFilesAccordeon from "../../containers/AttachedFilesAccordeon";
import InformationsAccordion from "../../containers/InformationsAccordion";
import ReportedAnomaliesAccordion from "../../containers/ReportedAnomaliesAccordion";

interface ViewDialogProps {
    onClose: () => void;
    healthReporting: IHealthReportingFull;
}

export default function ViewDialog({ onClose, healthReporting }: Readonly<ViewDialogProps>) {
    const renderContent = () => (
        <>
            <InformationsAccordion
                reportInformations={healthReporting.reportInformations}
                status={healthReporting.status}
            />
            <ReportedAnomaliesAccordion reportedAnomalies={healthReporting.reportedAnomalies} />
            {(healthReporting.files.length > 0 || healthReporting.comment) && (
                <AttachedFilesAccordeon
                    healthReportingId={healthReporting.id}
                    comment={healthReporting.comment}
                    attachments={healthReporting.files}
                />
            )}
        </>
    );

    return (
        <GenericDialog
            onClose={onClose}
            title={`Visualisation du signalement ${healthReporting.id}`}
            renderContent={renderContent}
            renderActions={() => <GenericActionsDialog onClose={onClose} closeLabel="Fermer" displaySubmit={false} />}
            maxWidth="xl"
        />
    );
}
