// === NPM
import React, { useMemo } from "react";
import { useOutletContext } from "react-router-dom";
// === LOCAL
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import {
    HealthReportingOutletContext,
    IReportedAnomalyFull,
    IReportInformationsFull,
    IWorkshopInformations,
} from "@/components/HealthReporting/AnimalAbuse/interface";
import { useAuth } from "@/routers/useAuth";
import AttachedFilesAccordeon from "../../../../containers/AttachedFilesAccordeon";
import InformationsAccordion from "../../../../containers/InformationsAccordion";
import ReportedAnomaliesAccordion from "../../../../containers/ReportedAnomaliesAccordion";
import { useFormHealthReporting } from "../../useFormHealthReporting";

interface RecapDialogProps {
    onClose: (confirm: boolean) => void;
    files: File[];
}

export default function RecapDialog({ onClose, files }: Readonly<RecapDialogProps>) {
    const auth = useAuth();
    const { form } = useFormHealthReporting();
    const { anomalyTypes } = useOutletContext<HealthReportingOutletContext>();

    const reportInformations: IReportInformationsFull & IWorkshopInformations = {
        ...form.reportInformations,
        ...form.workshopInformations,
        veterinaryFirstname: auth.userInfo.firstname,
        veterinaryLastname: auth.userInfo.lastname,
        veterinaryId: auth.userInfo.id,
        dpeName: auth.userInfo.properties.dpes.find((dpe) => dpe.id === form.reportInformations.dpeId)?.name,
    };

    const reportedAnomalies = useMemo(
        () =>
            form.reportedAnomalies.map((anomaly) => {
                const newAnomaly: IReportedAnomalyFull = {
                    anomalyType: anomalyTypes.find((anomalyType) => anomalyType.uuid === anomaly.anomalyTypeUuid)
                        ?.label,
                    species: anomaly.species,
                    animalCount: anomaly.animalCount,
                    individualIdentification: anomaly.individualIdentification,
                };
                return newAnomaly;
            }),
        [form.reportedAnomalies]
    );

    const renderContent = () => (
        <>
            <InformationsAccordion reportInformations={reportInformations} />
            <ReportedAnomaliesAccordion reportedAnomalies={reportedAnomalies} />
            {(files.length > 0 || form.comment) && <AttachedFilesAccordeon comment={form.comment} files={files} />}
        </>
    );

    return (
        <GenericDialog
            onClose={() => onClose(false)}
            title="Récapitulatif du signalement de maltraitance"
            renderContent={renderContent}
            renderActions={() => (
                <GenericActionsDialog
                    onClose={() => onClose(false)}
                    onSubmit={() => onClose(true)}
                    closeLabel="Retour au formulaire"
                    displaySubmit
                />
            )}
            maxWidth="xl"
        />
    );
}
