// === NPM
import React from "react";
// === LOCAL
import PowerBiBox from "@/components/generics/layout/PowerBiBox";

export default function FollowUp() {
    return (
        <PowerBiBox>
            <iframe title="suivi_declaration" width="100%" height="100%" src={window._env_.REACT_APP_PBI_DELIVERY} />
        </PowerBiBox>
    );
}
