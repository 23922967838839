import { IDpeProperties, IUserInfo } from "@/interfaces/user";
import { IVaccine, VaccineUsage } from "@/interfaces/vaccination";
import { Preference } from "@/resources/PermissionConstant";
import { PreferencesAndVaccineUsage } from "./interface";

export const filterVaccines = (vaccines: IVaccine[], userInfo: IUserInfo, dpes: IDpeProperties[], dpeId: string) => {
    let result = [...vaccines];

    const authorizedVaccines = Object.values(Preference).reduce((acc, pref) => {
        if (userInfo.preferences[pref] && PreferencesAndVaccineUsage[pref]) {
            acc = acc.concat(PreferencesAndVaccineUsage[pref]);
        }
        return acc;
    }, []);

    result = result.filter((v) => authorizedVaccines.includes(v.useKey));

    const dpe = dpes.find((d) => d.id === dpeId);
    if (!dpe?.sanitaryDpe) {
        return result.filter((v) => v.useKey !== VaccineUsage.IAHP);
    }

    return result;
};
