// === NPM
import React from "react";
import { useOutletContext } from "react-router-dom";
import { VaccinesOutlined } from "@mui/icons-material";
import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Info } from "@/assets/icons/info.svg";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import GenericTitleValueText from "@/components/generics/text/GenericTitleValueText";
import { IAntimicrobialContext, IDrugPrescriptionReporting } from "../../../interface";

interface DetailDialogProps {
    onClose: () => void;
    drug: IDrugPrescriptionReporting;
}

export default function DetailDialog({ onClose, drug }: Readonly<DetailDialogProps>) {
    const { species } = useOutletContext<IAntimicrobialContext>();

    const renderInformations = () => {
        return (
            <Grid container spacing={6} p={2}>
                <Grid item xs={12} md={6}>
                    <Stack direction="row" spacing={1}>
                        <Info />
                        <Typography variant="h5">Information du cessionnaire</Typography>
                    </Stack>

                    <Card sx={{ mt: 2 }}>
                        <CardContent sx={{ height: "100%" }}>
                            <Stack spacing={2} p={2}>
                                <GenericTitleValueText title="ID élevage" value={drug.farmId} />
                                <GenericTitleValueText title="Raison sociale" value={drug.farmName} />
                                <GenericTitleValueText title="Code postal" value={drug.farmPostalCode} />
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack direction="row" spacing={1}>
                        <VaccinesOutlined />
                        <Typography variant="h5">Information de l'ordonnance</Typography>
                    </Stack>

                    <Card sx={{ mt: 2 }}>
                        <CardContent sx={{ height: "100%" }}>
                            <Stack spacing={2} p={2}>
                                <GenericTitleValueText
                                    title="Catégorie d'espèces"
                                    value={species.find((s) => s.uuid === drug.speciesUuid)?.label}
                                />
                                <GenericTitleValueText
                                    title="Sous catégorie d'espèces"
                                    value={species.find((s) => s.uuid === drug.subSpeciesUuid)?.label}
                                />
                                <GenericTitleValueText title="Nombre d'animaux traités" value={drug.animalQuantity} />
                                <GenericTitleValueText title="Durée du traitement" value={drug.treatmentDuration} />
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    };

    return (
        <GenericDialog
            title={`${drug.prescriptionId} - ${drug.gtinCode}`}
            onClose={onClose}
            renderContent={() => renderInformations()}
            renderActions={() => <GenericActionsDialog onClose={onClose} displaySubmit={false} closeLabel="Fermer" />}
        />
    );
}
