import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

interface GenericDialogProps {
    onClose: () => void;
    title: string;
    paperProps?: any;
    renderContent: any;
    renderActions: any;
    id?: string;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}

export default function GenericDialog({
    onClose,
    title,
    paperProps,
    renderContent,
    renderActions,
    id,
    maxWidth = "md",
}: Readonly<GenericDialogProps>) {
    return (
        <Dialog id={id} onClose={onClose} open maxWidth={maxWidth} PaperProps={paperProps} fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers sx={{ height: "100%" }}>
                {renderContent()}
            </DialogContent>
            <DialogActions>{renderActions()}</DialogActions>
        </Dialog>
    );
}
