// === NPM
import React, { useState } from "react";
import { Card, Stack, Typography } from "@mui/material";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
// === LOCAL
import { ReactComponent as Calendar } from "@/assets/icons/shared/calendar.svg";
import { ReactComponent as Star } from "@/assets/icons/shared/star.svg";
import { ReactComponent as Type } from "@/assets/icons/training/catalog/type.svg";
import { ReactComponent as Certificate } from "@/assets/icons/training/certificate.svg";
import DownloadAction from "@/components/generics/actions/DownloadAction";
import ViewAction from "@/components/generics/actions/ViewAction";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import { IInscriptionVeterinary, ISession } from "@/components/HealthAccreditationTraining/interface";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { HttpStatus } from "@/interfaces/global";
import { colors } from "@/resources/CssConstant";
import TrainingService from "@/services/TrainingService";
import ViewSessionDialog from "../../ViewSessionDialog";

interface IInscriptionCard {
    inscription: IInscriptionVeterinary;
}

export default function InscriptionCard({ inscription }: Readonly<IInscriptionCard>) {
    const [session, setSession] = useState<ISession>(null);

    const getSession = async () => {
        const res = await TrainingService.getHealthAccreditationTrainingSession(inscription.sessionUuid);
        if (res.status === HttpStatus.OK) {
            setSession(res.data);
        }
    };

    const getAttestation = async () => {
        const res = await TrainingService.getHealthAccreditationAttestation(inscription.uuid);
        if (res.status === HttpStatus.OK) {
            const file = new Blob([res.data], { type: res.headers["content-type"] });
            saveAs(file, res.headers["content-disposition"].split("filename=")[1].slice(1, -1));
        }
    };

    return (
        <>
            <Card>
                <StyledCardContent>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" p={1}>
                        <Stack direction="row" spacing={3} alignItems="center">
                            <Certificate />
                            <Stack spacing={1}>
                                <Typography variant="bold" color={colors.primaryColor}>
                                    {inscription.trainingTitle}
                                </Typography>
                                <Stack direction="row" spacing={3}>
                                    <GenericIconText
                                        icon={<Calendar />}
                                        iconTooltip="Date"
                                        text={DateTime.fromISO(inscription.startDate).toLocaleString()}
                                    />
                                    <GenericIconText
                                        icon={<Star />}
                                        iconTooltip="Points ECTS"
                                        text={inscription.ectsPoints}
                                    />
                                    <GenericIconText
                                        icon={<Type />}
                                        iconTooltip="Type"
                                        text={inscription.trainingType.label}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack direction="row">
                            <ViewAction title="Voir le détail" onClick={getSession} />
                            <DownloadAction title="Télécharger l'attestation" onClick={getAttestation} />
                        </Stack>
                    </Stack>
                </StyledCardContent>
            </Card>
            {session && <ViewSessionDialog session={session} onClose={() => setSession(null)} />}
        </>
    );
}
