// === NPM
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FileUploadOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridSortModel } from "@mui/x-data-grid-pro";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
// === LOCAL
import { ReactComponent as ArrowLeft } from "@/assets/icons/actions/arrow-left.svg";
import { ReactComponent as Cancel } from "@/assets/icons/actions/cancel.svg";
import { ReactComponent as Wrong } from "@/assets/icons/actions/cancel.svg";
import { ReactComponent as Download } from "@/assets/icons/actions/download.svg";
import { ReactComponent as Info } from "@/assets/icons/info.svg";
import { ReactComponent as Warning } from "@/assets/icons/shared/danger-triangle.svg";
import { ReactComponent as Valid } from "@/assets/icons/shared/valid.svg";
import { ReactComponent as Absent } from "@/assets/icons/training/inscriptions/absent.svg";
import { ReactComponent as Present } from "@/assets/icons/training/inscriptions/present.svg";
import { ReactComponent as Register } from "@/assets/icons/training/inscriptions/register.svg";
import { ReactComponent as Unregister } from "@/assets/icons/training/inscriptions/unregister.svg";
import IconActionButton from "@/components/generics/actions/IconActionButton";
import GenericButton from "@/components/generics/buttons/GenericButton";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import ImportDialog from "@/components/generics/dialogs/ImportDialog";
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import IconBannerText from "@/components/generics/IconBannerText";
import GenericTable from "@/components/generics/table/GenericTable";
import {
    IInscriptionOvvt,
    InscriptionStatus,
    ISession,
    PresenceStatus,
    SessionOutletContext,
} from "@/components/HealthAccreditationTraining/interface";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { useDepartments } from "@/context/useDepartmentContext";
import { useProvideGlobal } from "@/context/useGlobalContext";
import useTimeout from "@/hooks/useTimeout";
import {
    ActionsColumnProps,
    ArrayOfTwo,
    FilterType,
    HttpStatus,
    IPagination,
    SortDirection,
} from "@/interfaces/global";
import { CALYPSO_HEADERS, defaultPagination, DocumentMimeTypeFile } from "@/resources/AppConstant";
import { statusColor } from "@/resources/CssConstant";
import { createPayload, getEnumKeyByValue, validateFile } from "@/resources/utils";
import { routerLinks } from "@/routers/RouterConstant";
import TrainingService from "@/services/TrainingService";
import ViewSessionDialog from "../ViewSessionDialog";

export interface IInscriptionsOvvtFilters {
    veterinary: string;
    lastTrainingDate: ArrayOfTwo;
    registrationStatus: string[];
    presenceStatus: string[];
}

interface IInscriptionsOvvtFiltersPastSession {
    veterinary: string;
    lastTrainingDate: ArrayOfTwo;
    presenceStatus: string[];
}

interface IInscriptionsOvvtFiltersFutureSession {
    veterinary: string;
    lastTrainingDate: ArrayOfTwo;
    registrationStatus: string[];
}

export default function InscriptionsOvvt() {
    const navigate = useNavigate();
    const location = useLocation();
    const { sessionUuid } = useParams();

    const { departments } = useDepartments(); //departments sera utile lorsque la synchro avec les DPA sera faite
    const { horsePowers } = useOutletContext<SessionOutletContext>();
    const { loadingRequest, loadingButton } = useProvideGlobal();
    const loading = loadingRequest || loadingButton;

    const [session, setSession] = useState<{ startDate: string; internalId: string; title: string }>(null);
    const [pastSession, setPastSession] = useState<boolean>(null);
    const [inscriptions, setInscriptions] = useState<IInscriptionOvvt[]>([]);
    const [inputFilters, setInputFilters] = useState<
        IInscriptionsOvvtFiltersPastSession | IInscriptionsOvvtFiltersFutureSession
    >({
        veterinary: "",
        lastTrainingDate: [null, null],
        registrationStatus: [],
        presenceStatus: [],
    });
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "veterinary", sort: "asc" }]);
    const [completeSession, setCompleteSession] = useState<ISession>(null);
    const [openImportDialog, setOpenImportDialog] = useState<boolean>(false);
    const [selectedInscription, setSelectedInscription] = useState<IInscriptionOvvt>(null);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getInscriptions();
    }, [pagination, sortModel]);

    useEffect(() => {
        if (sessionUuid && !session) {
            initSession();
        } else {
            setSession(location.state.session);
            setPastSession(
                DateTime.fromISO(location.state.session.startDate.slice(0, -5)) < DateTime.now().plus({ minutes: 30 })
            );
        }
    }, []);

    const getInscriptions = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        };
        const res = await TrainingService.getParticipations(sessionUuid, payload);
        if (res.status === HttpStatus.OK) {
            setInscriptions(res.data);
            setRowCount(+res.headers[CALYPSO_HEADERS.TABLE_COUNT]);
        }
    };

    const getSession = async () => {
        return await TrainingService.getHealthAccreditationTrainingSession(sessionUuid);
    };

    const initSession = async () => {
        const res = await getSession();
        if (res.status === HttpStatus.OK) {
            setSession({
                startDate: res.data.startDate,
                title: res.data.healthAccreditationTraining.title,
                internalId: res.data.internalId,
            });
            setPastSession(DateTime.fromISO(res.data.startDate.slice(0, -5)) < DateTime.now().plus({ minutes: 30 }));
        }
    };

    const handleDisplaySession = async () => {
        const res = await getSession();
        if (res.status === HttpStatus.OK) {
            setCompleteSession(res.data);
        }
    };

    const downloadAttendanceSheetFile = async () => {
        const res = await TrainingService.getAttendanceSheet(sessionUuid);
        if (res.status === HttpStatus.OK) {
            const file = new Blob([res.data], { type: res.headers["content-type"] });
            saveAs(file, res.headers["content-disposition"].split("filename=")[1].slice(1, -1));
        }
    };

    const importAttendanceSheet = async (file: File) => {
        const res = await TrainingService.postAttendanceSheet(sessionUuid, file);
        if (res.status === HttpStatus.ACCEPTED) {
            setOpenImportDialog(false);
        }
    };

    const changeRegistrationStatus = async (uuid: string, status: InscriptionStatus) => {
        const res =
            status === InscriptionStatus.REGISTERED
                ? await TrainingService.validateParticipation(uuid)
                : await TrainingService.invalidateParticipation(uuid);
        if (res.status === HttpStatus.NO_CONTENT) {
            toast.success("Statut d'inscription mis à jour avec succès.");
            getInscriptions();
        }
    };

    const changePresenceStatus = async (uuid: string, status: PresenceStatus) => {
        const res =
            status === PresenceStatus.PRESENT
                ? await TrainingService.patchPresent(uuid)
                : await TrainingService.patchAbsent(uuid);
        if (res.status === HttpStatus.NO_CONTENT) {
            toast.success("Présence mise à jour avec succès.");
            getInscriptions();
        }
    };

    const removeVeterinary = async () => {
        const res = await TrainingService.deleteRegistration(selectedInscription.uuid);
        if (res.status === HttpStatus.NO_CONTENT) {
            toast.success("Inscription supprimée avec succès");
            getInscriptions();
            setSelectedInscription(null);
        }
    };

    const renderStatusColumn = (params: GridRenderCellParams) => {
        if (pastSession === null) return;
        if (pastSession) {
            return (
                <Box width={100} display="flex" justifyContent="center">
                    {PresenceStatus[params.row.presenceStatus] === PresenceStatus.UNDEFINED && (
                        <IconBannerText
                            icon={<Info />}
                            message={PresenceStatus.UNDEFINED}
                            color="black"
                            backgroundColor={statusColor.infoBackground}
                        />
                    )}
                    {PresenceStatus[params.row.presenceStatus] === PresenceStatus.PRESENT && (
                        <IconBannerText
                            icon={<Valid />}
                            message={PresenceStatus.PRESENT}
                            color={statusColor.successText}
                            backgroundColor={statusColor.successBackground}
                        />
                    )}
                    {PresenceStatus[params.row.presenceStatus] === PresenceStatus.ABSENT && (
                        <IconBannerText
                            icon={<Wrong />}
                            message={PresenceStatus.ABSENT}
                            color={statusColor.errorText}
                            backgroundColor={statusColor.errorBackground}
                        />
                    )}
                </Box>
            );
        } else {
            return (
                <Box width={100} display="flex" justifyContent="center">
                    {InscriptionStatus[params.row.registrationStatus] === InscriptionStatus.PRE_REGISTERED ? (
                        <IconBannerText
                            icon={<Info />}
                            message={InscriptionStatus.PRE_REGISTERED}
                            color={statusColor.warningText}
                            backgroundColor={statusColor.warningBackground}
                        />
                    ) : (
                        <IconBannerText
                            icon={<Valid />}
                            message={InscriptionStatus.REGISTERED}
                            color="black"
                            backgroundColor={statusColor.infoBackground}
                        />
                    )}
                </Box>
            );
        }
    };

    const renderActionColumn = (params: GridRenderCellParams) => {
        if (pastSession === null) return;
        if (pastSession) {
            return (
                <Box>
                    {(PresenceStatus[params.row.presenceStatus] === PresenceStatus.UNDEFINED ||
                        PresenceStatus[params.row.presenceStatus] === PresenceStatus.ABSENT) && (
                        <IconActionButton
                            icon={<Present />}
                            title="Marquer comme Présent"
                            onClick={() => changePresenceStatus(params.row.uuid, PresenceStatus.PRESENT)}
                        />
                    )}
                    {(PresenceStatus[params.row.presenceStatus] === PresenceStatus.UNDEFINED ||
                        PresenceStatus[params.row.presenceStatus] === PresenceStatus.PRESENT) && (
                        <IconActionButton
                            icon={<Absent />}
                            title="Marquer comme Absent"
                            onClick={() => changePresenceStatus(params.row.uuid, PresenceStatus.ABSENT)}
                        />
                    )}
                </Box>
            );
        } else {
            return (
                <Box>
                    {InscriptionStatus[params.row.registrationStatus] === InscriptionStatus.PRE_REGISTERED ? (
                        <IconActionButton
                            icon={<Register />}
                            title="Inscrire ce vétérinaire"
                            onClick={() => changeRegistrationStatus(params.row.uuid, InscriptionStatus.REGISTERED)}
                        />
                    ) : (
                        <IconActionButton
                            icon={<Unregister />}
                            title="Passer en préinscrit"
                            onClick={() => changeRegistrationStatus(params.row.uuid, InscriptionStatus.PRE_REGISTERED)}
                        />
                    )}
                    <IconActionButton
                        icon={<Cancel />}
                        title="Désinscrire ce vétérinaire"
                        onClick={() => setSelectedInscription(params.row)}
                    />
                </Box>
            );
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const columns: GridColDef[] = [
        {
            field: "veterinary",
            flex: 1,
            headerName: "Vétérinaire",
        },
        {
            field: "lastTrainingDate",
            flex: 1,
            headerName: "Date de dernière formation",
            valueGetter: (params: GridRenderCellParams) =>
                params.row.lastTrainingDate
                    ? DateTime.fromISO(params.row.lastTrainingDate).toLocaleString()
                    : "Inexistante",
        },
        {
            field: "species",
            flex: 1,
            headerName: "Espèces",
            valueGetter: (params: GridRenderCellParams) =>
                params.row.species ? params.row.species.label : params.row.otherSpecies,
            sortable: false,
        },
        {
            field: "distance",
            flex: 1,
            headerName: "Distance",
            valueGetter: (params: GridRenderCellParams) => `${params.row.distance}km`,
        },
        {
            field: "horsePower",
            flex: 1,
            headerName: "Chevaux fiscaux",
            valueGetter: (params: GridRenderCellParams) =>
                horsePowers.find((hp) => hp.key === params.row.horsePower)?.label,
        },
        {
            field: pastSession ? "presenceStatus" : "registrationStatus",
            flex: 1,
            headerName: "Statut",
            renderCell: renderStatusColumn,
        },
        {
            ...ActionsColumnProps,
            width: 100,
            renderCell: renderActionColumn,
        },
    ];

    const filterConfigurations: FilterConfigurations<
        IInscriptionsOvvtFiltersPastSession | IInscriptionsOvvtFiltersFutureSession
    > = {
        veterinary: { type: FilterType.INPUT, label: "Vétérinaire" },
        lastTrainingDate: { type: FilterType.DATEPICKER, label: "Date de dernière formation" },
        ...(!pastSession
            ? {
                  registrationStatus: {
                      type: FilterType.SELECT,
                      label: "Statut",
                      values: Object.entries(InscriptionStatus)
                          .filter(
                              ([key]) => key !== getEnumKeyByValue(InscriptionStatus, InscriptionStatus.UNREGISTERED)
                          )
                          .map(([key, label]) => ({ key, label })),
                  },
              }
            : {
                  presenceStatus: {
                      type: FilterType.SELECT,
                      label: "Statut",
                      values: Object.entries(PresenceStatus).map(([key, label]) => ({ key, label })),
                  },
              }),
    };

    return (
        <>
            <Stack spacing={2} sx={{ width: "100%" }}>
                <Box>
                    <LoadingButton
                        onClick={() => navigate(routerLinks.healthAccreditationTraining.sessions.view())}
                        startIcon={<ArrowLeft />}
                    >
                        Revenir aux sessions de formation
                    </LoadingButton>
                </Box>
                <Typography variant="h4">{`Gestion de la session ${session?.internalId} - ${session?.title}`}</Typography>
                <Box display="flex" justifyContent="space-between">
                    <SecondaryButton onClick={handleDisplaySession} variant="outlined" loading={loading}>
                        Voir le détail de la session
                    </SecondaryButton>
                    <Stack spacing={1} direction="row">
                        <GenericButton
                            label="Télécharger la feuille d'émargement vierge"
                            onClick={downloadAttendanceSheetFile}
                            startIcon={<Download />}
                            loading={loading}
                        />
                        <GenericButton
                            label="Importer la feuille d'émargement"
                            onClick={() => setOpenImportDialog(true)}
                            startIcon={<FileUploadOutlined />}
                            loading={loading}
                        />
                    </Stack>
                </Box>
                <Card>
                    <CardContent>
                        <GenericFilters
                            inputFilters={inputFilters}
                            filterConfigurations={filterConfigurations}
                            initialValues={{
                                veterinary: "",
                                lastTrainingDate: [null, null],
                                registrationStatus: [],
                                presenceStatus: [],
                            }}
                            setInputFilters={setInputFilters}
                        />
                        <GenericTable
                            rows={inscriptions}
                            columns={columns}
                            getRowId={(row) => row.uuid}
                            onPageSizeChange={handlePageSizeChange}
                            onPageChange={handlePageChange}
                            page={pagination.page}
                            pageSize={pagination.pageSize}
                            autoHeight
                            sortingMode="server"
                            paginationMode="server"
                            sortModel={sortModel}
                            rowCount={rowCount}
                            onSortModelChange={(model) => setSortModel(model)}
                            sortingOrder={[SortDirection.ASC, SortDirection.DESC]}
                            filterMode="server"
                        />
                    </CardContent>
                </Card>
            </Stack>
            {completeSession && (
                <ViewSessionDialog session={completeSession} onClose={() => setCompleteSession(null)} />
            )}
            {openImportDialog && (
                <ImportDialog
                    onClose={() => setOpenImportDialog(false)}
                    onValid={importAttendanceSheet}
                    validateFile={validateFile}
                    title="Import de la feuille d'émargement"
                    acceptedFiles={DocumentMimeTypeFile}
                />
            )}
            {!!selectedInscription && (
                <GenericDialog
                    title={`Désinscription du vétérinaire ${selectedInscription.veterinary}`}
                    onClose={() => setSelectedInscription(null)}
                    renderActions={() => (
                        <GenericActionsDialog
                            onClose={() => setSelectedInscription(null)}
                            onSubmit={removeVeterinary}
                            validLabel="Oui"
                            closeLabel="Non"
                        />
                    )}
                    renderContent={() => (
                        <Stack spacing={1}>
                            <IconBannerText
                                icon={<Warning />}
                                message={
                                    "Attention\nVous allez définitivement désinscrire un vétérinaire d'une session"
                                }
                                color={statusColor.warningText}
                                backgroundColor={statusColor.warningBackground}
                            />
                            <Typography>
                                {`Confirmez-vous la désinscription du vétérinaire ${selectedInscription.veterinary} de la session de formation ${session.internalId} - ${session.title} ?`}
                            </Typography>
                            <Typography>Cette désinscription sera définitive.</Typography>
                        </Stack>
                    )}
                />
            )}
        </>
    );
}
