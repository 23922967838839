// === NPM
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box, Stack } from "@mui/material";
// === LOCAL
import { colors } from "@/resources/CssConstant";
import { UserSituation } from "@/resources/PermissionConstant";
import PermissionsTabs from "@/resources/PermissionsTabs";
import { routerLinks } from "@/routers/RouterConstant";

export default function PowerBi() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === routerLinks.delivery.powerBi.base())
            navigate(routerLinks.delivery.powerBi.followUp());
    }, []);

    return (
        <Stack height="100%" width="100%">
            <PermissionsTabs
                tabs={[
                    {
                        url: routerLinks.delivery.powerBi.followUp(),
                        label: "Tableaux de suivi",
                        permissions: [UserSituation.ADMIN_CALYPSO, UserSituation.ANMV_MED],
                    },
                    {
                        url: routerLinks.delivery.powerBi.indicators(),
                        label: "Tableaux d'indicateurs",
                        permissions: [UserSituation.ADMIN_CALYPSO, UserSituation.ANMV_MED],
                    },
                    {
                        url: routerLinks.delivery.powerBi.dashBoard(),
                        label: "Tableaux de bord",
                    },
                ]}
            />
            <Box
                display="flex"
                sx={{
                    flexGrow: 1,
                    mx: 5,
                    mb: 4,
                    pt: 5,
                    px: 5,
                    backgroundColor: colors.background,
                    borderRadius: 5,
                }}
            >
                <Outlet />
            </Box>
        </Stack>
    );
}
