// === NPM
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Box, Card, Stack, Typography } from "@mui/material";
import { saveAs } from "file-saver";
// === LOCAL
import { ReactComponent as Document } from "@/assets/icons/shared/paper.svg";
import { ReactComponent as Regenerate } from "@/assets/icons/shared/refresh.svg";
import { ReactComponent as Show } from "@/assets/icons/shared/show.svg";
import { ReactComponent as Veterinary } from "@/assets/icons/shared/veterinary.svg";
import { ReactComponent as Certificate } from "@/assets/icons/training/certificateIcon.svg";
import DownloadAction from "@/components/generics/actions/DownloadAction";
import IconActionButton from "@/components/generics/actions/IconActionButton";
import ViewAction from "@/components/generics/actions/ViewAction";
import GenericButton from "@/components/generics/buttons/GenericButton";
import ImportDialog from "@/components/generics/dialogs/ImportDialog";
import GenericAccordion from "@/components/generics/layout/GenericAccordion";
import { IParticipationDdpp, ISession, ISessionDdppProcess } from "@/components/HealthAccreditationTraining/interface";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import StyledChip from "@/components/styled/StyledChip";
import { HttpStatus } from "@/interfaces/global";
import { DocumentMimeTypeFile } from "@/resources/AppConstant";
import { openPreview, validateFile } from "@/resources/utils";
import TrainingService from "@/services/TrainingService";
import ViewSessionDialog from "../../ViewSessionDialog";

interface ISessionCardProps {
    session: ISessionDdppProcess;
}

export default function SessionCard({ session }: Readonly<ISessionCardProps>) {
    const [participations, setParticipations] = useState<IParticipationDdpp[]>(null);
    const [completeSession, setCompleteSession] = useState<ISession>(null);
    const [openImportDialog, setOpenImportDialog] = useState<boolean>(false);

    const awaitingParticipations = participations?.filter((participation) => !participation.attestationSigned);
    const generatedParticipations = participations?.filter((participation) => !!participation.attestationSigned);

    const getAttestationsCached = async () => {
        if (participations) return;
        const res = await TrainingService.getHealthAccreditationTrainingSessionParticipationsToProcess(session.uuid);
        if (res.status === HttpStatus.OK) {
            setParticipations(res.data);
        }
    };

    const getAttendanceSheet = async () => {
        const file: File = await getAttendanceSheetFile();
        if (!file) return;
        openPreview(file);
    };

    const getAttendanceSheetFile = async () => {
        const res = await TrainingService.getAttendanceSheet(session.uuid);
        if (res.status === HttpStatus.OK) {
            const file = new Blob([res.data], { type: res.headers["content-type"] });
            const fileName = res.headers["content-disposition"].split("filename=")[1].slice(1, -1);
            const newFile = new File([file], fileName, {
                type: res.headers["content-type"],
            });
            return newFile;
        }
        return null;
    };

    const viewAttestation = async (uuid: string) => {
        const file: File = await getAttestationFile(uuid);
        if (!file) return;
        openPreview(file);
    };

    const downloadAttestation = async (uuid: string) => {
        const file: File = await getAttestationFile(uuid);
        if (!file) return;
        saveAs(file);
    };

    const getAttestationFile = async (uuid: string) => {
        const res = await TrainingService.getHealthAccreditationAttestation(uuid);
        if (res.status === HttpStatus.OK) {
            const file = new Blob([res.data], { type: res.headers["content-type"] });
            const fileName = res.headers["content-disposition"].split("filename=")[1].slice(1, -1);
            const newFile = new File([file], fileName, {
                type: res.headers["content-type"],
            });
            return newFile;
        }
        return null;
    };

    const getCompleteSession = async () => {
        const res = await TrainingService.getHealthAccreditationTrainingSession(session.uuid);
        if (res.status === HttpStatus.OK) {
            setCompleteSession(res.data);
        }
    };

    const importAttendanceSheet = async (file: File) => {
        const res = await TrainingService.postAttendanceSheet(session.uuid, file);
        if (res.status === HttpStatus.ACCEPTED) {
            setOpenImportDialog(false);
            toast.success("Feuille d'émargement importée avec succès.");
        }
    };

    const renderParticipations = () => (
        <Box>
            {awaitingParticipations.map((participation) => (
                <Card key={participation.registrationUuid}>
                    <StyledCardContent>
                        <Stack alignItems="center" p={1} spacing={1}>
                            <Veterinary />
                            <Typography>{`${participation.veterinaryId} - ${participation.veterinaryFirstname} ${participation.veterinaryLastname}`}</Typography>
                            <GenericButton
                                startIcon={<Certificate />}
                                label="Générer l'attestation en attente"
                                onClick={() => {
                                    alert("ok");
                                }}
                            />
                        </Stack>
                    </StyledCardContent>
                </Card>
            ))}
        </Box>
    );

    const renderAttestations = () => (
        <Box>
            {generatedParticipations.map((participation) => (
                <Card key={participation.registrationUuid}>
                    <StyledCardContent>
                        <Stack alignItems="center" p={2} spacing={1}>
                            <Veterinary />
                            <Typography>{`${participation.veterinaryId} - ${participation.veterinaryFirstname} ${participation.veterinaryLastname}`}</Typography>
                            <Box>
                                <ViewAction
                                    title="Voir l'attestation"
                                    onClick={() => viewAttestation(participation.registrationUuid)}
                                />
                                <DownloadAction
                                    title="Télécharger l'attestation"
                                    onClick={() => downloadAttestation(participation.registrationUuid)}
                                />
                                <IconActionButton
                                    title="Regénérer l'attestation"
                                    onClick={() => alert("todo")}
                                    icon={<Regenerate />}
                                />
                            </Box>
                        </Stack>
                    </StyledCardContent>
                </Card>
            ))}
        </Box>
    );

    return (
        <div onMouseEnter={getAttestationsCached} role="none">
            <GenericAccordion
                title={`Session ${session.internalId} - ${session.trainingTitle}`}
                info={
                    (session.needAttendanceSheetSign || session.needAttestationSign) &&
                    "Cette session nécessite des actions"
                }
            >
                <Stack spacing={6} width="100%" p={2}>
                    <Box>
                        <SecondaryButton onClick={getCompleteSession} variant="outlined">
                            Voir le détail de la session
                        </SecondaryButton>
                    </Box>
                    <Box justifyContent="space-between" display="flex">
                        <Stack spacing={3}>
                            <Typography variant="bold">Feuille d'émargement</Typography>
                            <StyledChip
                                icon={<Document style={{ color: "inherit" }} />}
                                label={session.attendanceSheetName}
                                onDelete={getAttendanceSheet}
                                deleteIcon={<Show />}
                            />
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Box>
                                <GenericButton
                                    label="Importer la feuille d'émargement"
                                    onClick={() => setOpenImportDialog(true)}
                                />
                            </Box>
                            {session.needAttendanceSheetSign && (
                                <Box>
                                    <GenericButton label="Signer la feuille d'émargement" onClick={() => alert("ok")} />
                                </Box>
                            )}
                        </Stack>
                    </Box>
                    {awaitingParticipations?.length > 0 && (
                        <Box justifyContent="space-between" display="flex">
                            <Stack spacing={3}>
                                <Typography variant="bold">Attestations en attente</Typography>
                                {renderParticipations()}
                            </Stack>
                            <Box>
                                <GenericButton
                                    startIcon={<Certificate />}
                                    label="Générer toutes les attestations en attente"
                                    onClick={() => alert("ok")}
                                />
                            </Box>
                        </Box>
                    )}
                    {generatedParticipations?.length > 0 && (
                        <Box display="flex">
                            <Stack spacing={3}>
                                <Typography variant="bold">Attestations générées</Typography>
                                {renderAttestations()}
                            </Stack>
                        </Box>
                    )}
                </Stack>
            </GenericAccordion>
            {completeSession && (
                <ViewSessionDialog session={completeSession} onClose={() => setCompleteSession(null)} />
            )}
            {openImportDialog && (
                <ImportDialog
                    onClose={() => setOpenImportDialog(false)}
                    onValid={importAttendanceSheet}
                    validateFile={validateFile}
                    title="Import de la feuille d'émargement"
                    acceptedFiles={DocumentMimeTypeFile}
                />
            )}
        </div>
    );
}
