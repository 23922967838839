// === NPM
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
    Checkbox,
    Grid,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
// === LOCAL
import GenericButton from "@/components/generics/buttons/GenericButton";
import { ReceiverUser } from "../../../interface";

interface TransferListProps {
    users: ReceiverUser[];
    setSelectedUsers: Dispatch<SetStateAction<ReceiverUser[]>>;
    selectedUsers: ReceiverUser[];
    errors: string;
    loading: boolean;
}

function not(usersList: ReceiverUser[], usersChecked: ReceiverUser[]) {
    return usersList.filter((value) => usersChecked.indexOf(value) === -1);
}

function intersection(usersChecked: ReceiverUser[], usersList: ReceiverUser[]) {
    return usersChecked.filter((value) => usersList.indexOf(value) !== -1);
}

export default function TransferList({
    users,
    setSelectedUsers,
    selectedUsers,
    errors,
    loading,
}: Readonly<TransferListProps>) {
    const [checked, setChecked] = useState<ReceiverUser[]>([]);
    const [usersLeft, setUsersLeft] = useState<ReceiverUser[]>([]);
    const [usersRight, setUsersRight] = useState<ReceiverUser[]>(selectedUsers ?? []);

    useEffect(() => {
        setUsersLeft(users.filter((item) => !selectedUsers.map((user) => user.id).includes(item.id)));
    }, [users]);

    useEffect(() => {
        setSelectedUsers(usersRight);
    }, [usersRight]);

    const leftChecked = intersection(checked, usersLeft);
    const rightChecked = intersection(checked, usersRight);

    const handleToggle = (value: ReceiverUser) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setUsersRight(usersRight.concat(usersLeft));
        setUsersLeft([]);
    };

    const handleCheckedRight = () => {
        setUsersRight(usersRight.concat(leftChecked));
        setUsersLeft(not(usersLeft, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setUsersLeft(usersLeft.concat(rightChecked));
        setUsersRight(not(usersRight, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setUsersLeft(usersLeft.concat(usersRight));
        setUsersRight([]);
    };

    const customList = (items: ReceiverUser[], loading?: boolean) => (
        <Paper variant="outlined" sx={{ width: "100%", height: 230, overflow: "auto" }}>
            {loading && <LinearProgress />}
            <List dense component="div" role="list">
                {items.map((value: ReceiverUser) => {
                    const labelId = `transfer-list-item-${value}-label`;

                    return (
                        <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        "aria-labelledby": labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                primary={` ${value.id} - ${value.lastname} ${value.firstname}`}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
    );

    return (
        <>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item md={5}>
                    <Typography>Résultats de la recherche</Typography>
                    {customList(usersLeft, loading)}
                </Grid>
                <Grid item md={2}>
                    <Stack direction="column" alignItems="center">
                        <GenericButton
                            onClick={handleAllRight}
                            label="≫"
                            disabled={usersLeft.length === 0}
                            aria-label="move all right"
                            sx={{ my: 0.5 }}
                        />
                        <GenericButton
                            onClick={handleCheckedRight}
                            label="&gt;"
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                            sx={{ my: 0.5 }}
                        />
                        <GenericButton
                            onClick={handleCheckedLeft}
                            label="&lt;"
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                            sx={{ my: 0.5 }}
                        />
                        <GenericButton
                            onClick={handleAllLeft}
                            label="≪"
                            disabled={usersRight.length === 0}
                            aria-label="move all left"
                            sx={{ my: 0.5 }}
                        />
                    </Stack>
                </Grid>
                <Grid item md={5}>
                    <Typography>Destinataires</Typography>
                    {customList(usersRight)}
                </Grid>
            </Grid>
            {errors && <Typography color="red">{errors}</Typography>}
        </>
    );
}
