// === NPM
import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Calendar } from "@/assets/icons/shared/calendar.svg";
import { ReactComponent as Location } from "@/assets/icons/shared/location.svg";
import { ReactComponent as User } from "@/assets/icons/shared/user.svg";
import GenericAccordion from "@/components/generics/layout/GenericAccordion";
import GenericTitleValueText from "@/components/generics/text/GenericTitleValueText";
import { ComplianceType, IVaccinationSite } from "@/interfaces/vaccination";
import { getEnumKeyByValue, toLocaleDateFormat } from "@/resources/utils";
import { IVaccinationSiteInformation } from "../VaccinationSite/interface";

interface InterventionAccordionProps {
    vaccinationSiteInformation: IVaccinationSiteInformation | IVaccinationSite;
    workshopId: string;
    complianceType: keyof typeof ComplianceType;
    complianceComment: string;
}

export default function InterventionAccordion({
    vaccinationSiteInformation,
    workshopId,
    complianceType,
    complianceComment,
}: Readonly<InterventionAccordionProps>) {
    return (
        <GenericAccordion title="Intervention de vaccination" defaultExpanded>
            <Grid container rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12} md={4} display="flex" gap={1}>
                    <Box>
                        <Calendar />
                    </Box>
                    <Stack spacing={1}>
                        <Box mt={-0.25}>
                            <Typography variant="bold" fontSize={18}>
                                Intervention
                            </Typography>
                        </Box>
                        {vaccinationSiteInformation.id && (
                            <GenericTitleValueText title="Chantier" value={vaccinationSiteInformation.id} />
                        )}
                        <GenericTitleValueText
                            title="Date de l'intervention"
                            value={toLocaleDateFormat(vaccinationSiteInformation.date)}
                        />
                        <GenericTitleValueText
                            title="Résultat du compte-rendu"
                            value={ComplianceType[complianceType]}
                        />
                        {complianceType !== getEnumKeyByValue(ComplianceType, ComplianceType.COMPLIANT) && (
                            <GenericTitleValueText
                                title="Motif de non conformité du compte-rendu"
                                value={complianceComment}
                            />
                        )}
                    </Stack>
                </Grid>
                <Grid item xs={12} md={3.5} display="flex" gap={1}>
                    <Box>
                        <User />
                    </Box>
                    <Stack spacing={1}>
                        <Box mt={-0.25}>
                            <Typography variant="bold" fontSize={18}>
                                Responsable de l'intervention
                            </Typography>
                        </Box>
                        <GenericTitleValueText
                            title="Vétérinaire"
                            value={`${vaccinationSiteInformation.veterinaryId} - ${vaccinationSiteInformation.veterinaryLastname} ${vaccinationSiteInformation.veterinaryFirstname}`}
                        />
                        <GenericTitleValueText
                            title="DPE"
                            value={`${vaccinationSiteInformation.dpeId} - ${vaccinationSiteInformation.dpeName} -\n ${vaccinationSiteInformation.dpePostalCode} ${vaccinationSiteInformation.dpeCity}`}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={4.5} display="flex" gap={1}>
                    <Box>
                        <Location />
                    </Box>
                    <Stack spacing={1}>
                        <Box mt={-0.25}>
                            <Typography variant="bold" fontSize={18}>
                                Lieu de l'intervention
                            </Typography>
                        </Box>
                        <GenericTitleValueText title="Établissement" value={vaccinationSiteInformation.farmName} />
                        <GenericTitleValueText
                            title="Identifiant de l'établissement"
                            value={vaccinationSiteInformation.farmId}
                        />
                        <GenericTitleValueText title="Identifiant atelier" value={workshopId} />
                    </Stack>
                </Grid>
            </Grid>
        </GenericAccordion>
    );
}
