import React from "react";
import { LoadingButton } from "@mui/lab";

interface ContextualActionButtonProps {
    onClick: any;
    label: string;
    disabled?: boolean;
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | "neutral";
    startIcon?: React.ReactElement;
    loading?: boolean;
}

export default function ContextualActionButton(props: ContextualActionButtonProps) {
    return (
        <LoadingButton
            loading={props.loading}
            onClick={props.onClick}
            disabled={props.disabled}
            color={props.color}
            loadingPosition={props.startIcon ? "start" : undefined}
            startIcon={props.startIcon}
            sx={{
                borderBottom: "2px solid",
                borderRadius: 0,
            }}
        >
            {props.label}
        </LoadingButton>
    );
}
