// === NPM
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";
// === LOCAL
import { AdapterCalypso } from "@/resources/AdapterCalypso";

interface GenericDateTimePickerProps {
    label: string;
    value: string;
    name: string;
    onChange: (targetEvent: { target: { name: string; value: string } }) => void;
    required?: boolean;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
    maxDateTime?: DateTime;
    minDateTime?: DateTime;
    size?: "small" | "medium";
    disableDate?: (date: DateTime) => boolean;
}

export default function GenericDateTimePicker({
    label,
    onChange,
    name,
    value,
    error,
    helperText,
    required,
    disabled,
    maxDateTime,
    minDateTime,
    size,
    disableDate,
}: Readonly<GenericDateTimePickerProps>) {
    const [date, setDate] = useState<DateTime>(value ? DateTime.fromISO(value) : null);

    const handleChange = (event: DateTime | null) => {
        const targetEvent = {
            target: {
                name,
                value: event ? event.toISO() : "",
            },
        };
        setDate(event);
        onChange(targetEvent);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterCalypso} adapterLocale={"fr-FR"}>
            <DateTimePicker
                label={label}
                value={date}
                disabled={disabled}
                inputFormat="dd/MM/yyyy HH:mm"
                onChange={handleChange}
                maxDate={maxDateTime}
                minDate={minDateTime}
                shouldDisableDate={disableDate}
                shouldDisableMonth={disableDate}
                shouldDisableYear={disableDate}
                renderInput={(params) => (
                    <TextField
                        variant="outlined"
                        {...params}
                        error={error}
                        helperText={helperText}
                        required={required}
                        fullWidth
                        size={size}
                    />
                )}
            />
        </LocalizationProvider>
    );
}
