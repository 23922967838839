// === Import: NPM
import React from "react";
import { Box, Stack } from "@mui/material";
// === Import: LOCAL
import { GenericText } from "./GenericText";

interface GenericTitleValueTextProps {
    value: string | number;
    title: string;
    boldTitle?: boolean;
    icon?: React.ReactElement;
}
export default function GenericTitleValueText({
    value,
    title,
    boldTitle = true,
    icon,
}: Readonly<GenericTitleValueTextProps>) {
    return icon ? (
        <Stack direction="row" spacing={1} alignItems="center">
            {icon}
            <GenericText bold={boldTitle} value={`${title}\u00A0: `} />
            <GenericText value={value} />
        </Stack>
    ) : (
        <Box>
            <GenericText bold={boldTitle} value={`${title}\u00A0: `} />
            <GenericText value={value} />
        </Box>
    );
}
