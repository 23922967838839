// === NPM
import React, { Dispatch, SetStateAction } from "react";
import { CardHeader } from "@mui/material";
// === LOCAL
import { ReactComponent as Settings } from "@/assets/icons/shared/settings.svg";
import IconActionButton from "@/components/generics/actions/IconActionButton";
import { colors } from "@/resources/CssConstant";

interface TileTitleProps {
    title: string;
    setOpenManageDialog: Dispatch<SetStateAction<boolean>>;
}

export default function TileTitle({ title, setOpenManageDialog }: Readonly<TileTitleProps>) {
    return (
        <CardHeader
            titleTypographyProps={{ fontSize: 20, fontWeight: 500, color: colors.primaryColor }}
            title={title}
            action={
                <IconActionButton
                    icon={<Settings color="primary" />}
                    title="Accéder à la gestion des versions"
                    onClick={() => setOpenManageDialog(true)}
                />
            }
        />
    );
}
