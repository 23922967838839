// === NPM
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { TransitionGroup } from "react-transition-group";
import { Add } from "@mui/icons-material";
import { Collapse, Skeleton, Stack } from "@mui/material";
import { AxiosResponse } from "axios";
// === LOCAL
import GenericButton from "@/components/generics/buttons/GenericButton";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import { HttpStatus } from "@/interfaces/global";
import BillingService from "@/services/BillingService";
import { IChargeableCreateUpdate, IChargeableRequiredFields, IChargeableVersion } from "../../../../interface";
import ChargeableVersionForm from "./containers/ChargeableVersionForm";
import ChargeableVersionTile from "./containers/ChargeableVersionTile";

interface ChargeableVersionsDialogProps {
    chargeableKey: string;
    title: string;
    onClose: () => void;
    refreshData: () => void;
}

export default function ChargeableVersionsDialog({
    chargeableKey,
    title,
    onClose,
    refreshData,
}: Readonly<ChargeableVersionsDialogProps>) {
    const [versions, setVersions] = useState<IChargeableVersion[]>([]);
    const [requiredFields, setRequiredFields] = useState<IChargeableRequiredFields>({
        amv: false,
        externalMultiplier: false,
        externalMultiplierDescription: "",
    });
    const [isAdding, setIsAdding] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        getChargeableVersions();
        getRequiredFields();
    }, []);

    const getChargeableVersions = async () => {
        setLoading(true);
        const res = await BillingService.getChargeablesVersions(chargeableKey);
        if (res.status === HttpStatus.OK) {
            setVersions(res.data);
        }
        setLoading(false);
    };

    const getRequiredFields = async () => {
        setLoading(true);
        const res = await BillingService.getChargeableRequiredFields(chargeableKey);
        if (res.status === HttpStatus.OK) {
            setRequiredFields(res.data);
        }
        setLoading(false);
    };

    const handleSuccess = (res: AxiosResponse, status: HttpStatus, message: string) => {
        if (res.status === status) {
            toast.success(message);
            getChargeableVersions();
            refreshData();
        }
    };

    const createVersion = async (data: IChargeableCreateUpdate) => {
        const res = await BillingService.createChargeableVersion(chargeableKey, data);
        handleSuccess(res, HttpStatus.CREATED, "Vous avez ajouté une nouvelle version");
        setIsAdding(false);
    };

    const updateVersion = async (data: IChargeableCreateUpdate, uuid: string) => {
        const res = await BillingService.updateChargeableVersion(uuid, data);
        handleSuccess(res, HttpStatus.NO_CONTENT, "Vos modifications ont bien été enregistrées");
    };

    const deleteVersion = async (uuid: string) => {
        const res = await BillingService.deleteChargeableVersion(uuid);
        handleSuccess(res, HttpStatus.NO_CONTENT, "La version a bien été supprimée");
    };

    const renderContent = () =>
        loading ? (
            <Skeleton variant="rounded" width="100%" height="150px" sx={{ borderRadius: "30px" }} />
        ) : (
            <Stack alignItems="center" spacing={2}>
                <GenericButton
                    onClick={() => setIsAdding(true)}
                    label="Ajouter une version"
                    startIcon={<Add />}
                    disabled={isAdding}
                />
                <TransitionGroup style={{ display: "flex", flexDirection: "column", width: "100%", gap: "10px" }}>
                    {isAdding && (
                        <Collapse>
                            <ChargeableVersionForm
                                version={{
                                    uuid: null,
                                    version: versions.length + 1,
                                    startDate: null,
                                    active: true,
                                    vatKey: null,
                                    used: false,
                                }}
                                previousValues={versions[0]}
                                onCancel={() => setIsAdding(false)}
                                onSubmit={createVersion}
                                requiredFields={requiredFields}
                            />
                        </Collapse>
                    )}
                    {versions.map((v, index) => (
                        <Collapse key={v.uuid}>
                            <ChargeableVersionTile
                                version={v}
                                previousValues={versions[index + 1]}
                                nextValues={versions[index - 1]}
                                updateVersion={updateVersion}
                                deleteVersion={deleteVersion}
                                requiredFields={requiredFields}
                            />
                        </Collapse>
                    ))}
                </TransitionGroup>
            </Stack>
        );

    return (
        <GenericDialog
            title={`Gestion des versions de ${title}`}
            onClose={onClose}
            renderContent={() => renderContent()}
            renderActions={() => <GenericActionsDialog onClose={onClose} displaySubmit={false} closeLabel="Fermer" />}
        />
    );
}
