// === NPM
import React, { useState } from "react";
import { Checkbox, FormControl, FormControlLabel, FormGroup, Stack } from "@mui/material";
// === LOCAL
import GenericTextField from "../../inputs/GenericTextField";
import { FilterConfiguration } from "../GenericFilters";

interface SelectWithAutocompleteProps {
    config: FilterConfiguration;
    value: string[];
    handleSelectFilter: (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    name: string;
}

export default function SelectWithAutocomplete({
    config,
    value,
    handleSelectFilter,
    name,
}: Readonly<SelectWithAutocompleteProps>) {
    const [text, setText] = useState<string>("");

    return (
        <Stack p={2}>
            <GenericTextField
                value={text}
                onChange={(event) => setText(event.target.value)}
                label=""
                size="small"
                variant="standard"
                placeholder="Rechercher..."
            />
            <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
                <FormGroup>
                    {config.values
                        .filter((f) => f.label.toLowerCase().includes(text.toLowerCase()))
                        .sort((v1, v2) => v1.label.localeCompare(v2.label))
                        .map((v, i) => {
                            return (
                                <FormControlLabel
                                    key={`${v.key}-${i}`}
                                    control={
                                        <Checkbox
                                            checked={value.includes(v.key)}
                                            onChange={handleSelectFilter(name)}
                                            name={v.key}
                                        />
                                    }
                                    label={v.label}
                                />
                            );
                        })}
                </FormGroup>
            </FormControl>
        </Stack>
    );
}
