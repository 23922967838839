// === NPM
import React from "react";
import { Outlet } from "react-router-dom";
// === LOCAL
import PageContent from "@/components/generics/layout/PageContent";
import PermissionsTabs from "@/resources/PermissionsTabs";
import { routerLinks } from "@/routers/RouterConstant";
import { FormDeliveryProvider } from "./containers/FormManager/containers/useFormDelivery";

export default function ManualDelivery() {
    return (
        <>
            <PermissionsTabs
                tabs={[
                    {
                        url: routerLinks.delivery.manualDelivery.view(),
                        label: "Consultation des saisies manuelles",
                    },
                    {
                        url: routerLinks.delivery.manualDelivery.form(),
                        label: "Déclaration de saisie manuelle",
                    },
                ]}
            />
            <PageContent>
                <FormDeliveryProvider>
                    <Outlet />
                </FormDeliveryProvider>
            </PageContent>
        </>
    );
}
