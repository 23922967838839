// === NPM
import { ListItemButton } from "@mui/material";
import { styled } from "@mui/material/styles";
// === LOCAL
import { drawerColors } from "@/resources/CssConstant";

export const DrawerListItemButton = styled(ListItemButton)({
    "justifyContent": "center",
    "marginBottom": 2,
    "borderRadius": 10,
    "&.Mui-selected": {
        backgroundColor: drawerColors.background,
    },
});
