// === NPM
import React from "react";
import { Outlet } from "react-router-dom";
// === LOCAL
import PageContent from "@/components/generics/layout/PageContent";
import { Preference, UserSituation } from "@/resources/PermissionConstant";
import PermissionsTabs from "@/resources/PermissionsTabs";
import { routerLinks } from "@/routers/RouterConstant";

export default function ButcherySector() {
    return (
        <>
            <PermissionsTabs
                tabs={[
                    {
                        url: routerLinks.sanitaryFollowUp.butcherySector.exclusion(),
                        label: "Exclusion de la filière bouchère",
                    },
                    {
                        url: routerLinks.sanitaryFollowUp.butcherySector.search(),
                        label: "Recherche équidés",
                        permissions: [UserSituation.REGISTERED_IN_PRACTICE],
                        preferences: [Preference.BUTCHERY_SECTOR],
                    },
                ]}
            />
            <PageContent>
                <Outlet />
            </PageContent>
        </>
    );
}
