// === NPM
import React, { RefObject, useEffect, useRef, useState } from "react";
import { AddRounded } from "@mui/icons-material";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
// === LOCAL
import GenericButton from "@/components/generics/buttons/GenericButton";
import { IHealthReportingForm, IReportedAnomaly } from "@/components/HealthReporting/AnimalAbuse/interface";
import { isArrayTruthy } from "@/resources/utils";
import { useFormHealthReporting } from "../../../useFormHealthReporting";
import Anomaly from "./Anomaly";

interface ReportedAnomaliesProps {
    formRef: RefObject<HTMLButtonElement>;
    getValuesRef: RefObject<HTMLButtonElement>;
    handleNextStep: (partialValues: Partial<IHealthReportingForm>, finalStep?: boolean) => void;
    setSaveAndQuit: (value: boolean) => void;
}

const anomalyInitialValues: IReportedAnomaly = {
    anomalyTypeUuid: undefined,
    species: undefined,
    animalCount: null,
    individualIdentification: "",
};

export default function ReportedAnomalies({
    formRef,
    getValuesRef,
    handleNextStep,
    setSaveAndQuit,
}: Readonly<ReportedAnomaliesProps>) {
    const { form, setForm } = useFormHealthReporting();
    const [anomalies, setAnomalies] = useState<IReportedAnomaly[]>(
        form?.reportedAnomalies
            ? form?.reportedAnomalies.map((anomaly) => ({ ...anomaly, internalId: crypto.randomUUID() }))
            : [
                  {
                      ...anomalyInitialValues,
                      internalId: crypto.randomUUID(),
                  },
              ]
    );
    const [validAnomalies, setValidAnomalies] = useState<boolean[]>([false]);
    const [validSaveDraft, setValidSaveDraft] = useState<boolean[]>([]);

    const anomalyRef = useRef<Record<number, HTMLButtonElement>>({});
    const anomalyGetValuesRef = useRef<Record<number, HTMLButtonElement>>({});

    useEffect(() => {
        setValidAnomalies(Array(anomalies.length).fill(false));
        setValidSaveDraft(Array(anomalies.length).fill(false));
    }, [anomalies.length]);

    useEffect(() => {
        setForm((prev) => ({ ...prev, reportedAnomalies: anomalies }));
    }, [anomalies]);

    useEffect(() => {
        const draftValid = isArrayTruthy(validSaveDraft);
        if (draftValid) {
            setSaveAndQuit(true);
        }
    }, [validSaveDraft]);

    useEffect(() => {
        const anomaliesAreValid = isArrayTruthy(validAnomalies);
        if (anomaliesAreValid) {
            handleNextStep({ reportedAnomalies: anomalies });
        }
    }, [validAnomalies]);

    const handleAddAnomaly = () => {
        const newAnomalies = [...anomalies, { ...anomalyInitialValues, internalId: crypto.randomUUID() }];
        setAnomalies(newAnomalies);
    };

    const handleUpdateAnomaly = (internalId: string, anomaly: IReportedAnomaly) => {
        setAnomalies((prev) => prev.map((a) => (a.internalId === internalId ? { ...anomaly, internalId } : a)));
    };

    const handleDeleteAnomaly = (internalId: string) => {
        const newAnomalies = anomalies.filter((anomaly) => anomaly.internalId !== internalId);
        setAnomalies(newAnomalies);
    };

    const submitAnomalies = () => {
        setValidAnomalies(Array(anomalies.length).fill(false));
        Object.values(anomalyRef.current).forEach((anomaly) => anomaly?.click());
    };

    const saveDraftAnomalies = () => {
        Object.values(anomalyGetValuesRef.current).forEach((anomaly) => anomaly?.click());
    };

    return (
        <Stack spacing={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
                <Typography variant="h5">Anomalies remarquées</Typography>
                <Tooltip title={anomalies.length >= 10 ? "Vous êtes limité à 10 anomalies" : ""}>
                    <span>
                        <GenericButton
                            label="Ajouter une anomalie"
                            startIcon={<AddRounded />}
                            onClick={handleAddAnomaly}
                            disabled={anomalies.length >= 10}
                        />
                    </span>
                </Tooltip>
            </Box>

            {anomalies?.map((anomaly, index) => (
                <Anomaly
                    key={anomaly.internalId}
                    anomalyRef={anomalyRef}
                    anomalyGetValuesRef={anomalyGetValuesRef}
                    index={index}
                    handleUpdateAnomaly={handleUpdateAnomaly}
                    handleDeleteAnomaly={handleDeleteAnomaly}
                    anomaliesLength={anomalies.length}
                    initialValues={anomaly}
                    setValidAnomalies={setValidAnomalies}
                    setValidSaveDraft={setValidSaveDraft}
                />
            ))}
            <button style={{ display: "none" }} type="submit" ref={formRef} onClick={() => submitAnomalies()} />
            <button style={{ display: "none" }} ref={getValuesRef} onClick={() => saveDraftAnomalies()} />
        </Stack>
    );
}
