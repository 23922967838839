// === NPM
import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterLuxon } from "@mui/x-date-pickers-pro/AdapterLuxon";
import { DateTime } from "luxon";
import { toLocaleDateFormat } from "@/resources/utils";

interface DateFilterProps {
    value: string;
    name: string;
    maxDate?: string;
    minDate?: string;
    onChange: (event, name: string) => void;
}

export default function DateFilter({ value, name, onChange, maxDate, minDate }: Readonly<DateFilterProps>) {
    const [date, setDate] = useState<DateTime>(null);
    const [intervalError, setIntervalError] = useState<string>(null);

    useEffect(() => {
        const newDate = value ? DateTime.fromISO(value) : null;
        setDate(newDate);
    }, [value]);

    const handleChange = (event: DateTime) => {
        if (event < DateTime.fromISO(minDate)) {
            setIntervalError(`Veuillez saisir une date supérieure au ${toLocaleDateFormat(minDate)}`);
            return;
        }
        if (event > DateTime.fromISO(maxDate)) {
            setIntervalError(`Veuillez saisir une date inférieure au ${toLocaleDateFormat(maxDate)}`);
            return;
        }
        if (event.isValid) {
            const targetEvent = {
                target: {
                    name,
                    value: event ? event.toISODate() : "",
                },
            };
            onChange(targetEvent, name);
        }
        setIntervalError(null);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="FR-fr">
            <DatePicker
                value={date}
                onChange={(event) => {
                    handleChange(event);
                }}
                maxDate={DateTime.fromISO(maxDate)}
                minDate={DateTime.fromISO(minDate)}
                renderInput={(params) => (
                    <TextField size="small" {...params} error={!!intervalError} helperText={intervalError} />
                )}
            />
        </LocalizationProvider>
    );
}
