import { INotificationCore } from "@/interfaces/notification";
import { IReferential } from "@/interfaces/referential";
import { UserType } from "@/interfaces/user";

export enum NotificationStatus {
    CREATED = "Créée",
    SCHEDULED = "Programmée",
    PUBLISHED = "Publiée",
    ARCHIVED = "Archivée",
}

export enum CampaignStatus {
    SUCCESS = "Succès",
    ERROR = "Erreur",
    PARTIAL_SUCCESS = "Succès partiel",
}

export enum SendMode {
    MAIL = "Mail",
    CALYPSO_APPLICATION = "Notification Calypso",
    BOTH = "Mail et Notification Calypso",
}

export enum BroadcastType {
    GENERIC = "Générique",
    TARGETED = "Ciblée",
}

export enum AllowedTargetType {
    fam_dam = "Fabricant d'aliment",
    veterinary = "Vétérinaire",
    pharmacist = "Pharmacien",
    VIMS = "Editeurs de VIMS",
    TRAINING_ORGANIZATION = "Organismes de formation",
    ALL = "Tous les utilisateurs Calypso",
}

export enum AllowedTargetSituation {
    registered_in_practice = "Inscrit en exercice",
    public_sector_veterinarian = "Vétérinaire secteur public",
    third_countries_veterinary_registered_diploma = "Vétérinaire pays tiers et diplôme enregistré",
    fam_dam_user = "Déclarant FAM/DAM",
    fam_dam_admin = "Administrateur FAM/DAM",
    pharmacist = "Pharmacien",
}

export interface IAllowedTarget {
    type: string;
    situations: string[];
}

export enum NotificationType {
    MANUAL = "MANUAL",
    AUTO = "AUTO",
}

export enum AuthorType {
    ADMIN_CALYPSO = "Administrateur Calypso",
    ANMV_NOTIF = "ANMV Notif",
}

export interface INotificationShort extends INotificationCore {
    status: NotificationStatus;
    campaignStatus: CampaignStatus;
    internalId: string;
    sendMode: SendMode;
    broadcastType: BroadcastType;
    authorId: string;
    authorFirstname: string;
    authorLastname: string;
}

export interface ReceiverUser {
    id: string;
    firstname: string;
    lastname: string;
    type: string;
}

export interface INotificationFull {
    uuid: string;
    internalId: string;
    sendMode: string;
    broadcastType: string;
    displayType: string;
    status: NotificationStatus;
    campaignStatus: CampaignStatus;
    subject: string;
    publicationDate: string;
    publicationEndDate: string;
    authorId: string;
    authorType: UserType;
    authorFirstname: string;
    authorLastname: string;
    content: string;
    attachments: IAttachment[];
    receiverUsers: ReceiverUser[];
    receiverGroups: IReferential[];
    readCount: number;
}

export interface NotificationFullForm extends INotificationFull {
    receiversType: string;
    receiversUserIds: string[];
    receiversSituationIds: string[];
}

export type NotificationPublish = Pick<INotificationShort, "publicationDate">;

export interface INotificationCreateUpdate {
    sendMode: string;
    broadcastType: string;
    displayType: string;
    status: string;
    subject: string;
    content: string;
    publicationDate: string;
    publicationEndDate: string;
    receiversUserIds: string[];
    receiversSituationIds: string[];
}

export interface IPublishedNotificationPatch {
    publicationEndDate: string;
}

export interface NotificationFilters {
    status: string[];
    campaignStatus: string[];
    internalId: string;
    displayType: string[];
    sendMode: string[];
    broadcastType: string[];
    subject: string;
    authorType: string[];
    authorLastname: string;
    authorFirstname: string;
    authorId: string;
    publicationDate: string[];
    publicationEndDate: string[];
}

export interface NotificationContext {
    allowedTargets: IAllowedTarget[];
}

export interface IAttachment {
    filename: string;
    uuid: string;
}

export interface IConfig {
    key: string;
    publicationDayDelay: number;
    manageable: boolean;
    sendMode: keyof typeof SendMode;
}
