// === NPM
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Stack } from "@mui/material";
import { DateTime } from "luxon";
// === LOCAL
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import { HttpStatus } from "@/interfaces/global";
import { getEnumKeyByValue, nullifyEmptyFields } from "@/resources/utils";
import { routerLinks } from "@/routers/RouterConstant";
import NotificationService from "@/services/NotificationService";
import { INotificationCreateUpdate, NotificationStatus, ReceiverUser, SendMode } from "../../interface";
import { useSelectedNotification } from "../useSelectedNotification";
import FormStepper from "./containers/FormStepper";
import GeneralInformations from "./containers/GeneralInformations/GeneralInformations";
import NotificationContent from "./containers/NotificationContent/NotificationContent";
import PublicationDates from "./containers/PublicationDates/PublicationDates";
import Recipients from "./containers/Recipients/Recipients";

const initialValues: INotificationCreateUpdate = {
    displayType: "",
    sendMode: getEnumKeyByValue(SendMode, SendMode.CALYPSO_APPLICATION),
    broadcastType: "",
    subject: null,
    content: "",
    receiversUserIds: [],
    receiversSituationIds: [],
    publicationDate: DateTime.now().plus({ hours: 1 }).toISO(),
    publicationEndDate: DateTime.now().plus({ months: 2 }).toISO(),
    status: getEnumKeyByValue(NotificationStatus, NotificationStatus.CREATED),
};

export function Form() {
    const navigate = useNavigate();

    const { selectedNotification, setSelectedNotification } = useSelectedNotification();

    const formRef = useRef<HTMLButtonElement>(null);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [openConfirmCancelDialog, setOpenConfirmCancelDialog] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [form, setForm] = useState<INotificationCreateUpdate>(
        selectedNotification
            ? {
                  sendMode: selectedNotification.sendMode,
                  broadcastType: selectedNotification.broadcastType,
                  displayType: selectedNotification.displayType,
                  status: selectedNotification.status,
                  subject: selectedNotification.subject,
                  content: selectedNotification.content,
                  publicationDate: selectedNotification.publicationDate,
                  publicationEndDate: selectedNotification.publicationEndDate,
                  receiversUserIds: selectedNotification.receiverUsers.map((item) => item.id),
                  receiversSituationIds: selectedNotification.receiverGroups.map((item) => item.key),
              }
            : initialValues
    );
    const [files, setFiles] = useState<File[]>([]);
    const [receiversUsers, setReceiversUsers] = useState<ReceiverUser[]>(
        selectedNotification ? selectedNotification.receiverUsers : []
    );
    const [selectedReceiversType, setSelectedReceiversType] = useState<string>(
        selectedNotification?.receiverUsers.length > 0 ? selectedNotification.receiverUsers[0].type : ""
    );

    useEffect(() => {
        getNotificationFiles();
    }, []);

    const getNotificationFile = async (fileList: File[], fileUuid: string) => {
        const res = await NotificationService.getUserNotificationFile(selectedNotification.uuid, fileUuid);
        if (res.status === HttpStatus.OK) {
            const file = new Blob([res.data], { type: res.headers["content-type"] });
            const fileName = res.headers["content-disposition"].split("filename=")[1].slice(1, -1);
            const newFile = new File([file], fileName, {
                type: res.headers["content-type"],
            });
            fileList.push(newFile);
        }
    };

    const getNotificationFiles = async () => {
        setLoading(true);
        const fileList: File[] = [];
        selectedNotification &&
            Promise.all(
                selectedNotification?.attachments?.map((attachment) => getNotificationFile(fileList, attachment.uuid))
            );
        setFiles(fileList);
        setLoading(false);
    };

    const createNotification = async (notification: INotificationCreateUpdate) => {
        setLoading(true);
        notification = nullifyEmptyFields(notification) as INotificationCreateUpdate;
        const res = await NotificationService.postNotification({ ...notification }, files ?? null);
        if (res.status === HttpStatus.CREATED) {
            navigate(routerLinks.admin.notifications.gestion());
            toast.success("Notification créée avec succès");
        }
        setLoading(false);
    };

    const updateNotification = async (uuid: string, notification: INotificationCreateUpdate, files?: File[]) => {
        setLoading(true);
        notification = nullifyEmptyFields(notification) as INotificationCreateUpdate;
        const res = await NotificationService.putNotification(uuid, notification, files ?? null);
        if (res.status === HttpStatus.OK) {
            navigate(routerLinks.admin.notifications.gestion());
            toast.success("Notification mise à jour avec succès");
        }
        setLoading(false);
    };

    const handleNextStep = () => {
        setActiveStep(activeStep + 1);
    };

    const handlePreviousStep = () => {
        setActiveStep(activeStep - 1);
    };

    const handleSubmit = async (data: { publicationDate: string; publicationEndDate: string }) => {
        if (selectedNotification) {
            updateNotification(selectedNotification.uuid, { ...form, ...data }, files);
        } else {
            createNotification({ ...form, ...data });
        }
    };

    const renderSteps = () => {
        switch (activeStep) {
            case 0:
                return (
                    <GeneralInformations
                        formRef={formRef}
                        onValid={handleNextStep}
                        form={form}
                        setForm={setForm}
                        selectedReceiversType={selectedReceiversType}
                        setSelectedReceiversType={setSelectedReceiversType}
                        setFiles={setFiles}
                    />
                );
            case 1:
                return (
                    <NotificationContent
                        formRef={formRef}
                        onValid={handleNextStep}
                        form={form}
                        setForm={setForm}
                        files={files}
                        setFiles={setFiles}
                    />
                );
            case 2:
                return (
                    <Recipients
                        formRef={formRef}
                        onValid={handleNextStep}
                        form={form}
                        setForm={setForm}
                        selectedReceiversType={selectedReceiversType}
                        receiversUsers={receiversUsers}
                        setReceiversUsers={setReceiversUsers}
                    />
                );
            case 3:
                return <PublicationDates formRef={formRef} onValid={handleSubmit} form={form} />;
        }
    };

    const handleClose = (confirm: boolean) => {
        if (confirm) {
            setSelectedNotification(null);
            navigate(routerLinks.admin.notifications.gestion());
        } else {
            setOpenConfirmCancelDialog(false);
        }
    };

    return (
        <Stack height="100%" width="100%" spacing={2}>
            <FormStepper
                activeStep={activeStep}
                handleNextStep={() => {
                    formRef.current.click();
                }}
                handlePreviousStep={handlePreviousStep}
                setForm={setForm}
                setOpenConfirmCancelDialog={setOpenConfirmCancelDialog}
                loading={loading}
            >
                <Box sx={{ flexGrow: 1, my: 4 }}>{renderSteps()}</Box>
            </FormStepper>
            {openConfirmCancelDialog && (
                <GenericConfirmDialog
                    title="Annuler la saisie"
                    message="Êtes-vous sûr de vouloir annuler ? Vous allez perdre toutes les informations saisies."
                    onClose={handleClose}
                />
            )}
        </Stack>
    );
}
