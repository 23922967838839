// === NPM
import React from "react";
import { useOutletContext } from "react-router-dom";
import { Stack } from "@mui/material";
// === LOCAL
import { FaqOutletContext } from "../../interface";
import Category from "./containers/Category";
import CategoryAndTagSkeleton from "./containers/CategoryAndTagSkeleton";
import Tag from "./containers/Tag";

export default function CategoryAndTag() {
    const { loading } = useOutletContext<FaqOutletContext>();

    return loading ? (
        <CategoryAndTagSkeleton />
    ) : (
        <Stack height="100%" spacing={8} width="100%">
            <Category />
            <Tag />
        </Stack>
    );
}
