// === NPM
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { GridSortModel } from "@mui/x-data-grid-pro";
// === LOCAL
import { ReactComponent as Billable } from "@/assets/icons/billing/billable.svg";
import { ReactComponent as InMemory } from "@/assets/icons/billing/in_memory.svg";
import { ReactComponent as InstructionOngoing } from "@/assets/icons/billing/instruction_ongoing.svg";
import { ReactComponent as New } from "@/assets/icons/billing/new.svg";
import { ReactComponent as Rejected } from "@/assets/icons/billing/rejected.svg";
import { ReactComponent as ToPay } from "@/assets/icons/billing/to_pay.svg";
import { ReactComponent as Validated } from "@/assets/icons/billing/validated.svg";
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import {
    GenericPresetFilters,
    PresetFilterConfigurations,
} from "@/components/generics/presetFilters/GenericPresetFilters";
import { useDepartments } from "@/context/useDepartmentContext";
import useTimeout from "@/hooks/useTimeout";
import { FilterType, HttpStatus, IPagination } from "@/interfaces/global";
import { VaccinationSiteUserType } from "@/interfaces/vaccination";
import { CALYPSO_HEADERS, defaultVaccinationPagination } from "@/resources/AppConstant";
import { UserSituation } from "@/resources/PermissionConstant";
import {
    convertEnumToKeyLabelObject,
    createPayload,
    getEnumKeyByValue,
    hasAccess,
    toLocaleDateFormat,
} from "@/resources/utils";
import { useAuth } from "@/routers/useAuth";
import VaccinationService from "@/services/VaccinationService";
import VaccinationInterventionTable from "../../containers/VaccinationInterventionTable";
import {
    BillingStatus,
    IBillable,
    IBillableCounts,
    IBillableFilters,
    IBillableUserAdminDdppCounts,
    IBillableVetCounts,
    IBillingContext,
} from "../interface";
import VaccinationSitesDataGrid from "./containers/VaccinationSitesDataGrid";

export default function VaccinationSitesWithinCalypso() {
    const auth = useAuth();
    const { departments } = useDepartments();
    const billingStatuses = useOutletContext<IBillingContext>().billingStatuses.filter(
        (s) => s.key !== getEnumKeyByValue(BillingStatus, BillingStatus.OUTSIDE_CALYPSO)
    );
    const ddppBillingStatuses = billingStatuses.filter(
        (s) =>
            s.key !== getEnumKeyByValue(BillingStatus, BillingStatus.NEW) &&
            s.key !== getEnumKeyByValue(BillingStatus, BillingStatus.BILLABLE)
    );
    const isAdminDdppOrUserDdppOrAdminCalypso = hasAccess(auth.userInfo.situation, [
        UserSituation.USER_DDPP,
        UserSituation.ADMIN_DDPP,
        UserSituation.ADMIN_CALYPSO,
    ]);
    const initialValues = {
        id: "",
        date: [],
        userType: [],
        dpe: "",
        veterinary: "",
        departmentInseeCode: [],
        farmId: "",
        billingStatus: [],
    };
    const initialBillingStatusCountValues: IBillableCounts = {
        paymentRequested: 0,
        instructionOngoing: 0,
        validated: 0,
        inPaymentMemory: 0,
        rejected: 0,
        newVaccinationSite: 0,
        billable: 0,
    };

    const [billables, setBillables] = useState<IBillable[]>([]);
    const [billingCounts, setBillingCounts] = useState<IBillableCounts>(initialBillingStatusCountValues);
    const [inputFilters, setInputFilters] = useState<IBillableFilters>(initialValues);
    const [pagination, setPagination] = useState<IPagination>(defaultVaccinationPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "id", sort: "desc" }]);
    const [vaccinationSiteId, setVaccinationSiteId] = useState<number>(null);
    const [vaccinationSiteDate, setVaccinationSiteDate] = useState<string>(null);

    useEffect(() => {
        getBillableCount();
        getBillables();
    }, [pagination, sortModel]);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    const getBillables = async () => {
        const res = await VaccinationService.getBillables(false, {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        });
        if (res.status === HttpStatus.OK) {
            setBillables(res.data);
            setRowCount(+res.headers[CALYPSO_HEADERS.TABLE_COUNT]);
            setVaccinationSiteId(null);
            setVaccinationSiteDate(null);
        }
    };

    const getBillableCount = async () => {
        const res = await VaccinationService.getBillableCount();
        if (res.status === HttpStatus.OK) {
            setBillingCounts(res.data);
        }
    };

    const userAdminDdppPresetFilterConfiguration: PresetFilterConfigurations<
        IBillableUserAdminDdppCounts,
        IBillableFilters
    > = {
        paymentRequested: {
            icon: <ToPay />,
            title: BillingStatus.PAYMENT_REQUESTED,
            value: billingCounts?.paymentRequested,
            config: {
                ...initialValues,
                billingStatus: [getEnumKeyByValue(BillingStatus, BillingStatus.PAYMENT_REQUESTED)],
            },
        },
        instructionOngoing: {
            icon: <InstructionOngoing />,
            title: BillingStatus.INSTRUCTION_ONGOING,
            value: billingCounts?.instructionOngoing,
            config: {
                ...initialValues,
                billingStatus: [getEnumKeyByValue(BillingStatus, BillingStatus.INSTRUCTION_ONGOING)],
            },
        },
        validated: {
            icon: <Validated />,
            title: BillingStatus.VALIDATED,
            value: billingCounts?.validated,
            config: {
                ...initialValues,
                billingStatus: [getEnumKeyByValue(BillingStatus, BillingStatus.VALIDATED)],
            },
        },
        inPaymentMemory: {
            icon: <InMemory />,
            title: "Dans un mémoire de paiement",
            value: billingCounts?.inPaymentMemory,
            config: {
                ...initialValues,
                billingStatus: [
                    getEnumKeyByValue(BillingStatus, BillingStatus.TO_PAY),
                    getEnumKeyByValue(BillingStatus, BillingStatus.PAID),
                ],
            },
        },
        rejected: {
            icon: <Rejected />,
            title: BillingStatus.REJECTED,
            value: billingCounts?.rejected,
            config: {
                ...initialValues,
                billingStatus: [getEnumKeyByValue(BillingStatus, BillingStatus.REJECTED)],
            },
        },
    };
    const userVetPresetFilterConfiguration: PresetFilterConfigurations<IBillableVetCounts, IBillableFilters> = {
        newVaccinationSite: {
            icon: <New />,
            title: BillingStatus.NEW,
            value: billingCounts?.newVaccinationSite,
            config: {
                ...initialValues,
                billingStatus: [getEnumKeyByValue(BillingStatus, BillingStatus.NEW)],
            },
        },
        billable: {
            icon: <Billable />,
            title: BillingStatus.BILLABLE,
            value: billingCounts?.billable,
            config: {
                ...initialValues,
                billingStatus: [getEnumKeyByValue(BillingStatus, BillingStatus.BILLABLE)],
            },
        },
        paymentRequested: {
            icon: <ToPay />,
            title: BillingStatus.PAYMENT_REQUESTED,
            value: billingCounts?.paymentRequested,
            config: {
                ...initialValues,
                billingStatus: [getEnumKeyByValue(BillingStatus, BillingStatus.PAYMENT_REQUESTED)],
            },
        },
        validated: {
            icon: <Validated />,
            title: BillingStatus.VALIDATED,
            value: billingCounts?.validated,
            config: {
                ...initialValues,
                billingStatus: [getEnumKeyByValue(BillingStatus, BillingStatus.VALIDATED)],
            },
        },
        inPaymentMemory: {
            icon: <InMemory />,
            title: "Dans un mémoire de paiement",
            value: billingCounts?.inPaymentMemory,
            config: {
                ...initialValues,
                billingStatus: [
                    getEnumKeyByValue(BillingStatus, BillingStatus.TO_PAY),
                    getEnumKeyByValue(BillingStatus, BillingStatus.PAID),
                ],
            },
        },
        rejected: {
            icon: <Rejected />,
            title: BillingStatus.REJECTED,
            value: billingCounts?.rejected,
            config: {
                ...initialValues,
                billingStatus: [getEnumKeyByValue(BillingStatus, BillingStatus.REJECTED)],
            },
        },
    };
    const filterConfigurations: FilterConfigurations<IBillableFilters> = {
        id: {
            label: "Identifiant chantier",
            type: FilterType.NUMBER,
        },
        billingStatus: {
            label: "Statut facturation",
            type: FilterType.SELECT,
            values: hasAccess(auth.userInfo.situation, [UserSituation.USER_DDPP, UserSituation.ADMIN_DDPP])
                ? ddppBillingStatuses
                : billingStatuses,
        },
        date: {
            label: "Date chantier",
            type: FilterType.DATEPICKER,
        },
        userType: {
            label: "Type de vaccinateur",
            type: FilterType.SELECT,
            values: convertEnumToKeyLabelObject(VaccinationSiteUserType),
        },
        dpe: {
            label: "DPE",
            type: FilterType.INPUT,
        },
        veterinary: {
            label: "Vétérinaire",
            type: FilterType.INPUT,
        },
        departmentInseeCode: {
            label: "Département du chantier",
            type: FilterType.SELECT_AUTOCOMPLETE,
            values: departments.map((d) => ({ label: `${d.inseeCode} - ${d.name}`, key: d.inseeCode })),
        },
        farmId: {
            label: "Identifiant Établissement",
            type: FilterType.INPUT,
        },
    };

    return (
        <Stack height="100%" spacing={2} width="100%">
            <Typography variant="h4">Mes chantiers en demande de paiement</Typography>
            <Box
                display="flex"
                flexDirection="column"
                alignContent="center"
                justifyContent="space-between"
                width="100%"
            >
                {isAdminDdppOrUserDdppOrAdminCalypso ? (
                    <GenericPresetFilters
                        superFilterConfiguration={userAdminDdppPresetFilterConfiguration}
                        inputFilters={inputFilters}
                        setInputFilters={setInputFilters}
                        clearFilters={() => setInputFilters(initialValues)}
                    />
                ) : (
                    <GenericPresetFilters
                        superFilterConfiguration={userVetPresetFilterConfiguration}
                        inputFilters={inputFilters}
                        setInputFilters={setInputFilters}
                        clearFilters={() => setInputFilters(initialValues)}
                    />
                )}
            </Box>
            <Card>
                <CardContent>
                    <GenericFilters
                        inputFilters={inputFilters}
                        filterConfigurations={filterConfigurations}
                        initialValues={initialValues}
                        setInputFilters={setInputFilters}
                    />
                    <VaccinationSitesDataGrid
                        outsideCalypso={false}
                        billables={billables}
                        pagination={pagination}
                        setPagination={setPagination}
                        rowCount={rowCount}
                        sortModel={sortModel}
                        setSortModel={setSortModel}
                        setVaccinationSiteId={setVaccinationSiteId}
                        setVaccinationSiteDate={setVaccinationSiteDate}
                        getBillableCount={getBillableCount}
                        getBillables={getBillables}
                    />
                </CardContent>
            </Card>
            {vaccinationSiteId && (
                <VaccinationInterventionTable
                    vaccinationSiteId={vaccinationSiteId}
                    canEdit={false}
                    title={`Suivi du chantier n°${vaccinationSiteId} en date du ${toLocaleDateFormat(vaccinationSiteDate)}`}
                />
            )}
        </Stack>
    );
}
