// === NPM
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Card, FormControl, Stack } from "@mui/material";
import { z } from "zod";
// === LOCAL
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { stringRequired } from "@/resources/FormUtils";
import { convertEnumToKeyLabelObject } from "@/resources/utils";
import { HealthReportingStatus, IHealthReportingFull } from "../../../interface";
import AttachedFilesAccordeon from "../../containers/AttachedFilesAccordeon";
import InformationsAccordion from "../../containers/InformationsAccordion";
import ReportedAnomaliesAccordion from "../../containers/ReportedAnomaliesAccordion";

interface EditDialogProps {
    onClose: () => void;
    onValid: (form: any) => void;
    healthReporting: IHealthReportingFull;
}

const formSchema = z.object({
    status: stringRequired(),
});

type ValidationSchema = z.infer<typeof formSchema>;

export default function EditDialog({ onClose, onValid, healthReporting }: Readonly<EditDialogProps>) {
    const {
        formState: { errors },
        register,
        control,
        handleSubmit,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            status: healthReporting.status ?? null,
        },
    });

    const renderStatus = () => (
        <Card sx={{ p: 1 }}>
            <StyledCardContent>
                <FormControl fullWidth error={!!errors.status} required>
                    <Controller
                        name="status"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <GenericSelect
                                {...register("status")}
                                value={value}
                                onChange={onChange}
                                label="Statut"
                                optionsValue="key"
                                options={convertEnumToKeyLabelObject(HealthReportingStatus)}
                                error={!!error}
                                helperText={error?.message}
                                required
                            />
                        )}
                    />
                </FormControl>
            </StyledCardContent>
        </Card>
    );

    const renderContent = () => (
        <Stack>
            {renderStatus()}
            <InformationsAccordion reportInformations={healthReporting.reportInformations} />
            <ReportedAnomaliesAccordion reportedAnomalies={healthReporting.reportedAnomalies} />
            {(healthReporting.files.length > 0 || healthReporting.comment) && (
                <AttachedFilesAccordeon
                    healthReportingId={healthReporting.id}
                    comment={healthReporting.comment}
                    attachments={healthReporting.files}
                />
            )}
        </Stack>
    );

    return (
        <GenericDialog
            onClose={onClose}
            title={`Traitement du signalement ${healthReporting.id}`}
            renderContent={renderContent}
            renderActions={() => <GenericActionsDialog onClose={onClose} onSubmit={handleSubmit(onValid)} />}
        />
    );
}
