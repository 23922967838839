// === NPM
import { AxiosResponse } from "axios";
// === LOCAL
import {
    IAccount,
    IAccountType,
    IAccountWithKeys,
    IAlert,
    IApiKey,
    IApiKeyType,
    ICreateAccount,
    IPeriodicity,
    IProfile,
} from "@/components/Administration/containers/ApiKeys/interface";
import { HttpMethod, Loader } from "@/interfaces/global";
import { IOrganization } from "@/interfaces/referential";
import { objToQueryParams } from "@/resources/utils";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class ApiKeyService {
    // ----------- ACCOUNTS -----------

    async getAccounts(type: string): Promise<AxiosResponse<IAccountWithKeys[]>> {
        const query = objToQueryParams({ type });

        return HttpService.fetch({
            url: endpoints.apiKeyService.account.accountByTypes(query),
            method: HttpMethod.GET,
            loader: Loader.TABLE,
        });
    }

    async patchAccount(uuid: string, payload: { email: string; endDate: string }): Promise<AxiosResponse<IAccount>> {
        return HttpService.fetch({
            url: endpoints.apiKeyService.account.accountByUuid(uuid),
            method: HttpMethod.PATCH,
            data: payload,
            loader: Loader.REQUEST,
        });
    }

    async deleteAccount(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.apiKeyService.account.accountByUuid(uuid),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    async postAccountToken(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.apiKeyService.account.accountToken(uuid),
            method: HttpMethod.POST,
            loader: Loader.REQUEST,
        });
    }

    async createDrugTransferAccount(data: ICreateAccount): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.apiKeyService.account.drugTransfer(),
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async createContinuousTrainingAccount(data: ICreateAccount): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.apiKeyService.account.continuousTraining(),
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async createSsoAccount(data: ICreateAccount): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.apiKeyService.account.sso(),
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async createVaccinationInterventionAccount(data: ICreateAccount): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.apiKeyService.account.vaccinationIntervention(),
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async createBnoAccount(data: ICreateAccount): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.apiKeyService.account.bno(),
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    // ----------- ALERTS -----------

    async getAlerts(payload: any): Promise<AxiosResponse<IAlert[]>> {
        const query = objToQueryParams(payload);
        return HttpService.fetch({
            url: endpoints.apiKeyService.alerts(query),
            method: HttpMethod.GET,
            loader: Loader.TABLE,
        });
    }

    async updateAlert(uuid: string, payload: { treated: boolean }): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.apiKeyService.alertByUuid(uuid),
            method: HttpMethod.PUT,
            data: payload,
            loader: Loader.REQUEST,
        });
    }

    // ----------- API KEY -----------

    async getApiKeys(payload: any): Promise<AxiosResponse<IApiKey[]>> {
        const query = objToQueryParams(payload);

        return HttpService.fetch({
            url: endpoints.apiKeyService.apiKey.apiKeys(query),
            method: HttpMethod.GET,
        });
    }

    async patchApiKey(uuid: string, payload: { endDate: string }): Promise<AxiosResponse<IApiKey>> {
        return HttpService.fetch({
            url: endpoints.apiKeyService.apiKey.apiKeyByUuid(uuid),
            method: HttpMethod.PATCH,
            data: payload,
            loader: Loader.REQUEST,
        });
    }

    async deleteApiKey(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.apiKeyService.apiKey.apiKeyByUuid(uuid),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    // ----------- REFERENTIALS -----------

    async getApiKeyProfileReferential(): Promise<AxiosResponse<IProfile[]>> {
        return HttpService.fetch({
            url: endpoints.apiKeyService.referentials.profile(),
            method: HttpMethod.GET,
        });
    }

    async getPeriodicitiesReferential(): Promise<AxiosResponse<IPeriodicity[]>> {
        return HttpService.fetch({
            url: endpoints.apiKeyService.referentials.periodicities(),
            method: HttpMethod.GET,
        });
    }

    async getTypesReferential(): Promise<AxiosResponse<IApiKeyType[]>> {
        return HttpService.fetch({
            url: endpoints.apiKeyService.referentials.apiKeyTypes(),
            method: HttpMethod.GET,
        });
    }

    async getAccountTypesReferential(): Promise<AxiosResponse<IAccountType[]>> {
        return HttpService.fetch({
            url: endpoints.apiKeyService.referentials.accountTypes(),
            method: HttpMethod.GET,
        });
    }

    async getSsoOrganizations(): Promise<AxiosResponse<IOrganization[]>> {
        return HttpService.fetch({
            url: endpoints.apiKeyService.referentials.ssoOrganizations(),
            method: HttpMethod.GET,
        });
    }

    async getVaccinationInterventionOrganizations(): Promise<AxiosResponse<IOrganization[]>> {
        return HttpService.fetch({
            url: endpoints.apiKeyService.referentials.vaccinationInterventionOrganizations(),
            method: HttpMethod.GET,
        });
    }

    async getBnoOrganizations(): Promise<AxiosResponse<IOrganization[]>> {
        return HttpService.fetch({
            url: endpoints.apiKeyService.referentials.bnoOrganizations(),
            method: HttpMethod.GET,
        });
    }
    // ----------- PERIODICITY -----------

    async putPeriodicity(
        profileKey: string,
        payload: { periodicityKey: string }
    ): Promise<AxiosResponse<{ periodicityKey: string }>> {
        return HttpService.fetch({
            url: endpoints.apiKeyService.profilePeriodicity(profileKey),
            method: HttpMethod.PUT,
            data: payload,
            loader: Loader.REQUEST,
        });
    }

    // ----------- API KEY -----------

    async getKeyFromToken(payload: { token: string }): Promise<AxiosResponse<{ apiKey: string }>> {
        const query = objToQueryParams(payload);

        return HttpService.fetch({
            url: endpoints.apiKeyService.apiKeyFromToken(query),
            method: HttpMethod.GET,
        });
    }
}

export default new ApiKeyService();
