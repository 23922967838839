// === NPM
import React, { Dispatch, RefObject, SetStateAction, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Clear } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import { DateTime } from "luxon";
import { z } from "zod";
// === LOCAL
import IconActionButton from "@/components/generics/actions/IconActionButton";
import GenericDatePicker from "@/components/generics/inputs/GenericDatePicker";
import { defaultIntegerLimit } from "@/resources/AppConstant";
import { colors } from "@/resources/CssConstant";
import { stringRequired } from "@/resources/FormUtils";
import { IManualDelivery, manualDeliveryInitialValues } from "../interface";

interface ManualDeliveryProps {
    manualDelivery: IManualDelivery;
    index: number;
    handleDeleteManualDelivery: (internalId: string) => void;
    handleUpdateManualDelivery: (internalId: string, manualDelivery: IManualDelivery) => void;
    manualDeliveryRef: RefObject<Record<number, HTMLButtonElement>>;
    setIsValidDeliveries: Dispatch<SetStateAction<boolean[]>>;
    quantityError: string;
    deliveriesLength: number;
}

const formSchema = z.object({
    batchNumber: stringRequired().regex(
        /^[A-Z0-9-.]{1,15}$/,
        "Les caractères acceptés sont les majuscules, chiffres, points et tirets."
    ),
    expeditionDate: stringRequired().refine(
        (value) => DateTime.fromISO(value) <= DateTime.now().startOf("day"),
        "La date de livraison d'un lot ne peut pas être dans le futur"
    ),
    expirationDate: z.string().optional().nullable(),
    deliveredQuantity: z.coerce
        .number()
        .int("La quantité doit être un nombre entier strictement positif")
        .positive("La quantité doit être un nombre entier strictement positif"),
});

export type ManualDeliverySchema = z.infer<typeof formSchema>;

export default function ManualDelivery({
    manualDelivery,
    index,
    handleDeleteManualDelivery,
    handleUpdateManualDelivery,
    manualDeliveryRef,
    setIsValidDeliveries,
    quantityError,
    deliveriesLength,
}: Readonly<ManualDeliveryProps>) {
    const {
        formState: { errors },
        handleSubmit,
        control,
        register,
        watch,
    } = useForm<ManualDeliverySchema>({
        resolver: zodResolver(formSchema),
        defaultValues: manualDelivery ?? manualDeliveryInitialValues,
    });

    const data = watch();

    const onValid = () => {
        setIsValidDeliveries((prev) => prev.map((v, i) => (i === index ? true : v)));
    };

    useEffect(() => {
        const newManualDelivery: IManualDelivery = {
            ...(data as IManualDelivery),
            internalId: manualDelivery.internalId,
        };
        handleUpdateManualDelivery(manualDelivery.internalId, newManualDelivery);
    }, [data]);

    return (
        <Grid container spacing={2} px={2} pt={2} pb={1}>
            <Grid item xs>
                <TextField
                    {...register("batchNumber")}
                    label="Numéro de lot"
                    required
                    fullWidth
                    error={!!errors.batchNumber}
                    helperText={errors.batchNumber?.message}
                    inputProps={{
                        maxLength: 15,
                    }}
                />
            </Grid>
            <Grid item xs>
                <Controller
                    name="expirationDate"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <GenericDatePicker
                            value={value}
                            onChange={onChange}
                            name="expirationDate"
                            error={!!error}
                            helperText={error?.message}
                            label="Date de péremption"
                            minDate={DateTime.now().plus({ days: 1 })}
                        />
                    )}
                />
            </Grid>
            <Grid item xs>
                <Controller
                    name="expeditionDate"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <GenericDatePicker
                            value={value}
                            onChange={onChange}
                            name="expeditionDate"
                            error={!!error}
                            helperText={error?.message}
                            label="Date de livraison"
                            maxDate={DateTime.now()}
                        />
                    )}
                />
            </Grid>
            <Grid item xs>
                <TextField
                    {...register("deliveredQuantity")}
                    label="Quantité livrée"
                    required
                    fullWidth
                    error={!!errors.deliveredQuantity || !!quantityError}
                    helperText={(errors.deliveredQuantity?.message as string) || quantityError}
                    inputProps={{
                        maxLength: defaultIntegerLimit,
                    }}
                />
            </Grid>
            <Grid item xs={0.5} sx={{ textAlign: "center" }}>
                {deliveriesLength > 1 && (
                    <IconActionButton
                        title="Supprimer le lot"
                        onClick={() => handleDeleteManualDelivery(manualDelivery.internalId)}
                        icon={<Clear sx={{ color: colors.error }} />}
                    />
                )}
            </Grid>
            <button
                style={{ display: "none" }}
                onClick={handleSubmit(onValid)}
                ref={(el) => (manualDeliveryRef.current[index] = el)}
            />
        </Grid>
    );
}
