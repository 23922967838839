// === NPM
import React, { ChangeEvent, ElementType } from "react";
import { AddCircle } from "@mui/icons-material";
import {
    Box,
    Grid,
    IconButton,
    InputAdornment,
    InputBaseComponentProps,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
// === LOCAL
import StyledChip from "@/components/styled/StyledChip";
import { Form } from "@/hooks/useForm";
import { colors, drawerColors } from "@/resources/CssConstant";

interface TextListAdderProps<T> {
    form: Form<T>;
    label: string;
    valueLabel: keyof T;
    elementsList: string[];
    elementsListLabel: keyof T;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
    startAdornment?: JSX.Element;
    inputComponent?: ElementType<InputBaseComponentProps>;
    emptyText?: string;
}

export default function TextListAdder<T extends {}>({
    form,
    label,
    valueLabel,
    elementsList,
    elementsListLabel,
    error,
    helperText,
    disabled = false,
    inputComponent,
    startAdornment,
    emptyText,
}: TextListAdderProps<T>) {
    const removeElement = (index: number) => {
        const elements = elementsList;
        elements.splice(index, 1);
        const target = {
            target: { value: elements as unknown },
        } as ChangeEvent<HTMLInputElement & HTMLSelectElement>;
        form.handleChange(elementsListLabel)(target);
    };

    return (
        <form onSubmit={form.handleSubmit}>
            <Stack width="100%" spacing={1}>
                <Typography sx={{ fontSize: 16, fontWeight: 600 }}>{label}</Typography>
                <Grid container spacing={2} width="100%">
                    <Grid item xs={12} md={4}>
                        <Stack spacing={2}>
                            <Typography variant="bold">Ajouter un élément</Typography>
                            <TextField
                                label={label}
                                value={form.data[valueLabel]}
                                onChange={form.handleChange(valueLabel)}
                                InputProps={{
                                    inputComponent: inputComponent,
                                    startAdornment: startAdornment,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Ajouter">
                                                <IconButton type="submit">
                                                    <AddCircle color="primary" />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                                error={!!error}
                                helperText={helperText}
                                disabled={disabled}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Stack spacing={2}>
                            <Typography variant="bold">{label} déclarés</Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                    borderWidth: "2px",
                                    borderColor: drawerColors.background,
                                    borderStyle: "dashed",
                                    borderRadius: 5,
                                    p: 2,
                                }}
                            >
                                {elementsList.length > 0 ? (
                                    elementsList.map((element, index) => (
                                        <StyledChip key={index} label={element} onDelete={() => removeElement(index)} />
                                    ))
                                ) : (
                                    <Typography color={colors.secondaryColor}>{emptyText}</Typography>
                                )}
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        </form>
    );
}
