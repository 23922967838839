// === NPM
import React, { useMemo, useState } from "react";
import { Box, TextField } from "@mui/material";
import { GridFilterInputValueProps } from "@mui/x-data-grid-pro";
import { DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterLuxon } from "@mui/x-date-pickers-pro/AdapterLuxon";
import { DateTime } from "luxon";

// eslint-disable-next-line react/display-name
export default function DateRangeFilterTable({ item, applyValue }: Readonly<GridFilterInputValueProps>) {
    const [open, setOpen] = useState<boolean>(false);

    useMemo(() => {
        if (!item.value) {
            setOpen(true);
        }
    }, []);

    const onClose = () => {
        setOpen(false);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="FR-fr">
            <DateRangePicker
                startText=""
                endText=""
                value={item.value?.map((d) => (d ? DateTime.fromISO(d) : null)) || [null, null]}
                onChange={(newValue) => {
                    // prevent newValue to send [null, null] when data grid trigger render
                    if (newValue.some((v) => v !== null))
                        applyValue({ ...item, value: newValue.map((v: DateTime) => (v ? v.toISODate() : null)) });
                }}
                open={open}
                onClose={onClose}
                onOpen={() => setOpen(true)}
                renderInput={(startProps, endProps) => (
                    <>
                        <TextField size="small" {...startProps} />
                        <Box sx={{ mx: 2 }}> au </Box>
                        <TextField size="small" {...endProps} />
                    </>
                )}
            />
        </LocalizationProvider>
    );
}
