import React from "react";
import { Skeleton, Stack } from "@mui/material";

export default function ActivitiesSkeleton() {
    return (
        <Stack direction="row" spacing={2}>
            <Skeleton variant="text" width="100%" height={150} />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
        </Stack>
    );
}
