import { AdapterLuxon } from "@mui/x-date-pickers-pro/AdapterLuxon";
import { DateTime } from "luxon";

export class AdapterCalypso extends AdapterLuxon {
    getYearRange = (start: DateTime, end: DateTime): DateTime[] => {
        const years = [];

        while (end.year > start.year) {
            years.push(end);
            end = end.minus({ year: 1 });
        }

        return years;
    };
}
