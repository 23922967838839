// === NPM
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Card, Grid, TextField } from "@mui/material";
import { z } from "zod";
// === LOCAL
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { HttpStatus } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import { FORM_TEXT } from "@/resources/FormUtils";
import VaccinationService from "@/services/VaccinationService";
import { IVehicle } from "../../interface";

interface VehicleInformationsProps {
    vehicle: IVehicle;
    onValid: (data: IVehicle) => void;
}

const formSchema = z.object({
    plateNumber: z
        .string()
        .regex(
            new RegExp("^[A-Z0-9-]{1,15}$"),
            "Les caractères acceptés sont des majuscules, des chiffres et des tirets, avec 15 caractères maximum"
        )
        .nullable()
        .transform((value) => value.toUpperCase()),
    horsePower: z.string({ required_error: FORM_TEXT.required }).min(1, { message: FORM_TEXT.required }),
});

type ValidationSchema = z.infer<typeof formSchema>;

export default function VehicleInformations({ vehicle, onValid }: Readonly<VehicleInformationsProps>) {
    const {
        formState: { errors },
        handleSubmit,
        control,
        register,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: vehicle ?? {
            plateNumber: null,
            horsePower: "",
        },
    });

    const [horsePower, setHorsePower] = useState<IReferential[]>([]);

    useEffect(() => {
        getHorsePower();
    }, []);

    const getHorsePower = async () => {
        const res = await VaccinationService.getHorsePower();
        if (res.status === HttpStatus.OK) {
            setHorsePower(res.data);
        }
    };

    return (
        <Card sx={{ p: 2, width: "100%" }}>
            <StyledCardContent>
                <form onSubmit={handleSubmit(onValid)} noValidate>
                    <Grid container spacing={2} sx={{ alignItems: "center" }}>
                        <Grid item xs={5}>
                            <TextField
                                {...register("plateNumber")}
                                label="Numéro de plaque"
                                fullWidth
                                error={!!errors.plateNumber}
                                helperText={errors.plateNumber?.message}
                                required
                                inputProps={{ style: { textTransform: "uppercase" } }}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <Controller
                                name="horsePower"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <GenericSelect
                                        onChange={onChange}
                                        options={horsePower}
                                        optionsValue="key"
                                        error={!!error}
                                        helperText={error?.message}
                                        label="Chevaux fiscaux"
                                        value={value}
                                        required
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" color="primary" type="submit">
                                Mettre à jour
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </StyledCardContent>
        </Card>
    );
}
