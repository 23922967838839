// === NPM
import React from "react";
import { Card, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Calendar } from "@/assets/icons/shared/calendar.svg";
import { ReactComponent as Home } from "@/assets/icons/shared/home.svg";
import { ReactComponent as Paper } from "@/assets/icons/shared/paper.svg";
import { ReactComponent as Pet } from "@/assets/icons/shared/pet.svg";
import { ReactComponent as Workshop } from "@/assets/icons/shared/workshop.svg";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { IFarm, IWorkshopShort } from "@/interfaces/farm";
import { ISpeciesDetails } from "@/interfaces/referential";
import { toLocaleDateFormat } from "@/resources/utils";

interface ConfirmDialogProps {
    onClose: () => void;
    onConfirm: () => void;
    farm: IFarm;
    dpe: { id: string; name: string };
    fileName?: string;
    workshops: IWorkshopShort[];
    species: ISpeciesDetails;
    openingDate: string;
}

export default function ConfirmDialog({
    onClose,
    onConfirm,
    farm,
    dpe,
    fileName,
    workshops,
    species,
    openingDate,
}: Readonly<ConfirmDialogProps>) {
    const renderContent = () => (
        <Card>
            <StyledCardContent>
                <Stack spacing={4} p={2}>
                    <Stack spacing={1}>
                        <Typography variant="bold">Catégorie d'espèces</Typography>
                        <GenericIconText icon={<Pet />} text={species.label} iconTooltip="Catégorie d'espèce" />
                    </Stack>
                    <Stack spacing={1}>
                        <Typography variant="bold">Élevage</Typography>
                        <GenericIconText icon={<Home />} text={farm?.name} iconTooltip="Nom de l'élevage" />
                        <GenericIconText
                            icon={<Workshop />}
                            text={`Atelier${workshops.length > 1 ? "s" : ""} ${workshops.map((w) => w.id).join(", ")}`}
                            iconTooltip="Atelier"
                        />
                    </Stack>
                    <Stack spacing={1}>
                        <Typography variant="bold">Affectation à un DPE</Typography>
                        <GenericIconText
                            icon={<Home />}
                            text={`${dpe.id} - ${dpe.name}`}
                            iconTooltip="Informations du DPE"
                        />
                        <GenericIconText
                            icon={<Calendar />}
                            text={toLocaleDateFormat(openingDate)}
                            iconTooltip="Date d'ouverture"
                        />
                        <GenericIconText
                            icon={<Paper />}
                            text={
                                fileName
                                    ? `Je dispose du CERFA permettant de confirmer la validité de cette déclaration : ${fileName}`
                                    : "Je ne dispose pas du CERFA permettant de confirmer la validité de cette déclaration"
                            }
                            iconTooltip=""
                        />
                    </Stack>
                </Stack>
            </StyledCardContent>
        </Card>
    );

    return (
        <GenericDialog
            title="Confirmer la déclaration de relation"
            onClose={onClose}
            renderContent={() => renderContent()}
            renderActions={() => (
                <GenericActionsDialog
                    validLabel="Valider la relation"
                    onClose={onClose}
                    onSubmit={onConfirm}
                    closeLabel="Retour au formulaire"
                />
            )}
        />
    );
}
