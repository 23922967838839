// === NPM
import React, { useEffect, useState } from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
// === LOCAL
import { HttpStatus } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import NotificationService from "@/services/NotificationService";
import { IConfig } from "../../interface";
import ConfigCard from "./containers/ConfigCard";

export default function AutoNotificationConfiguration() {
    const [autoNotifTypes, setAutoNotifTypes] = useState<IReferential[]>([]);
    const [configs, setConfigs] = useState<IConfig[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        Promise.all([getAutoNotificationTypes(), getConfigs()]).then(() => {
            setLoading(false);
        });
    };

    const getConfigs = async () => {
        const response = await NotificationService.getConfigs();
        if (response.status === HttpStatus.OK) {
            setConfigs(response.data);
        }
    };

    const getAutoNotificationTypes = async () => {
        const response = await NotificationService.getAutoNotificationTypes();
        if (response.status === HttpStatus.OK) {
            setAutoNotifTypes(response.data);
        }
    };

    const updateConfigs = (config: IConfig) => {
        setConfigs((prev) => prev.map((c) => (c.key === config.key ? config : c)));
    };

    const renderSkeleton = () => (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 2 }}>
            <Skeleton width="50%" sx={{ fontSize: 40 }} />
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Skeleton variant="rounded" height={250} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Skeleton variant="rounded" height={250} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Skeleton variant="rounded" height={250} />
                </Grid>
            </Grid>
        </Box>
    );

    return loading ? (
        renderSkeleton()
    ) : (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 2 }}>
            <Typography variant="h4">Gestion des notifications automatiques</Typography>
            <Grid container spacing={3}>
                {configs.map((c) => (
                    <Grid item xs={12} md={4} key={c.key}>
                        <ConfigCard config={c} types={autoNotifTypes} updateConfigs={updateConfigs} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}
