// === NPM
import React from "react";
import { FieldError, Merge, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Close } from "@mui/icons-material";
import { Box, Chip, IconButton, InputAdornment, Stack, TextField, Tooltip } from "@mui/material";
import { z } from "zod";
// === LOCAL
import { ReactComponent as AddIcon } from "@/assets/icons/form/plus_icon.svg";
import { colors } from "@/resources/CssConstant";
import { FORM_TEXT } from "@/resources/FormUtils";

interface GenericEmailFieldProps {
    label: string;
    emails: string[];
    onChange: (emails: string[]) => void;
    error: Merge<FieldError, FieldError[]>;
    required?: boolean;
}

export default function GenericEmailField({
    label,
    emails,
    onChange,
    error,
    required,
}: Readonly<GenericEmailFieldProps>) {
    const formSchema = z.object({
        email: z
            .string({
                required_error: "Veuillez compléter le champ",
                invalid_type_error: "Veuillez compléter le champ",
            })
            .trim()
            .email({ message: FORM_TEXT.email })
            .refine((email) => !emails.includes(email), {
                message: "L'adresse mail existe déjà",
            }),
    });

    type ValidationSchema = z.infer<typeof formSchema>;

    const {
        formState: { errors },
        register,
        handleSubmit,
        reset,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            email: null,
        },
        reValidateMode: "onSubmit",
    });

    const onValid = (formValues: ValidationSchema) => {
        onChange([...emails, formValues.email]);
        reset();
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onDelete = (email: string) => (_) => {
        onChange(Array.from(emails.filter((item) => item !== email)));
    };

    return (
        <Stack spacing={1}>
            <form onSubmit={handleSubmit(onValid)} noValidate>
                <TextField
                    {...register("email")}
                    placeholder="Ajouter une adresse mail"
                    label={label}
                    error={!!errors.email || !!error}
                    helperText={errors.email?.message || error?.message}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip title="Ajouter">
                                    <IconButton type="submit" sx={{ alignSelf: "center" }}>
                                        <AddIcon style={{ width: "30", height: "30" }} />
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        ),
                    }}
                    required={required}
                />
            </form>
            {emails.length > 0 && (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {emails.map((email) => (
                        <React.Fragment key={email}>
                            <Chip
                                key={email}
                                label={email}
                                onDelete={(!required || emails.length > 1) && onDelete(email)}
                                sx={{
                                    "& .MuiChip-deleteIcon": {
                                        color: colors.black,
                                    },
                                }}
                                deleteIcon={<Close />}
                            />
                        </React.Fragment>
                    ))}
                </Box>
            )}
        </Stack>
    );
}
