// === NPM
import React from "react";
import { useDrop } from "react-dnd";
import { Box } from "@mui/material";
// === LOCAL
import { IQuestionShort } from "@/interfaces/faq";
import { FaqDropableType } from "../../../interface";

interface DropzoneProps {
    index: number;
    moveQuestion: (uuid: string, to: number, categoryUuid: string) => void;
    categoryUuid: string;
}

export default function Dropzone({ index, moveQuestion, categoryUuid }: Readonly<DropzoneProps>) {
    const [, drop] = useDrop(
        () => ({
            accept: FaqDropableType.QUESTION,
            hover(draggedQuestion: IQuestionShort) {
                moveQuestion(draggedQuestion.uuid, index, categoryUuid);
            },
        }),
        [moveQuestion, index]
    );

    return <Box sx={{ p: 1 }} ref={drop} />;
}
