// === NPM
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { HttpStatus } from "@/interfaces/global";
import { IReferential, ISpeciesDetails } from "@/interfaces/referential";
// === LOCAL
import { IVaccine, VaccineType, VaccineUsage } from "@/interfaces/vaccination";
import ReferentialService from "@/services/ReferentialService";
import VaccinationService from "@/services/VaccinationService";

export default function VaccinationIahp() {
    const [vaccines, setVaccines] = useState<IVaccine[]>([]);
    const [species, setSpecies] = useState<ISpeciesDetails[]>([]);
    const [tiers, setTiers] = useState<IReferential[]>([]);
    const [sectors, setSectors] = useState<IReferential[]>([]);
    const [horsePower, setHorsePower] = useState<IReferential[]>([]);
    const [billingStatuses, setBillingStatuses] = useState<IReferential[]>([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        Promise.all([getVaccines(), getSpecies(), getTiers(), getSectors(), getBillingStatuses(), getHorsePower()]);
    };

    const getVaccines = async () => {
        const res = await VaccinationService.getVaccines();
        if (res.status === HttpStatus.OK) {
            setVaccines(res.data);
        }
    };

    const getSpecies = async () => {
        const res = await ReferentialService.getSpecies({ use: ["iahp", "drug"] });
        if (res.status === HttpStatus.OK) {
            setSpecies(res.data);
        }
    };

    const getTiers = async () => {
        const res = await VaccinationService.getTiers();
        if (res.status === HttpStatus.OK) {
            setTiers(res.data);
        }
    };

    const getSectors = async () => {
        const res = await VaccinationService.getSectors();
        if (res.status === HttpStatus.OK) {
            setSectors(res.data);
        }
    };

    const getBillingStatuses = async () => {
        const res = await VaccinationService.getBillingStatuses();
        if (res.status === HttpStatus.OK) {
            setBillingStatuses(res.data);
        }
    };

    const getHorsePower = async () => {
        const res = await VaccinationService.getHorsePower();
        if (res.status === HttpStatus.OK) {
            setHorsePower(res.data);
        }
    };

    return (
        <Outlet
            context={{
                // TODO - Remove usekey filter when we need to deal with other context (bluetongue, etc.)
                vaccines: vaccines.filter((v) => v.type !== VaccineType.DILUENT && v.useKey === VaccineUsage.IAHP),
                diluents: vaccines.filter((v) => v.type === VaccineType.DILUENT && v.useKey === VaccineUsage.IAHP),
                tiers: tiers,
                species: species.filter((s) => !s.parentUuid),
                subSpecies: species.filter((s) => s.parentUuid),
                sectors: sectors,
                billingStatuses: billingStatuses,
                horsePower: horsePower,
            }}
        />
    );
}
