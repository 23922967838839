// === NPM
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
// === LOCAL
import PageContent from "@/components/generics/layout/PageContent";
import { HttpStatus } from "@/interfaces/global";
import { IType } from "@/interfaces/user";
import UserService from "@/services/UserService";

export default function User() {
    const [userTypesReferential, setUserTypes] = useState<IType[]>([]);

    useEffect(() => {
        getTypesReferential();
    }, []);

    const getTypesReferential = async () => {
        const res = await UserService.getUserTypesReferential();
        if (res.status === HttpStatus.OK) {
            setUserTypes(res.data);
        }
    };

    return (
        <PageContent>
            <Outlet context={{ userTypesReferential }} />
        </PageContent>
    );
}
