// === NPM
import React, { ChangeEvent, RefObject } from "react";
import { useOutletContext } from "react-router-dom";
import { Card, Grid, Stack, Switch, Typography } from "@mui/material";
// === LOCAL
import GenericSwitch from "@/components/generics/buttons/GenericSwitch";
import InfoIcon from "@/components/generics/InfoIcon";
import GenericComment from "@/components/generics/inputs/GenericComment";
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import GenericTextField from "@/components/generics/inputs/GenericTextField";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { useForm } from "@/hooks/useForm";
import { IMonitoringInterventionData } from "@/interfaces/vaccination";
import { defaultIntegerLimit, maxIntegerValue } from "@/resources/AppConstant";
import { FORM_TEXT, PATTERN } from "@/resources/FormUtils";
import { MonitoringInterventionOutletContext } from "../../interface";
import { useFormMonitoringIntervention } from "../containers/useFormMonitoringIntervention";

interface InterventionDataProps {
    formRef: RefObject<HTMLButtonElement>;
    previousRef: RefObject<HTMLButtonElement>;
    onValid: () => void;
    handlePreviousStep: () => void;
}

export default function InterventionData({
    formRef,
    previousRef,
    onValid,
    handlePreviousStep,
}: Readonly<InterventionDataProps>) {
    const { clinicalNonComplianceReasons } = useOutletContext<MonitoringInterventionOutletContext>();

    const { form, setForm } = useFormMonitoringIntervention();

    const { data, errors, handleSubmit, handleChange, cleanValue } = useForm<IMonitoringInterventionData>({
        initialValues: form?.interventionData
            ? {
                  ...form.interventionData,
                  comment: form.interventionData.comment ?? "",
                  virologySampleComment: form.interventionData.virologySampleComment ?? "",
              }
            : {
                  complianceClinicalExamination: false,
                  clinicalNonComplianceReason: "",
                  monitoringResultAvailability: false,
                  comment: "",
                  virologySampleNumber: 60,
                  virologySampleComment: "",
              },
        validations: {
            clinicalNonComplianceReason: {
                custom: {
                    isValid: (value) =>
                        (!data.complianceClinicalExamination && value) ||
                        (data.complianceClinicalExamination && !value),
                    message: FORM_TEXT.required,
                },
            },
            comment: {
                custom: {
                    isValid: (value) => !(value.trim().length === 0 && value.length > 0),
                    message: FORM_TEXT.emptyString,
                },
                limitValue: {
                    isValid: (value) => value.length <= 1000,
                    message: "Le commentaire est limité à 1000 caractères",
                },
            },
            virologySampleNumber: {
                required: {
                    value: true,
                    message: FORM_TEXT.required,
                },
                pattern: {
                    value: PATTERN.positiveNumber,
                    message: "Le nombre d'écouvillons prélevés doit être un entier positif",
                },
                limitValue: {
                    isValid: (value) => +value <= maxIntegerValue,
                    message: FORM_TEXT.maxInteger,
                },
            },
            virologySampleComment: {
                limitValue: {
                    isValid: (value) => value.length <= 1000,
                    message: "Le commentaire est limité à 1000 caractères",
                },
                custom: {
                    isValid: (value) => !(value.trim().length === 0 && value.length > 0),
                    message: FORM_TEXT.emptyString,
                },
            },
        },
        onSubmit: () => {
            setForm({ ...form, interventionData: data });
            onValid();
        },
    });

    const handleCompliantSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const compliantTarget = {
            target: { value: null, checked: e.target.checked },
        } as ChangeEvent<HTMLInputElement & HTMLSelectElement>;
        handleChange("complianceClinicalExamination")(compliantTarget);
        cleanValue("clinicalNonComplianceReason");
    };

    const handleResultSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const resultTarget = {
            target: { value: null, checked: e.target.checked },
        } as ChangeEvent<HTMLInputElement & HTMLSelectElement>;
        handleChange("monitoringResultAvailability")(resultTarget);
    };

    const renderData = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} sx={{ pl: 3 }}>
                        <GenericSwitch
                            title="Résultat de l'examen clinique"
                            leftText="Non conforme"
                            rightText="Conforme"
                            value={data.complianceClinicalExamination}
                            onChange={handleCompliantSwitch}
                        />
                    </Grid>
                    {!data.complianceClinicalExamination && (
                        <Grid item xs={12} md={4}>
                            <GenericSelect
                                onChange={handleChange("clinicalNonComplianceReason")}
                                options={clinicalNonComplianceReasons}
                                required
                                optionsValue="key"
                                error={!!errors.clinicalNonComplianceReason}
                                helperText={errors.clinicalNonComplianceReason}
                                label="Motif de non conformité"
                                value={data.clinicalNonComplianceReason}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} md={4} sx={{ pl: 3 }}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="bold">
                                Mise à disposition des résultats de la surveillance passive
                            </Typography>
                            <InfoIcon tooltip="En accord avec la mortalité relevée dans le registre d’élevage." />
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant={!data.monitoringResultAvailability ? "bold" : "body1"}>Non</Typography>
                            <Switch checked={data.monitoringResultAvailability} onChange={handleResultSwitch} />
                            <Typography variant={data.monitoringResultAvailability ? "bold" : "body1"}>Oui</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <GenericComment
                            label="Commentaire"
                            value={data.comment ?? ""}
                            onChange={handleChange("comment")}
                            rows={3}
                            placeholder="Commentaire facultatif"
                            helperText={errors.comment}
                            maxLength={1000}
                        />
                    </Grid>
                </Grid>
            </StyledCardContent>
        </Card>
    );

    const renderSample = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <GenericTextField
                            label="Nombre d'écouvillons prélevés"
                            value={data.virologySampleNumber ?? ""}
                            onChange={handleChange("virologySampleNumber")}
                            required
                            error={!!errors.virologySampleNumber}
                            helperText={errors.virologySampleNumber}
                            maxLength={defaultIntegerLimit}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GenericComment
                            label="Commentaire"
                            value={data.virologySampleComment ?? ""}
                            onChange={handleChange("virologySampleComment")}
                            rows={3}
                            placeholder="Commentaire facultatif"
                            helperText={errors.virologySampleComment}
                            maxLength={1000}
                        />
                    </Grid>
                </Grid>
            </StyledCardContent>
        </Card>
    );

    return (
        <>
            <form onSubmit={handleSubmit} noValidate>
                <Stack spacing={3} width="100%">
                    <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                        Données de l'intervention de surveillance
                    </Typography>
                    {renderData()}

                    <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                        Prélèvements pour virologie
                    </Typography>
                    {renderSample()}
                </Stack>

                <button style={{ display: "none" }} type="submit" ref={formRef} />
            </form>
            <button
                style={{ display: "none" }}
                onClick={(e) => {
                    e.stopPropagation();
                    setForm((prev) => ({ ...prev, interventionData: data }));
                    handlePreviousStep();
                }}
                ref={previousRef}
            />
        </>
    );
}
