// === NPM
import React from "react";
import { Add } from "@mui/icons-material";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
// === LOCAL
import GenericButton from "@/components/generics/buttons/GenericButton";

export default function CategoryAndTagSkeleton() {
    const renderCategorySkeleton = () => (
        <Stack height="100%" spacing={2} width="100%">
            <Box justifyContent="space-between" display="flex">
                <Typography variant="h4">Gestion des catégories</Typography>
                <GenericButton label="Ajouter une catégorie" startIcon={<Add />} disabled onClick={undefined} />
            </Box>
            <Stack spacing={2}>
                <Skeleton variant="rounded" height={60} />
                <Skeleton variant="rounded" height={60} />
                <Skeleton variant="rounded" height={60} />
                <Skeleton variant="rounded" height={60} />
                <Skeleton variant="rounded" height={60} />
            </Stack>
        </Stack>
    );

    const renderTagSkeleton = () => (
        <Stack height="100%" spacing={2} width="100%">
            <Box justifyContent="space-between" display="flex">
                <Typography variant="h4">Gestion des tags</Typography>
                <GenericButton label="Ajouter un tag" startIcon={<Add />} disabled onClick={undefined} />
            </Box>
            <Skeleton variant="rounded" sx={{ borderRadius: 10 }} height={150} />
        </Stack>
    );
    return (
        <Stack height="100%" spacing={8} width="100%">
            {renderCategorySkeleton()}
            {renderTagSkeleton()}
        </Stack>
    );
}
