// === NPM
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { Add } from "@mui/icons-material";
import { Box, Card, Stack, Typography } from "@mui/material";
import { GridRenderCellParams, GridSortModel, GridValueFormatterParams } from "@mui/x-data-grid-pro";
// === LOCAL
import { ReactComponent as Plus } from "@/assets/icons/actions/plus.svg";
import { ReactComponent as Info } from "@/assets/icons/info.svg";
import DeleteAction from "@/components/generics/actions/DeleteAction";
import EditAction from "@/components/generics/actions/EditAction";
import IconActionButton from "@/components/generics/actions/IconActionButton";
import ViewAction from "@/components/generics/actions/ViewAction";
import GenericButton from "@/components/generics/buttons/GenericButton";
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import IconBannerText from "@/components/generics/IconBannerText";
import PermissionsCheck from "@/components/generics/PermissionsCheck";
import GenericTable from "@/components/generics/table/GenericTable";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { useDepartments } from "@/context/useDepartmentContext";
import useTimeout from "@/hooks/useTimeout";
import { ActionsColumnProps, FilterType, HttpStatus, IPagination } from "@/interfaces/global";
import { IVaccinationSite, IVaccinationSiteShort, VaccinationSiteUserType } from "@/interfaces/vaccination";
import { CALYPSO_HEADERS, defaultVaccinationPagination, defaultVaccinationRowsPerPage } from "@/resources/AppConstant";
import { colors } from "@/resources/CssConstant";
import { Preference, UserSituation } from "@/resources/PermissionConstant";
import { convertEnumToKeyLabelObject, createPayload, toLocaleDateFormat } from "@/resources/utils";
import { routerLinks } from "@/routers/RouterConstant";
import VaccinationService from "@/services/VaccinationService";
import VaccinationSiteDetailsDialog from "../../containers/VaccinationSiteDetailsDialog";
import WorkshopsCell from "../../containers/WorkshopsCells";
import { IVaccinationSiteFilters, IVaccinationSiteWorkshopInfo, VaccinationSiteOutletContext } from "../interface";
import VaccinationSiteDeleteDialog from "./containers/VaccinationSiteDeleteDialog";
import VaccinationSiteDetails from "./containers/VaccinationSiteDetails";

export function View() {
    const { departments } = useDepartments();
    const { billingStatuses } = useOutletContext<VaccinationSiteOutletContext>();
    const navigate = useNavigate();
    const [vaccinationSites, setVaccinationSites] = useState<IVaccinationSiteShort[]>([]);
    const [pagination, setPagination] = useState<IPagination>(defaultVaccinationPagination);
    const [rowCount, setRowCount] = useState<number>(0);

    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "id", sort: "desc" }]);
    const [inputFilters, setInputFilters] = useState<IVaccinationSiteFilters>({
        id: "",
        billingStatus: [],
        date: [null, null],
        userType: [],
        dpe: "",
        veterinary: "",
        departmentInseeCode: [],
        farmId: "",
    });
    const [selectedVaccinationSite, setSelectedVaccinationSite] = useState<IVaccinationSiteShort>(null);
    const [vaccinationSiteWorkshopsInfo, setVaccinationSiteWorkshopsInfo] = useState<IVaccinationSiteWorkshopInfo[]>(
        []
    );
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [openRecapDialog, setOpenRecapDialog] = useState<boolean>(false);
    const [vaccinationSite, setVaccinationSite] = useState<IVaccinationSite>(null);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getVaccinationSites();
    }, [pagination, sortModel]);

    const getVaccinationSites = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        };
        const res = await VaccinationService.getVaccinationSites(payload);
        if (res.status === HttpStatus.OK) {
            setVaccinationSites(res.data);
            setRowCount(+res.headers[CALYPSO_HEADERS.TABLE_COUNT]);
        }
    };

    const deleteVaccinationSite = async (data: { reason: string }) => {
        let res;
        if (!selectedVaccinationSite.hasCertificate)
            res = await VaccinationService.deleteVaccinationSite(selectedVaccinationSite.id);
        else res = await VaccinationService.patchVaccinationSiteCorrection(selectedVaccinationSite.id, data);
        if (res.status === HttpStatus.NO_CONTENT) {
            toast.success("Le chantier a bien été supprimé");
            getVaccinationSites();
            setOpenDeleteDialog(false);
            setSelectedVaccinationSite(null);
        }
    };

    const handleViewVaccinationSite = async (id: number) => {
        const res = await VaccinationService.getVaccinationSite(id);
        if (res.status === HttpStatus.OK) {
            setVaccinationSite(res.data);
            setOpenRecapDialog(true);
        }
    };

    const handleUpdateVaccinationSite = async (id: number) => {
        const res = await VaccinationService.getVaccinationSite(id);
        if (res.status === HttpStatus.OK) {
            navigate(`${routerLinks.iahp.vaccinationSite.base()}/${id}/saisie-chantier`, {
                state: { vaccinationSite: res.data },
            });
        }
    };

    const handleDeleteVaccinationSite = async (id: number) => {
        const res = await VaccinationService.getVaccinationSiteWorkshopsInfo(id);
        if (res.status === HttpStatus.OK) {
            setVaccinationSiteWorkshopsInfo(res.data);
            setOpenDeleteDialog(true);
        }
    };

    const handleCreateVaccinationIntervention = async (vaccinationSiteId: number) => {
        const res = await VaccinationService.getVaccinationSite(vaccinationSiteId);
        if (res.status === HttpStatus.OK) {
            navigate(`${routerLinks.iahp.vaccinationSite.base()}/${vaccinationSiteId}/saisie-intervention`, {
                state: { vaccinationSite: res.data },
            });
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const columns = [
        {
            field: "id",
            headerName: "Identifiant chantier",
            width: 150,
        },
        {
            field: "billingStatus",
            headerName: "Statut facturation",
            width: 150,
            valueGetter: (params: GridRenderCellParams) =>
                billingStatuses.find((status) => status.key === params.row.billingStatus)?.label,
            sortable: false,
        },
        {
            field: "date",
            headerName: "Date chantier",
            width: 150,
            valueFormatter: (params: GridValueFormatterParams<string>) =>
                params.value ? toLocaleDateFormat(params.value) : "",
        },
        {
            field: "userType",
            headerName: "Type de vaccinateur",
            width: 150,
            valueFormatter: (params: GridValueFormatterParams<string>) => VaccinationSiteUserType[params.value],
            sortable: false,
        },
        {
            field: "dpeId",
            headerName: "DPE",
            width: 200,
            valueGetter: (params: GridRenderCellParams) => `${params.row.dpeId} - ${params.row.dpeName}`,
        },
        {
            field: "veterinaryId",
            headerName: "Vétérinaire",
            width: 200,
            valueGetter: (params: GridRenderCellParams) =>
                `${params.row.veterinaryId} - ${params.row.veterinaryLastname.toUpperCase()} ${
                    params.row.veterinaryFirstname
                }`,
        },
        {
            field: "departmentInseeCode",
            headerName: "Département du chantier",
            width: 200,
            valueGetter: (params: GridRenderCellParams) =>
                params.row.departmentInseeCode
                    ? `${params.row.departmentInseeCode} - ${
                          departments.find((d) => d.inseeCode === params.row.departmentInseeCode)?.name
                      }`
                    : "",
        },
        {
            field: "farmId",
            headerName: "Identifiant établissement",
            width: 200,
        },
        {
            field: "workshopIds",
            headerName: "Identifiant atelier",
            width: 150,
            sortable: false,
            renderCell: (params: GridRenderCellParams) =>
                params.row.workshopIds.length > 0 && (
                    <>
                        <WorkshopsCell workshopIds={params.row.workshopIds} />
                        <Typography variant="body2">{params.row.workshopIds[0]}</Typography>
                        {params.row.workshopIds.length > 1 && <Typography variant="body2">...</Typography>}
                    </>
                ),
        },
        {
            ...ActionsColumnProps,
            renderCell: (params: GridRenderCellParams) => (
                <Box>
                    <ViewAction
                        title="Voir le détail"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleViewVaccinationSite(params.row.id);
                        }}
                    />
                    {params.row.modifiable && (
                        <>
                            <PermissionsCheck
                                requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                                preferences={[Preference.IAHP]}
                            >
                                <EditAction
                                    title="Modifier"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleUpdateVaccinationSite(params.row.id);
                                    }}
                                />
                            </PermissionsCheck>
                            <PermissionsCheck
                                requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                                preferences={[Preference.IAHP]}
                            >
                                <IconActionButton
                                    icon={<Plus />}
                                    title="Créer une intervention de vaccination"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleCreateVaccinationIntervention(params.row.id);
                                    }}
                                />
                            </PermissionsCheck>
                            <PermissionsCheck
                                requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                                preferences={[Preference.IAHP]}
                            >
                                <DeleteAction
                                    title="Supprimer"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedVaccinationSite(params.row);
                                        handleDeleteVaccinationSite(params.row.id);
                                    }}
                                />
                            </PermissionsCheck>
                        </>
                    )}
                </Box>
            ),
            width: 250,
        },
    ];

    const filterConfigurations: FilterConfigurations<IVaccinationSiteFilters> = {
        id: { label: "Identifiant chantier", type: FilterType.NUMBER },
        billingStatus: {
            label: "Statut facturation",
            type: FilterType.SELECT,
            values: billingStatuses,
        },
        date: { label: "Date chantier", type: FilterType.DATEPICKER },
        userType: {
            label: "Type de vaccinateur",
            type: FilterType.SELECT,
            values: convertEnumToKeyLabelObject(VaccinationSiteUserType),
        },
        dpe: { label: "DPE", type: FilterType.INPUT },
        veterinary: { label: "Vétérinaire", type: FilterType.INPUT },
        departmentInseeCode: {
            label: "Département du chantier",
            type: FilterType.SELECT_AUTOCOMPLETE,
            values: departments.map((d) => ({ label: `${d.inseeCode} - ${d.name}`, key: d.inseeCode })),
        },
        farmId: { label: "Identifiant établissement", type: FilterType.INPUT },
    };

    return (
        <>
            <Stack width="100%" spacing={2}>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Typography variant="h4">Mes chantiers</Typography>

                    <PermissionsCheck
                        requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                        preferences={[Preference.IAHP]}
                    >
                        <GenericButton
                            onClick={() => navigate(routerLinks.iahp.vaccinationSite.form())}
                            label="Nouveau chantier"
                            startIcon={<Add />}
                        />
                    </PermissionsCheck>
                </Box>
                <IconBannerText
                    message={`Les chantiers facturés ou en attente de facturation ainsi que les interventions associées ne sont plus modifiables. Vous pouvez suivre leur état d'avancement dans la section "Facturation IAHP".`}
                    icon={<Info />}
                    color={colors.white}
                    backgroundColor={colors.primaryColor}
                />
                <Card>
                    <StyledCardContent
                        sx={{
                            "& .selected": {
                                backgroundColor: colors.background,
                            },
                        }}
                    >
                        <GenericFilters
                            inputFilters={inputFilters}
                            filterConfigurations={filterConfigurations}
                            initialValues={{
                                id: "",
                                billingStatus: [],
                                date: [null, null],
                                userType: [],
                                dpe: "",
                                veterinary: "",
                                departmentInseeCode: [],
                                farmId: "",
                            }}
                            setInputFilters={setInputFilters}
                        />
                        <GenericTable
                            rows={vaccinationSites}
                            columns={columns}
                            onPageSizeChange={handlePageSizeChange}
                            onPageChange={handlePageChange}
                            page={pagination.page}
                            pageSize={pagination.pageSize}
                            autoHeight
                            sortingMode="server"
                            paginationMode="server"
                            sortModel={sortModel}
                            rowCount={rowCount}
                            onSortModelChange={(model) => setSortModel(model)}
                            sortingOrder={["asc", "desc"]}
                            filterMode="server"
                            rowsPerPageOptions={defaultVaccinationRowsPerPage}
                            onRowClick={(params) => setSelectedVaccinationSite(params.row)}
                            getRowClassName={(params) =>
                                params.row.id === selectedVaccinationSite?.id ? "selected" : ""
                            }
                        />
                    </StyledCardContent>
                </Card>
                {selectedVaccinationSite && (
                    <VaccinationSiteDetails
                        vaccinationSite={selectedVaccinationSite}
                        refreshVaccinationSites={getVaccinationSites}
                    />
                )}
            </Stack>
            {openDeleteDialog && (
                <VaccinationSiteDeleteDialog
                    vaccinationSiteId={selectedVaccinationSite.id}
                    vaccinationSiteWorkshopsInfo={vaccinationSiteWorkshopsInfo}
                    onValid={deleteVaccinationSite}
                    onClose={() => setOpenDeleteDialog(false)}
                    hasCertificate={selectedVaccinationSite.hasCertificate}
                />
            )}
            {openRecapDialog && (
                <VaccinationSiteDetailsDialog
                    vaccinationSite={vaccinationSite}
                    onClose={() => setOpenRecapDialog(false)}
                />
            )}
        </>
    );
}
