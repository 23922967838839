// === NPM
import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AddRounded } from "@mui/icons-material";
import { Card, CardHeader, Grid, Stack, Typography } from "@mui/material";
import { z } from "zod";
// === LOCAL
import { ReactComponent as Delete } from "@/assets/icons/actions/delete.svg";
import { ReactComponent as Info } from "@/assets/icons/info.svg";
import ContextualActionButton from "@/components/generics/buttons/ContextualActionButton";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import IconBannerText from "@/components/generics/IconBannerText";
import GenericAutocomplete from "@/components/generics/inputs/GenericAutocomplete";
import GenericComment from "@/components/generics/inputs/GenericComment";
import GenericErrorMessageText from "@/components/generics/text/GenericErrorMessageText";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import useTimeout from "@/hooks/useTimeout";
import { HttpStatus } from "@/interfaces/global";
import { IVaccinationSiteDpe } from "@/interfaces/vaccination";
import { colors, statusColor } from "@/resources/CssConstant";
import { stringRequired } from "@/resources/FormUtils";
import { useAuth } from "@/routers/useAuth";
import VaccinationService from "@/services/VaccinationService";
import { BillingStatusKeys, ICreateMemory, IValidatedVaccinationSite } from "../../interface";
import DropableList from "./DropableList";
import VaccinationSiteTile from "./VaccinationSiteTile";

interface MemoryDialogProps {
    onClose: (value: boolean, memory?: ICreateMemory) => void;
    memoryId?: number;
    title?: string;
    dpeId?: string;
}

const memorySchema = z.object({
    dpeId: stringRequired(),
    title: stringRequired(),
    vaccinationSiteIds: z.array(z.number()).min(1, "Veuillez ajouter au moins un chantier"),
});

type FormSchema = z.infer<typeof memorySchema>;

export default function MemoryDialog({ onClose, memoryId, title, dpeId }: Readonly<MemoryDialogProps>) {
    const auth = useAuth();
    const [dpes, setDpes] = useState<IVaccinationSiteDpe[]>([]);
    const [validatedVaccinationSites, setValidatedVaccinationSites] = useState<IValidatedVaccinationSite[]>([]);
    const [selectedVaccinationSites, setSelectedVaccinationSites] = useState<IValidatedVaccinationSite[]>([]);
    const [selectedDpe, setSelectedDpe] = useState<IVaccinationSiteDpe>(null);
    const [dpeSearch, setDpeSearch] = useState<string>("");

    const {
        formState: { errors },
        handleSubmit,
        setValue,
        watch,
        control,
    } = useForm<FormSchema>({
        resolver: zodResolver(memorySchema),
        defaultValues: {
            dpeId: dpeId ?? "",
            title: title ?? "",
            vaccinationSiteIds: [],
        },
    });

    const vaccinationSiteIds = watch("vaccinationSiteIds");

    useEffect(() => {
        if (memoryId) getDpes();
    }, []);

    useEffect(() => {
        getValidatedVaccinationSites();
    }, [selectedDpe]);

    useTimeout(() => {
        if (dpeSearch) {
            getDpes();
        }
    }, [dpeSearch]);

    const isTouchDevice = () => {
        if ("ontouchstart" in window) {
            return true;
        }
        return false;
    };

    const getDpes = async () => {
        const payload = {
            dpe: dpeId ?? dpeSearch,
            departmentInseeCode: auth.userInfo.properties.inseeCode,
            billingStatus: BillingStatusKeys.VALIDATED,
        };
        const res = await VaccinationService.getVaccinationSitesDpes(payload);
        if (res.status === HttpStatus.OK) {
            if (memoryId) setSelectedDpe(res.data[0]);
            setDpes(res.data);
        }
    };

    const getValidatedVaccinationSites = async () => {
        if (!selectedDpe) {
            setValidatedVaccinationSites([]);
            return;
        }
        const res = await VaccinationService.getValidatedVaccinationSites(selectedDpe.id);
        if (res.status === HttpStatus.OK) {
            setValidatedVaccinationSites(res.data);
        }
    };

    const onChangeDpe = (event) => {
        if (event?.target?.value) {
            setSelectedDpe(event.target.value);
            setSelectedVaccinationSites([]);
            setValue("vaccinationSiteIds", []);
            setValue("dpeId", event.target.value?.id);
        }
    };

    const onSubmit = async (data: FormSchema) => {
        onClose(true, data as ICreateMemory);
    };

    const deleteVaccinationSite = (vaccinationSite: IValidatedVaccinationSite) => {
        const filterSelected = selectedVaccinationSites.filter((site) => site.id !== vaccinationSite.id);
        setSelectedVaccinationSites(filterSelected);
        const updatedValidated = [...validatedVaccinationSites, vaccinationSite];
        setValidatedVaccinationSites(updatedValidated);
        setValue(
            "vaccinationSiteIds",
            vaccinationSiteIds.filter((id) => id !== vaccinationSite.id)
        );
    };

    const addVaccinationSite = (vaccinationSite: IValidatedVaccinationSite) => {
        const filterValidated = validatedVaccinationSites.filter((site) => site.id !== vaccinationSite.id);
        setValidatedVaccinationSites(filterValidated);
        const updateSelected = [...selectedVaccinationSites, vaccinationSite];
        setSelectedVaccinationSites(updateSelected);
        setValue("vaccinationSiteIds", [...vaccinationSiteIds, vaccinationSite.id]);
    };

    const addAll = () => {
        setValidatedVaccinationSites([]);
        const updateSelected = [...selectedVaccinationSites, ...validatedVaccinationSites];
        setSelectedVaccinationSites(updateSelected);
        setValue(
            "vaccinationSiteIds",
            updateSelected.map((v) => v.id)
        );
    };

    const deleteAll = () => {
        setSelectedVaccinationSites([]);
        const updatedValidated = [...validatedVaccinationSites, ...selectedVaccinationSites];
        setValidatedVaccinationSites(updatedValidated);
        setValue("vaccinationSiteIds", []);
    };

    const renderVaccinationSiteDropzone = () => (
        <Stack direction="row" spacing={2} sx={{ maxHeight: "calc(100% - 250px)", minHeight: "300px" }}>
            <DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend}>
                <Card sx={{ padding: 2, width: "100%" }}>
                    <CardHeader
                        titleTypographyProps={{ fontSize: 18, fontWeight: 500 }}
                        title="Chantiers du DPE validés par la DDPP"
                        action={
                            <ContextualActionButton
                                startIcon={<AddRounded />}
                                onClick={() => addAll()}
                                label="Tout ajouter"
                            />
                        }
                    />
                    <StyledCardContent sx={{ overflow: "auto", height: "85%", alignContent: "center", mt: 1 }}>
                        <Stack spacing={2} width="100%">
                            {validatedVaccinationSites.length > 0 ? (
                                validatedVaccinationSites.map((site) => (
                                    <VaccinationSiteTile
                                        key={site.id}
                                        vaccinationSite={site}
                                        onAdd={addVaccinationSite}
                                    />
                                ))
                            ) : (
                                <Typography variant="bold" alignSelf="center" color={colors.secondaryColor}>
                                    {selectedDpe ? "Aucun chantier disponible" : "Veuillez sélectionner un DPE"}
                                </Typography>
                            )}
                        </Stack>
                    </StyledCardContent>
                </Card>

                <Card sx={{ padding: 2, width: "100%" }}>
                    <CardHeader
                        title={
                            <Typography variant="bold" sx={{ fontSize: 18, fontWeight: 500 }}>
                                {`Chantiers ajoutés (${selectedVaccinationSites.length})`}
                                <Typography component="span" variant="h5" color="error">
                                    *
                                </Typography>
                            </Typography>
                        }
                        action={
                            <ContextualActionButton
                                startIcon={<Delete />}
                                onClick={() => deleteAll()}
                                label="Tout supprimer"
                                color="error"
                            />
                        }
                    />
                    <StyledCardContent sx={{ overflow: "auto", height: "90%" }}>
                        <GenericErrorMessageText fieldError={errors?.vaccinationSiteIds} />
                        <DropableList
                            addVaccinationSite={addVaccinationSite}
                            onDelete={deleteVaccinationSite}
                            selectedVaccinationSites={selectedVaccinationSites}
                        />
                    </StyledCardContent>
                </Card>
            </DndProvider>
        </Stack>
    );

    const renderVaccinationInformations = () => (
        <Card sx={{ padding: 2, width: "100%", maxHeight: "200px", minHeight: "200px" }}>
            <CardHeader titleTypographyProps={{ fontSize: 18, fontWeight: 500 }} title="Informations du mémoire" />
            <StyledCardContent>
                <Grid container columnSpacing={2}>
                    <Grid item xs={6}>
                        <Controller
                            name="title"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <GenericComment
                                    value={value}
                                    label="Libellé du mémoire"
                                    onChange={onChange}
                                    helperText={error?.message}
                                    rows={1}
                                    placeholder="Libellé du mémoire"
                                    maxLength={100}
                                    disabled={!!memoryId}
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <GenericAutocomplete
                            value={selectedDpe}
                            onChange={onChangeDpe}
                            onInputChange={(event, value) => setDpeSearch(value)}
                            label="N° ordre - Nom du DPE"
                            options={dpes}
                            required
                            getOptionLabel={(option) =>
                                option.id
                                    ? `${option.id} - ${option.name} - ${option.postalCode} ${option.cityName}`
                                    : ""
                            }
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            error={!!errors?.dpeId}
                            helperText={errors?.dpeId?.message}
                            noOptionsText={
                                dpeSearch ? "Aucun résultat" : "Saisissez des caractères pour lancer la recherche"
                            }
                            disabled={!!memoryId}
                        />
                    </Grid>
                </Grid>
            </StyledCardContent>
        </Card>
    );

    const renderContent = () => (
        <Stack spacing={2} sx={{ height: "100%" }}>
            <IconBannerText
                icon={<Info />}
                message="Les DPE proposés sont ceux qui ont des chantiers dans votre département, validés par vos soins et non facturés."
                color="#183B55"
                backgroundColor={statusColor.infoBackground}
            />
            {renderVaccinationInformations()}
            {renderVaccinationSiteDropzone()}
        </Stack>
    );

    return (
        <GenericDialog
            maxWidth="xl"
            paperProps={{ sx: { width: "100%", height: "100%" } }}
            title={memoryId ? `Ajouter des chantiers au mémoire n°${memoryId}` : "Création d'un mémoire de paiement"}
            onClose={() => onClose(false)}
            renderContent={() => renderContent()}
            renderActions={() => (
                <GenericActionsDialog
                    onClose={() => onClose(false)}
                    onSubmit={handleSubmit(onSubmit)}
                    closeLabel="Fermer"
                />
            )}
        />
    );
}
