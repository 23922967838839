// === NPM
import React from "react";
import { Box, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Info } from "@/assets/icons/info.svg";
import { colors } from "@/resources/CssConstant";

interface InfoTextProps {
    message: string;
    py?: number;
}

export default function InfoText({ message, py }: Readonly<InfoTextProps>) {
    return (
        <Stack spacing={1} direction="row" alignItems="center" py={py ?? 3}>
            <Box display="flex" alignItems="center" sx={{ color: colors.primaryColor }}>
                <Info />
            </Box>
            <Typography variant="bold" color={colors.primaryColor}>
                {message}
            </Typography>
        </Stack>
    );
}
