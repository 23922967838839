// === NPM
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, FormControlLabel, FormGroup, Skeleton, Stack, Switch, Typography } from "@mui/material";
// === LOCAL
import PermissionsCheck from "@/components/generics/PermissionsCheck";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { useProvideGlobal } from "@/context/useGlobalContext";
import { HttpStatus } from "@/interfaces/global";
import { FeatureFlags, hasFeatureFlag } from "@/resources/hasFeatureFlag";
import { UserSituation } from "@/resources/PermissionConstant";
import { useAuth } from "@/routers/useAuth";
import UserService from "@/services/UserService";
import { IUserPreferences, IVehicle } from "../interface";
import VehicleInformations from "./containers/VehicleInformations";

export default function UserPreferences() {
    const auth = useAuth();
    const { loadingButton } = useProvideGlobal();
    const [preferences, setPreferences] = useState<IUserPreferences>(null);

    useEffect(() => {
        getUserPreferences();
    }, []);

    const getUserPreferences = async () => {
        const res = await UserService.getUserPreferences(auth.userInfo.id);
        if (res.status === HttpStatus.OK) {
            setPreferences(res.data);
        }
    };

    const handlePreferences = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const payload = { ...preferences, [event.target.name]: event.target.checked };
        setPreferences(payload);
        const res = await UserService.putUserPreferences(auth.userInfo.id, payload);
        if (res.status === HttpStatus.OK) {
            toast.success("Préférences mises à jour avec succès");
            auth.refreshUserInfo();
        } else {
            setPreferences((prev) => ({
                ...prev,
                [event.target.name]: !event.target.checked,
            }));
        }
    };

    const handleVehiclePreferences = async (data: IVehicle) => {
        const payload = { ...preferences, vehicle: data };
        const res = await UserService.putUserPreferences(auth.userInfo.id, payload);
        if (res.status === HttpStatus.OK) {
            toast.success("Préférences mises à jour avec succès");
            auth.refreshUserInfo();
        }
    };

    return preferences ? (
        <Stack width="100%" height="100%" spacing={2}>
            <Typography variant="h5">Mes informations</Typography>
            <Card sx={{ p: 2, width: "100%" }}>
                <StyledCardContent>
                    <FormGroup>
                        <PermissionsCheck requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={preferences.iahp}
                                        name="iahp"
                                        onChange={handlePreferences}
                                        disabled={loadingButton}
                                    />
                                }
                                label="Je suis vétérinaire sanitaire d'élevage de canards."
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={preferences.sheep}
                                        name="sheep"
                                        onChange={handlePreferences}
                                        disabled={loadingButton}
                                    />
                                }
                                label="Je suis vétérinaire sanitaire pour les élevages ovins."
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={preferences.cattle}
                                        name="cattle"
                                        onChange={handlePreferences}
                                        disabled={loadingButton}
                                    />
                                }
                                label="Je suis vétérinaire sanitaire pour les élevages bovins."
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={preferences.butcherySector}
                                        name="butcherySector"
                                        onChange={handlePreferences}
                                        disabled={loadingButton}
                                    />
                                }
                                label="Je suis vétérinaire d'élevage d'équins."
                            />
                        </PermissionsCheck>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={preferences.emailNotification}
                                    name="emailNotification"
                                    onChange={handlePreferences}
                                    disabled={loadingButton}
                                />
                            }
                            label="Je souhaite recevoir les notifications envoyées à destination de ma boîte mail."
                        />
                    </FormGroup>
                </StyledCardContent>
            </Card>
            {hasFeatureFlag(FeatureFlags.VACCINATION_SITES) && (
                <PermissionsCheck requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}>
                    <Typography variant="h5">Informations de mon véhicule</Typography>
                    <VehicleInformations vehicle={preferences.vehicle} onValid={handleVehiclePreferences} />
                </PermissionsCheck>
            )}
        </Stack>
    ) : (
        <Skeleton variant="rounded" height={100} width="100%" />
    );
}
