// === NPM
import { AxiosResponse } from "axios";
// === LOCAL
import {
    ICatalog,
    ICreateTraining,
    ITraining,
    ITrainingDpe,
    ITrainingStatus,
    ITrainingUserInformation,
} from "@/components/ContinuousTraining/interface";
import { IAttestationsVeterinaryFilters } from "@/components/HealthAccreditationTraining/containers/Sessions/containers/AttestationsVeterinary/AttestationsVeterinary";
import { IInscriptionsOvvtFilters } from "@/components/HealthAccreditationTraining/containers/Sessions/containers/InscriptionsOvvt/InscriptionsOvvt";
import { IInscriptionsVeterinaryFilters } from "@/components/HealthAccreditationTraining/containers/Sessions/containers/InscriptionsVeterinary/InscriptionsVeterinary";
import {
    ICatalogHealthAccreditationTrainingFilters,
    IHealthAccreditationTrainingCreate,
    IInscriptionOvvt,
    IInscriptionVeterinary,
    INextSessionScheduled,
    IParticipationDdpp,
    IRegistration,
    IRegistrationCreate,
    ISession,
    ISessionCounts,
    ISessionCountsDdpp,
    ISessionCountsDdppProcess,
    ISessionCountsOvvt,
    ISessionCreate,
    ISessionDdppProcess,
    ISessionInfo,
    ISessionShortAdminDdppOvvt,
    ISessionShortVeterinary,
    ISessionsToProcessFilters,
    IViewFilters,
} from "@/components/HealthAccreditationTraining/interface";
import { ArrayOfTwo, HttpMethod, IExportFilename, Loader, SearchParams, UserTypeHeader } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import {
    IHealthAccreditationTraining,
    IHealthAccreditationTrainingShort,
    ITrainingReferential,
    ITrainingReferentialArchived,
    ITrainingType,
} from "@/interfaces/training";
import { CALYPSO_HEADERS } from "@/resources/AppConstant";
import { formatParams, objToQueryParams } from "@/resources/utils";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class TrainingService {
    // REFERENTIALS
    async getTypes(): Promise<AxiosResponse<ITrainingReferential[]>> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.types,
            method: HttpMethod.GET,
            loader: Loader.TABLE,
        });
    }
    async postType(data: { label: string }): Promise<AxiosResponse<ITrainingReferential[]>> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.types,
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async deleteType(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.type(uuid),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    async putType(uuid: string, data: { label: string }): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.type(uuid),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    async getStatus(): Promise<AxiosResponse<ITrainingStatus[]>> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.status,
            method: HttpMethod.GET,
        });
    }

    async getFormats(): Promise<AxiosResponse<ITrainingReferentialArchived[]>> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.formats,
            method: HttpMethod.GET,
            loader: Loader.TABLE,
        });
    }

    async postFormat(data: { label: string }): Promise<AxiosResponse<ITrainingReferential[]>> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.formats,
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async deleteFormat(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.format(uuid),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    async putFormat(uuid: string, data: { label: string }): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.format(uuid),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    async archiveFormat(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.archiveFormat(uuid),
            method: HttpMethod.PATCH,
            loader: Loader.REQUEST,
        });
    }

    async restoreFormat(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.restoreFormat(uuid),
            method: HttpMethod.PATCH,
            loader: Loader.REQUEST,
        });
    }

    // CONTINUOUS-TRAINING

    async getUserTrainingInformations(): Promise<AxiosResponse<ITrainingUserInformation>> {
        return HttpService.fetch({
            url: endpoints.trainingService.continuousTraining.trainingInformations(),
            method: HttpMethod.GET,
        });
    }

    async getContinuousTrainings(
        payload: SearchParams &
            Partial<{
                title: string;
                typeUuid: string[];
                formatUuid: string[];
                startDate: ArrayOfTwo[];
                ectsPoints: string;
                allTraings: string;
            }>
    ): Promise<AxiosResponse<ITraining[]>> {
        return HttpService.fetch({
            url: endpoints.trainingService.continuousTraining.base,
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async postContinuousTraining(data: ICreateTraining, file: File): Promise<AxiosResponse> {
        const blob = new Blob([JSON.stringify(data)], {
            type: "application/json",
        });
        const formData = new FormData();
        formData.append("training", blob);
        formData.append("file", file);

        return HttpService.fetch({
            url: endpoints.trainingService.continuousTraining.base,
            method: HttpMethod.POST,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
            loader: Loader.REQUEST,
        });
    }

    async putContinuousTraining(trainingUuid: string, data: ICreateTraining, file: File): Promise<AxiosResponse> {
        const blob = new Blob([JSON.stringify(data)], {
            type: "application/json",
        });
        const formData = new FormData();
        formData.append("training", blob);
        formData.append("file", file);

        return HttpService.fetch({
            url: endpoints.trainingService.continuousTraining.training(trainingUuid),
            method: HttpMethod.PUT,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
            loader: Loader.REQUEST,
        });
    }

    async getContinuousTrainingFile(trainingUuid: string, fileUuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.continuousTraining.trainingFile(trainingUuid, fileUuid),
            method: HttpMethod.GET,
            responseType: "blob",
            loader: Loader.BUTTON,
        });
    }

    async getContinuousTrainingDpeFile(
        dpeOrdinalNumber: string,
        trainingUuid: string,
        fileUuid: string
    ): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.continuousTraining.dpeFile(dpeOrdinalNumber, trainingUuid, fileUuid),
            method: HttpMethod.GET,
            responseType: "blob",
        });
    }

    async deleteTraining(trainingUuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.continuousTraining.training(trainingUuid),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    // CATALOG

    async getContinuousTrainingCatalog(
        payload: SearchParams &
            Partial<{
                title: string;
                organization: string[];
                format: string[];
                type: string[];
                themes: string;
                species: string[];
                startDate: string[];
                departments: string[];
                postalCode: string[];
                municipalities: string[];
            }>
    ): Promise<AxiosResponse<ICatalog[]>> {
        const query = objToQueryParams(payload);

        return HttpService.fetch({
            url: endpoints.trainingService.catalog.continuousTrainingCatalog(query),
            method: HttpMethod.GET,
            loader: Loader.TABLE,
        });
    }

    async getDpeContinuousTraining(
        dpeOrdinalNumber: string,
        payload: { startDate?: string }
    ): Promise<AxiosResponse<ITrainingDpe[]>> {
        const query = objToQueryParams(payload);

        return HttpService.fetch({
            url: endpoints.trainingService.continuousTraining.dpes(dpeOrdinalNumber, query),
            method: HttpMethod.GET,
            loader: Loader.TABLE,
        });
    }

    async exportTrainingCertificates(data: IExportFilename): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.exports.continuousTrainings(),
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    // HEALTH ACCREDITATION

    async getHealthAccreditationTrainings(
        payload: SearchParams & Partial<ICatalogHealthAccreditationTrainingFilters>
    ): Promise<AxiosResponse<IHealthAccreditationTrainingShort[]>> {
        return HttpService.fetch({
            url: endpoints.trainingService.healthAccreditationTraining.base,
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getHealthAccreditationTraining(uuid: string): Promise<AxiosResponse<IHealthAccreditationTraining>> {
        return HttpService.fetch({
            url: endpoints.trainingService.healthAccreditationTraining.training(uuid),
            method: HttpMethod.GET,
            loader: Loader.BUTTON,
        });
    }

    async postHealthAccreditationTraining(
        data: IHealthAccreditationTrainingCreate,
        files: File[]
    ): Promise<AxiosResponse> {
        const blob = new Blob([JSON.stringify(data)], {
            type: "application/json",
        });
        const formData = new FormData();
        files?.forEach((item) => {
            formData.append("files", item);
        });
        formData.append("training", blob);

        return HttpService.fetch({
            url: endpoints.trainingService.healthAccreditationTraining.base,
            method: HttpMethod.POST,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
            loader: Loader.REQUEST,
        });
    }

    async putHealthAccreditationTraining(
        uuid: string,
        data: IHealthAccreditationTrainingCreate,
        files: File[]
    ): Promise<AxiosResponse> {
        const blob = new Blob([JSON.stringify(data)], {
            type: "application/json",
        });
        const formData = new FormData();
        files?.forEach((item) => {
            formData.append("files", item);
        });
        formData.append("training", blob);

        return HttpService.fetch({
            url: endpoints.trainingService.healthAccreditationTraining.training(uuid),
            method: HttpMethod.PUT,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
            loader: Loader.REQUEST,
        });
    }

    async archiveHealthAccreditationTraining(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.healthAccreditationTraining.training(uuid),
            method: HttpMethod.PATCH,
            loader: Loader.REQUEST,
        });
    }

    async getHealthAccreditationTrainingFile(uuid: string, fileUuid: string): Promise<AxiosResponse<File>> {
        return HttpService.fetch({
            url: endpoints.trainingService.healthAccreditationTraining.trainingFile(uuid, fileUuid),
            method: HttpMethod.GET,
            responseType: "blob",
        });
    }

    async getHealthAccreditationTrainingTypes(): Promise<AxiosResponse<ITrainingType[]>> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.healthAccreditationTypes(),
            method: HttpMethod.GET,
        });
    }

    async postHealthAccreditationTrainingSession(data: ISessionCreate): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.sessions.base,
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async putHealthAccreditationTrainingSession(uuid: string, data: ISessionCreate): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.sessions.session(uuid),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    async getHealthAccreditationTrainingSessions(
        payload: SearchParams & Partial<IViewFilters>
    ): Promise<AxiosResponse<ISessionShortVeterinary[]> | AxiosResponse<ISessionShortAdminDdppOvvt[]>> {
        return HttpService.fetch({
            url: endpoints.trainingService.sessions.base,
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getHealthAccreditationTrainingSession(uuid: string): Promise<AxiosResponse<ISession>> {
        return HttpService.fetch({
            url: endpoints.trainingService.sessions.session(uuid),
            method: HttpMethod.GET,
            loader: Loader.BUTTON,
        });
    }

    async cancelHealthAccreditationTrainingSession(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.sessions.cancel(uuid),
            method: HttpMethod.PATCH,
            loader: Loader.REQUEST,
        });
    }

    async getHealthAccreditationTrainingSessionCounts(
        userType: UserTypeHeader.OVVT
    ): Promise<AxiosResponse<ISessionCountsOvvt>>;
    async getHealthAccreditationTrainingSessionCounts(
        userType: UserTypeHeader.DDPP
    ): Promise<AxiosResponse<ISessionCountsDdpp>>;
    async getHealthAccreditationTrainingSessionCounts(
        userType: UserTypeHeader
    ): Promise<AxiosResponse<ISessionCounts>> {
        return HttpService.fetch({
            url: endpoints.trainingService.sessions.counts(),
            method: HttpMethod.GET,
            headers: {
                [CALYPSO_HEADERS.USER_TYPE]: userType,
            },
        });
    }

    async getHealthAccreditationTrainingSessionDdppProcessCounts(): Promise<AxiosResponse<ISessionCountsDdppProcess>> {
        return HttpService.fetch({
            url: endpoints.trainingService.sessions.ddppCounts(),
            method: HttpMethod.GET,
        });
    }

    async getParticipations(
        sessionUuid: string,
        payload: SearchParams & Partial<IInscriptionsOvvtFilters>
    ): Promise<AxiosResponse<IInscriptionOvvt[]>> {
        return HttpService.fetch({
            url: endpoints.trainingService.sessions.participations(sessionUuid),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getAttendanceSheet(sessionUuid: string): Promise<AxiosResponse<File>> {
        return HttpService.fetch({
            url: endpoints.trainingService.sessions.attendanceSheet(sessionUuid),
            method: HttpMethod.GET,
            loader: Loader.REQUEST,
            responseType: "blob",
        });
    }

    async postAttendanceSheet(sessionUuid: string, file: File): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append("file", file);

        return HttpService.fetch({
            url: endpoints.trainingService.sessions.attendanceSheet(sessionUuid),
            method: HttpMethod.POST,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
            loader: Loader.REQUEST,
        });
    }

    async validateParticipation(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.participations.validate(uuid),
            method: HttpMethod.PATCH,
            loader: Loader.BUTTON,
        });
    }

    async invalidateParticipation(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.participations.invalidate(uuid),
            method: HttpMethod.PATCH,
            loader: Loader.BUTTON,
        });
    }

    async patchPresent(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.participations.present(uuid),
            method: HttpMethod.PATCH,
            loader: Loader.BUTTON,
        });
    }

    async patchAbsent(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.participations.absent(uuid),
            method: HttpMethod.PATCH,
            loader: Loader.BUTTON,
        });
    }

    async deleteRegistration(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.participations.participation(uuid),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    async postRegistration(data: IRegistrationCreate): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.participations.base,
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async getRegistration(uuid: string, loader: boolean = true): Promise<AxiosResponse<IRegistration>> {
        return HttpService.fetch({
            url: endpoints.trainingService.participations.participation(uuid),
            method: HttpMethod.GET,
            loader: loader && Loader.BUTTON,
        });
    }

    async putRegistration(uuid: string, data: IRegistrationCreate): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.participations.participation(uuid),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    async getVeterinaryRegistrations(
        payload: SearchParams & Partial<IInscriptionsVeterinaryFilters>
    ): Promise<AxiosResponse<IInscriptionVeterinary[]>> {
        return HttpService.fetch({
            url: endpoints.trainingService.participations.base,
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getVeterinaryAttestations(
        payload: SearchParams & Partial<IAttestationsVeterinaryFilters>
    ): Promise<AxiosResponse<IInscriptionVeterinary[]>> {
        return HttpService.fetch({
            url: endpoints.trainingService.participations.validated(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getNextSessionScheduled(): Promise<AxiosResponse<INextSessionScheduled | null>> {
        return HttpService.fetch({
            url: endpoints.trainingService.participations.next(),
            method: HttpMethod.GET,
        });
    }

    async getHealthAccreditationInfo(): Promise<AxiosResponse<ISessionInfo>> {
        return HttpService.fetch({
            url: endpoints.trainingService.participations.info(),
            method: HttpMethod.GET,
        });
    }

    async getHealthAccreditationAttestation(uuid: string): Promise<AxiosResponse<File>> {
        return HttpService.fetch({
            url: endpoints.trainingService.participations.attestation(uuid),
            method: HttpMethod.GET,
            loader: Loader.BUTTON,
            responseType: "blob",
        });
    }

    async getHorsePowers(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.horsePowers(),
            method: HttpMethod.GET,
        });
    }

    async getHealthAccreditationTrainingSessionToProcessDdpp(
        payload: SearchParams & Partial<ISessionsToProcessFilters>
    ): Promise<AxiosResponse<ISessionDdppProcess[]>> {
        return HttpService.fetch({
            url: endpoints.trainingService.sessions.sessionsToProcess(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getHealthAccreditationTrainingSessionParticipationsToProcess(
        uuid: string
    ): Promise<AxiosResponse<IParticipationDdpp[]>> {
        return HttpService.fetch({
            url: endpoints.trainingService.sessions.participationsToProcess(uuid),
            method: HttpMethod.GET,
            loader: Loader.TABLE,
        });
    }
}

export default new TrainingService();
