// === NPM
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
// === LOCAL
import { HttpStatus } from "@/interfaces/global";
import { IContinuousTrainingOrganization } from "@/interfaces/referential";
import { ITrainingReferential } from "@/interfaces/training";
import { UserSituation } from "@/resources/PermissionConstant";
import PermissionsTabs from "@/resources/PermissionsTabs";
import { routerLinks } from "@/routers/RouterConstant";
import ReferentialService from "@/services/ReferentialService";
import TrainingService from "@/services/TrainingService";
import PageContent from "../generics/layout/PageContent";
import ContinuousTrainingTour from "./containers/ContinuousTrainingTour";

export default function ContinuousTraining() {
    const [types, setTypes] = useState<ITrainingReferential[]>([]);
    const [organizations, setOrganizations] = useState<IContinuousTrainingOrganization[]>([]);
    const [formats, setFormats] = useState<ITrainingReferential[]>([]);

    useEffect(() => {
        getTrainingTypesReferential();
        getTrainingOrganizationsReferential();
        getTrainingFormatsReferential();
    }, []);

    const getTrainingOrganizationsReferential = async () => {
        const res = await ReferentialService.getContinuousTrainingOrganizationReferential({ archived: ["false"] });
        if (res.status === HttpStatus.OK) {
            setOrganizations(res.data);
        }
    };

    const getTrainingFormatsReferential = async () => {
        const res = await TrainingService.getFormats();
        if (res.status === HttpStatus.OK) {
            setFormats(res.data.filter((format) => !format.archived));
        }
    };

    const getTrainingTypesReferential = async () => {
        const res = await TrainingService.getTypes();
        if (res.status === HttpStatus.OK) {
            setTypes(res.data);
        }
    };

    return (
        <>
            <ContinuousTrainingTour />
            <PermissionsTabs
                tabs={[
                    {
                        url: routerLinks.continuousTraining.trainings(),
                        label: "Formations continues",
                        permissions: [UserSituation.REGISTERED_IN_PRACTICE],
                        id: "continuous-training-tab",
                    },
                    {
                        url: routerLinks.continuousTraining.catalog(),
                        label: "Catalogue des formations continues",
                        permissions: [UserSituation.ADMIN_CALYPSO, UserSituation.REGISTERED_IN_PRACTICE],
                        id: "catalog-tab",
                    },
                ]}
            />
            <PageContent>
                <Outlet context={{ types, organizations, formats }} />
            </PageContent>
        </>
    );
}
