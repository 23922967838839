// === NPM
import React from "react";
import { Grid, Stack } from "@mui/material";
import * as DOMPurify from "dompurify";
// === LOCAL
import { ReactComponent as Duration } from "@/assets/icons/shared/duration.svg";
import { ReactComponent as Document } from "@/assets/icons/shared/paper.svg";
import { ReactComponent as Pet } from "@/assets/icons/shared/pet.svg";
import { ReactComponent as Show } from "@/assets/icons/shared/show.svg";
import { ReactComponent as Star } from "@/assets/icons/shared/star.svg";
import { ReactComponent as Attendees } from "@/assets/icons/training/catalog/attendees.svg";
import { ReactComponent as Type } from "@/assets/icons/training/catalog/type.svg";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import GenericAccordion from "@/components/generics/layout/GenericAccordion";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import StyledChip from "@/components/styled/StyledChip";
import { HttpStatus } from "@/interfaces/global";
import { IHealthAccreditationTraining } from "@/interfaces/training";
import { openPreview } from "@/resources/utils";
import TrainingService from "@/services/TrainingService";

interface ViewDialogProps {
    training: IHealthAccreditationTraining;
    onClose: () => void;
}

export default function ViewDialog({ training, onClose }: Readonly<ViewDialogProps>) {
    const onFilePreview = async (fileUuid: string) => {
        const file: File = await getTrainingFile(fileUuid);
        if (!file) return;
        openPreview(file);
    };

    const getTrainingFile = async (fileUuid: string): Promise<File> => {
        const res = await TrainingService.getHealthAccreditationTrainingFile(training.uuid, fileUuid);
        if (res.status === HttpStatus.OK) {
            const file = new Blob([res.data], { type: res.headers["content-type"] });
            const fileName = res.headers["content-disposition"].split("filename=")[1].slice(1, -1);
            const newFile = new File([file], fileName, {
                type: res.headers["content-type"],
            });
            return newFile;
        }
        return null;
    };

    return (
        <GenericDialog
            title={`${training.internalId} : ${training.title}`}
            onClose={onClose}
            renderActions={() => <GenericActionsDialog onClose={onClose} closeLabel="Retour" displaySubmit={false} />}
            renderContent={() => (
                <Stack spacing={1}>
                    <GenericAccordion title="Informations générales" defaultExpanded>
                        <Stack spacing={1} m={1}>
                            <GenericIconText
                                icon={<Star />}
                                text={`${training.ectsPoints} ECTS`}
                                iconTooltip="Points ECTS"
                            />
                            <GenericIconText
                                icon={<Type />}
                                text={training.type.label}
                                iconTooltip="Type de la formation"
                            />
                            <GenericIconText icon={<Pet />} text={training.targetPublic} iconTooltip="Public cible" />
                            <GenericIconText
                                icon={<Attendees />}
                                text={`${training.inscriptionNumberMin} à ${training.inscriptionNumberMax} participants`}
                                iconTooltip="Participants"
                            />
                            <GenericIconText icon={<Duration />} text={`${training.duration}h`} iconTooltip="Durée" />
                        </Stack>
                    </GenericAccordion>
                    <GenericAccordion title="Contenu et objectifs de la formation" defaultExpanded>
                        <Stack spacing={1}>
                            <div
                                className="ql-editor"
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(training.content),
                                }}
                            />
                            <Grid container spacing={1}>
                                {training?.attachedFiles?.map((attachment) => (
                                    <Grid item key={attachment.uuid}>
                                        <StyledChip
                                            key={attachment.uuid}
                                            label={attachment.filename}
                                            onDelete={() => onFilePreview(attachment.uuid)}
                                            deleteIcon={<Show />}
                                            icon={<Document style={{ color: "inherit" }} />}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Stack>
                    </GenericAccordion>
                </Stack>
            )}
        />
    );
}
