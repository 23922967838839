// === NPM
import React from "react";
import { Box, Card, CardHeader, Grid, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Location } from "@/assets/icons/shared/location.svg";
import { ReactComponent as Workshop } from "@/assets/icons/shared/workshop.svg";
import GenericAccordion from "@/components/generics/layout/GenericAccordion";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { useDepartments } from "@/context/useDepartmentContext";
import { colors } from "@/resources/CssConstant";

interface WorkshopsAccordionProps {
    departmentInseeCode: string;
    workshops: { id: string; city: string }[];
    farmId: string;
    farmName: string;
}

export default function WorkshopsAccordion({
    departmentInseeCode,
    workshops,
    farmId,
    farmName,
}: Readonly<WorkshopsAccordionProps>) {
    const { departments } = useDepartments();
    const department = departments.find((department) => department.inseeCode === departmentInseeCode);

    const renderWorkshop = (workshop) => (
        <Card sx={{ paddingX: 2, paddingY: 1 }}>
            <CardHeader
                title={`Atelier n° ${workshop.id}`}
                sx={{
                    color: colors.primaryColor,
                }}
            />
            <StyledCardContent>
                <GenericIconText
                    text={`${workshop.city} - ${department?.name}`}
                    icon={<Location />}
                    iconTooltip="Localisation"
                />
            </StyledCardContent>
        </Card>
    );

    return (
        <GenericAccordion title="Ateliers d'intervention" defaultExpanded>
            <Box sx={{ p: 2 }}>
                <Typography variant="bold">Établissement :</Typography>
                <GenericIconText text={`${farmName} - ${farmId}`} icon={<Workshop />} iconTooltip="Établissement" />
            </Box>
            <Box sx={{ p: 2 }}>
                <Typography variant="bold">Liste des ateliers :</Typography>
            </Box>
            <Grid container rowSpacing={3} columnSpacing={2}>
                {workshops.map((workshop) => (
                    <Grid item xs={12} md={6} lg={3} key={workshop.id}>
                        {renderWorkshop(workshop)}
                    </Grid>
                ))}
            </Grid>
        </GenericAccordion>
    );
}
