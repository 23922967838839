// === NPM
import React, { RefObject } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
    Card,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { z } from "zod";
// === LOCAL
import GenericDatePicker from "@/components/generics/inputs/GenericDatePicker";
import GenericTextField from "@/components/generics/inputs/GenericTextField";
import GenericErrorMessageText from "@/components/generics/text/GenericErrorMessageText";
import InfoText from "@/components/generics/text/InfoText";
import { IHealthReportingForm, IReportInformations } from "@/components/HealthReporting/AnimalAbuse/interface";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { FORM_TEXT, stringRequired } from "@/resources/FormUtils";
import { useAuth } from "@/routers/useAuth";
import { useFormHealthReporting } from "../../../useFormHealthReporting";

interface ReportInformationsProps {
    formRef: RefObject<HTMLButtonElement>;
    getValuesRef: RefObject<HTMLButtonElement>;
    handleNextStep: (partialValues: Partial<IHealthReportingForm>, finalStep?: boolean) => void;
    setSaveAndQuit: (value: boolean) => void;
}

const reportInformationSchema = z.object({
    dpeId: stringRequired(),
    observationDate: stringRequired().refine(
        (value) => (value ? DateTime.fromISO(value) <= DateTime.now().startOf("day") : false),
        {
            message: "La date doit être inférieure ou égale à la date du jour",
        }
    ),
    transmitted: z.boolean({
        required_error: FORM_TEXT.required,
        invalid_type_error: FORM_TEXT.required,
    }),
});

type ReportInformationValidationSchema = z.infer<typeof reportInformationSchema>;

export default function ReportInformations({
    formRef,
    getValuesRef,
    handleNextStep,
    setSaveAndQuit,
}: Readonly<ReportInformationsProps>) {
    const auth = useAuth();
    const { form, setForm } = useFormHealthReporting();

    const {
        formState: { errors },
        handleSubmit,
        control,
        getValues,
    } = useForm<ReportInformationValidationSchema>({
        resolver: zodResolver(reportInformationSchema),
        defaultValues: {
            dpeId: form?.reportInformations?.dpeId ?? "",
            observationDate: form?.reportInformations?.observationDate ?? null,
            transmitted: form?.reportInformations?.transmitted ?? null,
        },
    });

    const validateReportInformations = (formValues: ReportInformationValidationSchema) => {
        handleNextStep({ reportInformations: formValues as IReportInformations });
    };

    const saveDraftValues = () => {
        const values = getValues();
        setForm({
            ...form,
            reportInformations: values as IReportInformations,
        });
        setSaveAndQuit(true);
    };

    const renderHealthReportingInformation = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <FormControl fullWidth>
                    <Grid container spacing={2}>
                        <Grid item md={4} xs={12}>
                            <Controller
                                name="observationDate"
                                control={control}
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <GenericDatePicker
                                        value={value}
                                        onChange={onChange}
                                        label="Date d'observation des faits"
                                        required
                                        name="observationDate"
                                        maxDate={DateTime.now()}
                                        error={!!error}
                                        helperText={error?.message}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </FormControl>
            </StyledCardContent>
        </Card>
    );

    const renderHealthReportingDeclarant = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                        <FormControl fullWidth error={!!errors.dpeId} required>
                            <InputLabel>N° ordre - Nom du DPE</InputLabel>
                            <Controller
                                name="dpeId"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        value={field.value ?? ""}
                                        label="N° ordre - Nom du DPE"
                                        IconComponent={KeyboardArrowDown}
                                    >
                                        {auth.userInfo.properties.dpes.map((dpe) => (
                                            <MenuItem key={dpe.id} value={dpe.id}>
                                                {`${dpe.id} - ${dpe.name}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            <GenericErrorMessageText fieldError={errors?.dpeId} />
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <GenericTextField
                            label="N° ordre - Nom du vétérinaire"
                            value={`${auth.userInfo.id} - ${auth.userInfo.lastname} ${auth.userInfo.firstname}`}
                            disabled
                        />
                    </Grid>
                </Grid>
            </StyledCardContent>
        </Card>
    );

    const renderHealthReportingTransfer = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <InfoText message="Depuis la loi N°2021-1539 portant modification du code pénal (Art. 226-14), le vétérinaire peut lever son secret professionnel auprès du procureur de la république sans risque d'engager sa responsabilité civile, pénale ou disciplinaire. " />
                <FormControl>
                    <Typography variant="h5">
                        Souhaitez-vous porter ce signalement à la connaissance du procureur ?{" "}
                        <Typography component="span" variant="h5" color="error">
                            *
                        </Typography>
                    </Typography>
                    <Controller
                        name="transmitted"
                        control={control}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                            <>
                                <RadioGroup
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e.target.value === "true");
                                    }}
                                >
                                    <FormControlLabel
                                        value="false"
                                        control={<Radio />}
                                        label="Non"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="true"
                                        control={<Radio />}
                                        label="Oui"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                                {error && <FormHelperText error={!!errors}>{error?.message}</FormHelperText>}
                                {value && (
                                    <Typography variant="bold">
                                        Vous avez la possibilité de télécharger le récapitulatif de votre signalement
                                        après sa validation. Il vous appartient de l'adresser au procureur de la
                                        république avec vos documents.
                                    </Typography>
                                )}
                            </>
                        )}
                    />
                </FormControl>
            </StyledCardContent>
        </Card>
    );

    return (
        <Stack spacing={3} width="100%">
            <form onSubmit={handleSubmit(validateReportInformations)} noValidate>
                <Stack spacing={3} width="100%">
                    <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                        Informations du signalement
                    </Typography>
                    {renderHealthReportingInformation()}
                    <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                        Informations du déclarant
                    </Typography>
                    {renderHealthReportingDeclarant()}
                    <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                        Transfert du signalement
                    </Typography>
                    {renderHealthReportingTransfer()}
                </Stack>
                <button style={{ display: "none" }} type="submit" ref={formRef} />
                <button style={{ display: "none" }} onClick={() => saveDraftValues()} ref={getValuesRef} />
            </form>
        </Stack>
    );
}
