// === NPM
import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
// === LOCAL
import Spinner from "@/components/generics/layout/Spinner";
import { Preference, UserSituation } from "@/resources/PermissionConstant";
import { useAuth } from "./useAuth";

interface RoleRouteProps {
    redirection: string;
    permissions: UserSituation[];
    preferences?: Preference[];
    children: React.ReactNode;
}

export default function RoleRoute({ children, redirection, permissions, preferences }: RoleRouteProps) {
    const auth = useAuth();

    if (!permissions.includes(auth.userInfo.situation as UserSituation)) {
        return <Navigate to={redirection} />;
    }

    if (preferences?.length > 0 && preferences.every((p) => auth.userInfo.preferences[p] === false)) {
        return <Navigate to={redirection} />;
    }

    return <Suspense fallback={<Spinner />}>{children}</Suspense>;
}
