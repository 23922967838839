// === NPM
import React from "react";
import { useOutletContext } from "react-router-dom";
import { SellOutlined } from "@mui/icons-material";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
// === LOCAL
import { ReactComponent as Pet } from "@/assets/icons/shared/pet.svg";
import { ReactComponent as Syringe } from "@/assets/icons/shared/syringe.svg";
import GenericAccordion from "@/components/generics/layout/GenericAccordion";
import GenericTable from "@/components/generics/table/GenericTable";
import GenericTitleValueText from "@/components/generics/text/GenericTitleValueText";
import { GenericGridColumns } from "@/interfaces/global";
import { InjectionType, VaccineBatch } from "@/interfaces/vaccination";
import { toLocaleDateFormat } from "@/resources/utils";
import { IAnimalBatch, IAnimalInformation, VaccinationSiteOutletContext } from "../VaccinationSite/interface";

interface AnimalAccordionProps {
    animalInformation: IAnimalInformation;
    vaccineBatches: VaccineBatch[];
    diluentBatches: VaccineBatch[];
    vaccinationDate: string;
    injectionType: keyof typeof InjectionType;
    animalBatches?: IAnimalBatch[];
}

export default function AnimalAccordion({
    animalInformation,
    vaccineBatches,
    diluentBatches,
    vaccinationDate,
    injectionType,
    animalBatches,
}: Readonly<AnimalAccordionProps>) {
    const { vaccines, species, subSpecies, sectors, tiers, diluents } =
        useOutletContext<VaccinationSiteOutletContext>();

    const vaccine = vaccines.find((v) => v.gtinCode === animalInformation.vaccineGtinCode);
    const diluent = diluents.find((v) => v.gtinCode === animalInformation.diluentGtinCode);

    const columns: GenericGridColumns[] = [
        {
            field: "imepNumber",
            headerName: "IMEP",
            flex: 0.8,
            sortable: false,
        },
        {
            field: "previousInterventionId",
            headerName: "Numéro d'intervention de vaccination précédente",
            flex: 2,
            sortable: false,
        },
        {
            field: "declaredWorkshopId",
            headerName: "INUAV déclaré",
            flex: 1,
            sortable: false,
        },
        {
            field: "installationDate",
            headerName: "Date de mise en place déclarée",
            flex: 1.5,
            sortable: false,
            valueGetter: (params: GridRenderCellParams) => toLocaleDateFormat(params.row.installationDate),
        },
        {
            field: "hatchingDate",
            headerName: "Date d'éclosion",
            flex: 1,
            sortable: false,
            valueGetter: (params: GridRenderCellParams) => toLocaleDateFormat(params.row.hatchingDate),
        },
        {
            field: "age",
            headerName: "Âge à la vaccination",
            flex: 1,
            sortable: false,
            valueGetter: (params: GridRenderCellParams) =>
                `${
                    Math.round(
                        DateTime.fromISO(params.row.hatchingDate).diff(DateTime.fromISO(vaccinationDate), ["days"]).days
                    ) * -1
                } jours`,
        },
    ];

    const renderAnimalBatches = () => (
        <Stack direction="row" spacing={1} marginTop={2}>
            <Box>
                <SellOutlined />
            </Box>
            <Stack spacing={1} width="100%">
                <Typography variant="bold" fontSize={18}>
                    Déclaration de lot
                </Typography>
                <GenericTable
                    rows={animalBatches}
                    columns={columns}
                    getRowId={(row) => row.uuid}
                    autoHeight
                    hideFooter
                    disableToolbarButton
                    disableColumnResize
                />
            </Stack>
        </Stack>
    );

    return (
        <GenericAccordion title="Animaux vaccinés" defaultExpanded>
            <Grid container rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12} md={4} display="flex" gap={1}>
                    <Box>
                        <Syringe />
                    </Box>
                    <Stack spacing={1}>
                        <Box mt={-0.25}>
                            <Typography variant="bold" fontSize={18}>
                                Vaccin
                            </Typography>
                        </Box>
                        <GenericTitleValueText title="Nom du vaccin" value={vaccine?.name} />
                        <GenericTitleValueText
                            title="Numéro de lot du vaccin"
                            value={vaccineBatches.map((vb) => vb.number).join(", ")}
                        />
                        {diluent && (
                            <>
                                <GenericTitleValueText title="Nom du diluant" value={diluent?.name} />
                                <GenericTitleValueText
                                    title="Numéro de lot du diluant"
                                    value={diluentBatches.map((vb) => vb.number).join(", ")}
                                />
                            </>
                        )}
                    </Stack>
                </Grid>
                <Grid item xs={12} md={4} display="flex">
                    <Stack spacing={1}>
                        <Box height={27} />
                        <GenericTitleValueText title="Injection" value={InjectionType[injectionType]} />

                        <GenericTitleValueText
                            title="Nombre d'animaux vaccinés"
                            value={animalInformation.animalCount}
                        />
                        <GenericTitleValueText
                            title="Nombre de doses administrées"
                            value={animalInformation.doseCount}
                        />
                        <GenericTitleValueText
                            title="Nombre de flacons prescrits"
                            value={animalInformation.prescribedDoseCount}
                        />
                        {animalInformation.prescribedDoseCount >= 100 && (
                            <Typography variant="bold" color="error">
                                Êtes-vous sûr de vouloir prescrire plus de 100 flacons ?
                            </Typography>
                        )}
                    </Stack>
                </Grid>
                <Grid item xs={12} md={4} display="flex" gap={1}>
                    <Box>
                        <Pet />
                    </Box>
                    <Stack spacing={1}>
                        <Box mt={-0.25}>
                            <Typography variant="bold" fontSize={18}>
                                Animaux
                            </Typography>
                        </Box>
                        <GenericTitleValueText
                            title="Catégorie d'espèce"
                            value={species.find((s) => s.uuid === animalInformation.speciesUuid)?.label}
                        />
                        <GenericTitleValueText
                            title="Sous-catégorie d'espèce"
                            value={subSpecies.find((s) => s.uuid === animalInformation.subSpeciesUuid)?.label}
                        />
                        <GenericTitleValueText
                            title="Filière"
                            value={sectors.find((s) => s.key === animalInformation.sector)?.label}
                        />
                        <GenericTitleValueText
                            title="Etage"
                            value={tiers.find((t) => t.key === animalInformation.tier)?.label}
                        />
                    </Stack>
                </Grid>
                {animalInformation.comment && (
                    <Grid item marginX={4}>
                        <GenericTitleValueText title="Commentaire" value={animalInformation.comment} />
                    </Grid>
                )}
            </Grid>
            {animalBatches?.length > 0 && renderAnimalBatches()}
        </GenericAccordion>
    );
}
