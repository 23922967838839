import { EffectCallback, useEffect } from "react";
import useIsFirstRender from "./useIsFirstRender";

/**
 *
 * This is a custom React hook allowing to trigger a useEffect after the first render.
 * @param {EffectCallback} effect useEffect function
 * @param {unknown} trigger A value that the hook will watch for changes. When the value changes
 */
function useEffectAfterMount(effect: EffectCallback, trigger: unknown[]) {
    const isFirstRender = useIsFirstRender();

    useEffect(() => {
        if (isFirstRender) {
            return;
        }
        effect();
    }, [...trigger]);
}

export default useEffectAfterMount;
