// === NPM
import React from "react";
import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

export const InfoTooltip = styled(({ className, ...props }: Readonly<TooltipProps>) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500,
    },
});
