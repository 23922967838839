// CONTINUOUS TRAINING

export interface ITrainingReferential {
    uuid: string;
    label: string;
    code: string;
}

export interface ITrainingReferentialArchived extends ITrainingReferential {
    archived: boolean;
}

export enum ContinuousTrainingOrganizationType {
    PRIVATE = "Privé",
    PUBLIC = "Public",
    EDITION = "Edition",
}

// HEALTH ACCREDITATION TRAINING

export interface ITrainingType {
    uuid: string;
    label: string;
    fixed: boolean;
}

export interface IAttachment {
    uuid: string;
    filename: string;
}

export interface IHealthAccreditationTrainingShort {
    uuid: string;
    internalId: string;
    title: string;
    type: ITrainingType;
    targetPublic: string;
    duration: number;
    ectsPoints: number;
    inscriptionNumberMin: number;
    inscriptionNumberMax: number;
    hasSessions: boolean;
    archived: boolean;
}

export interface IHealthAccreditationTraining
    extends Omit<IHealthAccreditationTrainingShort, "hasSessions" | "archived"> {
    attachedFiles: IAttachment[];
    content: string;
}

export interface ITrainer {
    trainerType: string;
    veterinaryId?: string;
    lastname?: string;
    firstname?: string;
}

export enum SessionMode {
    FACE_TO_FACE = "Présentiel",
    DISTANCE = "Distanciel",
}

export enum TrainerType {
    VETERINARY = "Vétérinaire",
    MINISTRY_AGENT = "Agent du ministère de l'agriculture",
}
