// === NPM
import React from "react";
import { useDrop } from "react-dnd";
import { Card } from "@mui/material";
// === LOCAL
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { colors } from "@/resources/CssConstant";
import { IModule } from "../interface";

interface DropableModuleProps {
    index: number;
    dropModule: (module: IModule, index: number) => void;
}

export default function DropableModule({ index, dropModule }: Readonly<DropableModuleProps>) {
    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: "MODULE",
            drop: (item: IModule) => dropModule(item, index),
            collect: (monitor) => ({
                isOver: monitor.isOver({ shallow: true }),
            }),
        }),
        [index, dropModule]
    );

    return (
        <Card
            ref={drop}
            variant="outlined"
            sx={{
                borderRadius: "20px",
                width: "100%",
                height: "100%",
                transform: isOver ? "scale(1.1) rotate(8deg)" : "scale(1)",
                minHeight: 98,
            }}
        >
            <StyledCardContent
                sx={{
                    height: "100%",
                    backgroundColor: colors.lightGray,
                    width: "100%",
                }}
            />
        </Card>
    );
}
