// === NPM
import React, { Dispatch, SetStateAction, useContext, useState } from "react";
// === LOCAL
import { IVaccinationInterventionForm } from "./interface";

export interface UseFormIntervention {
    form: IVaccinationInterventionForm;
    setForm: Dispatch<SetStateAction<IVaccinationInterventionForm>>;
}

export const formInterventionContext = React.createContext<any>({});

export const useFormIntervention = (): UseFormIntervention => {
    return useContext(formInterventionContext);
};

export const FormInterventionProvider = ({ children }: React.PropsWithChildren) => {
    const auth = useProvideFormIntervention();
    return <formInterventionContext.Provider value={auth}>{children}</formInterventionContext.Provider>;
};

export const initFormIntervention: IVaccinationInterventionForm = {
    type: null,
    generalInformation: {
        workshopId: "",
        complianceType: null,
        complianceComment: null,
    },
    animalInformation: {
        injectionType: null,
        vaccineGtinCode: null,
        vaccineBatchUuids: [],
        diluentGtinCode: null,
        diluentBatchUuids: [],
        animalCount: null,
        doseCount: null,
        comment: null,
        speciesUuid: "",
        subSpeciesUuid: "",
        prescribedDoseCount: null,
        sector: "",
        tier: "",
    },
    id: null,
    animalBatches: [],
    certificateGeneration: false,
};

const useProvideFormIntervention = () => {
    const [form, setForm] = useState<IVaccinationInterventionForm>(initFormIntervention);

    return {
        form,
        setForm,
    };
};
